@font-face {
font-family: "uicons-regular-rounded";
src: url("../../webfonts/uicons-regular-rounded.eot#iefix") format("embedded-opentype"),
url("../../webfonts/uicons-regular-rounded.woff2") format("woff2"),
url("../../webfonts/uicons-regular-rounded.woff") format("woff");
}

    i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
font-family: uicons-regular-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-rr-0:before {
    content: "\f101";
    }
        .fi-rr-1:before {
    content: "\f102";
    }
        .fi-rr-2:before {
    content: "\f103";
    }
        .fi-rr-3:before {
    content: "\f104";
    }
        .fi-rr-4:before {
    content: "\f105";
    }
        .fi-rr-5:before {
    content: "\f106";
    }
        .fi-rr-6:before {
    content: "\f107";
    }
        .fi-rr-7:before {
    content: "\f108";
    }
        .fi-rr-8:before {
    content: "\f109";
    }
        .fi-rr-9:before {
    content: "\f10a";
    }
        .fi-rr-a:before {
    content: "\f10b";
    }
        .fi-rr-acorn:before {
    content: "\f10c";
    }
        .fi-rr-ad:before {
    content: "\f10d";
    }
        .fi-rr-add-document:before {
    content: "\f10e";
    }
        .fi-rr-add-folder:before {
    content: "\f10f";
    }
        .fi-rr-add:before {
    content: "\f110";
    }
        .fi-rr-address-book:before {
    content: "\f111";
    }
        .fi-rr-address-card:before {
    content: "\f112";
    }
        .fi-rr-air-conditioner:before {
    content: "\f113";
    }
        .fi-rr-air-freshener:before {
    content: "\f114";
    }
        .fi-rr-alarm-clock:before {
    content: "\f115";
    }
        .fi-rr-alarm-exclamation:before {
    content: "\f116";
    }
        .fi-rr-alarm-plus:before {
    content: "\f117";
    }
        .fi-rr-alarm-snooze:before {
    content: "\f118";
    }
        .fi-rr-album-circle-plus:before {
    content: "\f119";
    }
        .fi-rr-album-circle-user:before {
    content: "\f11a";
    }
        .fi-rr-album-collection:before {
    content: "\f11b";
    }
        .fi-rr-album:before {
    content: "\f11c";
    }
        .fi-rr-align-center:before {
    content: "\f11d";
    }
        .fi-rr-align-justify:before {
    content: "\f11e";
    }
        .fi-rr-align-left:before {
    content: "\f11f";
    }
        .fi-rr-ambulance:before {
    content: "\f120";
    }
        .fi-rr-angle-circle-down:before {
    content: "\f121";
    }
        .fi-rr-angle-circle-left:before {
    content: "\f122";
    }
        .fi-rr-angle-circle-right:before {
    content: "\f123";
    }
        .fi-rr-angle-circle-up:before {
    content: "\f124";
    }
        .fi-rr-angle-double-left:before {
    content: "\f125";
    }
        .fi-rr-angle-double-right:before {
    content: "\f126";
    }
        .fi-rr-angle-double-small-down:before {
    content: "\f127";
    }
        .fi-rr-angle-double-small-left:before {
    content: "\f128";
    }
        .fi-rr-angle-double-small-right:before {
    content: "\f129";
    }
        .fi-rr-angle-double-small-up:before {
    content: "\f12a";
    }
        .fi-rr-angle-down:before {
    content: "\f12b";
    }
        .fi-rr-angle-left:before {
    content: "\f12c";
    }
        .fi-rr-angle-right:before {
    content: "\f12d";
    }
        .fi-rr-angle-small-down:before {
    content: "\f12e";
    }
        .fi-rr-angle-small-left:before {
    content: "\f12f";
    }
        .fi-rr-angle-small-right:before {
    content: "\f130";
    }
        .fi-rr-angle-small-up:before {
    content: "\f131";
    }
        .fi-rr-angle-square-down:before {
    content: "\f132";
    }
        .fi-rr-angle-square-left:before {
    content: "\f133";
    }
        .fi-rr-angle-square-right:before {
    content: "\f134";
    }
        .fi-rr-angle-square-up:before {
    content: "\f135";
    }
        .fi-rr-angle-up:before {
    content: "\f136";
    }
        .fi-rr-angry:before {
    content: "\f137";
    }
        .fi-rr-animated-icon:before {
    content: "\f138";
    }
        .fi-rr-apartment:before {
    content: "\f139";
    }
        .fi-rr-api:before {
    content: "\f13a";
    }
        .fi-rr-apple-crate:before {
    content: "\f13b";
    }
        .fi-rr-apple-whole:before {
    content: "\f13c";
    }
        .fi-rr-apps-add:before {
    content: "\f13d";
    }
        .fi-rr-apps-delete:before {
    content: "\f13e";
    }
        .fi-rr-apps-sort:before {
    content: "\f13f";
    }
        .fi-rr-apps:before {
    content: "\f140";
    }
        .fi-rr-archive:before {
    content: "\f141";
    }
        .fi-rr-archway:before {
    content: "\f142";
    }
        .fi-rr-arrow-alt-circle-down:before {
    content: "\f143";
    }
        .fi-rr-arrow-alt-circle-left:before {
    content: "\f144";
    }
        .fi-rr-arrow-alt-circle-right:before {
    content: "\f145";
    }
        .fi-rr-arrow-alt-circle-up:before {
    content: "\f146";
    }
        .fi-rr-arrow-alt-down:before {
    content: "\f147";
    }
        .fi-rr-arrow-alt-from-bottom:before {
    content: "\f148";
    }
        .fi-rr-arrow-alt-from-left:before {
    content: "\f149";
    }
        .fi-rr-arrow-alt-from-right:before {
    content: "\f14a";
    }
        .fi-rr-arrow-alt-from-top:before {
    content: "\f14b";
    }
        .fi-rr-arrow-alt-left:before {
    content: "\f14c";
    }
        .fi-rr-arrow-alt-right:before {
    content: "\f14d";
    }
        .fi-rr-arrow-alt-square-down:before {
    content: "\f14e";
    }
        .fi-rr-arrow-alt-square-left:before {
    content: "\f14f";
    }
        .fi-rr-arrow-alt-square-right:before {
    content: "\f150";
    }
        .fi-rr-arrow-alt-square-up:before {
    content: "\f151";
    }
        .fi-rr-arrow-alt-to-bottom:before {
    content: "\f152";
    }
        .fi-rr-arrow-alt-to-left:before {
    content: "\f153";
    }
        .fi-rr-arrow-alt-to-right:before {
    content: "\f154";
    }
        .fi-rr-arrow-alt-to-top:before {
    content: "\f155";
    }
        .fi-rr-arrow-alt-up:before {
    content: "\f156";
    }
        .fi-rr-arrow-circle-down:before {
    content: "\f157";
    }
        .fi-rr-arrow-circle-left:before {
    content: "\f158";
    }
        .fi-rr-arrow-circle-right:before {
    content: "\f159";
    }
        .fi-rr-arrow-circle-up:before {
    content: "\f15a";
    }
        .fi-rr-arrow-down-from-dotted-line:before {
    content: "\f15b";
    }
        .fi-rr-arrow-down-left:before {
    content: "\f15c";
    }
        .fi-rr-arrow-down-small-big:before {
    content: "\f15d";
    }
        .fi-rr-arrow-down-to-dotted-line:before {
    content: "\f15e";
    }
        .fi-rr-arrow-down-to-square:before {
    content: "\f15f";
    }
        .fi-rr-arrow-down-triangle-square:before {
    content: "\f160";
    }
        .fi-rr-arrow-down:before {
    content: "\f161";
    }
        .fi-rr-arrow-from-bottom:before {
    content: "\f162";
    }
        .fi-rr-arrow-from-left:before {
    content: "\f163";
    }
        .fi-rr-arrow-from-right:before {
    content: "\f164";
    }
        .fi-rr-arrow-from-top:before {
    content: "\f165";
    }
        .fi-rr-arrow-left-from-line:before {
    content: "\f166";
    }
        .fi-rr-arrow-left:before {
    content: "\f167";
    }
        .fi-rr-arrow-right-to-bracket:before {
    content: "\f168";
    }
        .fi-rr-arrow-right:before {
    content: "\f169";
    }
        .fi-rr-arrow-small-down:before {
    content: "\f16a";
    }
        .fi-rr-arrow-small-left:before {
    content: "\f16b";
    }
        .fi-rr-arrow-small-right:before {
    content: "\f16c";
    }
        .fi-rr-arrow-small-up:before {
    content: "\f16d";
    }
        .fi-rr-arrow-square-down:before {
    content: "\f16e";
    }
        .fi-rr-arrow-square-left:before {
    content: "\f16f";
    }
        .fi-rr-arrow-square-right:before {
    content: "\f170";
    }
        .fi-rr-arrow-square-up:before {
    content: "\f171";
    }
        .fi-rr-arrow-to-bottom:before {
    content: "\f172";
    }
        .fi-rr-arrow-to-left:before {
    content: "\f173";
    }
        .fi-rr-arrow-to-right:before {
    content: "\f174";
    }
        .fi-rr-arrow-to-top:before {
    content: "\f175";
    }
        .fi-rr-arrow-trend-down:before {
    content: "\f176";
    }
        .fi-rr-arrow-trend-up:before {
    content: "\f177";
    }
        .fi-rr-arrow-turn-down-left:before {
    content: "\f178";
    }
        .fi-rr-arrow-turn-down-right:before {
    content: "\f179";
    }
        .fi-rr-arrow-up-from-dotted-line:before {
    content: "\f17a";
    }
        .fi-rr-arrow-up-from-square:before {
    content: "\f17b";
    }
        .fi-rr-arrow-up-left-from-circle:before {
    content: "\f17c";
    }
        .fi-rr-arrow-up-left:before {
    content: "\f17d";
    }
        .fi-rr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f17e";
    }
        .fi-rr-arrow-up-right-from-square:before {
    content: "\f17f";
    }
        .fi-rr-arrow-up-right:before {
    content: "\f180";
    }
        .fi-rr-arrow-up-small-big:before {
    content: "\f181";
    }
        .fi-rr-arrow-up-square-triangle:before {
    content: "\f182";
    }
        .fi-rr-arrow-up-to-dotted-line:before {
    content: "\f183";
    }
        .fi-rr-arrow-up:before {
    content: "\f184";
    }
        .fi-rr-arrows-alt-h:before {
    content: "\f185";
    }
        .fi-rr-arrows-alt-v:before {
    content: "\f186";
    }
        .fi-rr-arrows-alt:before {
    content: "\f187";
    }
        .fi-rr-arrows-cross:before {
    content: "\f188";
    }
        .fi-rr-arrows-from-dotted-line:before {
    content: "\f189";
    }
        .fi-rr-arrows-from-line:before {
    content: "\f18a";
    }
        .fi-rr-arrows-h-copy:before {
    content: "\f18b";
    }
        .fi-rr-arrows-h:before {
    content: "\f18c";
    }
        .fi-rr-arrows-repeat-1:before {
    content: "\f18d";
    }
        .fi-rr-arrows-repeat:before {
    content: "\f18e";
    }
        .fi-rr-arrows-retweet:before {
    content: "\f18f";
    }
        .fi-rr-arrows-to-dotted-line:before {
    content: "\f190";
    }
        .fi-rr-arrows-to-line:before {
    content: "\f191";
    }
        .fi-rr-arrows:before {
    content: "\f192";
    }
        .fi-rr-assept-document:before {
    content: "\f193";
    }
        .fi-rr-assistive-listening-systems:before {
    content: "\f194";
    }
        .fi-rr-asterik:before {
    content: "\f195";
    }
        .fi-rr-at:before {
    content: "\f196";
    }
        .fi-rr-attribution-pen:before {
    content: "\f197";
    }
        .fi-rr-attribution-pencil:before {
    content: "\f198";
    }
        .fi-rr-aubergine:before {
    content: "\f199";
    }
        .fi-rr-audio-description-slash:before {
    content: "\f19a";
    }
        .fi-rr-austral-sign:before {
    content: "\f19b";
    }
        .fi-rr-avocado:before {
    content: "\f19c";
    }
        .fi-rr-b:before {
    content: "\f19d";
    }
        .fi-rr-baby-carriage:before {
    content: "\f19e";
    }
        .fi-rr-baby:before {
    content: "\f19f";
    }
        .fi-rr-background:before {
    content: "\f1a0";
    }
        .fi-rr-backpack:before {
    content: "\f1a1";
    }
        .fi-rr-bacon:before {
    content: "\f1a2";
    }
        .fi-rr-bacteria:before {
    content: "\f1a3";
    }
        .fi-rr-bacterium:before {
    content: "\f1a4";
    }
        .fi-rr-badge-check:before {
    content: "\f1a5";
    }
        .fi-rr-badge-dollar:before {
    content: "\f1a6";
    }
        .fi-rr-badge-percent:before {
    content: "\f1a7";
    }
        .fi-rr-badge-sheriff:before {
    content: "\f1a8";
    }
        .fi-rr-badge:before {
    content: "\f1a9";
    }
        .fi-rr-bags-shopping:before {
    content: "\f1aa";
    }
        .fi-rr-bahai:before {
    content: "\f1ab";
    }
        .fi-rr-baht-sign:before {
    content: "\f1ac";
    }
        .fi-rr-balance-scale-left:before {
    content: "\f1ad";
    }
        .fi-rr-balance-scale-right:before {
    content: "\f1ae";
    }
        .fi-rr-ball:before {
    content: "\f1af";
    }
        .fi-rr-balloons:before {
    content: "\f1b0";
    }
        .fi-rr-ballot-check:before {
    content: "\f1b1";
    }
        .fi-rr-ballot:before {
    content: "\f1b2";
    }
        .fi-rr-ban-bug:before {
    content: "\f1b3";
    }
        .fi-rr-ban:before {
    content: "\f1b4";
    }
        .fi-rr-band-aid:before {
    content: "\f1b5";
    }
        .fi-rr-bangladeshi-taka-sign:before {
    content: "\f1b6";
    }
        .fi-rr-bank:before {
    content: "\f1b7";
    }
        .fi-rr-barber-shop:before {
    content: "\f1b8";
    }
        .fi-rr-barcode-read:before {
    content: "\f1b9";
    }
        .fi-rr-barcode-scan:before {
    content: "\f1ba";
    }
        .fi-rr-barcode:before {
    content: "\f1bb";
    }
        .fi-rr-bars-filter:before {
    content: "\f1bc";
    }
        .fi-rr-bars-progress:before {
    content: "\f1bd";
    }
        .fi-rr-bars-sort:before {
    content: "\f1be";
    }
        .fi-rr-bars-staggered:before {
    content: "\f1bf";
    }
        .fi-rr-baseball-alt:before {
    content: "\f1c0";
    }
        .fi-rr-baseball:before {
    content: "\f1c1";
    }
        .fi-rr-basket-shopping-simple:before {
    content: "\f1c2";
    }
        .fi-rr-basket:before {
    content: "\f1c3";
    }
        .fi-rr-basketball:before {
    content: "\f1c4";
    }
        .fi-rr-battery-bolt:before {
    content: "\f1c5";
    }
        .fi-rr-battery-empty:before {
    content: "\f1c6";
    }
        .fi-rr-battery-full:before {
    content: "\f1c7";
    }
        .fi-rr-battery-half:before {
    content: "\f1c8";
    }
        .fi-rr-battery-quarter:before {
    content: "\f1c9";
    }
        .fi-rr-battery-slash:before {
    content: "\f1ca";
    }
        .fi-rr-battery-three-quarters:before {
    content: "\f1cb";
    }
        .fi-rr-beacon:before {
    content: "\f1cc";
    }
        .fi-rr-bed-alt:before {
    content: "\f1cd";
    }
        .fi-rr-bed:before {
    content: "\f1ce";
    }
        .fi-rr-beer:before {
    content: "\f1cf";
    }
        .fi-rr-bell-concierge:before {
    content: "\f1d0";
    }
        .fi-rr-bell-ring:before {
    content: "\f1d1";
    }
        .fi-rr-bell-school:before {
    content: "\f1d2";
    }
        .fi-rr-bell:before {
    content: "\f1d3";
    }
        .fi-rr-bells:before {
    content: "\f1d4";
    }
        .fi-rr-bench-tree:before {
    content: "\f1d5";
    }
        .fi-rr-betamax:before {
    content: "\f1d6";
    }
        .fi-rr-bike:before {
    content: "\f1d7";
    }
        .fi-rr-biking-mountain:before {
    content: "\f1d8";
    }
        .fi-rr-biking:before {
    content: "\f1d9";
    }
        .fi-rr-billiard:before {
    content: "\f1da";
    }
        .fi-rr-binary-circle-check:before {
    content: "\f1db";
    }
        .fi-rr-binary-lock:before {
    content: "\f1dc";
    }
        .fi-rr-binary-slash:before {
    content: "\f1dd";
    }
        .fi-rr-binary:before {
    content: "\f1de";
    }
        .fi-rr-binoculars:before {
    content: "\f1df";
    }
        .fi-rr-biohazard:before {
    content: "\f1e0";
    }
        .fi-rr-bitcoin-sign:before {
    content: "\f1e1";
    }
        .fi-rr-blender-phone:before {
    content: "\f1e2";
    }
        .fi-rr-blinds-open:before {
    content: "\f1e3";
    }
        .fi-rr-blinds-raised:before {
    content: "\f1e4";
    }
        .fi-rr-blinds:before {
    content: "\f1e5";
    }
        .fi-rr-block-brick:before {
    content: "\f1e6";
    }
        .fi-rr-block:before {
    content: "\f1e7";
    }
        .fi-rr-blog-pencil:before {
    content: "\f1e8";
    }
        .fi-rr-blog-text:before {
    content: "\f1e9";
    }
        .fi-rr-bold:before {
    content: "\f1ea";
    }
        .fi-rr-bolt:before {
    content: "\f1eb";
    }
        .fi-rr-bomb:before {
    content: "\f1ec";
    }
        .fi-rr-bone-break:before {
    content: "\f1ed";
    }
        .fi-rr-bone:before {
    content: "\f1ee";
    }
        .fi-rr-bong:before {
    content: "\f1ef";
    }
        .fi-rr-book-alt:before {
    content: "\f1f0";
    }
        .fi-rr-book-arrow-right:before {
    content: "\f1f1";
    }
        .fi-rr-book-arrow-up:before {
    content: "\f1f2";
    }
        .fi-rr-book-atlas:before {
    content: "\f1f3";
    }
        .fi-rr-book-bookmark:before {
    content: "\f1f4";
    }
        .fi-rr-book-circle-arrow-right:before {
    content: "\f1f5";
    }
        .fi-rr-book-circle-arrow-up:before {
    content: "\f1f6";
    }
        .fi-rr-book-font:before {
    content: "\f1f7";
    }
        .fi-rr-book-medical:before {
    content: "\f1f8";
    }
        .fi-rr-book-open-cover:before {
    content: "\f1f9";
    }
        .fi-rr-book-open-reader:before {
    content: "\f1fa";
    }
        .fi-rr-book-quran:before {
    content: "\f1fb";
    }
        .fi-rr-book-section:before {
    content: "\f1fc";
    }
        .fi-rr-book-tanakh:before {
    content: "\f1fd";
    }
        .fi-rr-book-user:before {
    content: "\f1fe";
    }
        .fi-rr-book:before {
    content: "\f1ff";
    }
        .fi-rr-bookmark-slash:before {
    content: "\f200";
    }
        .fi-rr-bookmark:before {
    content: "\f201";
    }
        .fi-rr-books-medical:before {
    content: "\f202";
    }
        .fi-rr-books:before {
    content: "\f203";
    }
        .fi-rr-border-all:before {
    content: "\f204";
    }
        .fi-rr-border-bottom:before {
    content: "\f205";
    }
        .fi-rr-border-center-h:before {
    content: "\f206";
    }
        .fi-rr-border-center-v:before {
    content: "\f207";
    }
        .fi-rr-border-inner:before {
    content: "\f208";
    }
        .fi-rr-border-left:before {
    content: "\f209";
    }
        .fi-rr-border-none:before {
    content: "\f20a";
    }
        .fi-rr-border-outer:before {
    content: "\f20b";
    }
        .fi-rr-border-right:before {
    content: "\f20c";
    }
        .fi-rr-border-style-alt:before {
    content: "\f20d";
    }
        .fi-rr-border-style:before {
    content: "\f20e";
    }
        .fi-rr-border-top:before {
    content: "\f20f";
    }
        .fi-rr-bottle:before {
    content: "\f210";
    }
        .fi-rr-bow-arrow:before {
    content: "\f211";
    }
        .fi-rr-bowling-ball:before {
    content: "\f212";
    }
        .fi-rr-bowling-pins:before {
    content: "\f213";
    }
        .fi-rr-bowling:before {
    content: "\f214";
    }
        .fi-rr-box-alt:before {
    content: "\f215";
    }
        .fi-rr-box-ballot:before {
    content: "\f216";
    }
        .fi-rr-box-check:before {
    content: "\f217";
    }
        .fi-rr-box-dollar:before {
    content: "\f218";
    }
        .fi-rr-box-fragile:before {
    content: "\f219";
    }
        .fi-rr-box-open-full:before {
    content: "\f21a";
    }
        .fi-rr-box-open:before {
    content: "\f21b";
    }
        .fi-rr-box-tissue:before {
    content: "\f21c";
    }
        .fi-rr-box-up:before {
    content: "\f21d";
    }
        .fi-rr-box:before {
    content: "\f21e";
    }
        .fi-rr-boxes:before {
    content: "\f21f";
    }
        .fi-rr-boxing-glove:before {
    content: "\f220";
    }
        .fi-rr-bracket-curly-right:before {
    content: "\f221";
    }
        .fi-rr-bracket-curly:before {
    content: "\f222";
    }
        .fi-rr-bracket-round-right:before {
    content: "\f223";
    }
        .fi-rr-bracket-round:before {
    content: "\f224";
    }
        .fi-rr-bracket-square-right:before {
    content: "\f225";
    }
        .fi-rr-bracket-square:before {
    content: "\f226";
    }
        .fi-rr-brackets-curly:before {
    content: "\f227";
    }
        .fi-rr-brackets-round:before {
    content: "\f228";
    }
        .fi-rr-brackets-square:before {
    content: "\f229";
    }
        .fi-rr-braille:before {
    content: "\f22a";
    }
        .fi-rr-brain-circuit:before {
    content: "\f22b";
    }
        .fi-rr-brain:before {
    content: "\f22c";
    }
        .fi-rr-bread-loaf:before {
    content: "\f22d";
    }
        .fi-rr-bread-slice:before {
    content: "\f22e";
    }
        .fi-rr-bread:before {
    content: "\f22f";
    }
        .fi-rr-briefcase-arrow-right:before {
    content: "\f230";
    }
        .fi-rr-briefcase-blank:before {
    content: "\f231";
    }
        .fi-rr-briefcase:before {
    content: "\f232";
    }
        .fi-rr-brightness-low:before {
    content: "\f233";
    }
        .fi-rr-brightness:before {
    content: "\f234";
    }
        .fi-rr-bring-forward:before {
    content: "\f235";
    }
        .fi-rr-bring-front:before {
    content: "\f236";
    }
        .fi-rr-broccoli:before {
    content: "\f237";
    }
        .fi-rr-broken-image:before {
    content: "\f238";
    }
        .fi-rr-broom:before {
    content: "\f239";
    }
        .fi-rr-browser:before {
    content: "\f23a";
    }
        .fi-rr-browsers:before {
    content: "\f23b";
    }
        .fi-rr-brush:before {
    content: "\f23c";
    }
        .fi-rr-bug-slash:before {
    content: "\f23d";
    }
        .fi-rr-bug:before {
    content: "\f23e";
    }
        .fi-rr-building:before {
    content: "\f23f";
    }
        .fi-rr-bulb:before {
    content: "\f240";
    }
        .fi-rr-bullet:before {
    content: "\f241";
    }
        .fi-rr-bullhorn:before {
    content: "\f242";
    }
        .fi-rr-bullseye-arrow:before {
    content: "\f243";
    }
        .fi-rr-bullseye-pointer:before {
    content: "\f244";
    }
        .fi-rr-bullseye:before {
    content: "\f245";
    }
        .fi-rr-burger-alt:before {
    content: "\f246";
    }
        .fi-rr-burger-fries:before {
    content: "\f247";
    }
        .fi-rr-burger-glass:before {
    content: "\f248";
    }
        .fi-rr-burrito:before {
    content: "\f249";
    }
        .fi-rr-bus-alt:before {
    content: "\f24a";
    }
        .fi-rr-bus:before {
    content: "\f24b";
    }
        .fi-rr-business-time:before {
    content: "\f24c";
    }
        .fi-rr-butterfly:before {
    content: "\f24d";
    }
        .fi-rr-c:before {
    content: "\f24e";
    }
        .fi-rr-cabin:before {
    content: "\f24f";
    }
        .fi-rr-cake-birthday:before {
    content: "\f250";
    }
        .fi-rr-cake-wedding:before {
    content: "\f251";
    }
        .fi-rr-calculator:before {
    content: "\f252";
    }
        .fi-rr-calendar-arrow-down:before {
    content: "\f253";
    }
        .fi-rr-calendar-arrow-up:before {
    content: "\f254";
    }
        .fi-rr-calendar-check:before {
    content: "\f255";
    }
        .fi-rr-calendar-clock:before {
    content: "\f256";
    }
        .fi-rr-calendar-day:before {
    content: "\f257";
    }
        .fi-rr-calendar-days:before {
    content: "\f258";
    }
        .fi-rr-calendar-exclamation:before {
    content: "\f259";
    }
        .fi-rr-calendar-image:before {
    content: "\f25a";
    }
        .fi-rr-calendar-lines-pen:before {
    content: "\f25b";
    }
        .fi-rr-calendar-lines:before {
    content: "\f25c";
    }
        .fi-rr-calendar-minus:before {
    content: "\f25d";
    }
        .fi-rr-calendar-pen:before {
    content: "\f25e";
    }
        .fi-rr-calendar-plus:before {
    content: "\f25f";
    }
        .fi-rr-calendar-star:before {
    content: "\f260";
    }
        .fi-rr-calendar-week:before {
    content: "\f261";
    }
        .fi-rr-calendar-xmark:before {
    content: "\f262";
    }
        .fi-rr-calendar:before {
    content: "\f263";
    }
        .fi-rr-calendars:before {
    content: "\f264";
    }
        .fi-rr-call-history:before {
    content: "\f265";
    }
        .fi-rr-call-incoming:before {
    content: "\f266";
    }
        .fi-rr-call-missed:before {
    content: "\f267";
    }
        .fi-rr-call-outgoing:before {
    content: "\f268";
    }
        .fi-rr-camcorder:before {
    content: "\f269";
    }
        .fi-rr-camera:before {
    content: "\f26a";
    }
        .fi-rr-camping:before {
    content: "\f26b";
    }
        .fi-rr-candy-alt:before {
    content: "\f26c";
    }
        .fi-rr-candy-cane:before {
    content: "\f26d";
    }
        .fi-rr-candy-corn:before {
    content: "\f26e";
    }
        .fi-rr-candy:before {
    content: "\f26f";
    }
        .fi-rr-Cannabis:before {
    content: "\f270";
    }
        .fi-rr-canned-food:before {
    content: "\f271";
    }
        .fi-rr-capsules:before {
    content: "\f272";
    }
        .fi-rr-car-alt:before {
    content: "\f273";
    }
        .fi-rr-car-battery:before {
    content: "\f274";
    }
        .fi-rr-car-bolt:before {
    content: "\f275";
    }
        .fi-rr-car-building:before {
    content: "\f276";
    }
        .fi-rr-car-bump:before {
    content: "\f277";
    }
        .fi-rr-car-bus:before {
    content: "\f278";
    }
        .fi-rr-car-circle-bolt:before {
    content: "\f279";
    }
        .fi-rr-car-crash:before {
    content: "\f27a";
    }
        .fi-rr-car-garage:before {
    content: "\f27b";
    }
        .fi-rr-car-mechanic:before {
    content: "\f27c";
    }
        .fi-rr-car-side-bolt:before {
    content: "\f27d";
    }
        .fi-rr-car-side:before {
    content: "\f27e";
    }
        .fi-rr-car-tilt:before {
    content: "\f27f";
    }
        .fi-rr-car-wash:before {
    content: "\f280";
    }
        .fi-rr-car:before {
    content: "\f281";
    }
        .fi-rr-caravan-alt:before {
    content: "\f282";
    }
        .fi-rr-caravan:before {
    content: "\f283";
    }
        .fi-rr-caret-circle-down:before {
    content: "\f284";
    }
        .fi-rr-caret-circle-right:before {
    content: "\f285";
    }
        .fi-rr-caret-circle-up:before {
    content: "\f286";
    }
        .fi-rr-caret-down:before {
    content: "\f287";
    }
        .fi-rr-caret-left:before {
    content: "\f288";
    }
        .fi-rr-caret-quare-up:before {
    content: "\f289";
    }
        .fi-rr-caret-right:before {
    content: "\f28a";
    }
        .fi-rr-caret-square-down:before {
    content: "\f28b";
    }
        .fi-rr-caret-square-left_1:before {
    content: "\f28c";
    }
        .fi-rr-caret-square-left:before {
    content: "\f28d";
    }
        .fi-rr-caret-square-right:before {
    content: "\f28e";
    }
        .fi-rr-caret-up:before {
    content: "\f28f";
    }
        .fi-rr-carrot:before {
    content: "\f290";
    }
        .fi-rr-cars:before {
    content: "\f291";
    }
        .fi-rr-cart-arrow-down:before {
    content: "\f292";
    }
        .fi-rr-cart-minus:before {
    content: "\f293";
    }
        .fi-rr-cart-shopping-fast:before {
    content: "\f294";
    }
        .fi-rr-cash-register:before {
    content: "\f295";
    }
        .fi-rr-cassette-tape:before {
    content: "\f296";
    }
        .fi-rr-cassette-vhs:before {
    content: "\f297";
    }
        .fi-rr-castle:before {
    content: "\f298";
    }
        .fi-rr-cedi-sign:before {
    content: "\f299";
    }
        .fi-rr-cent-sign:before {
    content: "\f29a";
    }
        .fi-rr-chair-office:before {
    content: "\f29b";
    }
        .fi-rr-chair:before {
    content: "\f29c";
    }
        .fi-rr-charging-station:before {
    content: "\f29d";
    }
        .fi-rr-chart-area:before {
    content: "\f29e";
    }
        .fi-rr-chart-bullet:before {
    content: "\f29f";
    }
        .fi-rr-chart-candlestick:before {
    content: "\f2a0";
    }
        .fi-rr-chart-connected:before {
    content: "\f2a1";
    }
        .fi-rr-chart-gantt:before {
    content: "\f2a2";
    }
        .fi-rr-chart-histogram:before {
    content: "\f2a3";
    }
        .fi-rr-chart-line-up:before {
    content: "\f2a4";
    }
        .fi-rr-chart-mixed:before {
    content: "\f2a5";
    }
        .fi-rr-chart-network:before {
    content: "\f2a6";
    }
        .fi-rr-chart-pie-alt:before {
    content: "\f2a7";
    }
        .fi-rr-chart-pie:before {
    content: "\f2a8";
    }
        .fi-rr-chart-pyramid:before {
    content: "\f2a9";
    }
        .fi-rr-chart-radar:before {
    content: "\f2aa";
    }
        .fi-rr-chart-scatter-3d:before {
    content: "\f2ab";
    }
        .fi-rr-chart-scatter-bubble:before {
    content: "\f2ac";
    }
        .fi-rr-chart-scatter:before {
    content: "\f2ad";
    }
        .fi-rr-chart-set-theory:before {
    content: "\f2ae";
    }
        .fi-rr-chart-simple-horizontal:before {
    content: "\f2af";
    }
        .fi-rr-chart-simple:before {
    content: "\f2b0";
    }
        .fi-rr-chart-tree-map:before {
    content: "\f2b1";
    }
        .fi-rr-chart-tree:before {
    content: "\f2b2";
    }
        .fi-rr-chart-user:before {
    content: "\f2b3";
    }
        .fi-rr-chart-waterfall:before {
    content: "\f2b4";
    }
        .fi-rr-chat-arrow-down:before {
    content: "\f2b5";
    }
        .fi-rr-chat-arrow-grow:before {
    content: "\f2b6";
    }
        .fi-rr-check:before {
    content: "\f2b7";
    }
        .fi-rr-checkbox:before {
    content: "\f2b8";
    }
        .fi-rr-cheese-alt:before {
    content: "\f2b9";
    }
        .fi-rr-cheese:before {
    content: "\f2ba";
    }
        .fi-rr-cherry:before {
    content: "\f2bb";
    }
        .fi-rr-chess-bishop:before {
    content: "\f2bc";
    }
        .fi-rr-chess-board:before {
    content: "\f2bd";
    }
        .fi-rr-chess-clock-alt:before {
    content: "\f2be";
    }
        .fi-rr-chess-clock:before {
    content: "\f2bf";
    }
        .fi-rr-chess-king-alt:before {
    content: "\f2c0";
    }
        .fi-rr-chess-king:before {
    content: "\f2c1";
    }
        .fi-rr-chess-knight-alt:before {
    content: "\f2c2";
    }
        .fi-rr-chess-knight:before {
    content: "\f2c3";
    }
        .fi-rr-chess-pawn-alt:before {
    content: "\f2c4";
    }
        .fi-rr-chess-piece:before {
    content: "\f2c5";
    }
        .fi-rr-chess-queen-alt:before {
    content: "\f2c6";
    }
        .fi-rr-chess-queen:before {
    content: "\f2c7";
    }
        .fi-rr-chess-rook-alt:before {
    content: "\f2c8";
    }
        .fi-rr-chess-rook:before {
    content: "\f2c9";
    }
        .fi-rr-chess:before {
    content: "\f2ca";
    }
        .fi-rr-chevron-double-down:before {
    content: "\f2cb";
    }
        .fi-rr-chevron-double-up:before {
    content: "\f2cc";
    }
        .fi-rr-child-head:before {
    content: "\f2cd";
    }
        .fi-rr-chocolate:before {
    content: "\f2ce";
    }
        .fi-rr-circle-0:before {
    content: "\f2cf";
    }
        .fi-rr-circle-1:before {
    content: "\f2d0";
    }
        .fi-rr-circle-2:before {
    content: "\f2d1";
    }
        .fi-rr-circle-3:before {
    content: "\f2d2";
    }
        .fi-rr-circle-4:before {
    content: "\f2d3";
    }
        .fi-rr-circle-5:before {
    content: "\f2d4";
    }
        .fi-rr-circle-6:before {
    content: "\f2d5";
    }
        .fi-rr-circle-7:before {
    content: "\f2d6";
    }
        .fi-rr-circle-8:before {
    content: "\f2d7";
    }
        .fi-rr-circle-9:before {
    content: "\f2d8";
    }
        .fi-rr-circle-bolt:before {
    content: "\f2d9";
    }
        .fi-rr-circle-book-open:before {
    content: "\f2da";
    }
        .fi-rr-circle-bookmark:before {
    content: "\f2db";
    }
        .fi-rr-circle-calendar:before {
    content: "\f2dc";
    }
        .fi-rr-circle-dashed:before {
    content: "\f2dd";
    }
        .fi-rr-circle-envelope:before {
    content: "\f2de";
    }
        .fi-rr-circle-half-stroke:before {
    content: "\f2df";
    }
        .fi-rr-circle-half:before {
    content: "\f2e0";
    }
        .fi-rr-circle-heart:before {
    content: "\f2e1";
    }
        .fi-rr-circle-phone-flip:before {
    content: "\f2e2";
    }
        .fi-rr-circle-phone-hangup:before {
    content: "\f2e3";
    }
        .fi-rr-circle-phone:before {
    content: "\f2e4";
    }
        .fi-rr-circle-quarter:before {
    content: "\f2e5";
    }
        .fi-rr-circle-small:before {
    content: "\f2e6";
    }
        .fi-rr-circle-star:before {
    content: "\f2e7";
    }
        .fi-rr-circle-three-quarters:before {
    content: "\f2e8";
    }
        .fi-rr-circle-user:before {
    content: "\f2e9";
    }
        .fi-rr-circle:before {
    content: "\f2ea";
    }
        .fi-rr-city:before {
    content: "\f2eb";
    }
        .fi-rr-clip:before {
    content: "\f2ec";
    }
        .fi-rr-clipboard-list-check:before {
    content: "\f2ed";
    }
        .fi-rr-clipboard-list:before {
    content: "\f2ee";
    }
        .fi-rr-clipboard-prescription:before {
    content: "\f2ef";
    }
        .fi-rr-clipboard-user:before {
    content: "\f2f0";
    }
        .fi-rr-clock-eight-thirty:before {
    content: "\f2f1";
    }
        .fi-rr-clock-eleven-thirty:before {
    content: "\f2f2";
    }
        .fi-rr-clock-eleven:before {
    content: "\f2f3";
    }
        .fi-rr-clock-five-thirty:before {
    content: "\f2f4";
    }
        .fi-rr-clock-five:before {
    content: "\f2f5";
    }
        .fi-rr-clock-four-thirty:before {
    content: "\f2f6";
    }
        .fi-rr-clock-nine-thirty:before {
    content: "\f2f7";
    }
        .fi-rr-clock-nine:before {
    content: "\f2f8";
    }
        .fi-rr-clock-one-thirty:before {
    content: "\f2f9";
    }
        .fi-rr-clock-one:before {
    content: "\f2fa";
    }
        .fi-rr-clock-seven-thirty:before {
    content: "\f2fb";
    }
        .fi-rr-clock-seven:before {
    content: "\f2fc";
    }
        .fi-rr-clock-six-thirty:before {
    content: "\f2fd";
    }
        .fi-rr-clock-six:before {
    content: "\f2fe";
    }
        .fi-rr-clock-ten-thirty:before {
    content: "\f2ff";
    }
        .fi-rr-clock-ten:before {
    content: "\f300";
    }
        .fi-rr-clock-three-thirty:before {
    content: "\f301";
    }
        .fi-rr-clock-three:before {
    content: "\f302";
    }
        .fi-rr-clock-twelve-thirty:before {
    content: "\f303";
    }
        .fi-rr-clock-twelve:before {
    content: "\f304";
    }
        .fi-rr-clock-two-thirty:before {
    content: "\f305";
    }
        .fi-rr-clock-two:before {
    content: "\f306";
    }
        .fi-rr-clock:before {
    content: "\f307";
    }
        .fi-rr-closed-captioning-slash:before {
    content: "\f308";
    }
        .fi-rr-cloud-check:before {
    content: "\f309";
    }
        .fi-rr-cloud-disabled:before {
    content: "\f30a";
    }
        .fi-rr-cloud-download-alt:before {
    content: "\f30b";
    }
        .fi-rr-cloud-download:before {
    content: "\f30c";
    }
        .fi-rr-cloud-drizzle:before {
    content: "\f30d";
    }
        .fi-rr-cloud-hail-mixed:before {
    content: "\f30e";
    }
        .fi-rr-cloud-hail:before {
    content: "\f30f";
    }
        .fi-rr-cloud-moon-rain:before {
    content: "\f310";
    }
        .fi-rr-cloud-moon:before {
    content: "\f311";
    }
        .fi-rr-cloud-rain:before {
    content: "\f312";
    }
        .fi-rr-cloud-rainbow:before {
    content: "\f313";
    }
        .fi-rr-cloud-share:before {
    content: "\f314";
    }
        .fi-rr-cloud-showers-heavy:before {
    content: "\f315";
    }
        .fi-rr-cloud-showers:before {
    content: "\f316";
    }
        .fi-rr-cloud-sleet:before {
    content: "\f317";
    }
        .fi-rr-cloud-snow:before {
    content: "\f318";
    }
        .fi-rr-cloud-sun-rain:before {
    content: "\f319";
    }
        .fi-rr-cloud-sun:before {
    content: "\f31a";
    }
        .fi-rr-cloud-upload-alt:before {
    content: "\f31b";
    }
        .fi-rr-cloud-upload:before {
    content: "\f31c";
    }
        .fi-rr-cloud:before {
    content: "\f31d";
    }
        .fi-rr-clouds-moon:before {
    content: "\f31e";
    }
        .fi-rr-clouds-sun:before {
    content: "\f31f";
    }
        .fi-rr-clouds:before {
    content: "\f320";
    }
        .fi-rr-club:before {
    content: "\f321";
    }
        .fi-rr-cocktail-alt:before {
    content: "\f322";
    }
        .fi-rr-cocktail:before {
    content: "\f323";
    }
        .fi-rr-code-branch:before {
    content: "\f324";
    }
        .fi-rr-code-commit:before {
    content: "\f325";
    }
        .fi-rr-code-compare:before {
    content: "\f326";
    }
        .fi-rr-code-fork:before {
    content: "\f327";
    }
        .fi-rr-code-merge:before {
    content: "\f328";
    }
        .fi-rr-code-pull-request-closed:before {
    content: "\f329";
    }
        .fi-rr-code-pull-request-draft:before {
    content: "\f32a";
    }
        .fi-rr-code-pull-request:before {
    content: "\f32b";
    }
        .fi-rr-code-simple:before {
    content: "\f32c";
    }
        .fi-rr-coffee-pot:before {
    content: "\f32d";
    }
        .fi-rr-coffee:before {
    content: "\f32e";
    }
        .fi-rr-coin:before {
    content: "\f32f";
    }
        .fi-rr-coins:before {
    content: "\f330";
    }
        .fi-rr-colon-sign:before {
    content: "\f331";
    }
        .fi-rr-comet:before {
    content: "\f332";
    }
        .fi-rr-comment-alt-check:before {
    content: "\f333";
    }
        .fi-rr-comment-alt-dots:before {
    content: "\f334";
    }
        .fi-rr-comment-alt-edit:before {
    content: "\f335";
    }
        .fi-rr-comment-alt-medical:before {
    content: "\f336";
    }
        .fi-rr-comment-alt-middle-top:before {
    content: "\f337";
    }
        .fi-rr-comment-alt-middle:before {
    content: "\f338";
    }
        .fi-rr-comment-alt-minus:before {
    content: "\f339";
    }
        .fi-rr-comment-alt:before {
    content: "\f33a";
    }
        .fi-rr-comment-arrow-down:before {
    content: "\f33b";
    }
        .fi-rr-comment-arrow-up-right:before {
    content: "\f33c";
    }
        .fi-rr-comment-arrow-up:before {
    content: "\f33d";
    }
        .fi-rr-comment-check:before {
    content: "\f33e";
    }
        .fi-rr-comment-code:before {
    content: "\f33f";
    }
        .fi-rr-comment-dollar:before {
    content: "\f340";
    }
        .fi-rr-comment-dots:before {
    content: "\f341";
    }
        .fi-rr-comment-exclamation:before {
    content: "\f342";
    }
        .fi-rr-comment-heart:before {
    content: "\f343";
    }
        .fi-rr-comment-image:before {
    content: "\f344";
    }
        .fi-rr-comment-info:before {
    content: "\f345";
    }
        .fi-rr-comment-medical:before {
    content: "\f346";
    }
        .fi-rr-comment-minus:before {
    content: "\f347";
    }
        .fi-rr-comment-pen:before {
    content: "\f348";
    }
        .fi-rr-comment-question:before {
    content: "\f349";
    }
        .fi-rr-comment-quote:before {
    content: "\f34a";
    }
        .fi-rr-comment-slash:before {
    content: "\f34b";
    }
        .fi-rr-comment-smile:before {
    content: "\f34c";
    }
        .fi-rr-comment-sms:before {
    content: "\f34d";
    }
        .fi-rr-comment-text:before {
    content: "\f34e";
    }
        .fi-rr-comment-user:before {
    content: "\f34f";
    }
        .fi-rr-comment-xmark:before {
    content: "\f350";
    }
        .fi-rr-comment:before {
    content: "\f351";
    }
        .fi-rr-comments-dollar:before {
    content: "\f352";
    }
        .fi-rr-comments-question-check:before {
    content: "\f353";
    }
        .fi-rr-comments-question:before {
    content: "\f354";
    }
        .fi-rr-comments:before {
    content: "\f355";
    }
        .fi-rr-compress-alt:before {
    content: "\f356";
    }
        .fi-rr-compress:before {
    content: "\f357";
    }
        .fi-rr-computer:before {
    content: "\f358";
    }
        .fi-rr-concierge-bell:before {
    content: "\f359";
    }
        .fi-rr-confetti:before {
    content: "\f35a";
    }
        .fi-rr-cookie:before {
    content: "\f35b";
    }
        .fi-rr-copy-alt:before {
    content: "\f35c";
    }
        .fi-rr-copy-image:before {
    content: "\f35d";
    }
        .fi-rr-copy:before {
    content: "\f35e";
    }
        .fi-rr-copyright:before {
    content: "\f35f";
    }
        .fi-rr-corn:before {
    content: "\f360";
    }
        .fi-rr-cow:before {
    content: "\f361";
    }
        .fi-rr-cowbell-more:before {
    content: "\f362";
    }
        .fi-rr-cowbell:before {
    content: "\f363";
    }
        .fi-rr-cream:before {
    content: "\f364";
    }
        .fi-rr-credit-card:before {
    content: "\f365";
    }
        .fi-rr-cricket:before {
    content: "\f366";
    }
        .fi-rr-croissant:before {
    content: "\f367";
    }
        .fi-rr-cross-circle:before {
    content: "\f368";
    }
        .fi-rr-cross-religion:before {
    content: "\f369";
    }
        .fi-rr-cross-small:before {
    content: "\f36a";
    }
        .fi-rr-cross:before {
    content: "\f36b";
    }
        .fi-rr-crown:before {
    content: "\f36c";
    }
        .fi-rr-crutch:before {
    content: "\f36d";
    }
        .fi-rr-crutches:before {
    content: "\f36e";
    }
        .fi-rr-cruzeiro-sign:before {
    content: "\f36f";
    }
        .fi-rr-crystal-ball:before {
    content: "\f370";
    }
        .fi-rr-cube:before {
    content: "\f371";
    }
        .fi-rr-cubes:before {
    content: "\f372";
    }
        .fi-rr-cupcake:before {
    content: "\f373";
    }
        .fi-rr-curling:before {
    content: "\f374";
    }
        .fi-rr-cursor-finger:before {
    content: "\f375";
    }
        .fi-rr-cursor-plus:before {
    content: "\f376";
    }
        .fi-rr-cursor-text-alt:before {
    content: "\f377";
    }
        .fi-rr-cursor-text:before {
    content: "\f378";
    }
        .fi-rr-cursor:before {
    content: "\f379";
    }
        .fi-rr-d:before {
    content: "\f37a";
    }
        .fi-rr-dart:before {
    content: "\f37b";
    }
        .fi-rr-dashboard:before {
    content: "\f37c";
    }
        .fi-rr-data-transfer:before {
    content: "\f37d";
    }
        .fi-rr-database:before {
    content: "\f37e";
    }
        .fi-rr-delete-document:before {
    content: "\f37f";
    }
        .fi-rr-delete-user:before {
    content: "\f380";
    }
        .fi-rr-delete:before {
    content: "\f381";
    }
        .fi-rr-democrat:before {
    content: "\f382";
    }
        .fi-rr-desktop-wallpaper:before {
    content: "\f383";
    }
        .fi-rr-devices:before {
    content: "\f384";
    }
        .fi-rr-dewpoint:before {
    content: "\f385";
    }
        .fi-rr-diagram-cells:before {
    content: "\f386";
    }
        .fi-rr-diagram-lean-canvas:before {
    content: "\f387";
    }
        .fi-rr-diagram-nested:before {
    content: "\f388";
    }
        .fi-rr-diagram-next:before {
    content: "\f389";
    }
        .fi-rr-diagram-predecessor:before {
    content: "\f38a";
    }
        .fi-rr-diagram-previous:before {
    content: "\f38b";
    }
        .fi-rr-diagram-project:before {
    content: "\f38c";
    }
        .fi-rr-diagram-sankey:before {
    content: "\f38d";
    }
        .fi-rr-diagram-subtask:before {
    content: "\f38e";
    }
        .fi-rr-diagram-successor:before {
    content: "\f38f";
    }
        .fi-rr-diagram-venn:before {
    content: "\f390";
    }
        .fi-rr-diamond-turn-right:before {
    content: "\f391";
    }
        .fi-rr-diamond:before {
    content: "\f392";
    }
        .fi-rr-dice-alt:before {
    content: "\f393";
    }
        .fi-rr-dice-d10:before {
    content: "\f394";
    }
        .fi-rr-dice-d12:before {
    content: "\f395";
    }
        .fi-rr-dice-d20:before {
    content: "\f396";
    }
        .fi-rr-dice-d4:before {
    content: "\f397";
    }
        .fi-rr-dice-d6:before {
    content: "\f398";
    }
        .fi-rr-dice-d8:before {
    content: "\f399";
    }
        .fi-rr-dice-four:before {
    content: "\f39a";
    }
        .fi-rr-dice-one:before {
    content: "\f39b";
    }
        .fi-rr-dice-six:before {
    content: "\f39c";
    }
        .fi-rr-dice-three:before {
    content: "\f39d";
    }
        .fi-rr-dice-two:before {
    content: "\f39e";
    }
        .fi-rr-dice:before {
    content: "\f39f";
    }
        .fi-rr-diploma:before {
    content: "\f3a0";
    }
        .fi-rr-disco-ball:before {
    content: "\f3a1";
    }
        .fi-rr-disease:before {
    content: "\f3a2";
    }
        .fi-rr-disk:before {
    content: "\f3a3";
    }
        .fi-rr-display-code:before {
    content: "\f3a4";
    }
        .fi-rr-distribute-spacing-horizontal:before {
    content: "\f3a5";
    }
        .fi-rr-distribute-spacing-vertical:before {
    content: "\f3a6";
    }
        .fi-rr-dizzy:before {
    content: "\f3a7";
    }
        .fi-rr-dna:before {
    content: "\f3a8";
    }
        .fi-rr-doctor:before {
    content: "\f3a9";
    }
        .fi-rr-document-signed:before {
    content: "\f3aa";
    }
        .fi-rr-document:before {
    content: "\f3ab";
    }
        .fi-rr-dollar:before {
    content: "\f3ac";
    }
        .fi-rr-donate:before {
    content: "\f3ad";
    }
        .fi-rr-dong-sign:before {
    content: "\f3ae";
    }
        .fi-rr-door-closed:before {
    content: "\f3af";
    }
        .fi-rr-door-open:before {
    content: "\f3b0";
    }
        .fi-rr-down-left-and-up-right-to-center:before {
    content: "\f3b1";
    }
        .fi-rr-down-left:before {
    content: "\f3b2";
    }
        .fi-rr-down-right:before {
    content: "\f3b3";
    }
        .fi-rr-down-to-line:before {
    content: "\f3b4";
    }
        .fi-rr-down:before {
    content: "\f3b5";
    }
        .fi-rr-download:before {
    content: "\f3b6";
    }
        .fi-rr-drafting-compass:before {
    content: "\f3b7";
    }
        .fi-rr-dreidel:before {
    content: "\f3b8";
    }
        .fi-rr-drink-alt:before {
    content: "\f3b9";
    }
        .fi-rr-drumstick:before {
    content: "\f3ba";
    }
        .fi-rr-dungeon:before {
    content: "\f3bb";
    }
        .fi-rr-duplicate:before {
    content: "\f3bc";
    }
        .fi-rr-e-learning:before {
    content: "\f3bd";
    }
        .fi-rr-e:before {
    content: "\f3be";
    }
        .fi-rr-ear-deaf:before {
    content: "\f3bf";
    }
        .fi-rr-ear:before {
    content: "\f3c0";
    }
        .fi-rr-earnings:before {
    content: "\f3c1";
    }
        .fi-rr-earth-africa:before {
    content: "\f3c2";
    }
        .fi-rr-earth-americas:before {
    content: "\f3c3";
    }
        .fi-rr-earth-asia:before {
    content: "\f3c4";
    }
        .fi-rr-earth-europa:before {
    content: "\f3c5";
    }
        .fi-rr-eclipse-alt:before {
    content: "\f3c6";
    }
        .fi-rr-eclipse:before {
    content: "\f3c7";
    }
        .fi-rr-edit-alt:before {
    content: "\f3c8";
    }
        .fi-rr-edit:before {
    content: "\f3c9";
    }
        .fi-rr-egg-fried:before {
    content: "\f3ca";
    }
        .fi-rr-egg:before {
    content: "\f3cb";
    }
        .fi-rr-elevator:before {
    content: "\f3cc";
    }
        .fi-rr-engine-warning:before {
    content: "\f3cd";
    }
        .fi-rr-enter:before {
    content: "\f3ce";
    }
        .fi-rr-envelope-ban:before {
    content: "\f3cf";
    }
        .fi-rr-envelope-bulk:before {
    content: "\f3d0";
    }
        .fi-rr-envelope-dot:before {
    content: "\f3d1";
    }
        .fi-rr-envelope-download:before {
    content: "\f3d2";
    }
        .fi-rr-envelope-marker:before {
    content: "\f3d3";
    }
        .fi-rr-envelope-open-dollar:before {
    content: "\f3d4";
    }
        .fi-rr-envelope-open-text:before {
    content: "\f3d5";
    }
        .fi-rr-envelope-open:before {
    content: "\f3d6";
    }
        .fi-rr-envelope-plus:before {
    content: "\f3d7";
    }
        .fi-rr-envelope:before {
    content: "\f3d8";
    }
        .fi-rr-envelopes:before {
    content: "\f3d9";
    }
        .fi-rr-equality:before {
    content: "\f3da";
    }
        .fi-rr-escalator:before {
    content: "\f3db";
    }
        .fi-rr-euro:before {
    content: "\f3dc";
    }
        .fi-rr-exchange-alt:before {
    content: "\f3dd";
    }
        .fi-rr-exchange:before {
    content: "\f3de";
    }
        .fi-rr-exclamation:before {
    content: "\f3df";
    }
        .fi-rr-exit:before {
    content: "\f3e0";
    }
        .fi-rr-expand-arrows-alt:before {
    content: "\f3e1";
    }
        .fi-rr-expand-arrows:before {
    content: "\f3e2";
    }
        .fi-rr-expand:before {
    content: "\f3e3";
    }
        .fi-rr-eye-crossed:before {
    content: "\f3e4";
    }
        .fi-rr-eye-dropper:before {
    content: "\f3e5";
    }
        .fi-rr-eye:before {
    content: "\f3e6";
    }
        .fi-rr-eyes:before {
    content: "\f3e7";
    }
        .fi-rr-f:before {
    content: "\f3e8";
    }
        .fi-rr-face-angry-horns:before {
    content: "\f3e9";
    }
        .fi-rr-face-anguished:before {
    content: "\f3ea";
    }
        .fi-rr-face-anxious-sweat:before {
    content: "\f3eb";
    }
        .fi-rr-face-astonished:before {
    content: "\f3ec";
    }
        .fi-rr-face-awesome:before {
    content: "\f3ed";
    }
        .fi-rr-face-beam-hand-over-mouth:before {
    content: "\f3ee";
    }
        .fi-rr-face-confounded:before {
    content: "\f3ef";
    }
        .fi-rr-face-confused:before {
    content: "\f3f0";
    }
        .fi-rr-face-cowboy-hat:before {
    content: "\f3f1";
    }
        .fi-rr-face-disappointed:before {
    content: "\f3f2";
    }
        .fi-rr-face-disguise:before {
    content: "\f3f3";
    }
        .fi-rr-face-downcast-sweat:before {
    content: "\f3f4";
    }
        .fi-rr-face-drooling:before {
    content: "\f3f5";
    }
        .fi-rr-face-explode:before {
    content: "\f3f6";
    }
        .fi-rr-face-expressionless:before {
    content: "\f3f7";
    }
        .fi-rr-face-eyes-xmarks:before {
    content: "\f3f8";
    }
        .fi-rr-face-fearful:before {
    content: "\f3f9";
    }
        .fi-rr-face-glasses:before {
    content: "\f3fa";
    }
        .fi-rr-face-grin-tongue-wink:before {
    content: "\f3fb";
    }
        .fi-rr-face-hand-yawn:before {
    content: "\f3fc";
    }
        .fi-rr-face-head-bandage:before {
    content: "\f3fd";
    }
        .fi-rr-face-hushed:before {
    content: "\f3fe";
    }
        .fi-rr-face-icicles:before {
    content: "\f3ff";
    }
        .fi-rr-face-lying:before {
    content: "\f400";
    }
        .fi-rr-face-mask:before {
    content: "\f401";
    }
        .fi-rr-face-monocle:before {
    content: "\f402";
    }
        .fi-rr-face-nauseated:before {
    content: "\f403";
    }
        .fi-rr-face-nose-steam:before {
    content: "\f404";
    }
        .fi-rr-face-party:before {
    content: "\f405";
    }
        .fi-rr-face-pensive:before {
    content: "\f406";
    }
        .fi-rr-face-persevering:before {
    content: "\f407";
    }
        .fi-rr-face-pleading:before {
    content: "\f408";
    }
        .fi-rr-face-raised-eyebrow:before {
    content: "\f409";
    }
        .fi-rr-face-relieved:before {
    content: "\f40a";
    }
        .fi-rr-face-sad-sweat:before {
    content: "\f40b";
    }
        .fi-rr-face-scream:before {
    content: "\f40c";
    }
        .fi-rr-face-shush:before {
    content: "\f40d";
    }
        .fi-rr-face-sleeping:before {
    content: "\f40e";
    }
        .fi-rr-face-sleepy:before {
    content: "\f40f";
    }
        .fi-rr-face-smile-halo:before {
    content: "\f410";
    }
        .fi-rr-face-smile-hearts:before {
    content: "\f411";
    }
        .fi-rr-face-smile-horns:before {
    content: "\f412";
    }
        .fi-rr-face-smile-tear:before {
    content: "\f413";
    }
        .fi-rr-face-smile-tongue:before {
    content: "\f414";
    }
        .fi-rr-face-smile-upside-down:before {
    content: "\f415";
    }
        .fi-rr-face-smiling-hands:before {
    content: "\f416";
    }
        .fi-rr-face-smirking:before {
    content: "\f417";
    }
        .fi-rr-face-sunglasses:before {
    content: "\f418";
    }
        .fi-rr-face-swear:before {
    content: "\f419";
    }
        .fi-rr-face-thermometer:before {
    content: "\f41a";
    }
        .fi-rr-face-thinking:before {
    content: "\f41b";
    }
        .fi-rr-face-tissue:before {
    content: "\f41c";
    }
        .fi-rr-face-tongue-money:before {
    content: "\f41d";
    }
        .fi-rr-face-tongue-sweat:before {
    content: "\f41e";
    }
        .fi-rr-face-unamused:before {
    content: "\f41f";
    }
        .fi-rr-face-vomit:before {
    content: "\f420";
    }
        .fi-rr-face-weary:before {
    content: "\f421";
    }
        .fi-rr-face-woozy:before {
    content: "\f422";
    }
        .fi-rr-face-worried:before {
    content: "\f423";
    }
        .fi-rr-face-zany:before {
    content: "\f424";
    }
        .fi-rr-face-zipper:before {
    content: "\f425";
    }
        .fi-rr-farm:before {
    content: "\f426";
    }
        .fi-rr-fax:before {
    content: "\f427";
    }
        .fi-rr-feather:before {
    content: "\f428";
    }
        .fi-rr-fence:before {
    content: "\f429";
    }
        .fi-rr-ferris-wheel:before {
    content: "\f42a";
    }
        .fi-rr-fighter-jet:before {
    content: "\f42b";
    }
        .fi-rr-file-ai:before {
    content: "\f42c";
    }
        .fi-rr-file-binary:before {
    content: "\f42d";
    }
        .fi-rr-file-chart-line:before {
    content: "\f42e";
    }
        .fi-rr-file-chart-pie:before {
    content: "\f42f";
    }
        .fi-rr-file-circle-info:before {
    content: "\f430";
    }
        .fi-rr-file-code:before {
    content: "\f431";
    }
        .fi-rr-file-eps:before {
    content: "\f432";
    }
        .fi-rr-file-excel:before {
    content: "\f433";
    }
        .fi-rr-file-export:before {
    content: "\f434";
    }
        .fi-rr-file-invoice-dollar:before {
    content: "\f435";
    }
        .fi-rr-file-invoice:before {
    content: "\f436";
    }
        .fi-rr-file-medical-alt:before {
    content: "\f437";
    }
        .fi-rr-file-medical:before {
    content: "\f438";
    }
        .fi-rr-file-pdf:before {
    content: "\f439";
    }
        .fi-rr-file-powerpoint:before {
    content: "\f43a";
    }
        .fi-rr-file-prescription:before {
    content: "\f43b";
    }
        .fi-rr-file-psd:before {
    content: "\f43c";
    }
        .fi-rr-file-signature:before {
    content: "\f43d";
    }
        .fi-rr-file-spreadsheet:before {
    content: "\f43e";
    }
        .fi-rr-file-video:before {
    content: "\f43f";
    }
        .fi-rr-file-word:before {
    content: "\f440";
    }
        .fi-rr-file:before {
    content: "\f441";
    }
        .fi-rr-files-medical:before {
    content: "\f442";
    }
        .fi-rr-fill:before {
    content: "\f443";
    }
        .fi-rr-film-slash:before {
    content: "\f444";
    }
        .fi-rr-film:before {
    content: "\f445";
    }
        .fi-rr-filter-slash:before {
    content: "\f446";
    }
        .fi-rr-filter:before {
    content: "\f447";
    }
        .fi-rr-filters:before {
    content: "\f448";
    }
        .fi-rr-fingerprint:before {
    content: "\f449";
    }
        .fi-rr-fire-flame-curved:before {
    content: "\f44a";
    }
        .fi-rr-fire-smoke:before {
    content: "\f44b";
    }
        .fi-rr-fish:before {
    content: "\f44c";
    }
        .fi-rr-fishing-rod:before {
    content: "\f44d";
    }
        .fi-rr-flag-alt:before {
    content: "\f44e";
    }
        .fi-rr-flag-checkered:before {
    content: "\f44f";
    }
        .fi-rr-flag-usa:before {
    content: "\f450";
    }
        .fi-rr-flag:before {
    content: "\f451";
    }
        .fi-rr-flame:before {
    content: "\f452";
    }
        .fi-rr-flip-horizontal:before {
    content: "\f453";
    }
        .fi-rr-floppy-disk-circle-arrow-right:before {
    content: "\f454";
    }
        .fi-rr-floppy-disk-circle-xmark:before {
    content: "\f455";
    }
        .fi-rr-florin-sign:before {
    content: "\f456";
    }
        .fi-rr-flower-bouquet:before {
    content: "\f457";
    }
        .fi-rr-flower-tulip:before {
    content: "\f458";
    }
        .fi-rr-flower:before {
    content: "\f459";
    }
        .fi-rr-flushed:before {
    content: "\f45a";
    }
        .fi-rr-fog:before {
    content: "\f45b";
    }
        .fi-rr-folder-download:before {
    content: "\f45c";
    }
        .fi-rr-folder-minus:before {
    content: "\f45d";
    }
        .fi-rr-folder-times:before {
    content: "\f45e";
    }
        .fi-rr-folder-tree:before {
    content: "\f45f";
    }
        .fi-rr-folder-upload:before {
    content: "\f460";
    }
        .fi-rr-folder-xmark:before {
    content: "\f461";
    }
        .fi-rr-folder:before {
    content: "\f462";
    }
        .fi-rr-folders:before {
    content: "\f463";
    }
        .fi-rr-follow-folder:before {
    content: "\f464";
    }
        .fi-rr-followcollection:before {
    content: "\f465";
    }
        .fi-rr-following:before {
    content: "\f466";
    }
        .fi-rr-football:before {
    content: "\f467";
    }
        .fi-rr-fork:before {
    content: "\f468";
    }
        .fi-rr-form:before {
    content: "\f469";
    }
        .fi-rr-fort:before {
    content: "\f46a";
    }
        .fi-rr-forward:before {
    content: "\f46b";
    }
        .fi-rr-fox:before {
    content: "\f46c";
    }
        .fi-rr-franc-sign:before {
    content: "\f46d";
    }
        .fi-rr-french-fries:before {
    content: "\f46e";
    }
        .fi-rr-frown:before {
    content: "\f46f";
    }
        .fi-rr-ftp:before {
    content: "\f470";
    }
        .fi-rr-funnel-dollar:before {
    content: "\f471";
    }
        .fi-rr-g:before {
    content: "\f472";
    }
        .fi-rr-gallery-thumbnails:before {
    content: "\f473";
    }
        .fi-rr-gallery:before {
    content: "\f474";
    }
        .fi-rr-game-board-alt:before {
    content: "\f475";
    }
        .fi-rr-gamepad:before {
    content: "\f476";
    }
        .fi-rr-garage-car:before {
    content: "\f477";
    }
        .fi-rr-garage-open:before {
    content: "\f478";
    }
        .fi-rr-garage:before {
    content: "\f479";
    }
        .fi-rr-garlic:before {
    content: "\f47a";
    }
        .fi-rr-gas-pump-alt:before {
    content: "\f47b";
    }
        .fi-rr-gas-pump-slash:before {
    content: "\f47c";
    }
        .fi-rr-gas-pump:before {
    content: "\f47d";
    }
        .fi-rr-gavel:before {
    content: "\f47e";
    }
        .fi-rr-gears:before {
    content: "\f47f";
    }
        .fi-rr-gem:before {
    content: "\f480";
    }
        .fi-rr-gif:before {
    content: "\f481";
    }
        .fi-rr-gift-card:before {
    content: "\f482";
    }
        .fi-rr-gift:before {
    content: "\f483";
    }
        .fi-rr-gifts:before {
    content: "\f484";
    }
        .fi-rr-gingerbread-man:before {
    content: "\f485";
    }
        .fi-rr-glass-cheers:before {
    content: "\f486";
    }
        .fi-rr-glass:before {
    content: "\f487";
    }
        .fi-rr-glasses:before {
    content: "\f488";
    }
        .fi-rr-globe-alt:before {
    content: "\f489";
    }
        .fi-rr-globe:before {
    content: "\f48a";
    }
        .fi-rr-golf-ball:before {
    content: "\f48b";
    }
        .fi-rr-golf:before {
    content: "\f48c";
    }
        .fi-rr-graduation-cap:before {
    content: "\f48d";
    }
        .fi-rr-grape:before {
    content: "\f48e";
    }
        .fi-rr-graphic-style:before {
    content: "\f48f";
    }
        .fi-rr-graphic-tablet:before {
    content: "\f490";
    }
        .fi-rr-grid-alt:before {
    content: "\f491";
    }
        .fi-rr-grid-dividers:before {
    content: "\f492";
    }
        .fi-rr-grid:before {
    content: "\f493";
    }
        .fi-rr-grill:before {
    content: "\f494";
    }
        .fi-rr-grimace:before {
    content: "\f495";
    }
        .fi-rr-grin-alt:before {
    content: "\f496";
    }
        .fi-rr-grin-beam-sweat:before {
    content: "\f497";
    }
        .fi-rr-grin-beam:before {
    content: "\f498";
    }
        .fi-rr-grin-hearts:before {
    content: "\f499";
    }
        .fi-rr-grin-squint-tears:before {
    content: "\f49a";
    }
        .fi-rr-grin-squint:before {
    content: "\f49b";
    }
        .fi-rr-grin-stars:before {
    content: "\f49c";
    }
        .fi-rr-grin-tears:before {
    content: "\f49d";
    }
        .fi-rr-grin-tongue-squint:before {
    content: "\f49e";
    }
        .fi-rr-grin-tongue-wink:before {
    content: "\f49f";
    }
        .fi-rr-grin-tongue:before {
    content: "\f4a0";
    }
        .fi-rr-grin-wink:before {
    content: "\f4a1";
    }
        .fi-rr-grin:before {
    content: "\f4a2";
    }
        .fi-rr-guarani-sign:before {
    content: "\f4a3";
    }
        .fi-rr-guitar:before {
    content: "\f4a4";
    }
        .fi-rr-gym:before {
    content: "\f4a5";
    }
        .fi-rr-h-square:before {
    content: "\f4a6";
    }
        .fi-rr-h:before {
    content: "\f4a7";
    }
        .fi-rr-hamburger-soda:before {
    content: "\f4a8";
    }
        .fi-rr-hamburger:before {
    content: "\f4a9";
    }
        .fi-rr-hammer-crash:before {
    content: "\f4aa";
    }
        .fi-rr-hammer-war:before {
    content: "\f4ab";
    }
        .fi-rr-hammer:before {
    content: "\f4ac";
    }
        .fi-rr-hand-back-fist:before {
    content: "\f4ad";
    }
        .fi-rr-hand-back-point-down:before {
    content: "\f4ae";
    }
        .fi-rr-hand-back-point-left:before {
    content: "\f4af";
    }
        .fi-rr-hand-back-point-ribbon:before {
    content: "\f4b0";
    }
        .fi-rr-hand-back-point-right:before {
    content: "\f4b1";
    }
        .fi-rr-hand-dots:before {
    content: "\f4b2";
    }
        .fi-rr-hand-fingers-crossed:before {
    content: "\f4b3";
    }
        .fi-rr-hand-heart:before {
    content: "\f4b4";
    }
        .fi-rr-hand-holding-box:before {
    content: "\f4b5";
    }
        .fi-rr-hand-holding-heart:before {
    content: "\f4b6";
    }
        .fi-rr-hand-holding-medical:before {
    content: "\f4b7";
    }
        .fi-rr-hand-holding-seeding:before {
    content: "\f4b8";
    }
        .fi-rr-hand-holding-skull:before {
    content: "\f4b9";
    }
        .fi-rr-hand-holding-usd:before {
    content: "\f4ba";
    }
        .fi-rr-hand-holding-water:before {
    content: "\f4bb";
    }
        .fi-rr-hand-horns:before {
    content: "\f4bc";
    }
        .fi-rr-hand-lizard:before {
    content: "\f4bd";
    }
        .fi-rr-hand-love:before {
    content: "\f4be";
    }
        .fi-rr-hand-peace:before {
    content: "\f4bf";
    }
        .fi-rr-hand-point-ribbon:before {
    content: "\f4c0";
    }
        .fi-rr-hand-wave:before {
    content: "\f4c1";
    }
        .fi-rr-hand:before {
    content: "\f4c2";
    }
        .fi-rr-hands-bubbles:before {
    content: "\f4c3";
    }
        .fi-rr-hands-clapping:before {
    content: "\f4c4";
    }
        .fi-rr-hands-holding-diamond:before {
    content: "\f4c5";
    }
        .fi-rr-hands-holding:before {
    content: "\f4c6";
    }
        .fi-rr-hands-usd:before {
    content: "\f4c7";
    }
        .fi-rr-handshake-angle:before {
    content: "\f4c8";
    }
        .fi-rr-handshake-simple-slash:before {
    content: "\f4c9";
    }
        .fi-rr-handshake:before {
    content: "\f4ca";
    }
        .fi-rr-hastag:before {
    content: "\f4cb";
    }
        .fi-rr-hat-birthday:before {
    content: "\f4cc";
    }
        .fi-rr-hat-chef:before {
    content: "\f4cd";
    }
        .fi-rr-hdd:before {
    content: "\f4ce";
    }
        .fi-rr-head-side-brain:before {
    content: "\f4cf";
    }
        .fi-rr-head-side-cough-slash:before {
    content: "\f4d0";
    }
        .fi-rr-head-side-cough:before {
    content: "\f4d1";
    }
        .fi-rr-head-side-heart:before {
    content: "\f4d2";
    }
        .fi-rr-head-side-mask:before {
    content: "\f4d3";
    }
        .fi-rr-head-side-medical:before {
    content: "\f4d4";
    }
        .fi-rr-head-side-thinking:before {
    content: "\f4d5";
    }
        .fi-rr-head-side-virus:before {
    content: "\f4d6";
    }
        .fi-rr-head-side:before {
    content: "\f4d7";
    }
        .fi-rr-head-vr:before {
    content: "\f4d8";
    }
        .fi-rr-headphones:before {
    content: "\f4d9";
    }
        .fi-rr-headset:before {
    content: "\f4da";
    }
        .fi-rr-heart-arrow:before {
    content: "\f4db";
    }
        .fi-rr-heart-crack:before {
    content: "\f4dc";
    }
        .fi-rr-heart-half-stroke:before {
    content: "\f4dd";
    }
        .fi-rr-heart-half:before {
    content: "\f4de";
    }
        .fi-rr-heart-rate:before {
    content: "\f4df";
    }
        .fi-rr-heart:before {
    content: "\f4e0";
    }
        .fi-rr-heat:before {
    content: "\f4e1";
    }
        .fi-rr-helicopter-side:before {
    content: "\f4e2";
    }
        .fi-rr-hexagon:before {
    content: "\f4e3";
    }
        .fi-rr-highlighter-line:before {
    content: "\f4e4";
    }
        .fi-rr-highlighter:before {
    content: "\f4e5";
    }
        .fi-rr-hiking:before {
    content: "\f4e6";
    }
        .fi-rr-hockey-puck:before {
    content: "\f4e7";
    }
        .fi-rr-hockey-sticks:before {
    content: "\f4e8";
    }
        .fi-rr-home-location-alt:before {
    content: "\f4e9";
    }
        .fi-rr-home-location:before {
    content: "\f4ea";
    }
        .fi-rr-home:before {
    content: "\f4eb";
    }
        .fi-rr-horizontal-rule:before {
    content: "\f4ec";
    }
        .fi-rr-hospital-symbol:before {
    content: "\f4ed";
    }
        .fi-rr-hospital-user:before {
    content: "\f4ee";
    }
        .fi-rr-hospital:before {
    content: "\f4ef";
    }
        .fi-rr-hospitals:before {
    content: "\f4f0";
    }
        .fi-rr-hot-tub:before {
    content: "\f4f1";
    }
        .fi-rr-hotdog:before {
    content: "\f4f2";
    }
        .fi-rr-hotel:before {
    content: "\f4f3";
    }
        .fi-rr-hourglass-end:before {
    content: "\f4f4";
    }
        .fi-rr-hourglass-start:before {
    content: "\f4f5";
    }
        .fi-rr-hourglass:before {
    content: "\f4f6";
    }
        .fi-rr-house-blank:before {
    content: "\f4f7";
    }
        .fi-rr-house-building:before {
    content: "\f4f8";
    }
        .fi-rr-house-chimney-blank:before {
    content: "\f4f9";
    }
        .fi-rr-house-chimney-crack:before {
    content: "\f4fa";
    }
        .fi-rr-house-chimney-medical:before {
    content: "\f4fb";
    }
        .fi-rr-house-chimney-window:before {
    content: "\f4fc";
    }
        .fi-rr-house-chimney:before {
    content: "\f4fd";
    }
        .fi-rr-house-crack:before {
    content: "\f4fe";
    }
        .fi-rr-house-flood:before {
    content: "\f4ff";
    }
        .fi-rr-house-laptop:before {
    content: "\f500";
    }
        .fi-rr-house-medical:before {
    content: "\f501";
    }
        .fi-rr-house-tree:before {
    content: "\f502";
    }
        .fi-rr-house-turret:before {
    content: "\f503";
    }
        .fi-rr-house-window:before {
    content: "\f504";
    }
        .fi-rr-hryvnia:before {
    content: "\f505";
    }
        .fi-rr-humidity:before {
    content: "\f506";
    }
        .fi-rr-hundred-points:before {
    content: "\f507";
    }
        .fi-rr-hurricane:before {
    content: "\f508";
    }
        .fi-rr-i:before {
    content: "\f509";
    }
        .fi-rr-ice-cream:before {
    content: "\f50a";
    }
        .fi-rr-ice-skate:before {
    content: "\f50b";
    }
        .fi-rr-icon-star:before {
    content: "\f50c";
    }
        .fi-rr-id-badge:before {
    content: "\f50d";
    }
        .fi-rr-id-card-clip-alt:before {
    content: "\f50e";
    }
        .fi-rr-igloo:before {
    content: "\f50f";
    }
        .fi-rr-images:before {
    content: "\f510";
    }
        .fi-rr-inbox-in:before {
    content: "\f511";
    }
        .fi-rr-inbox-out:before {
    content: "\f512";
    }
        .fi-rr-inbox:before {
    content: "\f513";
    }
        .fi-rr-inboxes:before {
    content: "\f514";
    }
        .fi-rr-incognito:before {
    content: "\f515";
    }
        .fi-rr-indent:before {
    content: "\f516";
    }
        .fi-rr-indian-rupee-sign:before {
    content: "\f517";
    }
        .fi-rr-industry-windows:before {
    content: "\f518";
    }
        .fi-rr-infinity:before {
    content: "\f519";
    }
        .fi-rr-info:before {
    content: "\f51a";
    }
        .fi-rr-inhaler:before {
    content: "\f51b";
    }
        .fi-rr-input-numeric:before {
    content: "\f51c";
    }
        .fi-rr-input-pipe:before {
    content: "\f51d";
    }
        .fi-rr-input-text:before {
    content: "\f51e";
    }
        .fi-rr-interactive:before {
    content: "\f51f";
    }
        .fi-rr-interlining:before {
    content: "\f520";
    }
        .fi-rr-interrogation:before {
    content: "\f521";
    }
        .fi-rr-island-tropical:before {
    content: "\f522";
    }
        .fi-rr-italian-lira-sign:before {
    content: "\f523";
    }
        .fi-rr-italic:before {
    content: "\f524";
    }
        .fi-rr-j:before {
    content: "\f525";
    }
        .fi-rr-jam:before {
    content: "\f526";
    }
        .fi-rr-joint:before {
    content: "\f527";
    }
        .fi-rr-jpg:before {
    content: "\f528";
    }
        .fi-rr-jug:before {
    content: "\f529";
    }
        .fi-rr-k:before {
    content: "\f52a";
    }
        .fi-rr-kerning:before {
    content: "\f52b";
    }
        .fi-rr-key-skeleton-left-right:before {
    content: "\f52c";
    }
        .fi-rr-key:before {
    content: "\f52d";
    }
        .fi-rr-keyboard-brightness-low:before {
    content: "\f52e";
    }
        .fi-rr-keyboard-brightness:before {
    content: "\f52f";
    }
        .fi-rr-keyboard:before {
    content: "\f530";
    }
        .fi-rr-keynote:before {
    content: "\f531";
    }
        .fi-rr-kidneys:before {
    content: "\f532";
    }
        .fi-rr-kip-sign:before {
    content: "\f533";
    }
        .fi-rr-kiss-beam:before {
    content: "\f534";
    }
        .fi-rr-kiss-wink-heart:before {
    content: "\f535";
    }
        .fi-rr-kiss:before {
    content: "\f536";
    }
        .fi-rr-kite:before {
    content: "\f537";
    }
        .fi-rr-knife:before {
    content: "\f538";
    }
        .fi-rr-l:before {
    content: "\f539";
    }
        .fi-rr-label:before {
    content: "\f53a";
    }
        .fi-rr-landmark-alt:before {
    content: "\f53b";
    }
        .fi-rr-language:before {
    content: "\f53c";
    }
        .fi-rr-laptop-code:before {
    content: "\f53d";
    }
        .fi-rr-laptop-medical:before {
    content: "\f53e";
    }
        .fi-rr-laptop-mobile:before {
    content: "\f53f";
    }
        .fi-rr-laptop:before {
    content: "\f540";
    }
        .fi-rr-lari-sign:before {
    content: "\f541";
    }
        .fi-rr-lasso-sparkles:before {
    content: "\f542";
    }
        .fi-rr-lasso:before {
    content: "\f543";
    }
        .fi-rr-laugh-beam:before {
    content: "\f544";
    }
        .fi-rr-laugh-squint:before {
    content: "\f545";
    }
        .fi-rr-laugh-wink:before {
    content: "\f546";
    }
        .fi-rr-laugh:before {
    content: "\f547";
    }
        .fi-rr-layer-minus:before {
    content: "\f548";
    }
        .fi-rr-layer-plus:before {
    content: "\f549";
    }
        .fi-rr-layers:before {
    content: "\f54a";
    }
        .fi-rr-layout-fluid:before {
    content: "\f54b";
    }
        .fi-rr-leaf:before {
    content: "\f54c";
    }
        .fi-rr-left:before {
    content: "\f54d";
    }
        .fi-rr-lemon:before {
    content: "\f54e";
    }
        .fi-rr-letter-case:before {
    content: "\f54f";
    }
        .fi-rr-lettuce:before {
    content: "\f550";
    }
        .fi-rr-level-down-alt:before {
    content: "\f551";
    }
        .fi-rr-level-down:before {
    content: "\f552";
    }
        .fi-rr-level-up-alt:before {
    content: "\f553";
    }
        .fi-rr-level-up:before {
    content: "\f554";
    }
        .fi-rr-license:before {
    content: "\f555";
    }
        .fi-rr-life-ring:before {
    content: "\f556";
    }
        .fi-rr-light-switch-off:before {
    content: "\f557";
    }
        .fi-rr-light-switch-on:before {
    content: "\f558";
    }
        .fi-rr-light-switch:before {
    content: "\f559";
    }
        .fi-rr-lightbulb-dollar:before {
    content: "\f55a";
    }
        .fi-rr-lightbulb-slash:before {
    content: "\f55b";
    }
        .fi-rr-line-width:before {
    content: "\f55c";
    }
        .fi-rr-link-alt:before {
    content: "\f55d";
    }
        .fi-rr-link-horizontal-slash:before {
    content: "\f55e";
    }
        .fi-rr-link-horizontal:before {
    content: "\f55f";
    }
        .fi-rr-link-slash-alt:before {
    content: "\f560";
    }
        .fi-rr-link-slash:before {
    content: "\f561";
    }
        .fi-rr-link:before {
    content: "\f562";
    }
        .fi-rr-lips:before {
    content: "\f563";
    }
        .fi-rr-lipstick:before {
    content: "\f564";
    }
        .fi-rr-lira-sign:before {
    content: "\f565";
    }
        .fi-rr-list-check:before {
    content: "\f566";
    }
        .fi-rr-list-dropdown:before {
    content: "\f567";
    }
        .fi-rr-list-timeline:before {
    content: "\f568";
    }
        .fi-rr-list:before {
    content: "\f569";
    }
        .fi-rr-litecoin-sign:before {
    content: "\f56a";
    }
        .fi-rr-loading:before {
    content: "\f56b";
    }
        .fi-rr-location-alt:before {
    content: "\f56c";
    }
        .fi-rr-location-crosshairs-slash:before {
    content: "\f56d";
    }
        .fi-rr-location-crosshairs:before {
    content: "\f56e";
    }
        .fi-rr-location-dot-slash:before {
    content: "\f56f";
    }
        .fi-rr-lock-alt:before {
    content: "\f570";
    }
        .fi-rr-lock:before {
    content: "\f571";
    }
        .fi-rr-luchador:before {
    content: "\f572";
    }
        .fi-rr-luggage-cart:before {
    content: "\f573";
    }
        .fi-rr-luggage-rolling:before {
    content: "\f574";
    }
        .fi-rr-lungs-virus:before {
    content: "\f575";
    }
        .fi-rr-lungs:before {
    content: "\f576";
    }
        .fi-rr-m:before {
    content: "\f577";
    }
        .fi-rr-magic-wand:before {
    content: "\f578";
    }
        .fi-rr-mailbox:before {
    content: "\f579";
    }
        .fi-rr-makeup-brush:before {
    content: "\f57a";
    }
        .fi-rr-man-head:before {
    content: "\f57b";
    }
        .fi-rr-manat-sign:before {
    content: "\f57c";
    }
        .fi-rr-map-marker-check:before {
    content: "\f57d";
    }
        .fi-rr-map-marker-cross:before {
    content: "\f57e";
    }
        .fi-rr-map-marker-edit:before {
    content: "\f57f";
    }
        .fi-rr-map-marker-home:before {
    content: "\f580";
    }
        .fi-rr-map-marker-minus:before {
    content: "\f581";
    }
        .fi-rr-map-marker-plus:before {
    content: "\f582";
    }
        .fi-rr-map-marker-question:before {
    content: "\f583";
    }
        .fi-rr-map-marker-slash:before {
    content: "\f584";
    }
        .fi-rr-map-marker-smile:before {
    content: "\f585";
    }
        .fi-rr-map-marker:before {
    content: "\f586";
    }
        .fi-rr-map-pin:before {
    content: "\f587";
    }
        .fi-rr-map:before {
    content: "\f588";
    }
        .fi-rr-marker-time:before {
    content: "\f589";
    }
        .fi-rr-marker:before {
    content: "\f58a";
    }
        .fi-rr-mars-double:before {
    content: "\f58b";
    }
        .fi-rr-mars-stroke-right:before {
    content: "\f58c";
    }
        .fi-rr-mars-stroke-up:before {
    content: "\f58d";
    }
        .fi-rr-mars:before {
    content: "\f58e";
    }
        .fi-rr-mask-carnival:before {
    content: "\f58f";
    }
        .fi-rr-mask-face:before {
    content: "\f590";
    }
        .fi-rr-mask:before {
    content: "\f591";
    }
        .fi-rr-medical-star:before {
    content: "\f592";
    }
        .fi-rr-medicine:before {
    content: "\f593";
    }
        .fi-rr-megaphone:before {
    content: "\f594";
    }
        .fi-rr-meh-blank:before {
    content: "\f595";
    }
        .fi-rr-meh-rolling-eyes:before {
    content: "\f596";
    }
        .fi-rr-meh:before {
    content: "\f597";
    }
        .fi-rr-melon:before {
    content: "\f598";
    }
        .fi-rr-memo-circle-check:before {
    content: "\f599";
    }
        .fi-rr-memo-pad:before {
    content: "\f59a";
    }
        .fi-rr-memo:before {
    content: "\f59b";
    }
        .fi-rr-memory:before {
    content: "\f59c";
    }
        .fi-rr-menu-burger:before {
    content: "\f59d";
    }
        .fi-rr-menu-dots-vertical:before {
    content: "\f59e";
    }
        .fi-rr-menu-dots:before {
    content: "\f59f";
    }
        .fi-rr-message-arrow-down:before {
    content: "\f5a0";
    }
        .fi-rr-message-arrow-up-right:before {
    content: "\f5a1";
    }
        .fi-rr-message-arrow-up:before {
    content: "\f5a2";
    }
        .fi-rr-message-bot:before {
    content: "\f5a3";
    }
        .fi-rr-message-code:before {
    content: "\f5a4";
    }
        .fi-rr-message-dollar:before {
    content: "\f5a5";
    }
        .fi-rr-message-image:before {
    content: "\f5a6";
    }
        .fi-rr-message-question:before {
    content: "\f5a7";
    }
        .fi-rr-message-quote:before {
    content: "\f5a8";
    }
        .fi-rr-message-slash:before {
    content: "\f5a9";
    }
        .fi-rr-message-sms:before {
    content: "\f5aa";
    }
        .fi-rr-message-text:before {
    content: "\f5ab";
    }
        .fi-rr-message-xmark:before {
    content: "\f5ac";
    }
        .fi-rr-messages-dollar:before {
    content: "\f5ad";
    }
        .fi-rr-messages-question:before {
    content: "\f5ae";
    }
        .fi-rr-messages:before {
    content: "\f5af";
    }
        .fi-rr-meteor:before {
    content: "\f5b0";
    }
        .fi-rr-microchip:before {
    content: "\f5b1";
    }
        .fi-rr-microphone-alt:before {
    content: "\f5b2";
    }
        .fi-rr-microphone:before {
    content: "\f5b3";
    }
        .fi-rr-microscope:before {
    content: "\f5b4";
    }
        .fi-rr-mill-sign:before {
    content: "\f5b5";
    }
        .fi-rr-mind-share:before {
    content: "\f5b6";
    }
        .fi-rr-minus-small:before {
    content: "\f5b7";
    }
        .fi-rr-minus:before {
    content: "\f5b8";
    }
        .fi-rr-mobile-button:before {
    content: "\f5b9";
    }
        .fi-rr-mobile-notch:before {
    content: "\f5ba";
    }
        .fi-rr-mobile:before {
    content: "\f5bb";
    }
        .fi-rr-mockup:before {
    content: "\f5bc";
    }
        .fi-rr-mode-landscape:before {
    content: "\f5bd";
    }
        .fi-rr-mode-portrait:before {
    content: "\f5be";
    }
        .fi-rr-money-bill-simple:before {
    content: "\f5bf";
    }
        .fi-rr-money-bill-wave-alt:before {
    content: "\f5c0";
    }
        .fi-rr-money-bill-wave:before {
    content: "\f5c1";
    }
        .fi-rr-money-bills-simple:before {
    content: "\f5c2";
    }
        .fi-rr-money-bills:before {
    content: "\f5c3";
    }
        .fi-rr-money-check-edit-alt:before {
    content: "\f5c4";
    }
        .fi-rr-money-check-edit:before {
    content: "\f5c5";
    }
        .fi-rr-money-check:before {
    content: "\f5c6";
    }
        .fi-rr-money-from-bracket:before {
    content: "\f5c7";
    }
        .fi-rr-money-simple-from-bracket:before {
    content: "\f5c8";
    }
        .fi-rr-money:before {
    content: "\f5c9";
    }
        .fi-rr-monument:before {
    content: "\f5ca";
    }
        .fi-rr-moon-stars:before {
    content: "\f5cb";
    }
        .fi-rr-moon:before {
    content: "\f5cc";
    }
        .fi-rr-mortar-pestle:before {
    content: "\f5cd";
    }
        .fi-rr-motorcycle:before {
    content: "\f5ce";
    }
        .fi-rr-mountains:before {
    content: "\f5cf";
    }
        .fi-rr-mouse:before {
    content: "\f5d0";
    }
        .fi-rr-move-to-folder-2:before {
    content: "\f5d1";
    }
        .fi-rr-move-to-folder:before {
    content: "\f5d2";
    }
        .fi-rr-mug-alt:before {
    content: "\f5d3";
    }
        .fi-rr-mug-hot-alt:before {
    content: "\f5d4";
    }
        .fi-rr-mug-hot:before {
    content: "\f5d5";
    }
        .fi-rr-mug-tea:before {
    content: "\f5d6";
    }
        .fi-rr-mug:before {
    content: "\f5d7";
    }
        .fi-rr-mushroom:before {
    content: "\f5d8";
    }
        .fi-rr-music-alt:before {
    content: "\f5d9";
    }
        .fi-rr-music-file:before {
    content: "\f5da";
    }
        .fi-rr-music:before {
    content: "\f5db";
    }
        .fi-rr-n:before {
    content: "\f5dc";
    }
        .fi-rr-naira-sign:before {
    content: "\f5dd";
    }
        .fi-rr-navigation:before {
    content: "\f5de";
    }
        .fi-rr-network-cloud:before {
    content: "\f5df";
    }
        .fi-rr-network:before {
    content: "\f5e0";
    }
        .fi-rr-nfc-lock:before {
    content: "\f5e1";
    }
        .fi-rr-nfc-magnifying-glass:before {
    content: "\f5e2";
    }
        .fi-rr-nfc-pen:before {
    content: "\f5e3";
    }
        .fi-rr-nfc-slash:before {
    content: "\f5e4";
    }
        .fi-rr-nfc-trash:before {
    content: "\f5e5";
    }
        .fi-rr-nfc:before {
    content: "\f5e6";
    }
        .fi-rr-no-people:before {
    content: "\f5e7";
    }
        .fi-rr-noodles:before {
    content: "\f5e8";
    }
        .fi-rr-notdef:before {
    content: "\f5e9";
    }
        .fi-rr-note-medical:before {
    content: "\f5ea";
    }
        .fi-rr-note-sticky:before {
    content: "\f5eb";
    }
        .fi-rr-note:before {
    content: "\f5ec";
    }
        .fi-rr-notebook-alt:before {
    content: "\f5ed";
    }
        .fi-rr-notebook:before {
    content: "\f5ee";
    }
        .fi-rr-notes-medical:before {
    content: "\f5ef";
    }
        .fi-rr-notes:before {
    content: "\f5f0";
    }
        .fi-rr-o:before {
    content: "\f5f1";
    }
        .fi-rr-object-exclude:before {
    content: "\f5f2";
    }
        .fi-rr-object-intersect:before {
    content: "\f5f3";
    }
        .fi-rr-object-subtract:before {
    content: "\f5f4";
    }
        .fi-rr-object-union:before {
    content: "\f5f5";
    }
        .fi-rr-octagon:before {
    content: "\f5f6";
    }
        .fi-rr-oil-can:before {
    content: "\f5f7";
    }
        .fi-rr-oil-temp:before {
    content: "\f5f8";
    }
        .fi-rr-olive-oil:before {
    content: "\f5f9";
    }
        .fi-rr-olives:before {
    content: "\f5fa";
    }
        .fi-rr-onion:before {
    content: "\f5fb";
    }
        .fi-rr-opacity:before {
    content: "\f5fc";
    }
        .fi-rr-overline:before {
    content: "\f5fd";
    }
        .fi-rr-p:before {
    content: "\f5fe";
    }
        .fi-rr-package:before {
    content: "\f5ff";
    }
        .fi-rr-page-break:before {
    content: "\f600";
    }
        .fi-rr-pager:before {
    content: "\f601";
    }
        .fi-rr-paint-brush:before {
    content: "\f602";
    }
        .fi-rr-paint-roller:before {
    content: "\f603";
    }
        .fi-rr-palette:before {
    content: "\f604";
    }
        .fi-rr-pan:before {
    content: "\f605";
    }
        .fi-rr-paper-plane-top:before {
    content: "\f606";
    }
        .fi-rr-paper-plane:before {
    content: "\f607";
    }
        .fi-rr-paperclip-vertical:before {
    content: "\f608";
    }
        .fi-rr-parking-circle-slash:before {
    content: "\f609";
    }
        .fi-rr-parking-circle:before {
    content: "\f60a";
    }
        .fi-rr-parking-slash:before {
    content: "\f60b";
    }
        .fi-rr-parking:before {
    content: "\f60c";
    }
        .fi-rr-passport:before {
    content: "\f60d";
    }
        .fi-rr-password:before {
    content: "\f60e";
    }
        .fi-rr-paste:before {
    content: "\f60f";
    }
        .fi-rr-pattern:before {
    content: "\f610";
    }
        .fi-rr-pause:before {
    content: "\f611";
    }
        .fi-rr-paw:before {
    content: "\f612";
    }
        .fi-rr-peach:before {
    content: "\f613";
    }
        .fi-rr-pen-clip:before {
    content: "\f614";
    }
        .fi-rr-pen-nib-slash:before {
    content: "\f615";
    }
        .fi-rr-pencil-ruler:before {
    content: "\f616";
    }
        .fi-rr-pencil:before {
    content: "\f617";
    }
        .fi-rr-pennant:before {
    content: "\f618";
    }
        .fi-rr-people-arrows-left-right:before {
    content: "\f619";
    }
        .fi-rr-people-carry-box:before {
    content: "\f61a";
    }
        .fi-rr-people-dress:before {
    content: "\f61b";
    }
        .fi-rr-people-pants:before {
    content: "\f61c";
    }
        .fi-rr-people-poll:before {
    content: "\f61d";
    }
        .fi-rr-people:before {
    content: "\f61e";
    }
        .fi-rr-pepper-hot:before {
    content: "\f61f";
    }
        .fi-rr-pepper:before {
    content: "\f620";
    }
        .fi-rr-percentage:before {
    content: "\f621";
    }
        .fi-rr-person-dress-simple:before {
    content: "\f622";
    }
        .fi-rr-person-dress:before {
    content: "\f623";
    }
        .fi-rr-person-praying:before {
    content: "\f624";
    }
        .fi-rr-person-pregnant:before {
    content: "\f625";
    }
        .fi-rr-person-seat-reclined:before {
    content: "\f626";
    }
        .fi-rr-person-seat:before {
    content: "\f627";
    }
        .fi-rr-person-simple:before {
    content: "\f628";
    }
        .fi-rr-person-walking-with-cane:before {
    content: "\f629";
    }
        .fi-rr-peseta-sign:before {
    content: "\f62a";
    }
        .fi-rr-peso-sign:before {
    content: "\f62b";
    }
        .fi-rr-pharmacy:before {
    content: "\f62c";
    }
        .fi-rr-phone-call:before {
    content: "\f62d";
    }
        .fi-rr-phone-cross:before {
    content: "\f62e";
    }
        .fi-rr-phone-flip:before {
    content: "\f62f";
    }
        .fi-rr-phone-office:before {
    content: "\f630";
    }
        .fi-rr-phone-pause:before {
    content: "\f631";
    }
        .fi-rr-phone-plus:before {
    content: "\f632";
    }
        .fi-rr-phone-slash:before {
    content: "\f633";
    }
        .fi-rr-photo-film-music:before {
    content: "\f634";
    }
        .fi-rr-photo-video:before {
    content: "\f635";
    }
        .fi-rr-physics:before {
    content: "\f636";
    }
        .fi-rr-picnic:before {
    content: "\f637";
    }
        .fi-rr-picpeople-filled:before {
    content: "\f638";
    }
        .fi-rr-picpeople:before {
    content: "\f639";
    }
        .fi-rr-picture:before {
    content: "\f63a";
    }
        .fi-rr-pie:before {
    content: "\f63b";
    }
        .fi-rr-piece:before {
    content: "\f63c";
    }
        .fi-rr-piggy-bank:before {
    content: "\f63d";
    }
        .fi-rr-pills:before {
    content: "\f63e";
    }
        .fi-rr-pineapple:before {
    content: "\f63f";
    }
        .fi-rr-ping-pong:before {
    content: "\f640";
    }
        .fi-rr-pipe-smoking:before {
    content: "\f641";
    }
        .fi-rr-pizza-slice:before {
    content: "\f642";
    }
        .fi-rr-plane-alt:before {
    content: "\f643";
    }
        .fi-rr-plane-arrival:before {
    content: "\f644";
    }
        .fi-rr-plane-departure:before {
    content: "\f645";
    }
        .fi-rr-plane-prop:before {
    content: "\f646";
    }
        .fi-rr-plane-tail:before {
    content: "\f647";
    }
        .fi-rr-plane:before {
    content: "\f648";
    }
        .fi-rr-plate:before {
    content: "\f649";
    }
        .fi-rr-play-alt:before {
    content: "\f64a";
    }
        .fi-rr-play-pause:before {
    content: "\f64b";
    }
        .fi-rr-play:before {
    content: "\f64c";
    }
        .fi-rr-playing-cards:before {
    content: "\f64d";
    }
        .fi-rr-plus-small:before {
    content: "\f64e";
    }
        .fi-rr-plus:before {
    content: "\f64f";
    }
        .fi-rr-podium-star:before {
    content: "\f650";
    }
        .fi-rr-podium:before {
    content: "\f651";
    }
        .fi-rr-poker-chip:before {
    content: "\f652";
    }
        .fi-rr-poll-h:before {
    content: "\f653";
    }
        .fi-rr-poo:before {
    content: "\f654";
    }
        .fi-rr-poop:before {
    content: "\f655";
    }
        .fi-rr-popcorn:before {
    content: "\f656";
    }
        .fi-rr-portrait:before {
    content: "\f657";
    }
        .fi-rr-pot:before {
    content: "\f658";
    }
        .fi-rr-pound:before {
    content: "\f659";
    }
        .fi-rr-power:before {
    content: "\f65a";
    }
        .fi-rr-prescription-bottle-alt:before {
    content: "\f65b";
    }
        .fi-rr-prescription-bottle-pill:before {
    content: "\f65c";
    }
        .fi-rr-prescription-bottle:before {
    content: "\f65d";
    }
        .fi-rr-prescription:before {
    content: "\f65e";
    }
        .fi-rr-presentation:before {
    content: "\f65f";
    }
        .fi-rr-print-magnifying-glass:before {
    content: "\f660";
    }
        .fi-rr-print:before {
    content: "\f661";
    }
        .fi-rr-procedures:before {
    content: "\f662";
    }
        .fi-rr-projector:before {
    content: "\f663";
    }
        .fi-rr-protractor:before {
    content: "\f664";
    }
        .fi-rr-pulse:before {
    content: "\f665";
    }
        .fi-rr-pump-medical:before {
    content: "\f666";
    }
        .fi-rr-pumpkin:before {
    content: "\f667";
    }
        .fi-rr-puzzle-alt:before {
    content: "\f668";
    }
        .fi-rr-puzzle:before {
    content: "\f669";
    }
        .fi-rr-pyramid:before {
    content: "\f66a";
    }
        .fi-rr-q:before {
    content: "\f66b";
    }
        .fi-rr-qrcode:before {
    content: "\f66c";
    }
        .fi-rr-question-square:before {
    content: "\f66d";
    }
        .fi-rr-question:before {
    content: "\f66e";
    }
        .fi-rr-quote-right:before {
    content: "\f66f";
    }
        .fi-rr-r:before {
    content: "\f670";
    }
        .fi-rr-racquet:before {
    content: "\f671";
    }
        .fi-rr-radiation-alt:before {
    content: "\f672";
    }
        .fi-rr-radiation:before {
    content: "\f673";
    }
        .fi-rr-radish:before {
    content: "\f674";
    }
        .fi-rr-rainbow:before {
    content: "\f675";
    }
        .fi-rr-raindrops:before {
    content: "\f676";
    }
        .fi-rr-ramp-loading:before {
    content: "\f677";
    }
        .fi-rr-rec:before {
    content: "\f678";
    }
        .fi-rr-receipt:before {
    content: "\f679";
    }
        .fi-rr-record-vinyl:before {
    content: "\f67a";
    }
        .fi-rr-rectabgle-vertical:before {
    content: "\f67b";
    }
        .fi-rr-rectangle-barcode:before {
    content: "\f67c";
    }
        .fi-rr-rectangle-code:before {
    content: "\f67d";
    }
        .fi-rr-rectangle-horizontal:before {
    content: "\f67e";
    }
        .fi-rr-rectangle-panoramic:before {
    content: "\f67f";
    }
        .fi-rr-rectangle-pro:before {
    content: "\f680";
    }
        .fi-rr-rectangle-vertical:before {
    content: "\f681";
    }
        .fi-rr-rectangle-xmark:before {
    content: "\f682";
    }
        .fi-rr-recycle:before {
    content: "\f683";
    }
        .fi-rr-redo-alt:before {
    content: "\f684";
    }
        .fi-rr-redo:before {
    content: "\f685";
    }
        .fi-rr-reel:before {
    content: "\f686";
    }
        .fi-rr-reflect-horizontal:before {
    content: "\f687";
    }
        .fi-rr-reflect-vertical:before {
    content: "\f688";
    }
        .fi-rr-reflect:before {
    content: "\f689";
    }
        .fi-rr-refresh:before {
    content: "\f68a";
    }
        .fi-rr-registered:before {
    content: "\f68b";
    }
        .fi-rr-remove-folder:before {
    content: "\f68c";
    }
        .fi-rr-remove-user:before {
    content: "\f68d";
    }
        .fi-rr-replace:before {
    content: "\f68e";
    }
        .fi-rr-reply-all:before {
    content: "\f68f";
    }
        .fi-rr-republican:before {
    content: "\f690";
    }
        .fi-rr-resize:before {
    content: "\f691";
    }
        .fi-rr-resources:before {
    content: "\f692";
    }
        .fi-rr-restaurant:before {
    content: "\f693";
    }
        .fi-rr-restroom-simple:before {
    content: "\f694";
    }
        .fi-rr-rewind:before {
    content: "\f695";
    }
        .fi-rr-rhombus:before {
    content: "\f696";
    }
        .fi-rr-right:before {
    content: "\f697";
    }
        .fi-rr-rings-wedding:before {
    content: "\f698";
    }
        .fi-rr-road:before {
    content: "\f699";
    }
        .fi-rr-rocket-lunch:before {
    content: "\f69a";
    }
        .fi-rr-rocket:before {
    content: "\f69b";
    }
        .fi-rr-roller-coaster:before {
    content: "\f69c";
    }
        .fi-rr-room-service:before {
    content: "\f69d";
    }
        .fi-rr-rotate-left:before {
    content: "\f69e";
    }
        .fi-rr-rotate-right:before {
    content: "\f69f";
    }
        .fi-rr-route-highway:before {
    content: "\f6a0";
    }
        .fi-rr-route-interstate:before {
    content: "\f6a1";
    }
        .fi-rr-route:before {
    content: "\f6a2";
    }
        .fi-rr-rss:before {
    content: "\f6a3";
    }
        .fi-rr-ruble-sign:before {
    content: "\f6a4";
    }
        .fi-rr-rugby:before {
    content: "\f6a5";
    }
        .fi-rr-ruler-combined:before {
    content: "\f6a6";
    }
        .fi-rr-ruler-horizontal:before {
    content: "\f6a7";
    }
        .fi-rr-ruler-triangle:before {
    content: "\f6a8";
    }
        .fi-rr-ruler-vertical:before {
    content: "\f6a9";
    }
        .fi-rr-running:before {
    content: "\f6aa";
    }
        .fi-rr-rupee-sign:before {
    content: "\f6ab";
    }
        .fi-rr-rupiah-sign:before {
    content: "\f6ac";
    }
        .fi-rr-rv:before {
    content: "\f6ad";
    }
        .fi-rr-s:before {
    content: "\f6ae";
    }
        .fi-rr-sack-dollar:before {
    content: "\f6af";
    }
        .fi-rr-sack:before {
    content: "\f6b0";
    }
        .fi-rr-sad-cry:before {
    content: "\f6b1";
    }
        .fi-rr-sad-tear:before {
    content: "\f6b2";
    }
        .fi-rr-sad:before {
    content: "\f6b3";
    }
        .fi-rr-salad:before {
    content: "\f6b4";
    }
        .fi-rr-salt-pepper:before {
    content: "\f6b5";
    }
        .fi-rr-sandwich:before {
    content: "\f6b6";
    }
        .fi-rr-sauce:before {
    content: "\f6b7";
    }
        .fi-rr-sausage:before {
    content: "\f6b8";
    }
        .fi-rr-scale:before {
    content: "\f6b9";
    }
        .fi-rr-scalpel-path:before {
    content: "\f6ba";
    }
        .fi-rr-scalpel:before {
    content: "\f6bb";
    }
        .fi-rr-school-bus:before {
    content: "\f6bc";
    }
        .fi-rr-school:before {
    content: "\f6bd";
    }
        .fi-rr-scissors:before {
    content: "\f6be";
    }
        .fi-rr-screen:before {
    content: "\f6bf";
    }
        .fi-rr-scroll-torah:before {
    content: "\f6c0";
    }
        .fi-rr-seal-exclamation:before {
    content: "\f6c1";
    }
        .fi-rr-search-alt:before {
    content: "\f6c2";
    }
        .fi-rr-search-dollar:before {
    content: "\f6c3";
    }
        .fi-rr-search-heart:before {
    content: "\f6c4";
    }
        .fi-rr-search-location:before {
    content: "\f6c5";
    }
        .fi-rr-search:before {
    content: "\f6c6";
    }
        .fi-rr-seat-airline:before {
    content: "\f6c7";
    }
        .fi-rr-security:before {
    content: "\f6c8";
    }
        .fi-rr-sensor-alert:before {
    content: "\f6c9";
    }
        .fi-rr-sensor-fire:before {
    content: "\f6ca";
    }
        .fi-rr-sensor-on:before {
    content: "\f6cb";
    }
        .fi-rr-sensor-smoke:before {
    content: "\f6cc";
    }
        .fi-rr-sensor:before {
    content: "\f6cd";
    }
        .fi-rr-settings-sliders:before {
    content: "\f6ce";
    }
        .fi-rr-settings:before {
    content: "\f6cf";
    }
        .fi-rr-share-square:before {
    content: "\f6d0";
    }
        .fi-rr-share:before {
    content: "\f6d1";
    }
        .fi-rr-shekel-sign:before {
    content: "\f6d2";
    }
        .fi-rr-shield-check:before {
    content: "\f6d3";
    }
        .fi-rr-shield-exclamation:before {
    content: "\f6d4";
    }
        .fi-rr-shield-interrogation:before {
    content: "\f6d5";
    }
        .fi-rr-shield-plus:before {
    content: "\f6d6";
    }
        .fi-rr-shield-virus:before {
    content: "\f6d7";
    }
        .fi-rr-shield:before {
    content: "\f6d8";
    }
        .fi-rr-ship-side:before {
    content: "\f6d9";
    }
        .fi-rr-ship:before {
    content: "\f6da";
    }
        .fi-rr-shoe-prints:before {
    content: "\f6db";
    }
        .fi-rr-shop-lock:before {
    content: "\f6dc";
    }
        .fi-rr-shop-slash:before {
    content: "\f6dd";
    }
        .fi-rr-shop:before {
    content: "\f6de";
    }
        .fi-rr-shopping-bag-add:before {
    content: "\f6df";
    }
        .fi-rr-shopping-bag:before {
    content: "\f6e0";
    }
        .fi-rr-shopping-basket:before {
    content: "\f6e1";
    }
        .fi-rr-shopping-cart-add:before {
    content: "\f6e2";
    }
        .fi-rr-shopping-cart-check:before {
    content: "\f6e3";
    }
        .fi-rr-shopping-cart:before {
    content: "\f6e4";
    }
        .fi-rr-shredder:before {
    content: "\f6e5";
    }
        .fi-rr-shrimp:before {
    content: "\f6e6";
    }
        .fi-rr-shuffle:before {
    content: "\f6e7";
    }
        .fi-rr-shuttle-van:before {
    content: "\f6e8";
    }
        .fi-rr-shuttlecock:before {
    content: "\f6e9";
    }
        .fi-rr-sidebar-flip:before {
    content: "\f6ea";
    }
        .fi-rr-sidebar:before {
    content: "\f6eb";
    }
        .fi-rr-sign-hanging:before {
    content: "\f6ec";
    }
        .fi-rr-sign-in-alt:before {
    content: "\f6ed";
    }
        .fi-rr-sign-out-alt:before {
    content: "\f6ee";
    }
        .fi-rr-signal-alt-1:before {
    content: "\f6ef";
    }
        .fi-rr-signal-alt-2:before {
    content: "\f6f0";
    }
        .fi-rr-signal-alt:before {
    content: "\f6f1";
    }
        .fi-rr-signal-stream-slash:before {
    content: "\f6f2";
    }
        .fi-rr-signal-stream:before {
    content: "\f6f3";
    }
        .fi-rr-signature-lock:before {
    content: "\f6f4";
    }
        .fi-rr-signature-slash:before {
    content: "\f6f5";
    }
        .fi-rr-signature:before {
    content: "\f6f6";
    }
        .fi-rr-sitemap:before {
    content: "\f6f7";
    }
        .fi-rr-skateboard:before {
    content: "\f6f8";
    }
        .fi-rr-skating:before {
    content: "\f6f9";
    }
        .fi-rr-skeleton:before {
    content: "\f6fa";
    }
        .fi-rr-skewer:before {
    content: "\f6fb";
    }
        .fi-rr-ski-jump:before {
    content: "\f6fc";
    }
        .fi-rr-ski-lift:before {
    content: "\f6fd";
    }
        .fi-rr-skiing-nordic:before {
    content: "\f6fe";
    }
        .fi-rr-skiing:before {
    content: "\f6ff";
    }
        .fi-rr-slash:before {
    content: "\f700";
    }
        .fi-rr-sledding:before {
    content: "\f701";
    }
        .fi-rr-sleigh:before {
    content: "\f702";
    }
        .fi-rr-smartphone:before {
    content: "\f703";
    }
        .fi-rr-smile-beam:before {
    content: "\f704";
    }
        .fi-rr-smile-plus:before {
    content: "\f705";
    }
        .fi-rr-smile-wink:before {
    content: "\f706";
    }
        .fi-rr-smile:before {
    content: "\f707";
    }
        .fi-rr-smiley-comment-alt:before {
    content: "\f708";
    }
        .fi-rr-smog:before {
    content: "\f709";
    }
        .fi-rr-smoke:before {
    content: "\f70a";
    }
        .fi-rr-smoking-ban:before {
    content: "\f70b";
    }
        .fi-rr-smoking:before {
    content: "\f70c";
    }
        .fi-rr-snooze:before {
    content: "\f70d";
    }
        .fi-rr-snow-blowing:before {
    content: "\f70e";
    }
        .fi-rr-snowboarding:before {
    content: "\f70f";
    }
        .fi-rr-snowflake:before {
    content: "\f710";
    }
        .fi-rr-snowflakes:before {
    content: "\f711";
    }
        .fi-rr-snowman-head:before {
    content: "\f712";
    }
        .fi-rr-snowmobile:before {
    content: "\f713";
    }
        .fi-rr-snowplow:before {
    content: "\f714";
    }
        .fi-rr-soap:before {
    content: "\f715";
    }
        .fi-rr-social-network:before {
    content: "\f716";
    }
        .fi-rr-sort-alpha-down-alt:before {
    content: "\f717";
    }
        .fi-rr-sort-alpha-down:before {
    content: "\f718";
    }
        .fi-rr-sort-alpha-up-alt:before {
    content: "\f719";
    }
        .fi-rr-sort-alpha-up:before {
    content: "\f71a";
    }
        .fi-rr-sort-alt:before {
    content: "\f71b";
    }
        .fi-rr-sort-amount-down-alt:before {
    content: "\f71c";
    }
        .fi-rr-sort-amount-down:before {
    content: "\f71d";
    }
        .fi-rr-sort-amount-up-alt:before {
    content: "\f71e";
    }
        .fi-rr-sort-amount-up:before {
    content: "\f71f";
    }
        .fi-rr-sort-down:before {
    content: "\f720";
    }
        .fi-rr-sort-numeric-down-alt:before {
    content: "\f721";
    }
        .fi-rr-sort-numeric-down:before {
    content: "\f722";
    }
        .fi-rr-sort-shapes-down:before {
    content: "\f723";
    }
        .fi-rr-sort-shapes-up:before {
    content: "\f724";
    }
        .fi-rr-sort-size-down:before {
    content: "\f725";
    }
        .fi-rr-sort-size-up:before {
    content: "\f726";
    }
        .fi-rr-sort:before {
    content: "\f727";
    }
        .fi-rr-soup:before {
    content: "\f728";
    }
        .fi-rr-spa:before {
    content: "\f729";
    }
        .fi-rr-space-shuttle:before {
    content: "\f72a";
    }
        .fi-rr-spade:before {
    content: "\f72b";
    }
        .fi-rr-spaghetti-monster-flying:before {
    content: "\f72c";
    }
        .fi-rr-sparkles:before {
    content: "\f72d";
    }
        .fi-rr-speaker:before {
    content: "\f72e";
    }
        .fi-rr-sphere:before {
    content: "\f72f";
    }
        .fi-rr-spinner:before {
    content: "\f730";
    }
        .fi-rr-split:before {
    content: "\f731";
    }
        .fi-rr-spoon:before {
    content: "\f732";
    }
        .fi-rr-sport:before {
    content: "\f733";
    }
        .fi-rr-square-0:before {
    content: "\f734";
    }
        .fi-rr-square-1:before {
    content: "\f735";
    }
        .fi-rr-square-2:before {
    content: "\f736";
    }
        .fi-rr-square-3:before {
    content: "\f737";
    }
        .fi-rr-square-4:before {
    content: "\f738";
    }
        .fi-rr-square-5:before {
    content: "\f739";
    }
        .fi-rr-square-6:before {
    content: "\f73a";
    }
        .fi-rr-square-7:before {
    content: "\f73b";
    }
        .fi-rr-square-8:before {
    content: "\f73c";
    }
        .fi-rr-square-9:before {
    content: "\f73d";
    }
        .fi-rr-square-bolt:before {
    content: "\f73e";
    }
        .fi-rr-square-code:before {
    content: "\f73f";
    }
        .fi-rr-square-heart:before {
    content: "\f740";
    }
        .fi-rr-square-info:before {
    content: "\f741";
    }
        .fi-rr-square-kanban:before {
    content: "\f742";
    }
        .fi-rr-square-minus:before {
    content: "\f743";
    }
        .fi-rr-square-phone-hangup:before {
    content: "\f744";
    }
        .fi-rr-square-plus:before {
    content: "\f745";
    }
        .fi-rr-square-poll-horizontal:before {
    content: "\f746";
    }
        .fi-rr-square-poll-vertical:before {
    content: "\f747";
    }
        .fi-rr-square-quote:before {
    content: "\f748";
    }
        .fi-rr-square-root:before {
    content: "\f749";
    }
        .fi-rr-square-small:before {
    content: "\f74a";
    }
        .fi-rr-square-star:before {
    content: "\f74b";
    }
        .fi-rr-square-terminal:before {
    content: "\f74c";
    }
        .fi-rr-square:before {
    content: "\f74d";
    }
        .fi-rr-squircle:before {
    content: "\f74e";
    }
        .fi-rr-stamp:before {
    content: "\f74f";
    }
        .fi-rr-star-comment-alt:before {
    content: "\f750";
    }
        .fi-rr-star-octogram:before {
    content: "\f751";
    }
        .fi-rr-star:before {
    content: "\f752";
    }
        .fi-rr-starfighter:before {
    content: "\f753";
    }
        .fi-rr-stars:before {
    content: "\f754";
    }
        .fi-rr-stats:before {
    content: "\f755";
    }
        .fi-rr-steak:before {
    content: "\f756";
    }
        .fi-rr-steering-wheel:before {
    content: "\f757";
    }
        .fi-rr-sterling-sign:before {
    content: "\f758";
    }
        .fi-rr-stethoscope:before {
    content: "\f759";
    }
        .fi-rr-sticker:before {
    content: "\f75a";
    }
        .fi-rr-stomach:before {
    content: "\f75b";
    }
        .fi-rr-stop:before {
    content: "\f75c";
    }
        .fi-rr-stopwatch:before {
    content: "\f75d";
    }
        .fi-rr-store-alt:before {
    content: "\f75e";
    }
        .fi-rr-store-lock:before {
    content: "\f75f";
    }
        .fi-rr-store-slash:before {
    content: "\f760";
    }
        .fi-rr-strawberry:before {
    content: "\f761";
    }
        .fi-rr-street-view:before {
    content: "\f762";
    }
        .fi-rr-stretcher:before {
    content: "\f763";
    }
        .fi-rr-subtitles:before {
    content: "\f764";
    }
        .fi-rr-subway:before {
    content: "\f765";
    }
        .fi-rr-suitcase-alt:before {
    content: "\f766";
    }
        .fi-rr-summer:before {
    content: "\f767";
    }
        .fi-rr-sun:before {
    content: "\f768";
    }
        .fi-rr-sunrise-alt:before {
    content: "\f769";
    }
        .fi-rr-sunrise:before {
    content: "\f76a";
    }
        .fi-rr-sunset:before {
    content: "\f76b";
    }
        .fi-rr-surfing:before {
    content: "\f76c";
    }
        .fi-rr-surprise:before {
    content: "\f76d";
    }
        .fi-rr-sushi:before {
    content: "\f76e";
    }
        .fi-rr-swimmer:before {
    content: "\f76f";
    }
        .fi-rr-sword:before {
    content: "\f770";
    }
        .fi-rr-symbol:before {
    content: "\f771";
    }
        .fi-rr-symbols:before {
    content: "\f772";
    }
        .fi-rr-syringe:before {
    content: "\f773";
    }
        .fi-rr-t:before {
    content: "\f774";
    }
        .fi-rr-table-columns:before {
    content: "\f775";
    }
        .fi-rr-table-layout:before {
    content: "\f776";
    }
        .fi-rr-table-picnic:before {
    content: "\f777";
    }
        .fi-rr-table-pivot:before {
    content: "\f778";
    }
        .fi-rr-table-rows:before {
    content: "\f779";
    }
        .fi-rr-table-tree:before {
    content: "\f77a";
    }
        .fi-rr-tablet:before {
    content: "\f77b";
    }
        .fi-rr-tachometer-alt-average:before {
    content: "\f77c";
    }
        .fi-rr-tachometer-alt-fastest:before {
    content: "\f77d";
    }
        .fi-rr-tachometer-alt-slow:before {
    content: "\f77e";
    }
        .fi-rr-tachometer-alt-slowest:before {
    content: "\f77f";
    }
        .fi-rr-tachometer-average:before {
    content: "\f780";
    }
        .fi-rr-tachometer-fast:before {
    content: "\f781";
    }
        .fi-rr-tachometer-fastest:before {
    content: "\f782";
    }
        .fi-rr-tachometer-slow:before {
    content: "\f783";
    }
        .fi-rr-tachometer-slowest:before {
    content: "\f784";
    }
        .fi-rr-tachometer:before {
    content: "\f785";
    }
        .fi-rr-taco:before {
    content: "\f786";
    }
        .fi-rr-tags:before {
    content: "\f787";
    }
        .fi-rr-tally-1:before {
    content: "\f788";
    }
        .fi-rr-tally-2:before {
    content: "\f789";
    }
        .fi-rr-tally-3:before {
    content: "\f78a";
    }
        .fi-rr-tally-4:before {
    content: "\f78b";
    }
        .fi-rr-tally:before {
    content: "\f78c";
    }
        .fi-rr-target:before {
    content: "\f78d";
    }
        .fi-rr-taxi-bus:before {
    content: "\f78e";
    }
        .fi-rr-taxi:before {
    content: "\f78f";
    }
        .fi-rr-teeth-open:before {
    content: "\f790";
    }
        .fi-rr-temperature-down:before {
    content: "\f791";
    }
        .fi-rr-temperature-frigid:before {
    content: "\f792";
    }
        .fi-rr-temperature-high:before {
    content: "\f793";
    }
        .fi-rr-temperature-low:before {
    content: "\f794";
    }
        .fi-rr-temperature-up:before {
    content: "\f795";
    }
        .fi-rr-template-alt:before {
    content: "\f796";
    }
        .fi-rr-template:before {
    content: "\f797";
    }
        .fi-rr-tenge:before {
    content: "\f798";
    }
        .fi-rr-tennis:before {
    content: "\f799";
    }
        .fi-rr-terminal:before {
    content: "\f79a";
    }
        .fi-rr-terrace:before {
    content: "\f79b";
    }
        .fi-rr-test-tube:before {
    content: "\f79c";
    }
        .fi-rr-test:before {
    content: "\f79d";
    }
        .fi-rr-text-check:before {
    content: "\f79e";
    }
        .fi-rr-text-height:before {
    content: "\f79f";
    }
        .fi-rr-text-shadow:before {
    content: "\f7a0";
    }
        .fi-rr-text-size:before {
    content: "\f7a1";
    }
        .fi-rr-text-slash:before {
    content: "\f7a2";
    }
        .fi-rr-text-width:before {
    content: "\f7a3";
    }
        .fi-rr-text:before {
    content: "\f7a4";
    }
        .fi-rr-thermometer-half:before {
    content: "\f7a5";
    }
        .fi-rr-thought-bubble:before {
    content: "\f7a6";
    }
        .fi-rr-thumbtack:before {
    content: "\f7a7";
    }
        .fi-rr-thunderstorm-moon:before {
    content: "\f7a8";
    }
        .fi-rr-thunderstorm-sun:before {
    content: "\f7a9";
    }
        .fi-rr-thunderstorm:before {
    content: "\f7aa";
    }
        .fi-rr-ticket-airline:before {
    content: "\f7ab";
    }
        .fi-rr-ticket-alt:before {
    content: "\f7ac";
    }
        .fi-rr-ticket:before {
    content: "\f7ad";
    }
        .fi-rr-tickets-airline:before {
    content: "\f7ae";
    }
        .fi-rr-time-add:before {
    content: "\f7af";
    }
        .fi-rr-time-check:before {
    content: "\f7b0";
    }
        .fi-rr-time-delete:before {
    content: "\f7b1";
    }
        .fi-rr-time-fast:before {
    content: "\f7b2";
    }
        .fi-rr-time-forward-sixty:before {
    content: "\f7b3";
    }
        .fi-rr-time-forward-ten:before {
    content: "\f7b4";
    }
        .fi-rr-time-forward:before {
    content: "\f7b5";
    }
        .fi-rr-time-half-past:before {
    content: "\f7b6";
    }
        .fi-rr-time-oclock:before {
    content: "\f7b7";
    }
        .fi-rr-time-past:before {
    content: "\f7b8";
    }
        .fi-rr-time-quarter-past:before {
    content: "\f7b9";
    }
        .fi-rr-time-quarter-to:before {
    content: "\f7ba";
    }
        .fi-rr-time-twenty-four:before {
    content: "\f7bb";
    }
        .fi-rr-tire-flat:before {
    content: "\f7bc";
    }
        .fi-rr-tire-pressure-warning:before {
    content: "\f7bd";
    }
        .fi-rr-tire-rugged:before {
    content: "\f7be";
    }
        .fi-rr-tire:before {
    content: "\f7bf";
    }
        .fi-rr-tired:before {
    content: "\f7c0";
    }
        .fi-rr-toilet-paper-blank:before {
    content: "\f7c1";
    }
        .fi-rr-tomato:before {
    content: "\f7c2";
    }
        .fi-rr-tool-box:before {
    content: "\f7c3";
    }
        .fi-rr-tool-crop:before {
    content: "\f7c4";
    }
        .fi-rr-tool-marquee:before {
    content: "\f7c5";
    }
        .fi-rr-tooth:before {
    content: "\f7c6";
    }
        .fi-rr-toothbrush:before {
    content: "\f7c7";
    }
        .fi-rr-tornado:before {
    content: "\f7c8";
    }
        .fi-rr-tower-control:before {
    content: "\f7c9";
    }
        .fi-rr-tractor:before {
    content: "\f7ca";
    }
        .fi-rr-trademark:before {
    content: "\f7cb";
    }
        .fi-rr-traffic-light-go:before {
    content: "\f7cc";
    }
        .fi-rr-traffic-light-slow:before {
    content: "\f7cd";
    }
        .fi-rr-traffic-light-stop:before {
    content: "\f7ce";
    }
        .fi-rr-traffic-light:before {
    content: "\f7cf";
    }
        .fi-rr-trailer:before {
    content: "\f7d0";
    }
        .fi-rr-train-side:before {
    content: "\f7d1";
    }
        .fi-rr-train-subway-tunnel:before {
    content: "\f7d2";
    }
        .fi-rr-train-tram:before {
    content: "\f7d3";
    }
        .fi-rr-train:before {
    content: "\f7d4";
    }
        .fi-rr-tram:before {
    content: "\f7d5";
    }
        .fi-rr-transform:before {
    content: "\f7d6";
    }
        .fi-rr-trash:before {
    content: "\f7d7";
    }
        .fi-rr-treasure-chest:before {
    content: "\f7d8";
    }
        .fi-rr-treatment:before {
    content: "\f7d9";
    }
        .fi-rr-tree-christmas:before {
    content: "\f7da";
    }
        .fi-rr-tree-deciduous:before {
    content: "\f7db";
    }
        .fi-rr-tree:before {
    content: "\f7dc";
    }
        .fi-rr-triangle-warning:before {
    content: "\f7dd";
    }
        .fi-rr-triangle:before {
    content: "\f7de";
    }
        .fi-rr-trophy-star:before {
    content: "\f7df";
    }
        .fi-rr-trophy:before {
    content: "\f7e0";
    }
        .fi-rr-truck-container:before {
    content: "\f7e1";
    }
        .fi-rr-truck-couch:before {
    content: "\f7e2";
    }
        .fi-rr-truck-loading:before {
    content: "\f7e3";
    }
        .fi-rr-truck-monster:before {
    content: "\f7e4";
    }
        .fi-rr-truck-moving:before {
    content: "\f7e5";
    }
        .fi-rr-truck-pickup:before {
    content: "\f7e6";
    }
        .fi-rr-truck-plow:before {
    content: "\f7e7";
    }
        .fi-rr-truck-ramp:before {
    content: "\f7e8";
    }
        .fi-rr-truck-side:before {
    content: "\f7e9";
    }
        .fi-rr-truck-tow:before {
    content: "\f7ea";
    }
        .fi-rr-tty:before {
    content: "\f7eb";
    }
        .fi-rr-tubes:before {
    content: "\f7ec";
    }
        .fi-rr-tugrik-sign:before {
    content: "\f7ed";
    }
        .fi-rr-turkey:before {
    content: "\f7ee";
    }
        .fi-rr-tv-music:before {
    content: "\f7ef";
    }
        .fi-rr-typewriter:before {
    content: "\f7f0";
    }
        .fi-rr-u:before {
    content: "\f7f1";
    }
        .fi-rr-umbrella-beach:before {
    content: "\f7f2";
    }
        .fi-rr-umbrella:before {
    content: "\f7f3";
    }
        .fi-rr-underline:before {
    content: "\f7f4";
    }
        .fi-rr-undo-alt:before {
    content: "\f7f5";
    }
        .fi-rr-undo:before {
    content: "\f7f6";
    }
        .fi-rr-uniform-martial-arts:before {
    content: "\f7f7";
    }
        .fi-rr-universal-access:before {
    content: "\f7f8";
    }
        .fi-rr-unlock:before {
    content: "\f7f9";
    }
        .fi-rr-up-left:before {
    content: "\f7fa";
    }
        .fi-rr-up-right:before {
    content: "\f7fb";
    }
        .fi-rr-up:before {
    content: "\f7fc";
    }
        .fi-rr-upload:before {
    content: "\f7fd";
    }
        .fi-rr-usb-pendrive:before {
    content: "\f7fe";
    }
        .fi-rr-usd-circle:before {
    content: "\f7ff";
    }
        .fi-rr-usd-square:before {
    content: "\f800";
    }
        .fi-rr-user-add:before {
    content: "\f801";
    }
        .fi-rr-user-gear:before {
    content: "\f802";
    }
        .fi-rr-user-md-chat:before {
    content: "\f803";
    }
        .fi-rr-user-md:before {
    content: "\f804";
    }
        .fi-rr-user-nurse:before {
    content: "\f805";
    }
        .fi-rr-user-pen:before {
    content: "\f806";
    }
        .fi-rr-user-pilot-tie:before {
    content: "\f807";
    }
        .fi-rr-user-pilot:before {
    content: "\f808";
    }
        .fi-rr-user-slash:before {
    content: "\f809";
    }
        .fi-rr-user-time:before {
    content: "\f80a";
    }
        .fi-rr-user:before {
    content: "\f80b";
    }
        .fi-rr-users-alt:before {
    content: "\f80c";
    }
        .fi-rr-users-gear:before {
    content: "\f80d";
    }
        .fi-rr-users-medical:before {
    content: "\f80e";
    }
        .fi-rr-users:before {
    content: "\f80f";
    }
        .fi-rr-utensils:before {
    content: "\f810";
    }
        .fi-rr-v:before {
    content: "\f811";
    }
        .fi-rr-vault:before {
    content: "\f812";
    }
        .fi-rr-vector-alt:before {
    content: "\f813";
    }
        .fi-rr-vector:before {
    content: "\f814";
    }
        .fi-rr-venus-double:before {
    content: "\f815";
    }
        .fi-rr-venus-mars:before {
    content: "\f816";
    }
        .fi-rr-venus:before {
    content: "\f817";
    }
        .fi-rr-vest-patches:before {
    content: "\f818";
    }
        .fi-rr-vest:before {
    content: "\f819";
    }
        .fi-rr-video-arrow-down-left:before {
    content: "\f81a";
    }
        .fi-rr-video-arrow-up-right:before {
    content: "\f81b";
    }
        .fi-rr-video-camera-alt:before {
    content: "\f81c";
    }
        .fi-rr-video-camera:before {
    content: "\f81d";
    }
        .fi-rr-video-plus:before {
    content: "\f81e";
    }
        .fi-rr-video-slash:before {
    content: "\f81f";
    }
        .fi-rr-virus-slash:before {
    content: "\f820";
    }
        .fi-rr-virus:before {
    content: "\f821";
    }
        .fi-rr-viruses:before {
    content: "\f822";
    }
        .fi-rr-voicemail:before {
    content: "\f823";
    }
        .fi-rr-volcano:before {
    content: "\f824";
    }
        .fi-rr-volleyball:before {
    content: "\f825";
    }
        .fi-rr-volume:before {
    content: "\f826";
    }
        .fi-rr-vote-nay:before {
    content: "\f827";
    }
        .fi-rr-vote-yea:before {
    content: "\f828";
    }
        .fi-rr-w:before {
    content: "\f829";
    }
        .fi-rr-wagon-covered:before {
    content: "\f82a";
    }
        .fi-rr-walker:before {
    content: "\f82b";
    }
        .fi-rr-walkie-talkie:before {
    content: "\f82c";
    }
        .fi-rr-wallet:before {
    content: "\f82d";
    }
        .fi-rr-warehouse-alt:before {
    content: "\f82e";
    }
        .fi-rr-watch-fitness:before {
    content: "\f82f";
    }
        .fi-rr-watch-smart:before {
    content: "\f830";
    }
        .fi-rr-watch:before {
    content: "\f831";
    }
        .fi-rr-water-bottle:before {
    content: "\f832";
    }
        .fi-rr-water-ladder:before {
    content: "\f833";
    }
        .fi-rr-water-lower:before {
    content: "\f834";
    }
        .fi-rr-water-rise:before {
    content: "\f835";
    }
        .fi-rr-water:before {
    content: "\f836";
    }
        .fi-rr-watermelon:before {
    content: "\f837";
    }
        .fi-rr-wheat:before {
    content: "\f838";
    }
        .fi-rr-wheelchair-move:before {
    content: "\f839";
    }
        .fi-rr-wheelchair:before {
    content: "\f83a";
    }
        .fi-rr-whistle:before {
    content: "\f83b";
    }
        .fi-rr-wifi-1:before {
    content: "\f83c";
    }
        .fi-rr-wifi-2:before {
    content: "\f83d";
    }
        .fi-rr-wifi-alt:before {
    content: "\f83e";
    }
        .fi-rr-wifi-exclamation:before {
    content: "\f83f";
    }
        .fi-rr-wifi-slash:before {
    content: "\f840";
    }
        .fi-rr-wifi:before {
    content: "\f841";
    }
        .fi-rr-wind-warning:before {
    content: "\f842";
    }
        .fi-rr-wind:before {
    content: "\f843";
    }
        .fi-rr-window-alt:before {
    content: "\f844";
    }
        .fi-rr-window-maximize:before {
    content: "\f845";
    }
        .fi-rr-window-minimize:before {
    content: "\f846";
    }
        .fi-rr-window-restore:before {
    content: "\f847";
    }
        .fi-rr-windsock:before {
    content: "\f848";
    }
        .fi-rr-wine-glass-crack:before {
    content: "\f849";
    }
        .fi-rr-woman-head:before {
    content: "\f84a";
    }
        .fi-rr-won-sign:before {
    content: "\f84b";
    }
        .fi-rr-world:before {
    content: "\f84c";
    }
        .fi-rr-wrench-simple:before {
    content: "\f84d";
    }
        .fi-rr-x-ray:before {
    content: "\f84e";
    }
        .fi-rr-x:before {
    content: "\f84f";
    }
        .fi-rr-y:before {
    content: "\f850";
    }
        .fi-rr-yen:before {
    content: "\f851";
    }
        .fi-rr-yin-yang:before {
    content: "\f852";
    }
        .fi-rr-z:before {
    content: "\f853";
    }
        .fi-rr-zoom-in:before {
    content: "\f854";
    }
        .fi-rr-zoom-out:before {
    content: "\f855";
    }
