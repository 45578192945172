@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800&display=swap');
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/font-awesome/css/font-awesome.min.css';
@import '../node_modules/remixicon/fonts/remixicon.css';
@import '../node_modules/@flaticon/flaticon-uicons/css/all/all.rounded.css';
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/magnific-popup/dist/magnific-popup.css';

@import 'assets/css/off-canvas.css';
@import 'assets/css/rsmenu-main.css';
@import 'assets/css/rs-spacing.css';
@import 'assets/css/responsive.css';
@charset "utf-8";
/** 
* 
* -----------------------------------------------------------------------------
*
* Template : Sasco - SaaS Landing Pages HTML Template
* Author : rs-theme
  Author URI : http://www.rstheme.com/ 
*
* -----------------------------------------------------------------------------
* 
**//* TABLE OF CONTENT
---------------------------------------------------------
01. General CSS
02. Global Class CSS
03. Header Section CSS
04. Sticky Menu CSS
05. About Section CSS
06. apps Section CSS
07. Feature Section CSS
08. Breadcrumbs Section CSS
09. Banner Section CSS
10. Cta Section CSS
11. Counter Section CSS
12. Contact Section CSS
13. Video Section CSS
14. Choose Section CSS
15. Services Section CSS
16. Faq Section CSS
17. Team Section CSS
18. Process Section CSS
19. Partner Section CSS
20. Pricing Section CSS
21. Blog Section CSS
22. Testimonial Section CSS
23. Footer Section CSS
24. Scroll Up CSS
25. Preloader CSS
26. Animation Part CSS

--------------------------------------------------------*/
/* -----------------------------------
	01. General CSS
-------------------------------------*/
html,
body {
  font-size: 18px;
  color: #3e3e3e;
  font-family: 'Inter', sans-serif;
  vertical-align: baseline;
  line-height: 1.7;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
  line-height: 1.8;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  color: #121933;
  margin: 0 0 26px;
  line-height: 1.2;
}
h1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: 700;
}
h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}
h4 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 700;
}
h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
h6 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}
.text-md-left {
  text-align: left;
}
.text-md-right {
  text-align: right;
}
.text-right {
  text-align: right;
}
.no-border {
  border: none !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.extra-bold {
  font-weight: 800 !important;
}
.white-color {
  color: #ffffff !important;
}
.title-color {
  color: #101010 !important;
}
.body-color {
  color: #3e3e3e !important;
}
.white-bg {
  background-color: #fff;
}
.gray-bg {
  background-color: #FFFBF6;
}
.gray-bg2 {
  background-color: #F8F8F8;
}
.gray-bg3 {
  background-color: #F8FFF4;
}
.gray-bg4 {
  background-color: #F5F8FF;
}
.gray-bg5 {
  background-image: linear-gradient(180deg, #ffffff 0%, #fafbff 100%);
}
.gray-bg6 {
  background-color: #0D062E;
}
.gray-bg7 {
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.gray-bg8 {
  background-image: linear-gradient(180deg, #ffffff 0%, #f8faff 100%);
  border-radius: 40px 40px 40px 40px;
}
.gray-bg9 {
  background-color: #F4F6FF;
}
.gray-bg10 {
  background-color: #F9F9F9;
}
.gray-bg11 {
  background-color: #FAF7FF;
}
.gray-bg12 {
  background-color: #f1f1f1;
}
.gray-bg13 {
  background-color: #FAF6FF;
}
.gray-bg14 {
  background-color: #FFF9F9;
}
.gray-bg15 {
  background-image: linear-gradient(130deg, #f5f4ff 0%, #fef4f6 100%);
}
.gray-bg16 {
  background-color: #f4fefe;
}
.gray-bg17 {
  background-color: #f8fef7;
}
.gray-bg18 {
  background-color: #FCF9FF;
}
.gray-bg19 {
  background-color: #FBFBFB;
}
.gray-bg20 {
  background-color: #F3F3F3;
}
.gray-bg21 {
  background-color: #eef2ff;
}
.gray-bg22 {
  background: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
}
.gray-bg23 {
  background-image: linear-gradient(180deg, #ffffff 0%, #f8faff 100%);
}
.gray-bg24 {
  background: #F5F7FE;
}
.gray-bg25 {
  background-image: linear-gradient(180deg, #f4faff 0%, #f7f4fd 100%);
}
.gray-bg26 {
  background-color: #F5F5F5;
}
.green-bg {
  background-color: #28544B;
}
.green-bg2 {
  background-color: #1A4D2E;
}
.blue-bg {
  background-color: #140D36;
}
.blue-bg2 {
  background-color: #534FF8;
}
.blue-bg3 {
  background-color: #7900FF;
}
.blue-bg4 {
  background-color: #2B104E;
}
.blue-bg5 {
  background-color: #2b59ff;
}
.black-bg {
  background-color: #060314;
}
.black-bg2 {
  background-color: #121933;
}
.black-bg3 {
  background-color: #15012D;
}
.black-bg4 {
  background-color: #0A0209;
}
.black-bg5 {
  background-color: #0d0324;
}
.black-bg6 {
  background-color: #100434;
}
.black-bg7 {
  background-color: #121c3f;
}
.home-black {
  background: #090423;
}
a {
  color: #2b59ff;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #2b59ff;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #2b59ff;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #2b59ff;
  text-shadow: none;
  color: #ffffff;
}
/*.bg1 {*/
/*  background: url(assets/images/bg/about-bg1.png);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg2 {*/
/*  background: url(assets/images/bg/video-bg.png);*/
/*  background-position: bottom center;*/
/*  background-repeat: no-repeat;*/
/*}*/
/*.bg3 {*/
/*  background: url(assets/images/bg/testi-bg.png);*/
/*  background-position: bottom center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg4 {*/
/*  background: url(assets/images/bg/cta-bg.png);*/
/*  background-position: top center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg5 {*/
/*  background: url(assets/images/bg/testi-bg2.png);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg6 {*/
/*  background: url(assets/images/bg/cta-bg3.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg7 {*/
/*  background: url(assets/images/bg/cta-bg2.png);*/
/*  background-position: top center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg8 {*/
/*  background: url(assets/images/bg/video-bg2.jpg);*/
/*  background-position: center center;*/
/*  background-size: cover;*/
/*}*/
/*.bg9 {*/
/*  background: url(assets/images/bg/video-bg-gradient.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  margin-top: -405px;*/
/*  padding: 205px 0 200px;*/
/*  margin-bottom: 0px;*/
/*}*/
/*.bg10 {*/
/*  background-color: #0A0209;*/
/*  background: url(assets/images/bg/app-bg15.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg11 {*/
/*  background-color: #0A0209;*/
/*  background: url(assets/images/bg/cta-bg4.png);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
/*.bg12 {*/
/*  background-color: #000000;*/
/*  background: url(assets/images/bg/home-faq.jpg);*/
/*  background-attachment: fixed;*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
.offwrap {
  cursor: url(assets/images/close.png), auto;
  width: 100%;
  left: 100%;
  transition: all 0.8s ease-out 0s;
  position: fixed;
  background: #fff;
  height: 100vh;
  top: 0;
  bottom: 0;
  opacity: .8;
  z-index: 999;
}
.copyright p {
  font-size: 15px;
}
body.nav-expanded .offwrap {
  left: 0%;
  transition: all 0.8s ease-out 0s;
}
.bg-unset {
  background: unset !important;
}
.rs-menu-toggle {
  color: #2b59ff;
  text-align: right;
}
button {
  cursor: pointer;
}
.glyph-icon:before {
  margin: 0;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}
.dots {
  list-style: disc;
  margin: 0 0 1.5em 3em;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
ul.check-lists .list-item {
  padding-bottom: calc(9px);
  display: flex;
}
ul.check-lists .list-item .icon-list-icon i {
  color: #2b59ff;
  font-size: 18px;
}
ul.check-lists .list-item .icon-list-icon i:before {
  font-weight: 600;
}
ul.check-lists .list-item .list-text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #121933;
  font-family: "Comfortaa", Sans-serif;
  padding-left: 10px;
}
ul.check-lists .list-item:last-child {
  padding-bottom: 0;
}
ul.check-lists.check-style2 .list-item .list-text {
  color: #121933;
  font-family: "Comfortaa", Sans-serif;
  font-weight: 700;
}
ul.check-lists.check-style3 .list-item .icon-list-icon i {
  color: #28544b;
}
ul.check-lists.check-style3 .list-item .list-text {
  color: #121933;
  font-family: "Comfortaa", Sans-serif;
  font-weight: 600;
}
ul.check-lists.check-style4 .list-item .icon-list-icon i {
  color: #6647FF;
  font-size: 20px;
  line-height: 27px;
  font-weight: 900;
}
ul.check-lists.check-style4 .list-item .list-text {
  color: #121933;
  font-family: "Comfortaa", Sans-serif;
  font-weight: 400;
}
ul.check-lists.check-style4 .list-item .icon-list-icon i:before {
  font-weight: unset;
  position: relative;
  top: -2px;
}
ul.check-lists.check-style5 .list-item .icon-list-icon i {
  color: #8030da;
}
ul.check-lists.check-style5 .list-item .list-text {
  color: #121933;
  font-family: "Comfortaa", Sans-serif;
  font-weight: 400;
}
ul.check-lists.check-style6 .list-item .icon-list-icon i {
  color: #113cfc;
}
ul.check-lists.check-style7 .list-item .list-text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
ul.check-lists.check-style8 .list-item .icon-list-icon i {
  font-size: 30px;
}
ul.check-lists.check-style8 .list-item .list-text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
ul.check-lists.check-style8 .list-item .icon-list-icon i:before {
  font-weight: 300;
}
.rs-features-list li {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.rs-features-list li img {
  margin: 0px 10px 0px 0px;
  width: 20px;
  height: 20px;
}
.rs-features-list li .feature-text {
  font-weight: 600;
}
.rs-contact-box .address-item {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.rs-contact-box .address-item .address-icon {
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  width: 14px;
  height: 14px;
  line-height: 20px !important;
  margin-right: 8px !important;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  position: relative;
}
.rs-contact-box .address-item .address-icon i {
  top: -3px !important;
  position: relative;
  font-size: 12px !important;
  color: #000000 !important;
}
.rs-contact-box .address-item .address-text .text .des {
  display: block;
  color: #FFFFFF;
}
.stylelisting {
  list-style-type: none;
  margin-left: 20px !important;
}
.stylelisting li {
  position: relative;
  padding-left: 22px !important;
  line-height: 34px;
  border: none !important;
}
.stylelisting li:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  content: "\f054";
  font-family: 'FontAwesome';
  color: #2b59ff;
  font-size: 12px;
}
.pagination-area {
  display: inline-block;
}
.pagination-area .nav-link {
  display: flex;
  align-items: center;
  padding: 15px 10px 15px 8px;
  border-radius: 0;
  background: #2b59ff;
}
.pagination-area .nav-link .page-number {
  padding: 2px 15px 0 20px;
  line-height: 12px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
  background: 0 0;
  color: #eee;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #e6e6e6;
}
.pagination-area .nav-link .page-number.border-none {
  border-right: unset;
}
.pagenav-link ul {
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  display: inline-block;
  padding: 0 3px;
}
.pagenav-link ul li {
  display: inline-block;
  padding: 0 18px;
  margin: 10px 0;
  border-right: 1px solid #E6E6E6;
  color: #2b59ff;
  font-size: 18px;
}
.pagenav-link ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;
}
.pagenav-link ul li a i:before {
  position: relative;
  padding-left: 3px;
  font-family: uicons-regular-rounded !important;
  color: #010d14;
  font-size: 20px;
  top: 5px;
  display: inline-block;
  transition: all 0.3s ease;
}
.pagenav-link ul li a:hover {
  color: #2b59ff;
}
.pagenav-link ul li a:hover i:before {
  color: #2b59ff;
}
.pagenav-link ul li:last-child {
  border-right: none;
}
blockquote {
  margin: 40px 0;
  padding: 30px 40px;
  color: #222;
  position: relative;
  background: #fbfbfb !important;
  font-weight: normal;
  font-style: italic;
  text-align: left;
  clear: both;
  font-weight: 400;
  border-left: 4px solid #2b59ff;
  border-color: #2b59ff;
}
blockquote p {
  font-size: 20px;
  margin: 0;
}
.slick-dots {
  position: absolute;
  line-height: 10px;
  bottom: -40px;
  z-index: 3;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}
.slick-dots li {
  display: inline-block;
  width: unset;
  height: unset;
  font-size: 0;
  margin: 0 5px;
}
.slick-dots li button {
  width: 15px;
  height: 15px;
  display: inline-block;
  font-size: unset;
  line-height: unset;
  padding: unset;
  border: none;
  background: #c55e2780;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  border-radius: 15px;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  width: 24px;
  background: #2b59ff;
  opacity: 1;
}
/* no-gutters Class Rules */
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
/* ------------------------------------
	02. Global Class CSS
---------------------------------------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.project-item .vertical-middle {
  position: relative;
  z-index: 9;
}
.vertical-middle {
  display: table;
  height: 100%;
  width: 100%;
}
.vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.readon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #ffffff;
  background: #2b59ff;
  padding: 16px 35px 16px 35px;
  z-index: 1;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.4s;
  overflow: hidden;
}
.readon.started {
  padding: 21px 40px 21px 40px;
  background-color: #DA573A;
  color: #ffffff;
  border-radius: 80px 80px 80px 80px;
  border: none;
  overflow: hidden;
}
.readon.started .btn-text {
  position: relative;
  z-index: 1;
}
.readon.started:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: '';
  color: #000 !important;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.readon.started:hover {
  color: #DA573A;
}
.readon.started:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.readon.started.sasco {
  background-color: transparent;
  color: #534FF8;
  border-radius: 40px 40px 40px 40px;
  padding: 20px 38px 20px 38px;
}
.readon.started.sasco:before {
  display: none;
}
.readon.started.get-blue {
  background-color: #2B59FF;
}
.readon.started.get-blue:before {
  background-color: #5272E4;
}
.readon.started.get-blue:hover {
  color: #FFFFFF;
  background-color: #5272E4;
}
.readon.started.get-blue.blue-light {
  padding: 18px 30px 16px 30px;
  background-color: #113CFC;
  border-radius: 5px 5px 5px 5px;
}
.readon.started.get-blue.blue-light:before {
  background-color: #093DC2;
}
.readon.started.get-blue.get-orange {
  background-color: #da573a;
}
.readon.started.get-blue.get-orange:before {
  background-color: #FD7B5E;
}
.readon.started.get-blue.get-demo {
  padding: 21px 40px 21px 40px;
  background-color: #2B59FF;
  border-radius: 15px 15px 15px 15px;
}
.readon.started.contact {
  padding: 18px 38px 16px 38px;
  background-color: #534FF8;
  color: #ffffff;
  border-radius: 40px 40px 40px 40px;
}
.readon.started.contact:before {
  background-color: #726FF0;
}
.readon.started.contact.trial {
  color: #353535;
  padding: 18px 15px 16px 15px;
  font-size: 16px;
  font-weight: 700;
  background: transparent;
}
.readon.started.contact.trial:before {
  display: none;
}
.readon.started.contact.trial i {
  position: relative;
  margin-left: 3px;
}
.readon.started.contact.trial i:before {
  font-size: 15px;
  font-weight: 400;
}
.readon.started.contact.trial:hover {
  color: #113cfc;
}
.readon.started.contact.trial:hover i {
  color: #113cfc;
}
.readon.started.get-green {
  background-color: #28544B;
}
.readon.started.get-green:before {
  background-color: #28544BCC;
}
.readon.started.get-green:hover {
  color: #FFFFFF;
  background-color: #28544BCC;
}
.readon.started.get-green.green-light {
  padding: 18px 30px 16px 30px;
  background-color: #1A4D2E;
  border-radius: 15px 15px 15px 15px;
}
.readon.started.get-green.green-light:before {
  background-color: #216A3E;
}
.readon.started.get-cta {
  padding: 16px 40px 16px 40px;
}
.readon.started.cta-btn {
  padding: 16px 38px 16px 38px;
  background-color: #F45D48;
  border-radius: 15px 15px 15px 15px;
}
.readon.started.cta-btn:before {
  background-color: #FFFFFF;
}
.readon.started.cta-btn.white-border {
  padding: 15px 30px 13px 30px !important;
  background-color: transparent !important;
  color: #ffffff !important;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 15px 15px 15px 15px;
  border-color: #FFFFFF;
}
.readon.started.cta-btn.white-border:before {
  border-radius: 0;
}
.readon.started.cta-btn.white-border:hover {
  background-color: #fff;
  color: #8030da !important;
}
.readon.started.cta-btn.white-border:hover:before {
  border-radius: 0;
}
.readon.started.cta-btn.get-small {
  color: #ffffff !important;
  background-color: #F45D48 !important;
  border-radius: 15px 15px 15px 15px !important;
}
.readon.started.cta-btn.get-small:hover {
  color: #da573a !important;
}
.readon.started.cta-btn.requset {
  padding: 16px 35px 16px 35px;
  background-color: #FFFFFF;
  color: #f05454;
  border-radius: 15px 15px 15px 15px;
}
.readon.started.cta-btn.requset:before {
  background-color: #fff;
}
.readon.started.cta-btn.started {
  padding: 16px 38px 16px 38px;
  background-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  color: #7900ff;
}
.readon.started.blue-normal {
  padding: 20px 40px 20px 40px;
  background-color: #0531A3;
  color: #ffffff;
  border-radius: 0px 0px 0px 0px;
}
.readon.started.blue-normal i {
  margin-left: 6px;
}
.readon.started.blue-normal i:before {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
  position: relative;
  top: 0px;
}
.readon.started.blue-normal .btn-text {
  position: relative;
  z-index: 9;
}
.readon.started.blue-normal:before {
  background-color: #093DC2;
}
.readon.started.blue-normal.get-free {
  padding: 18px 32px 18px 32px;
}
.readon.started.blue-normal.try-btn {
  padding: 17px 30px 17px 30px;
  background-color: #ffffff;
  color: #0531a3;
}
.readon.started.blue-normal.try-btn:before {
  background-color: #F2F2F2;
}
.readon.started.blue-normal.learn-more {
  padding: 20px 32px 21px 32px;
  background-color: transparent;
  background-image: linear-gradient(94.57deg, #9b25ff 8.35%, #0194e7 100%);
  border-radius: 50px 50px 50px 50px;
}
.readon.started.blue-normal.learn-more:before {
  background-color: #fff;
}
.readon.started.blue-normal.learn-more:hover {
  color: #121933;
}
.readon.started.blue-normal.learn-more.contact {
  padding: 17px 36px 17px 36px;
}
.readon.started.blue-normal.days-free {
  padding: 17px 34px 17px 34px;
  background-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  color: #1A4D2E;
  font-weight: 700;
}
.readon.started.blue-normal.days-free:before {
  background-color: #EDEDED;
}
.readon.started.free-trail {
  padding: 22px 28px 22px 28px;
  background-color: transparent;
  background-image: linear-gradient(94.57deg, #d6a700 8.35%, #7d16ff 100%);
  border-radius: 15px 15px 15px 15px;
  color: #ffffff;
}
.readon.started.free-trail:before {
  background-color: #7D16FF;
}
.readon.started.free-trail.learn-more {
  padding: 22px 39px 22px 39px;
}
.readon.started.orange-free {
  padding: 21px 40px 21px 40px;
  background-color: #FF9F29;
  color: #000;
  border-radius: 10px 10px 10px 10px;
}
.readon.started.orange-free:before {
  background: #fff;
}
.readon.started.orange-free:hover {
  color: #000;
}
.readon.started.orange-started {
  padding: 21px 40px 21px 40px;
  background-color: #F76540;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
}
.readon.started.get-purple {
  padding: 18px 30px 18px 30px;
  background-color: #6B0AD9;
  color: #ffffff;
  border-radius: 15px 15px 15px 15px;
}
.readon.started.get-purple i:before {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
  position: relative;
  top: 0px;
}
.readon.started.get-purple:before {
  background-color: #8030DA;
}
.readon.started.get-purple.contact {
  padding: 16px 30px 16px 30px;
}
.readon.started.get-purple.small1 {
  padding: 21px 40px 21px 40px;
  background-color: #7900FF;
  border-radius: 80px 80px 80px 80px;
}
.readon.started.get-purple.small1:before {
  background-color: #9A42FC;
}
.readon.started.down-orange {
  padding: 21px 40px 21px 50px;
  background-color: #F05454;
  border-radius: 40px 40px 40px 40px;
  color: #ffffff;
}
.readon.started.down-orange:before {
  background-color: #F87777;
}
.readon.started.small-cta {
  padding: 16px 38px 16px 38px;
  background-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
  color: #2b59ff;
}
.readon.started.small-cta:hover:before {
  background-color: #dee2e6;
}
.readon.started.small-cta.orange-btn {
  color: #DA573A;
}
.readon.know {
  padding: 22px 32px 22px 32px;
  background-color: #6647FF;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
}
.readon.know i {
  margin-left: 6px;
}
.readon.know i:before {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
  position: relative;
  top: 0px;
}
.readon.know:hover {
  background-color: #7B79ED;
  color: #FFFFFF;
}
.readon.try-btn {
  padding: 17px 34px 17px 34px;
  background-color: #FFFFFF;
  color: #6647FF;
  border-radius: 50px 50px 50px 50px;
}
.readon.try-btn:hover {
  color: #121933;
}
.readon.try-btn.free-days {
  border-radius: 15px 15px 15px 15px;
}
.readon.try-btn.free-days .btn-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.readon.try-btn.free-days:hover .btn-text {
  background-color: transparent;
  background-image: linear-gradient(280deg, #d6a700 0%, #7d16ff 100%);
}
.readon.apps-btn {
  padding: 14px 27px 19px 27px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #534FF8;
  border-radius: 15px 15px 15px 15px;
  color: #FFFFFF;
  background-color: #534FF8;
}
.readon.apps-btn i {
  font-size: 21.5px;
}
.readon.apps-btn i:before {
  top: 1px;
  position: relative;
  margin-right: 6px;
}
.readon.apps-btn:hover {
  color: #353535;
  background-color: #02010100;
  border-color: #353535;
}
.readon.apps-btn.play-btn {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  padding: 14px 27px 19px 27px;
  border-color: #353535;
  border-radius: 15px 15px 15px 15px;
  color: #353535;
  background-color: #00000000;
}
.readon.apps-btn.play-btn:hover {
  color: #FFFFFF;
  background-color: #534FF8;
  border-color: #534FF8;
}
.readon.apps-btn.play-btn.cta-app {
  padding: 19px 34px 19px 34px;
}
.readon.apps-btn.play-store {
  padding: 19px 30px 19px 30px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 15px 15px 15px 15px;
  color: #fff;
  background-color: #00000000;
}
.readon.apps-btn.play-store:hover {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000000FC;
  background-color: #460363;
}
.readon.apps-btn.pink-btn {
  background-image: linear-gradient(100deg, #f95660 0%, #910feb 100%);
  padding: 18px 30px 18px 30px;
  border-style: unset;
  border-width: unset;
  border-color: unset;
}
.readon.apps-btn.pink-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(100deg, #910feb 0%, #f95660 100%);
}
.readon.apps-btn.pink-btn.play-white {
  background-color: #F9566000;
  background-image: unset;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
}
.readon.apps-btn.pink-btn.play-white:hover {
  background-color: transparent;
  background-image: linear-gradient(100deg, #910feb 0%, #f95660 100%);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000000FC;
}
.readon.apps-btn.black-light {
  padding: 18px 30px 18px 30px;
  background-color: #460363;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #460363;
  border-radius: 15px 15px 15px 15px;
}
.readon.apps-btn.black-light:hover {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  color: #ffffff;
  border-color: #FFFFFF;
  background-color: #910FEB00;
}
.readon.try-free {
  font-weight: 600;
  color: #ffffff;
  padding: 18px 43px 18px 43px;
  border-radius: 70px;
  background-image: linear-gradient(120deg, #ff0945 0%, #ff803b 100%);
}
.readon.try-free:hover {
  background-image: linear-gradient(100deg, #ff803b 0%, #f2295b 100%);
}
.readon.try-free.get-small {
  padding: 20px 41px 20px 41px;
}
.readon.cta-started {
  padding: 21px 40px 21px 40px;
  background-color: #FFFFFF;
  border-radius: 60px 60px 60px 60px;
}
.readon.cta-started .btn-text {
  background-color: transparent;
  background-image: linear-gradient(120deg, #ff0945 0%, #ff803b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.readon.cta-started:hover .btn-text {
  background-color: transparent;
  background-image: linear-gradient(120deg, #ff803b 0%, #ff0945 100%);
}
.readon.cta-price {
  background: transparent;
  color: #FFFFFF;
  padding: 16px 10px 16px 5px;
}
.readon.cta-price i {
  margin-left: 10px;
}
.readon.cta-price i:before {
  font-size: 17px;
  margin: 0;
  position: relative;
  top: 3px;
}
.readon.cta-price:hover {
  color: #ccc;
}
.readon.download {
  padding: 20px 35px;
  background-color: transparent;
  background-image: linear-gradient(100deg, #f95660 0%, #910feb 100%);
  border-radius: 15px 15px 15px 15px;
  color: #FFFFFF;
  font-weight: 500;
}
.readon.download i {
  font-weight: 400;
  margin-right: 2px;
  font-family: 'remixicon' !important;
}
.readon.download:hover {
  background-image: linear-gradient(100deg, #910feb 0%, #f95660 100%);
}
.readon.download.listen {
  padding: 21px 35px;
}
.readon.download.listen i {
  font-weight: 400;
  margin-right: 5px;
  font-family: "FontAwesome" !important;
}
.readon.hire-us {
  padding: 21px 40px 21px 40px;
  background-color: #da573a;
  border-radius: 15px 15px 15px 15px;
  color: #ffffff;
}
.readon.hire-us:before {
  background-color: #FD7B5E;
}
.readon.hire-us:hover {
  color: #ffffff;
}
.readon.free-trial {
  background-color: unset;
  color: #353535;
  padding: 16px 0px 16px 0px;
}
.readon.free-trial i {
  margin-left: 6px;
}
.readon.free-trial i:before {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
  position: relative;
  top: -1px;
}
.readon.free-trial:hover {
  color: #2b59ff;
}
.readon.submit {
  background-color: #534FF8;
  border-radius: 7px 7px 7px 7px;
  box-shadow: 0px 15px 30px 0px rgba(43, 89, 255, 0.38);
  width: 100%;
  padding: 21px 42px 21px 42px;
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.readon.submit:hover {
  opacity: 0.9;
}
.readon.submit.submit2 {
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 15px 30px 0px rgba(43, 89, 255, 0.38);
}
.readon.submit.submit3 {
  width: 100%;
  border-radius: 5px 5px 5px 5px;
  background-color: #2B59FF;
}
.readon.submit.submit4 {
  max-width: 100%;
  box-shadow: unset;
  width: unset;
  border-radius: 5px 5px 5px 5px;
  background-color: #2B59FF;
}
.readon.error-btn {
  font-size: 16px;
  padding: 16px 38px;
  background: #ff622d;
  border-radius: 30px;
  color: #fff;
  transition: all .4s;
}
.readon.error-btn:hover {
  background: #fff;
  opacity: 1;
  color: #444;
}
/*-- Add Button CSS --*/
.add-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  padding: 8px 33px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  background: #2b59ff;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
}
.add-btn:hover {
  color: #ffffff;
  background: #121933;
}
.add-btn.cart {
  text-transform: capitalize;
}
/*-- Services Button CSS --*/
.services-button a {
  position: relative;
  display: inline-block;
  transition: all ease 0.3s;
  color: #727272;
}
.services-button a i {
  color: #727272;
  margin-left: 0px;
  transform: translateX(0px);
  transition: all ease 0.3s;
  display: inline-block;
}
.services-button a i:before {
  font-size: 24px;
}
.services-button a:hover i {
  color: #6647FF;
}
.services-button.ser-btn2 a {
  padding: 7px 27px 7px 27px;
  font-weight: 700;
  border-radius: 40px 40px 40px 40px;
  background-color: #F05454;
  color: #FFFFFF;
  margin: 0 4px;
}
.services-button.ser-btn2 a i {
  margin-right: 5px;
}
.services-button.ser-btn2 a i:before {
  font-size: 16px;
  top: -2px;
  color: #ffffff;
  position: relative;
}
.services-button.ser-btn2 a:hover {
  background-color: #F87777;
}
.services-button.ser-btn2 a.download {
  color: #F05454;
  background-color: #FFEFEF;
}
.services-button.ser-btn2 a.download i:before {
  color: #F05454;
}
.services-button.ser-btn2 a.download:hover {
  background-color: #F05454;
  color: #ffffff;
}
.services-button.ser-btn2 a.download:hover i:before {
  color: #ffffff;
}
/*-- Blog Button CSS --*/
.blog-button a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #3535359E;
  color: #353535;
  padding: 14px 22px 16px 22px;
  border-radius: 50px 50px 50px 50px;
  transition: all ease 0.3s;
}
.blog-button a .btn-txt {
  color: rgba(53, 53, 53, 0.8);
}
.blog-button a i {
  margin-left: 5px;
}
.blog-button a i:before {
  font-weight: 500;
  font-size: 17px;
  color: rgba(53, 53, 53, 0.8);
  font-family: 'remixicon' !important;
  position: relative;
  top: 1px;
}
.blog-button a:hover {
  color: #ffffff;
  background-color: #2b59ff;
  border-color: #2b59ff;
}
.blog-button a:hover .btn-txt {
  color: #ffffff;
}
.blog-button a:hover i:before {
  color: #fff;
}
.blog-button.blog-btn2 a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-color: #3535359E;
  padding: 16px 24px 18px 24px;
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
}
.blog-button.blog-btn2 a .btn-txt {
  position: relative;
  z-index: 3;
  color: #ffffff;
  opacity: 0.9;
}
.blog-button.blog-btn2 a:before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  background-color: #090423;
  border-radius: inherit;
  transition: all 0.3s;
}
.blog-button.blog-btn2 a i {
  margin-left: 10px;
  right: 0;
  transition: all ease 0.3s;
  position: relative;
}
.blog-button.blog-btn2 a i:before {
  color: #fff;
  opacity: 0.9;
}
.blog-button.blog-btn2 a:hover {
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #0194e7 8.35%, #9b25ff 94.57%);
}
.blog-button.blog-btn2 a:hover:before {
  background: #02010100;
}
.blog-button.blog-btn2 a:hover i {
  right: -8px;
}
.blog-button.blog-btn3 a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border-width: unset;
  border-color: unset;
  padding: 16px 24px 16px 24px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff0945 0%, #ff803b 100%);
}
.blog-button.blog-btn3 a .btn-txt {
  position: relative;
  z-index: 3;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff0945 0%, #ff803b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blog-button.blog-btn3 a:before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  background-color: #fff;
  border-radius: inherit;
  transition: all 0.3s;
}
.blog-button.blog-btn3 a i {
  margin-left: 10px;
  right: 0;
  transition: all ease 0.3s;
  position: relative;
}
.blog-button.blog-btn3 a i:before {
  -webkit-background-clip: text;
  color: transparent;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff0945 0%, #ff803b 100%);
}
.blog-button.blog-btn3 a:hover {
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #ff0945 8.35%, #ff803b 94.57%);
}
.blog-button.blog-btn3 a:hover .btn-txt {
  background-image: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.blog-button.blog-btn3 a:hover i:before {
  color: #fff;
}
.blog-button.blog-btn3 a:hover:before {
  background: #02010100;
}
.blog-button.blog-btn3 a:hover i {
  right: -8px;
}
.blog-button.inner-btn a {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  padding: 14px 0px 15px 0px;
  border: none;
  color: #121933;
  z-index: 1;
}
.blog-button.inner-btn a i {
  margin-left: 3px;
  transition: all ease 0.3s;
}
.blog-button.inner-btn a:hover {
  color: #2b59ff;
  background-color: unset;
}
.blog-button.inner-btn a:hover i {
  margin-left: 7px;
}
.blog-button.inner-btn a:hover i:before {
  color: #2b59ff;
}
.address-wrap-item .icon-part {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #2b59ff;
}
.address-wrap-item .icon-part i {
  text-align: center;
  color: #121933;
  border-radius: 100%;
  font-size: 30px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
}
.address-wrap-item .icon-part:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background: #2b59ff;
}
.address-wrap {
  display: flex;
  align-items: center;
}
.address-wrap .address-icon {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #fff;
}
.address-wrap .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background: #fff;
}
.address-wrap .address-icon i {
  text-align: center;
  color: #ff7425;
  border-radius: 100%;
  font-size: 30px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
  line-height: 55px;
}
.address-wrap .content-txt {
  padding-left: 30px;
}
.address-wrap .des {
  font-size: 25px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
}
/*Video Icon*/
.rs-videos .animate-border .popup-border {
  position: relative;
  font-weight: 700;
  color: #ffffff;
}
.rs-videos .animate-border .popup-border:hover {
  color: #f45d48;
}
.rs-videos .animate-border .popup-border img {
  position: absolute;
  left: 0;
  bottom: -15px;
}
.rs-videos .animate-border.intro-video .popup-border {
  color: #353535;
  font-size: 16px;
  font-weight: 600;
}
.rs-videos .animate-border.intro-video .popup-border .btn-text {
  position: relative;
  z-index: 9;
}
.rs-videos .animate-border.intro-video .popup-border i {
  margin-left: 6px;
}
.rs-videos .animate-border.intro-video .popup-border i:before {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
  position: relative;
  top: 0px;
}
.rs-videos .animate-border.intro-video .popup-border:hover {
  color: #0531a3;
}
.rs-videos .animate-border.watch-intro .popup-border {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.rs-videos .animate-border.watch-intro .popup-border .btn-text {
  position: relative;
  z-index: 9;
}
.rs-videos .animate-border.watch-intro .popup-border i {
  font-size: 20px;
  margin-right: 3px;
  color: transparent;
  background-image: linear-gradient(94.57deg, #9b25ff 8.35%, #0194e7 100%);
  position: relative;
  top: 5px;
  -webkit-background-clip: text;
}
.rs-videos .animate-border.watch-intro .popup-border:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -10px;
  height: 1px;
  background-image: linear-gradient(94.57deg, #9b25ff 8.35%, #0194e7 100%);
}
.rs-videos .animate-border.watch-intro.watch-style1 .popup-border i {
  background-image: linear-gradient(90deg, #d6a700 0%, #7d16ff 100%);
}
.rs-videos .animate-border.watch-intro.watch-style1 .popup-border:after {
  display: none;
}
.rs-videos .animate-border.watch-intro.watch-style1 .popup-border:hover {
  color: #d6a700;
}
.rs-videos .animate-border.watch-ornage .popup-border {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.rs-videos .animate-border.watch-ornage .popup-border .btn-text {
  position: relative;
  z-index: 9;
}
.rs-videos .animate-border.watch-ornage .popup-border i {
  font-size: 20px;
}
.rs-videos .animate-border.watch-ornage .popup-border:hover {
  color: #F76540;
}
.rs-videos .animate-border.work-orange .popup-border {
  position: relative;
  color: #F61A1A;
  color: #ff0945;
  font-size: 16px;
  font-weight: 600;
}
.rs-videos .animate-border.work-orange .popup-border .btn-text {
  position: relative;
  z-index: 9;
  color: #121933;
}
.rs-videos .animate-border.work-orange .popup-border i {
  font-size: 20px;
  margin-right: 3px;
  color: transparent;
  background-image: linear-gradient(120deg, #ff0945 0%, #ff803b 100%);
  position: relative;
  top: 5px;
  -webkit-background-clip: text;
}
.rs-videos .animate-border.work-orange .popup-border:hover .btn-text {
  color: #ff0945;
}
.margin-remove {
  margin: 0 !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.container-fluid {
  padding-left: 130px;
  padding-right: 130px;
}
.container-fluid2 {
  padding-left: 175px;
  padding-right: 175px;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.relative {
  position: relative;
}
.pt-relative {
  position: relative !important;
}
.sec-title {
  position: relative;
}
.sec-title .sub-text {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  color: #2b59ff;
  margin: 0 0 20px;
  display: block;
}
.sec-title .sub-text.sub-text2 {
  font-weight: 500;
  text-transform: uppercase;
  color: #28544b;
}
.sec-title .sub-text.sub-text3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #F76540;
}
.sec-title .sub-text.sub-text4 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #6B0AD9;
}
.sec-title .sub-text.sub-text5 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  background-image: linear-gradient(120deg, #f95660 0%, #910feb 100%);
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sec-title .sub-text.sub-text6 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #da573a;
}
.sec-title .title {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: #121933;
  margin: 0;
}
.sec-title .title.title2 {
  font-size: 55px;
  line-height: 60px;
  font-weight: 400;
  color: #121933;
}
.sec-title .title.title3 {
  font-size: 30px;
  line-height: 38px;
  color: #353535;
}
.sec-title .title.title5 {
  font-size: 40px;
  line-height: 50px;
}
.sec-title .title.title4 {
  position: relative;
  color: #ffffff;
  padding: 45px 0 0 0;
}
.sec-title .title.title4:before {
  content: "";
  position: absolute;
  z-index: 1;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0px !important;
  width: 30px !important;
  height: 8.17px !important;
  border-radius: 6px 6px 6px 6px;
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
}
.sec-title .title.title4.left-side:before {
  margin-left: 0;
}
.sec-title .title.title4.center-style2:before {
  background: #ffffff;
}
.sec-title .title.title5 {
  font-size: 35px;
  line-height: 45px;
  color: #353535;
}
.sec-title .title.title6 {
  font-size: 25px;
  line-height: 35px;
  color: #353535;
}
.sec-title .title.title7 {
  font-size: 20px;
  line-height: 30px;
}
.sec-title .desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #303030;
  margin: 0;
}
.sec-title .desc.desc2 {
  color: #BCBBBB;
}
.sec-title .desc.desc3 {
  color: rgba(255, 255, 255, 0.8);
}
.sec-title .desc.desc4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #FFFFFFCC;
}
.sec-title .desc.desc5 {
  color: #FFFFFFCC;
}
.sec-title .desc.desc6 {
  color: #353535;
}
.sec-title .desc.desc7 {
  color: rgba(255, 255, 255, 0.93);
}
.sec-title .desc.desc8 {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}
.sec-title2 {
  position: relative;
}
.sec-title2 .sub-text {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  background-image: linear-gradient(80deg, #ffffff 0%, #0194e7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #2B59FF;
  margin: 0 0 20px;
  display: block;
}
.sec-title2 .title {
  position: relative;
  font-size: 50px;
  line-height: 1.18;
  color: #FFFFFF;
  font-weight: 700;
  margin: 0px 0px 0px 0px;
}
.sec-title2 .title span {
  position: relative;
  z-index: 1;
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sec-title2 .desc {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #FFFFFFCC;
  margin: 0;
}
/* -----------------------
	03. Header Section CSS
--------------------------*/
.full-width-header {
  position: relative;
}
.full-width-header .rs-header {
  position: relative;
}
.full-width-header .rs-header .menu-area {
  transition: all 0.3s ease 0s;
  background: transparent;
}
.full-width-header .rs-header .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell.header-logo {
  display: unset;
  flex: unset;
}
.full-width-header .rs-header .menu-area .logo-area a .sticky-logo {
  display: none;
}
.full-width-header .rs-header .menu-area .logo-area img {
  transition: 0.4s;
  max-height: 30px;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area .main-menu {
  position: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 22px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child {
  margin-right: 0 !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  height: 110px;
  line-height: 110px;
  padding: 0;
  color: #ffffff;
  position: relative;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #DBD6D6;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a:before {
  content: "\f107";
  position: absolute;
  left: auto;
  font-family: 'FontAwesome' !important;
  right: 0px;
  top: 50%;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
  font-weight: 400;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #DBD6D6 !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a {
  color: #2b59ff !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
  margin: 0;
  padding: 20px 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #3B4052;
  height: unset;
  line-height: 25px;
  text-transform: capitalize;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #2b59ff;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  right: 45px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children:before {
  display: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.right {
  left: auto;
  right: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.last-item {
  display: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.last-item .sub-menu:before {
  height: 100px;
  background: #f00;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu:before {
  content: "";
  position: absolute;
  font-family: remixicon!important;
  font-size: 32px;
  top: 0;
  left: 31px;
  opacity: 1;
  transition: all .3s;
  color: #fff;
  background: #534ff8;
  width: 40px;
  height: 5px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.onepage-menu li.current-menu-item > a {
  color: #2b59ff;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul ul > li.current-menu-item > a {
  color: #2b59ff !important;
}
.full-width-header .rs-header .menu-area.sticky {
  background: #fff;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .normal-logo {
  display: none;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  height: 110px;
  line-height: 110px;
  color: #121933;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #2b59ff !important;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #2b59ff !important;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #2b59ff !important;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #2b59ff;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-quote a {
  color: #121933;
  border: 1.5px solid #121933;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-quote a:before {
  background: #2b59ff;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  border: 1.5px solid #2b59ff;
  background: #2b59ff;
}
.full-width-header .rs-header .expand-btn-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-signin .signin-button {
  font-size: 16px;
  background: transparent;
  height: 60px;
  line-height: 62px;
  padding: 0 37px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-signin .signin-button .sign-text i {
  font-weight: 400;
  margin-right: 5px;
  font-family: 'remixicon' !important;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #DBD6D6;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a {
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  display: inline-block;
  position: relative;
  color: #FFFFFF;
  padding: 13px 27px 13px 27px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #FFFFFF61;
  border-radius: 55px 55px 55px 55px;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a .btn-text {
  position: relative;
  z-index: 4;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a .btn-text i {
  font-weight: 400;
  top: 2px;
  margin-right: 4px;
  font-family: 'remixicon' !important;
}
.full-width-header .rs-header .expand-btn-inner ul .btn-quote a:hover {
  border-color: #F05454;
  background-color: #F05454;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link {
  display: none;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander {
  background: #372748;
  padding: 13px 14px 14px 14px;
  width: 52px;
  height: 52px;
  display: block;
  border-radius: 50%;
  margin-left: 28px;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span {
  height: 2px;
  width: 25px;
  display: block;
  background: #fff;
  transition: none;
  cursor: pointer;
  list-style: none;
  margin: 5px 0;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot1 {
  width: 16px;
  float: right;
  clear: right;
  margin-right: 2px;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot2 {
  clear: both;
}
.full-width-header .rs-header .expand-btn-inner ul .nav-link .nav-expander .bar span.dot3 {
  width: 16px;
  margin-left: 2px;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #fff !important;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #FFFFFFC9;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #ffffff;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a {
  color: #f45d48 !important;
}
.full-width-header .rs-header.header-style1 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #121933;
}
.full-width-header .rs-header.header-style1 .expand-btn-inner ul .btn-quote a {
  background: #f45d48;
  color: #ffffff;
  border: none;
}
.full-width-header .rs-header.header-style1 .expand-btn-inner ul .btn-quote a:hover {
  background-color: #d32a12;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a,
.full-width-header .rs-header.header-style1 .menu-area.sticky .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #121933;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #f45d48 !important;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky .expand-btn-inner ul .btn-quote a {
  color: #fff;
  border: none;
}
.full-width-header .rs-header.header-style1 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  border: none;
  background-color: #d32a12;
}
.full-width-header .rs-header.header-style1.header-modify1 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify1 .menu-area .row-table .col-cell.header-logo {
  display: unset;
  flex: unset;
}
.full-width-header .rs-header.header-style1.header-modify1 .menu-area .rs-menu-area {
  padding-right: 150px;
}
.full-width-header .rs-header.header-style1.header-modify1 .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify1 .expand-btn-inner ul .btn-quote a:before {
  background: #000 !important;
}
.full-width-header .rs-header.header-style1.header-modify1 .expand-btn-inner ul .btn-quote a {
  background: #6647ff;
  border-color: #6647ff;
  border: 1.5px solid #6647ff;
  border-radius: 15px;
}
.full-width-header .rs-header.header-style1.header-modify1 .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #333;
  border: 1.5px solid #333;
}
.full-width-header .rs-header.header-style1.header-modify1 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #f45d48;
}
.full-width-header .rs-header.header-style1.header-modify1 .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify1 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  background-color: #333;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #d6a700 !important;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #fff;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button,
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #fff !important;
}
.full-width-header .rs-header.header-style1.header-modify2 .menu-area.sticky {
  background: #1b114c !important;
}
.full-width-header .rs-header.header-style1.header-modify2 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #d6a700;
}
.full-width-header .rs-header.header-style1.header-modify2 .expand-btn-inner ul .btn-quote a {
  background: linear-gradient(100.24deg, #d6a700 8.35%, #7d16ff 94.57%);
  border: none !important;
  background-size: 200% auto !important;
  padding: 18px 32px 18px 32px;
  border-radius: 15px !important;
  color: #ffffff;
}
.full-width-header .rs-header.header-style1.header-modify2 .expand-btn-inner ul .btn-quote a:hover {
  background: linear-gradient(100.24deg, #7d16ff 8.35%, #d6a700 94.57%);
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-quote a {
  background: #113cfc;
  border: unset;
  color: #ffffff;
  border-radius: 15px;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-quote a:before {
  background: #4465fc;
  border-color: unset;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-signin .signin-button {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify3 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #113cfc;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #113cfc !important;
}
.full-width-header .rs-header.header-style1.header-modify3 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area .row-table .col-cell.header-logo {
  display: unset;
  flex: unset;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area .rs-menu-area {
  padding-right: 150px;
}
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #DBD6D6 !important;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify4 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify4 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify4 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify4 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu ul li.active a {
  color: #fe9e28 !important;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify4 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #DBD6D6 !important;
}
.full-width-header .rs-header.header-style1.header-modify4 .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify4 .expand-btn-inner ul .btn-quote a:before {
  background: #fff !important;
}
.full-width-header .rs-header.header-style1.header-modify4 .expand-btn-inner ul .btn-quote a {
  background: #ff9f29;
  border-color: #ff9f29;
  border: unset;
  border-radius: 70px;
}
.full-width-header .rs-header.header-style1.header-modify4 .expand-btn-inner ul .btn-quote a:hover {
  color: #ff9f29;
  background: #fff;
  border: unset;
}
.full-width-header .rs-header.header-style1.header-modify4 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #DBD6D6;
}
.full-width-header .rs-header.header-style1.header-modify4 .expand-btn-inner ul .btn-signin .signin-button {
  color: #fff;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify5 .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify5 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify5 .main-menu .rs-menu ul li.active a {
  color: #8030da !important;
}
.full-width-header .rs-header.header-style1.header-modify5 .expand-btn-inner ul .btn-quote a:hover {
  background-color: #e3542f;
}
.full-width-header .rs-header.header-style1.header-modify5 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify6 .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify6 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #7900ff !important;
}
.full-width-header .rs-header.header-style1.header-modify6 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a {
  border-color: unset;
  border: none;
  background-image: linear-gradient(100deg, #f95660 0%, #910feb 100%);
  border-radius: 15px;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a:before {
  background: unset !important;
}
.full-width-header .rs-header.header-style1.header-modify6 .expand-btn-inner ul .btn-quote a:hover {
  background-image: linear-gradient(100deg, #910feb 0%, #f95660 100%);
  color: #ffffff;
  border: none;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify7 .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify7 .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #534ff8 !important;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-quote a {
  background-color: #534FF8;
  padding: 12px 25px 12px 25px;
  border-radius: 55px 55px 55px 55px;
  border: none;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-quote a:hover {
  background-color: #413dd9c7;
}
.full-width-header .rs-header.header-style1.header-modify7 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  background-color: #413dd9c7;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #534ff8;
}
.full-width-header .rs-header.header-style1.header-modify7 .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify8 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify8 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #ccc !important;
}
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify8 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul.sub-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu ul li.active a {
  color: #534ff8 !important;
}
.full-width-header .rs-header.header-style1.header-modify8 .main-menu .rs-menu > ul > li.current-menu-item > a {
  color: #ccc !important;
}
.full-width-header .rs-header.header-style1.header-modify8 .expand-btn-inner ul .btn-quote a:before {
  background: #6c6af7 !important;
}
.full-width-header .rs-header.header-style1.header-modify8 .expand-btn-inner ul .btn-quote a {
  background: #534ff8;
  border-color: #534ff8;
  border: 1.5px solid #534ff8;
  border-radius: 70px;
}
.full-width-header .rs-header.header-style1.header-modify8 .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #6c6af7;
  border: 1.5px solid #6c6af7;
}
.full-width-header .rs-header.header-style1.header-modify8 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #f2f2f2;
}
.full-width-header .rs-header.header-style1.header-modify8 .expand-btn-inner ul .btn-signin .signin-button {
  color: #fff;
}
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify8 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #6c6af7;
  border: none;
}
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify9 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify9 .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #7900ff !important;
}
.full-width-header .rs-header.header-style1.header-modify9 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify9 .expand-btn-inner ul .btn-quote a:before {
  background: #a76ee5 !important;
}
.full-width-header .rs-header.header-style1.header-modify9 .expand-btn-inner ul .btn-quote a {
  background: #7900ff;
  border-color: #7900ff;
  border: 1.5px solid #7900ff;
  border-radius: 70px;
}
.full-width-header .rs-header.header-style1.header-modify9 .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #a76ee5;
  border: 1.5px solid #a76ee5;
}
.full-width-header .rs-header.header-style1.header-modify9 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #7900ff;
}
.full-width-header .rs-header.header-style1.header-modify9 .expand-btn-inner ul .btn-signin .signin-button {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify9 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: #a76ee5;
  border: none;
}
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .row-table {
  justify-content: unset;
}
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify10 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify10 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .main-menu .rs-menu ul.nav-menu li ul > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify10 .menu-area .main-menu .rs-menu ul li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify10 .main-menu .rs-menu ul li.current-menu-item > a:before {
  color: #ff0945 !important;
}
.full-width-header .rs-header.header-style1.header-modify10 .expand-btn-inner ul .btn-quote a:before {
  background: unset !important;
}
.full-width-header .rs-header.header-style1.header-modify10 .expand-btn-inner ul .btn-quote a {
  background-image: linear-gradient(120deg, #ff0945 0%, #ff803b 100%);
  border-color: unset;
  border: none;
  border-radius: 70px;
}
.full-width-header .rs-header.header-style1.header-modify10 .expand-btn-inner ul .btn-quote a:hover {
  background-image: linear-gradient(100deg, #ff803b 0%, #f2295b 100%);
  color: #ffffff;
  border: none;
}
.full-width-header .rs-header.header-style1.header-modify10 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #ff0945;
}
.full-width-header .rs-header.header-style1.header-modify10 .expand-btn-inner ul .btn-signin .signin-button {
  color: #353535;
}
.full-width-header .rs-header.header-style1.header-modify10 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #ff0945 !important;
}
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky {
  background: #462948 !important;
}
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button:hover,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #da573a !important;
}
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #fff;
}
.full-width-header .rs-header.header-style1.header-modify11 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #da573a;
}
.full-width-header .rs-header.header-style1.header-modify11 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #121933;
}
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button,
.full-width-header .rs-header.header-style1.header-modify11 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #fff !important;
}
.full-width-header .rs-header.header-style1.header-modify11 .expand-btn-inner ul .btn-quote a:before {
  background: unset !important;
}
.full-width-header .rs-header.header-style1.header-modify11 .expand-btn-inner ul .btn-quote a {
  background-image: linear-gradient(100deg, #f95660 0%, #910feb 100%);
  border-color: unset;
  border: none;
  border-radius: 70px;
}
.full-width-header .rs-header.header-style1.header-modify11 .expand-btn-inner ul .btn-quote a:hover {
  background-image: linear-gradient(100deg, #910feb 0%, #f95660 100%);
  color: #ffffff;
  border: none;
}
.full-width-header .rs-header.header-style2 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #353535;
}
.full-width-header .rs-header.header-style2 .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.header-style2 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover,
.full-width-header .rs-header.header-style2 .menu-area .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style2 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #0531a3 !important;
}
.full-width-header .rs-header.header-style2 .expand-btn-inner ul .btn-signin .signin-button {
  background: #0531a3;
  height: 67px;
  line-height: 63px;
  border-radius: 0;
}
.full-width-header .rs-header.header-style2 .expand-btn-inner ul .btn-signin .signin-button:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #121933;
  background: transparent;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.full-width-header .rs-header.header-style2 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #ffffff !important;
}
.full-width-header .rs-header.header-style2 .expand-btn-inner ul .btn-signin .signin-button .sign-text {
  position: relative;
  z-index: 9;
}
.full-width-header .rs-header.header-style2 .expand-btn-inner ul .btn-signin .signin-button:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
  z-index: 2;
  background: #053ed1 !important;
}
.full-width-header .rs-header.header-style2 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button {
  color: #fff;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .btn-signin .signin-button:hover {
  color: #6647ff;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .btn-quote a {
  background: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  border-radius: 50px;
  padding: 15px 29px 15px 29px;
  border: none;
}
.full-width-header .rs-header.header-style3 .expand-btn-inner ul .btn-quote a:hover {
  background: linear-gradient(100.24deg, #0194e7 8.35%, #9b25ff 94.57%);
}
.full-width-header .rs-header.header-style3 .menu-area.sticky .expand-btn-inner ul .btn-quote a {
  color: #fff;
  border: none;
}
.full-width-header .rs-header.header-style3 .menu-area.sticky .expand-btn-inner ul .btn-quote a:hover {
  color: #ffffff;
  background: linear-gradient(100.24deg, #0194e7 8.35%, #9b25ff 94.57%);
  border: none;
}
.full-width-header .rs-header.header-style3 .menu-area.sticky .main-menu .rs-menu ul li.current-menu-item > a:before,
.full-width-header .rs-header.header-style3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover,
.full-width-header .rs-header.header-style3 .menu-area.sticky .expand-btn-inner ul .btn-signin .signin-button,
.full-width-header .rs-header.header-style3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #fff !important;
}
.full-width-header .rs-header.header-style3 .menu-area.sticky {
  background: #1b114c !important;
}
.full-width-header .rs-header.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header .mobile-menu {
  display: none;
}
.full-width-header .mobile-menu .mobile-logo {
  float: left;
}
.full-width-header .mobile-menu .mobile-logo img {
  max-height: 30px;
}
.full-width-header .mobile-menu .rs-menu-toggle {
  color: #ffffff;
  float: right;
}
.full-width-header .mobile-menu .rs-menu-toggle.primary {
  color: #2b59ff;
}
.full-width-header .mobile-menu .rs-menu-toggle.secondary {
  color: #f45d48;
}
.full-width-header .mobile-menu .rs-menu-toggle .nav-dots {
  position: absolute;
  right: 0;
  width: 25px;
  height: 2px;
  background: #fff;
  z-index: 11;
  top: 23px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot1,
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  width: 15px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot2 {
  top: 28px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  top: 34px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot4 {
  top: 40px;
}
.full-width-header .right_menu_togle {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #eee;
  padding: 90px 60px 60px 50px;
  font-size: 1.15em;
  max-width: 480px;
  width: 100%;
  right: -500px;
  z-index: 999999;
  transition: all 0.3s ease;
  position: fixed;
  overflow-y: auto;
}
.full-width-header .right_menu_togle .close-btn {
  overflow: visible;
  position: absolute;
  top: 10px;
  display: block;
  z-index: 99;
  right: 57px;
  cursor: pointer;
  transition: all .3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close {
  position: absolute;
  top: 23px;
  display: block;
  z-index: 99;
  right: 3px;
  cursor: pointer;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 1px !important;
  height: 25px !important;
  background: #101010;
  border: none !important;
  transition: all 0.3s ease;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span.line2 {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.full-width-header .right_menu_togle .close-btn:hover .nav-close .line span {
  background: #2b59ff;
}
.full-width-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 35px;
}
.full-width-header .right_menu_togle .canvas-logo img {
  max-height: 45px;
}
.full-width-header .right_menu_togle .offcanvas-text {
  border-bottom: 1px solid rgba(62, 62, 62, 0.1);
  margin-bottom: 45px;
  padding-bottom: 24px;
}
.full-width-header .right_menu_togle .offcanvas-text p {
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  margin: 0px 0 38px;
}
.full-width-header .right_menu_togle .canvas-contact {
  margin-top: 50px;
}
.full-width-header .right_menu_togle .canvas-contact ul {
  display: flex;
  align-items: center;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button {
  font-size: 16px;
  background: transparent;
  height: 60px;
  line-height: 62px;
  margin-right: 25px;
  color: #121933;
  font-weight: 600;
  display: inline-block;
  border-radius: 50px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button .sign-text i {
  font-weight: 400;
  margin-right: 5px;
  font-family: 'remixicon' !important;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-signin .signin-button:hover {
  color: #2b59ff;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a {
  font-size: 16px;
  font-weight: 600;
  background-color: #2b59ff;
  display: inline-block;
  position: relative;
  color: #ffffff;
  padding: 13px 27px 13px 27px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #2b59ff;
  border-radius: 55px 55px 55px 55px;
  transition: all 0.3s ease;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a .btn-text {
  position: relative;
  z-index: 4;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a .btn-text i {
  font-weight: 400;
  top: 2px;
  margin-right: 4px;
  font-family: 'remixicon' !important;
}
.full-width-header .right_menu_togle .canvas-contact ul .btn-quote a:hover {
  border-color: #f45d48;
  background-color: #f45d48;
}
.full-width-header .mobile-navbar-menu .sub-menu {
  display: none;
  position: relative;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: left;
}
.full-width-header .mobile-navbar-menu .nav-menu li {
  position: relative;
}
.full-width-header .mobile-navbar-menu .nav-menu li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #121933;
  padding: 10px 0;
  line-height: 30px;
  width: 100%;
}
.full-width-header .mobile-navbar-menu .nav-menu li li a {
  padding-left: 25px;
}
.full-width-header .mobile-navbar-menu .nav-menu li li li a {
  padding-left: 50px;
}
.full-width-header .mobile-navbar-menu span.submenu-button {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 5px;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before,
.full-width-header .mobile-navbar-menu span.submenu-button:after {
  position: absolute;
  top: 24px;
  right: 10px;
  width: 20px;
  height: 1px;
  display: block;
  background: #333;
  z-index: 2;
  content: '';
  right: 12px;
  width: 12px;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before {
  width: 1px;
  height: 12px;
  right: 17px;
  top: 18px;
}
.full-width-header .mobile-navbar-menu span.submenu-button em {
  display: block;
  position: absolute;
  right: 0;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.full-width-header .mobile-navbar-menu .hash-has-sub span.submenu-button {
  width: 100%;
  cursor: pointer;
  top: 0;
  height: 50px;
}
.full-width-header .mobile-navbar-menu .submenu-button.submenu-opened:before {
  display: none;
}
.full-width-header .mobile-navbar-menu ul ul li,
.full-width-header .mobile-navbar-menu li:hover > ul > li {
  height: auto;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:after {
  top: 19px;
}
.full-width-header .mobile-navbar-menu ul.nav-menu > li:first-child > span.submenu-button:before {
  top: 13px;
}
.full-width-header .mobile-navbar-menu .nav-menu li.active-menu a,
.full-width-header .mobile-navbar-menu .nav-menu li a:hover {
  color: #2b59ff;
}
body.modal-open {
  padding: 0 !important;
}
body .modal.show .modal-dialog {
  width: 100%;
  max-width: 500px;
}
body .modal {
  z-index: 9999;
}
body .search-modal {
  padding: 0 !important;
  background: rgba(187, 11, 11, 0.9);
}
body .search-modal .modal-content {
  position: initial;
  border: 0;
  background: unset;
}
body .search-modal .search-block form {
  position: relative;
}
body .search-modal .search-block form input {
  background: 0 0;
  border: none;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  padding: 14px 17px;
  border-radius: unset;
}
body .search-modal .search-block form button {
  background: transparent;
  border: none;
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  position: absolute;
  content: '';
  right: 0;
  top: 11px;
}
body .search-modal .search-block form button i:before {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  opacity: .7;
}
body .search-modal .search-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #fff;
  opacity: 1;
}
body .search-modal .search-block ::-moz-placeholder {
  /* Firefox 19+ */

  color: #fff;
  opacity: 0.3;
}
body .search-modal .search-block :-ms-input-placeholder {
  /* IE 10+ */

  color: #fff;
  opacity: 1;
}
body .search-modal .search-block :-moz-placeholder {
  /* Firefox 18- */

  color: #fff;
  opacity: 1;
}
body .search-modal .close {
  position: fixed;
  right: 50px;
  top: 50px;
  background: #ffffff;
  color: #0a0a0a;
  width: 40px;
  height: 40px;
  line-height: 43px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all .5s ease;
  opacity: unset;
  box-shadow: unset;
  border: none;
}
body .search-modal .close i:before {
  font-size: 23px;
  position: relative;
  top: 4px;
  left: 0px;
  font-weight: 700;
}
body .search-modal .close:hover {
  opacity: 0.8 !important;
}
/*------------------------------------
	04. Sticky Menu CSS
--------------------------------------*/
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
	05. About Section CSS
---------------------------------------*/
.rs-about.about-style1 {
  position: relative;
}
.rs-about.about-style1.about-ser3 .ser-left {
  margin: 0px 0px 0px -190px;
}
.rs-about.about-style1.about-ser3 .inner-ser-btn {
  display: flex;
  align-items: center;
}
.rs-about.about-style1.about-ser3 .inner-ser-btn li {
  display: inline-block;
  margin-right: 28px;
}
.rs-about.about-style1.about-ser3 .inner-ser-btn li:last-child {
  margin-right: 0;
}
.rs-about.about-style1 .about-image-wrap {
  margin: 0px 0px 0px -120px;
}
.rs-about.about-style1 .about-image-wrap .images-part .man-img {
  position: absolute;
  top: 31.9%;
}
.rs-about.about-style1 .about-inner-wrap {
  margin: 0 -110px 0 0;
}
.rs-about.about-style1 .apps-feature {
  margin: 0% 0% 0% 0%;
  --e-column-margin-right: 0%;
  --e-column-margin-left: 0%;
  padding: 0% 5% 0% 38%;
}
.rs-about.about-style1 .total-application {
  background-color: #FFFBF6;
  padding: 120px 0px 140px 0px;
  border-radius: 50px 0px 0px 50px;
}
.rs-about.about-style1 .total-application .dashboard-img {
  position: relative;
  margin: 0px -10px 0px 70px;
}
.rs-about.about-style1 .total-application .dashboard-img .feature-img {
  position: absolute;
  bottom: 0;
}
.rs-about.about-style1 .total-application .dashboard-img .feature-img.shape1 {
  left: -63px;
  top: -15%;
}
.rs-about.about-style1 .total-application .dashboard-img .feature-img.shape2 {
  right: 19%;
  top: -18%;
}
.rs-about.about-style1 .total-application .dashboard-img .feature-img.shape3 {
  right: 31%;
  bottom: -31%;
}
.rs-about.about-style1.about-modify1 {
  position: relative;
}
.rs-about.about-style1.about-modify1 .about-wrap-left {
  margin: 0 0px 0 -120px;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle {
  position: absolute;
  bottom: 0;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.one {
  left: 9%;
  top: 21%;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.one img {
  max-width: 32px;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.two {
  left: 19%;
  top: 9%;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.two img {
  max-width: 15px;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.three {
  left: 43%;
  top: 9%;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.three img {
  max-width: 20px;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.four {
  left: 42%;
  top: 37%;
}
.rs-about.about-style1.about-modify1 .about-wrap-left .circle.four img {
  max-width: 32px;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .main-img {
  position: relative;
  right: -18%;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle {
  position: absolute;
  bottom: 0;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.one {
  right: 44%;
  top: 53%;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.one img {
  max-width: 15px;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.two {
  right: 14%;
  top: 76%;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.two img {
  max-width: 30px;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.three {
  right: 41%;
  top: 82%;
}
.rs-about.about-style1.about-modify1 .security-services .about-wrap .circle.three img {
  max-width: 20px;
}
.rs-about.about-style1.about-modify1 .about-img img {
  border-radius: 45px 45px 45px 45px;
}
.rs-about.about-style1.about-modify2 .total-application {
  background-color: transparent;
  padding: 0px 0px 0px 0px;
}
.rs-about.about-style2 .about-wrap {
  position: relative;
}
.rs-about.about-style2 .about-wrap .multi-img {
  position: absolute;
  bottom: -50px;
  right: -10%;
}
.rs-about.about-style2 .about-image-wrap {
  position: relative;
  margin: 0 -65px 0 0;
}
.rs-about.about-style2 .about-image-wrap .main-img {
  border-radius: 98px 98px 98px 98px;
}
.rs-about.about-style2 .about-image-wrap .images-part {
  position: absolute;
  bottom: 28%;
  left: 4%;
}
.rs-about.about-style2 .about-image-wrap .images-part img {
  border-radius: 30px;
}
.rs-about.about-style2 .about-image {
  margin: 0 -190px 0 0;
}
/* ------------------------------------
	06. apps Section CSS
---------------------------------------*/
.rs-apps-screenshots.apps-style1 {
  position: relative;
  z-index: 11;
}
.rs-apps-screenshots.apps-style1 .slider .grid-figure {
  position: relative;
  transition: all 0.3s ease 0s;
  padding: 0 40px;
}
.rs-apps-screenshots.apps-style1 .slider .grid-figure .slide-image {
  padding: 45px 0px 45px 0px;
}
.rs-apps-screenshots.apps-style1 .slider .grid-figure .slide-image img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
}
.rs-apps-screenshots.apps-style1 .slider .grid-figure.slick-center {
  transform: scale(1.13);
  z-index: 3;
}
.rs-apps-screenshots.apps-style1 .slider .grid-figure.slick-center .slider-image {
  padding: 45px 0px 45px 0px;
}
.rs-apps-screenshots.apps-style1 .slider .slick-list {
  padding: 0 !important;
}
.rs-apps-screenshots.apps-style1 .slider .slick-dots li button {
  background-color: #FFFFFF29;
}
.rs-apps-screenshots.apps-style1 .slider .slick-dots li:hover button,
.rs-apps-screenshots.apps-style1 .slider .slick-dots li.slick-active button {
  background-color: #ffffff;
}
.rs-apps-screenshots.apps-style1 .multi-images {
  position: absolute;
  left: 29%;
  transform: translateY(-93%);
  z-index: -11;
}
.rs-apps-screenshots.apps-style1 .multi-images img {
  max-width: 800px;
  animation-duration: 5s;
}
/* ------------------------------------
	07. Feature Section CSS
---------------------------------------*/
.rs-feature.feature-style1 .services-seo .images-part img {
  position: relative;
  right: -12%;
}
.rs-feature.feature-style1 .apps-image {
  padding: 115px 0px 105px 0px;
  background-color: #FFFBF6;
  text-align: center;
}
.rs-feature.feature-style1 .apps-feature {
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0% 30% 0% 7%;
}
.rs-feature.feature-style1 .score-metar {
  margin-top: 130px;
}
.rs-feature.feature-style1 .score-metar .secore-main {
  position: relative;
  margin: 75px 0px 0px -100px;
}
.rs-feature.feature-style1 .score-metar .secore-main .secore-small {
  position: absolute;
  bottom: 0;
}
.rs-feature.feature-style1 .score-metar .secore-main .secore-small.one {
  right: 18%;
  top: -28%;
}
.rs-feature.feature-style1 .score-metar .secore-main .secore-small.two {
  right: 13%;
  bottom: 7%;
}
.rs-feature.feature-style2 .feature-main {
  position: relative;
}
.rs-feature.feature-style2 .feature-main .multi-img {
  position: absolute;
}
.rs-feature.feature-style2 .feature-main .multi-img.one {
  right: -2%;
  bottom: -23%;
}
.rs-feature.feature-style2 .feature-main .multi-img.two {
  left: 2%;
  bottom: 25%;
}
.rs-feature.feature-style2 .feature-main .multi-img.three {
  left: 2%;
  bottom: 9%;
}
.rs-feature.feature-style2.feature-modify1 .feature-btn-part {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style2.feature-modify1 .feature-btn-part li {
  display: inline-block;
  margin-right: 26px;
}
.rs-feature.feature-style2.feature-modify1 .feature-btn-part li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style2.feature-modify1 .feature-img {
  position: relative;
  margin: 0px 0px 0px -144px;
}
.rs-feature.feature-style2.feature-modify1 .feature-img .multi-img {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify1 .feature-img .multi-img.middle {
  left: 25%;
  top: 3%;
}
.rs-feature.feature-style2.feature-modify1 .feature-img .multi-img.circle {
  left: 37%;
  top: 54%;
}
.rs-feature.feature-style2.feature-modify1 .features-connect .cloud-img img {
  max-width: 900px;
  right: -21%;
  position: relative;
}
.rs-feature.feature-style2.feature-modify2 .circle-image {
  position: relative;
  text-align: right;
  margin: 0px -110px 0px 0px;
}
.rs-feature.feature-style2.feature-modify2 .circle-image .multi-images {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify2 .circle-image .multi-images.one {
  right: 17%;
  top: -2%;
}
.rs-feature.feature-style2.feature-modify2 .circle-image .multi-images.two {
  right: 20%;
  top: 25%;
}
.rs-feature.feature-style2.feature-modify2 .circle-image .multi-images.three {
  right: 17%;
  bottom: -3.5%;
}
.rs-feature.feature-style2.feature-modify2 .circle-image2 {
  position: relative;
  margin: 0px 0px 0px -110px;
}
.rs-feature.feature-style2.feature-modify2 .circle-image2 .multi-images {
  position: absolute;
  z-index: 1;
}
.rs-feature.feature-style2.feature-modify2 .circle-image2 .multi-images.one {
  right: 15%;
  top: -11%;
  z-index: 111;
}
.rs-feature.feature-style2.feature-modify2 .circle-image2 .multi-images.two {
  right: 30%;
  top: 16%;
  z-index: 11;
}
.rs-feature.feature-style2.feature-modify2 .circle-image2 .multi-images.three {
  bottom: -12.5%;
  z-index: 111;
  left: -18%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group {
  position: relative;
  text-align: right;
  margin: 25% -68% 0% 0%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.img1 {
  right: 18%;
  top: -25%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.img2 {
  left: 13.5%;
  top: -25%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.circle2 {
  right: 13%;
  top: -36%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.group {
  right: 6%;
  top: 41%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.group2 {
  right: 2%;
  top: 70%;
}
.rs-feature.feature-style2.feature-modify3 .feature-group .multi-images.group2 img {
  max-width: 96px;
}
.rs-feature.feature-style2.feature-modify4 .feature-btn-wrap .bnr-wrap {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style2.feature-modify4 .feature-btn-wrap .bnr-wrap li {
  display: inline-block;
  margin-right: 10px;
}
.rs-feature.feature-style2.feature-modify4 .feature-btn-wrap .bnr-wrap li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style2.feature-modify4 .feature-img img {
  border-radius: 45px 45px 45px 45px;
}
.rs-feature.feature-style2.feature-modify5 .main-wrap-img {
  position: relative;
  text-align: right;
  margin: 0 -20px 0 0;
}
.rs-feature.feature-style2.feature-modify5 .main-wrap-img .multi-images {
  position: absolute;
}
.rs-feature.feature-style2.feature-modify5 .main-wrap-img .multi-images.one {
  left: -5%;
  top: 5%;
}
.rs-feature.feature-style2.feature-modify5 .main-wrap-img .multi-images.two {
  left: -5%;
  top: 27%;
}
.rs-feature.feature-style2.feature-modify5 .main-wrap-img .multi-images.three {
  bottom: -23%;
  left: -10%;
}
.rs-feature.feature-style2.feature-modify5 .heating-online .services-list {
  position: relative;
}
.rs-feature.feature-style2.feature-modify5 .heating-online .services-list .multi-images {
  position: absolute;
  bottom: -24%;
  right: -9%;
}
.rs-feature.feature-style2.feature-modify5 .heating-online .bnr-btn-wrap {
  display: flex;
  align-items: center;
}
.rs-feature.feature-style2.feature-modify5 .heating-online .bnr-btn-wrap li {
  display: inline-block;
  margin-right: 30px;
}
.rs-feature.feature-style2.feature-modify5 .heating-online .bnr-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-feature.feature-style2.feature-modify6 .social-icon-img {
  position: relative;
  z-index: 11;
}
.rs-feature.feature-style2.feature-modify6 .social-icon-img .social-main .main-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-feature.feature-style2.feature-modify6 .social-icon-img .social-main .border-big {
  position: absolute;
  left: 7%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 470px;
  z-index: -11;
}
.rs-feature.feature-style2.feature-modify6 .social-icon-img .social-main .border-small {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-feature.feature-style3 .feature-wrap-img {
  position: relative;
}
.rs-feature.feature-style3 .feature-wrap-img .shape-img {
  position: absolute;
  right: -18%;
  bottom: -18%;
}
.rs-feature.feature-style3 .phone-wrap-img {
  position: relative;
  z-index: 11;
}
.rs-feature.feature-style3 .phone-wrap-img .circle {
  position: absolute;
  left: -8%;
  top: 16%;
  z-index: -11;
}
.rs-feature.feature-style3 .services-wrap-img {
  position: relative;
  padding: 0px 0px 0px 40px;
}
.rs-feature.feature-style3 .services-wrap-img .ser-left {
  position: absolute;
  left: 11%;
  top: 38%;
}
/* ------------------------------------
	08. Breadcrumbs Section CSS
---------------------------------------*/
.rs-breadcrumbs .breadcrumbs-inner {
  position: relative;
  padding: 165px 0px 165px 0px;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-wrap {
  text-align: center;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-wrap .page-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 15px;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-wrap .breadcrumbs-title span a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  position: relative;
  color: #ffffff;
  padding-right: 15px;
  margin-right: 15px;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-wrap .breadcrumbs-title span a:before {
  content: "";
  background-color: #fff;
  height: 16px;
  width: 1px;
  position: absolute;
  right: -1px;
  top: 0px;
  transform: rotate(26deg);
  display: block !important;
}
.rs-breadcrumbs .breadcrumbs-inner .breadcrumbs-wrap .breadcrumbs-title .post {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 {
  padding: 165px 0 0px;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap {
  text-align: left;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .description {
  color: #FFFFFFC4;
  margin: 0;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap {
  padding: 60px 50px 65px 50px;
  background-color: #FFFFFF;
  border-radius: 40px 40px 40px 40px;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap .from-control {
  font-size: 18px;
  padding: 16px 30px 16px 30px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #35353545;
  color: #35353566;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap textarea {
  height: 160px;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #353535;
  opacity: 0.5;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #353535;
  opacity: 0.5;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #353535;
  opacity: 0.5;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .breadcrumbs-wrap .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #353535;
  opacity: 0.5;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .bnr-inner-shape .multi-shapes.one img {
  max-width: 64px;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .bnr-inner-shape .multi-shapes.one {
  left: -20%;
  top: 40%;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .bnr-inner-shape .multi-shapes.two {
  left: unset;
  top: 20%;
  right: -13%;
}
.rs-breadcrumbs .breadcrumbs-inner.breadcrumbs-style1 .bnr-inner-shape .multi-shapes.two img {
  max-width: 22px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes {
  position: absolute;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.one {
  left: -24%;
  top: 15%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.one img {
  max-width: 64px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.two {
  left: -10%;
  top: 46%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.two img {
  max-width: 46px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.three {
  left: -25%;
  bottom: 10%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.three img {
  max-width: 50px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.four {
  right: 14%;
  bottom: 16%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.four img {
  max-width: 30px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.five {
  right: 8%;
  bottom: 49%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.five img {
  max-width: 50px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.six {
  right: -11%;
  bottom: 12%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.six img {
  max-width: 124px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.seven {
  right: -8%;
  top: 28%;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.seven img {
  max-width: 23px;
}
.rs-breadcrumbs .breadcrumbs-inner .bnr-inner-shape .multi-shapes.eight {
  left: 48%;
  top: 28%;
}
.rs-breadcrumbs.inner-bg1 {
  background-image: linear-gradient(140deg, #bb04eb 0%, #26009c 100%);
}
/*.rs-breadcrumbs.inner-bg2 {*/
/*  background: url(assets/images/bg/contact-bg2.png);*/
/*  background-repeat: no-repeat;*/
/*  background-position: top center;*/
/*  background-size: cover;*/
/*  position: relative;*/
/*  z-index: 3;*/
/*}*/
/* ------------------------------------
	09. Banner Section CSS
---------------------------------------*/
.rs-banner.banner-style4 {
  padding: 300px 0px 163px 0px;
}
.rs-banner.banner-style4 .content-wrap .title {
  font-size: 85px;
  font-weight: 700;
  line-height: 100px;
  color: #FFFFFF;
  margin: 0px 0px 20px 0px;
}
.rs-banner.banner-style4 .content-wrap .description p {
  color: #fff;
  opacity: 0.8;
  margin: 0px 0px 37px 0px;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form {
  position: relative;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form input[type="email"] {
  padding: 22px 35px 22px 35px;
  border-radius: 50px 50px 50px 50px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FFFFFF40;
  background-color: #FFFFFF1A;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form input[type="submit"] {
  position: absolute;
  top: 6px;
  right: 6px;
  display: inline-block;
  padding: 17px 30px 17px 30px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 52px 52px 52px 52px;
  border: none;
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  transition: all 0.3s ease;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form input[type="submit"]:hover {
  opacity: .9;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #fff;
  opacity: 0.6;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form ::-moz-placeholder {
  /* Firefox 19+ */

  color: #fff;
  opacity: 0.6;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form :-ms-input-placeholder {
  /* IE 10+ */

  color: #fff;
  opacity: 0.6;
}
.rs-banner.banner-style4 .content-wrap .newsletter-form :-moz-placeholder {
  /* Firefox 18- */

  color: #fff;
  opacity: 0.6;
}
.rs-banner.banner-style4 .bnr-images {
  position: relative;
  text-align: right;
  margin: 0 -150px 0 0;
}
.rs-banner.banner-style4 .bnr-images .multi-main .multi-img {
  position: absolute;
  bottom: 0;
}
.rs-banner.banner-style4 .bnr-images .multi-main .multi-img.bnr-top {
  right: 0;
  top: -41%;
}
.rs-banner.banner-style4 .bnr-images .multi-main .multi-img.bnr-bottom {
  right: 0;
  top: 39%;
}
/* ------------------------------------
	10. Cta Section CSS
---------------------------------------*/

.rs-cta.cta-style4 .cta-wrap {
  background-color: transparent;
  background-image: linear-gradient(60deg, #f75562 0%, #8f0eee 100%);
  border-radius: 60px 60px 60px 60px;
  padding: 110px 125px 110px 190px;
  position: relative;
  overflow: hidden;
}
.rs-cta.cta-style4 .cta-wrap .cta-btn-wrap {
  display: flex;
  align-items: center;
}
.rs-cta.cta-style4 .cta-wrap .cta-btn-wrap li {
  display: inline-block;
  margin-right: 20px;
}
.rs-cta.cta-style4 .cta-wrap .cta-btn-wrap li:last-child {
  margin-right: 0;
}
.rs-cta.cta-style4 .cta-wrap .cta-images img {
  position: absolute;
  text-align: right;
  top: 38px;
  left: 87px;
}
/* ------------------------------------
	11. Counter Section CSS
---------------------------------------*/
.rs-counter.counter-style1 .rs-counter-list .count-text {
  text-align: center;
}
.rs-counter.counter-style1 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
}
.rs-counter.counter-style1 .rs-counter-list .count-text .count-number .prefix {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
}
.rs-counter.counter-style1 .rs-counter-list .count-text .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #FFFFFFBF;
  margin: 10px 0 0 0;
  display: block;
}
.rs-counter.counter-style1 .rs-counter-list .counter-border-left {
  position: relative;
}
.rs-counter.counter-style1 .rs-counter-list .counter-border-left:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 40px;
  background-color: #FFFFFF7D;
  top: 24px;
  right: -5px;
}
.rs-counter.counter-style1.counter-modify1 .counter-right-border {
  position: relative;
}
.rs-counter.counter-style1.counter-modify1 .counter-right-border:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 25px;
  background: #0531a3;
  top: 12px;
  right: 19px;
}
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text {
  text-align: left;
}
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 31px;
  line-height: 1em;
  color: #121933;
}
.rs-counter.counter-style1.counter-modify1 .rs-counter-list .count-text .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #353535;
  margin: 6px 0 0 0;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .title {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify2 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
}
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text {
  text-align: left;
}
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text .title {
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
}
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify3 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  background-color: transparent;
  background-image: linear-gradient(120deg, #f95660 0%, #910feb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rs-counter.counter-style1.counter-modify4 .counter-inner-wrap {
  background-color: #da573a;
  border-radius: 35px 35px 35px 35px;
}
.rs-counter.counter-style1.counter-modify4 .rs-counter-list .count-text .title {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}
.rs-counter.counter-style1.counter-modify4 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify4 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
}
.rs-counter.counter-style1.counter-modify5 .border-bottom {
  opacity: 0.2;
  border: 1px solid #353535;
}
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text {
  text-align: left;
}
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text .title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #121933;
}
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text .count-number .prefix,
.rs-counter.counter-style1.counter-modify5 .rs-counter-list .count-text .count-number .rs-count {
  font-size: 40px;
  line-height: 50px;
  color: #2b59ff;
  font-weight: 700;
}
/* ------------------------------------
	12. Contact Section CSS
---------------------------------------*/
.rs-contact.contact-style1 .contact-box {
  text-align: center;
  padding: 45px 0px 40px 0px;
  background-color: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
}
.rs-contact.contact-style1 .contact-box .address-item .address-icon {
  display: inline-block;
  position: relative;
  background: #2b59ff;
  line-height: 70px;
  width: 74px;
  height: 74px;
  border-radius: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.rs-contact.contact-style1 .contact-box .address-item .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  background: #2b59ff;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  opacity: 1;
  visibility: visible;
}
.rs-contact.contact-style1 .contact-box .address-item .address-icon i {
  text-align: center;
  color: #fff;
  border-radius: 100%;
  font-size: 30px;
  line-height: 78px;
  position: relative;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
}
.rs-contact.contact-style1 .contact-box .address-item .address-text .label {
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
  color: #353535 !important;
  display: block;
  margin: 0px 0px 12px 0px;
}
.rs-contact.contact-style1 .contact-box .address-item .address-text .des {
  display: block;
}
.rs-contact.contact-style1 .contact-box .address-item .address-text .des a {
  color: #3e3e3e;
}
.rs-contact.contact-style1 .contact-box .address-item .address-text .des a:hover {
  color: #2b59ff;
}
.rs-contact.contact-style1 .contact-wrap .form-group {
  position: relative;
}
.rs-contact.contact-style1 .contact-wrap .form-group i {
  position: absolute;
  top: 12px;
  left: 19px;
  font-size: 20px;
  z-index: 9;
  color: rgba(69, 69, 69, 0.7411764706);
}
.rs-contact.contact-style1 .contact-wrap .form-group .from-control {
  font-size: 18px;
  padding: 14px 30px 14px 47px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #35353521;
  color: #35353566;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style1 .contact-wrap .form-group textarea {
  height: 160px;
}
.rs-contact.contact-style1 .contact-wrap .form-group ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #353535;
  opacity: 0.5;
}
.rs-contact.contact-style1 .contact-wrap .form-group ::-moz-placeholder {
  /* Firefox 19+ */

  color: #353535;
  opacity: 0.5;
}
.rs-contact.contact-style1 .contact-wrap .form-group :-ms-input-placeholder {
  /* IE 10+ */

  color: #353535;
  opacity: 0.5;
}
.rs-contact.contact-style1 .contact-wrap .form-group :-moz-placeholder {
  /* Firefox 18- */

  color: #353535;
  opacity: 0.5;
}
.rs-contact.contact-style1 .contact-info {
  background-image: linear-gradient(150deg, #534ff8 34%, #e19fae 57 100%);
  padding: 65px 45px 100px 45px;
  border-radius: 10px 10px 10px 10px;
}
.rs-contact.contact-style1 .contact-info .contact-item .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 10px 0;
}
.rs-contact.contact-style1 .contact-info .contact-item .desc {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
}
.rs-contact.contact-style1 .contact-info .contact-item .desc a {
  font-size: 19px;
  line-height: 29px;
  font-weight: 500;
  color: #ffffff;
}
.rs-contact.contact-style1 .contact-info .contact-item .desc a:hover {
  opacity: 1;
}
.rs-contact.contact-style1 .contact-info .contact-item a {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #ffffff;
}
.rs-contact.contact-style1 .contact-info .contact-item a:hover {
  opacity: 1;
}
.rs-contact.contact-style1 .map-canvas {
  overflow: hidden;
}
.rs-contact.contact-style1 .map-canvas iframe {
  height: 500px;
  float: left;
  width: 100%;
  border: none;
}
.rs-contact.contact-style1 .con-bdr-style {
  position: relative;
}
.rs-contact.contact-style1 .con-bdr-style:before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #35353517;
  position: absolute;
  display: block;
}
.rs-contact.contact-style1 .main-contact .contact-wrap {
  padding: 60px 80px 65px 80px;
  background-color: #FFFFFF;
  border-radius: 60px 60px 60px 60px;
}
.rs-contact.contact-style1 .main-contact .contact-wrap .from-control {
  font-size: 18px;
  padding: 16px 30px 16px 30px;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #3535351A;
  color: #35353566;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style1 .main-contact .contact-wrap textarea {
  height: 160px;
}
.rs-contact.contact-style1 .main-contact .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #353535;
  opacity: 0.2;
}
.rs-contact.contact-style1 .main-contact .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #353535;
  opacity: 0.2;
}
.rs-contact.contact-style1 .main-contact .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #353535;
  opacity: 0.2;
}
.rs-contact.contact-style1 .main-contact .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #353535;
  opacity: 0.2;
}
.rs-contact.contact-style1.map-style1 .map-canvas {
  overflow: hidden;
  margin-top: -430px;
}
.rs-contact.contact-style1.map-style1 .map-canvas iframe {
  height: 900px;
  float: left;
  width: 100%;
  border: none;
}
.rs-contact.contact-style1.contact-modify1 {
  margin-top: -130px;
}
.rs-contact.contact-style1.contact-modify1 .contact-box {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.rs-contact.contact-style2 .address-boxs {
  display: flex;
  position: relative;
  align-items: center;
}
.rs-contact.contact-style2 .address-boxs .address-icon {
  margin-right: 20px;
  background-color: #2b59ff;
  width: 63px;
  height: 63px;
  line-height: 72px !important;
  border-radius: 100%;
  text-align: center;
}
.rs-contact.contact-style2 .address-boxs .address-icon i {
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
}
.rs-contact.contact-style2 .address-boxs .address-text .text .label {
  font-size: 16px;
  font-weight: 600;
  display: block;
}
.rs-contact.contact-style2 .address-boxs .address-text .text .des {
  font-size: 25px;
  font-weight: 300;
}
.rs-contact.contact-style2 .content-wrap {
  background-color: #F5F5F5;
  padding: 40px 40px 40px 40px;
  border-radius: 30px 30px 30px 30px;
}
.rs-contact.contact-style2 .content-wrap .form-group {
  position: relative;
}
.rs-contact.contact-style2 .content-wrap .form-group i {
  position: absolute;
  top: 14px;
  left: 28px;
  font-size: 20px;
  z-index: 9;
  color: rgba(69, 69, 69, 0.7411764706);
}
.rs-contact.contact-style2 .content-wrap .form-group .from-control {
  padding: 20px 30px 20px 56px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  line-height: 0;
  border-width: 1px 1px 1px 1px;
  border-color: #3535351F;
  color: #353535BA;
  background-color: #77181800;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.contact-style2 .content-wrap .form-group textarea {
  height: 120px;
  padding: 30px 30px 20px 56px !important;
}
.rs-contact.contact-style2 .content-wrap .form-group ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #3e3e3e;
  opacity: 0.61;
}
.rs-contact.contact-style2 .content-wrap .form-group ::-moz-placeholder {
  /* Firefox 19+ */

  color: #3e3e3e;
  opacity: 0.61;
}
.rs-contact.contact-style2 .content-wrap .form-group :-ms-input-placeholder {
  /* IE 10+ */

  color: #3e3e3e;
  opacity: 0.61;
}
.rs-contact.contact-style2 .content-wrap .form-group :-moz-placeholder {
  /* Firefox 18- */

  color: #3e3e3e;
  opacity: 0.61;
}
.rs-contact.contact-style2 .map-canvas {
  overflow: hidden;
}
.rs-contact.contact-style2 .map-canvas iframe {
  height: 600px;
  float: left;
  width: 100%;
  border: none;
}
.rs-contact.contact-style2.contact-modify2 .content-wrap {
  margin: 0px 0px 0px 0px;
  padding: 36px 50px 50px 50px;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 78px 100px rgba(0, 0, 0, 0.1);
}
.rs-contact.contact-style2.contact-modify2 .content-wrap .form-group .from-control {
  padding: 20px 30px 20px 56px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #35353530;
}
.rs-contact.contact-style2.contact-modify2 .content-wrap .form-group textarea {
  height: 160px;
}
/* ------------------------------------
	13. Video Section CSS
---------------------------------------*/
/*.rs-videos.video-style1 {*/
/*  position: relative;*/
/*}*/
/*.rs-videos.video-style1 .video-item {*/
/*  position: relative;*/
/*}*/
/*.rs-videos.video-style1 .video-item .overly-border a {*/
/*  position: absolute;*/
/*  transform: translateX(-50%) translateY(-50%);*/
/*  box-sizing: content-box;*/
/*  display: block;*/
/*  width: 80px;*/
/*  height: 80px;*/
/*  border-radius: 50%;*/
/*  line-height: 90px;*/
/*  padding: 0;*/
/*  margin: 0 auto;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  z-index: 9;*/
/*  text-align: center;*/
/*  background: #ffffff;*/
/*}*/
/*.rs-videos.video-style1 .video-item .overly-border a i {*/
/*  font-size: 30px;*/
/*  line-height: 80px;*/
/*  color: #2b59ff;*/
/*  position: relative;*/
/*  z-index: 1;*/
/*}*/
/*.rs-videos.video-style1 .video-item .overly-border a:before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  z-index: 0;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translateX(-50%) translateY(-50%);*/
/*  display: block;*/
/*  width: 80px;*/
/*  height: 80px;*/
/*  border-radius: 50%;*/
/*  animation: pulse-border 1500ms ease-out infinite;*/
/*  transition: all 0.3s ease;*/
/*  background: #ffffff;*/
/*}*/
/*.rs-videos.video-style1 .video-item .overly-border a:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  z-index: 1;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translateX(-50%) translateY(-50%);*/
/*  display: block;*/
/*  width: 80px;*/
/*  height: 80px;*/
/*  border-radius: 50%;*/
/*  transition: all 200ms;*/
/*  animation: pulse-border 1.5s ease-out infinite;*/
/*}*/
/*.rs-videos.video-style1 .video-item.video-wrap {*/
/*  max-width: 930px;*/
/*  margin: 0 auto;*/
/*  background-image: url(assets/images/video/video-image3.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 20px 20px 20px 20px;*/
/*  padding: 230px 0px 230px 0px;*/
/*}*/
/*.rs-videos.video-style1.video-modify1 .video-item.video-wrap2 {*/
/*  background-image: url(assets/images/video/video-image4.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 20px 20px 20px 20px;*/
/*  padding: 285px 0px 285px 0px;*/
/*}*/
/*.rs-videos.video-style1.video-modify1 .video-item.video-wrap2 .overly-border a i {*/
/*  color: #f05454;*/
/*}*/
/*.rs-videos.video-style1.video-modify2 .video-item.video-wrap3 {*/
/*  background-image: url(assets/images/video/video-bg-12.png);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 20px 20px 20px 20px;*/
/*  padding: 255px 0px 255px 0px;*/
/*}*/
/*.rs-videos.video-style1.video-modify2 .video-item.video-wrap3 .overly-border a i {*/
/*  color: #113cfc;*/
/*}*/
/*.rs-videos.video-style1.video-modify3 .video-item.video-wrap4 {*/
/*  background-image: url(assets/images/video/video-bg-about2.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 0;*/
/*  padding: 233px 0px 233px 0px;*/
/*}*/
/*.rs-videos.video-style1.video-modify3 .video-item.video-wrap4 .overly-border a i {*/
/*  color: #da573a;*/
/*}*/
/*.rs-videos.video-style1.video-modify4 .video-item.video-wrap5 {*/
/*  background-image: url(assets/images/video/service2-left-img.jpg);*/
/*  background-position: center center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  border-radius: 40px 40px 40px 40px;*/
/*  padding: 300px 0px 300px 0px;*/
/*  margin: 0px 0px 0px -70px;*/
/*}*/
/*.rs-videos.video-style1.video-modify4 .video-item.video-wrap5 .overly-border a i {*/
/*  color: #2b59ff;*/
/*}*/
/*.rs-videos.video-style1 .video-animation .video-animate {*/
/*  position: absolute;*/
/*}*/
/*.rs-videos.video-style1 .video-animation .video-animate.left {*/
/*  left: 21.2%;*/
/*  top: 19%;*/
/*}*/
/*.rs-videos.video-style1 .video-animation .video-animate.left img {*/
/*  max-width: 82px;*/
/*}*/
/*.rs-videos.video-style1 .video-animation .video-animate.right {*/
/*  right: 22%;*/
/*  top: 18%;*/
/*}*/
/*.rs-videos.video-style1 .video-animation .video-animate.right img {*/
/*  max-width: 82px;*/
/*}*/
/* ------------------------------------
	14. Choose Section CSS
---------------------------------------*/
.rs-choose.choose-style1 .choose-img {
  position: relative;
  margin: 0px 0px 0px -100px;
}
.rs-choose.choose-style1 .choose-img .watch-img {
  position: absolute;
  top: 64px;
  right: 50%;
}
.rs-choose.choose-style1 .choose-img .watch-img img {
  max-width: 50px;
}
.rs-choose.choose-style1 .gradiant-circle {
  position: relative;
  text-align: center;
}
.rs-choose.choose-style1 .gradiant-circle .device-img {
  position: absolute;
  bottom: 0;
}
.rs-choose.choose-style1 .gradiant-circle .device-img.one {
  top: -29%;
  right: 8%;
  width: auto;
}
.rs-choose.choose-style1 .gradiant-circle .device-img.two {
  top: -11%;
  right: 32%;
  width: auto;
}
/* ------------------------------------
	15. Services Section CSS
---------------------------------------*/
.rs-services.services-main-home .services-item {
  text-align: center;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 10px 40px 0px rgba(64, 25, 17, 0.04);
  transition: all 0.4s;
}
.rs-services.services-main-home .services-item .services-wrap {
  position: relative;
  padding: 40px 15px 40px 15px;
}
.rs-services.services-main-home .services-item .services-wrap .services-icon {
  line-height: 84px !important;
  min-width: 84px;
  height: 84px;
  border-radius: 84px 84px 84px 84px !important;
  background-color: #2B59FF;
  display: inline-block;
  transition: all ease 0.3s;
}
.rs-services.services-main-home .services-item .services-wrap .services-icon img {
  height: 34px;
  width: 34px;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
}
.rs-services.services-main-home .services-item .services-wrap .services-content {
  margin: 20px 0px 0px 0px;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 15px;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .title a:hover {
  color: #2b59ff;
}
.rs-services.services-main-home .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #3E3E3E;
  margin-bottom: 0px;
}
.rs-services.services-main-home .services-item:hover {
  transform: translateY(-6px);
  box-shadow: 0px 20px 50px 0px rgba(64, 25, 17, 0.04);
}
.rs-services.services-main-home .services-item:hover .services-wrap .services-icon img {
  transform: scale(0.9);
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-icon img {
  height: 55px;
  width: 55px;
  max-width: 100%;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-icon {
  background-color: unset;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-content {
  margin: 10px 0px 0px 0px;
}
.rs-services.services-main-home.services-modify1 .services-item .services-wrap .services-content .title a:hover {
  color: #28544b;
}
.rs-services.services-main-home.services-modify5 .services-item .services-wrap .services-icon {
  line-height: 71px !important;
  min-width: 71px;
  height: 71px;
  border-radius: 84px 84px 84px 84px !important;
}
.rs-services.services-main-home.services-modify5 .services-item .services-wrap .services-icon img {
  height: 31px;
  width: 31px;
}
.rs-services.services-main-home.services-modify5 .services-item .services-wrap .services-content .services-txt {
  margin-bottom: 0px;
}
.rs-services.services-main-home.services-modify5 .services-item .services-wrap {
  padding: 35px 15px 35px 15px;
}
.rs-services.services-style1 .services-item {
  padding: 30px 20px 22px 20px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  box-shadow: 20px 20px 60px 0px rgba(102, 71, 255, 0.07);
  background-color: #FFFFFF;
  text-align: center;
}
.rs-services.services-style1 .services-item .services-wrap .services-icon {
  position: relative;
  line-height: 57px !important;
  min-width: 57px;
  height: 57px;
  margin-bottom: 12px;
  border-radius: 15px 15px 15px 15px !important;
  text-align: center;
  background-color: #6647ff;
  display: inline-block;
  transition: all ease 0.3s;
}
.rs-services.services-style1 .services-item .services-wrap .services-icon img {
  height: 30.95px;
  width: 27.89px;
  max-width: unset;
}
.rs-services.services-style1 .services-item .services-wrap .services-icon:before {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #6647FF;
  border-style: solid;
  border-color: #6647FF;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: inherit;
  transition: all 0.3s;
  transform: translate(4px, 4px);
}
.rs-services.services-style1 .services-item .services-wrap .services-content {
  margin: 20px 0px 0px 0px;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 15px;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .title a:hover {
  color: #6647ff;
}
.rs-services.services-style1 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 5px;
}
.rs-services.services-style1 .services-item:hover {
  box-shadow: 20px 20px 60px 0px rgba(102, 71, 255, 0.1);
}
.rs-services.services-style1 .services-item:hover .services-wrap .services-icon:before {
  right: 0;
  bottom: 0;
  transform: unset;
}
.rs-services.services-style1 .services-item:hover .services-wrap .services-button a i {
  color: #6647FF;
}
.rs-services.services-style2 .services-item {
  background-color: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  padding: 38px 20px 46px 20px;
  box-shadow: 0px 4px 15px 0px rgba(64, 25, 17, 0.03);
  text-align: center;
}
.rs-services.services-style2 .services-item .services-wrap .services-icon {
  position: relative;
  line-height: 84px !important;
  min-width: 84px;
  height: 84px;
  border-radius: 84px 84px 84px 84px !important;
  text-align: center;
  background-color: #F2F6FF;
  display: inline-block;
  transition: all ease 0.3s;
}
.rs-services.services-style2 .services-item .services-wrap .services-icon img {
  width: 40px;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
}
.rs-services.services-style2 .services-item .services-wrap .services-content {
  margin: 21px 0px 0px 0px;
}
.rs-services.services-style2 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 17px;
}
.rs-services.services-style2 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style2 .services-item .services-wrap .services-content .title a:hover {
  color: #0531a3;
}
.rs-services.services-style2 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 5px;
}
.rs-services.services-style2 .services-item:hover .services-icon img {
  transform: scale(0.9);
}
.rs-services.services-style2.services-modify2 .services-item {
  background-color: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  padding: 25px 20px 25px 20px;
  box-shadow: unset;
  text-align: center;
}
.rs-services.services-style2.services-modify2 .services-item .services-wrap .services-icon {
  line-height: 70px !important;
  min-width: 70px;
  height: 70px;
  border-radius: 84px 84px 84px 84px !important;
  background-color: #1a4d2e;
}
.rs-services.services-style2.services-modify2 .services-item .services-wrap .services-icon img {
  width: 34px;
  padding: 2px 2px 2px 2px;
}
.rs-services.services-style2.services-modify2 .services-item .services-wrap .services-content .title {
  margin: 0 0 10px;
}
.rs-services.services-style2.services-modify2 .services-item .services-wrap .services-content .title a:hover {
  color: #1a4d2e;
}
.rs-services.services-style2.services-modify3 .services-item {
  background-color: #282E43;
  border-radius: 20px 20px 20px 20px;
  padding: 35px 35px 35px 35px;
  box-shadow: unset;
  text-align: center;
}
.rs-services.services-style2.services-modify3 .services-item .services-wrap .services-icon {
  line-height: 70px !important;
  min-width: 70px;
  height: 70px;
  border-radius: 84px 84px 84px 84px !important;
  background-color: #F76540;
}
.rs-services.services-style2.services-modify3 .services-item .services-wrap .services-icon img {
  width: 34px;
  padding: 2px 2px 2px 2px;
}
.rs-services.services-style2.services-modify3 .services-item .services-wrap .services-content .title {
  margin: 0 0 0px;
}
.rs-services.services-style2.services-modify3 .services-item .services-wrap .services-content .title a {
  color: #ffffff;
}
.rs-services.services-style2.services-modify3 .services-item .services-wrap .services-content .title a:hover {
  color: #F76540;
}
.rs-services.services-style3 .services-item {
  padding: 30px 30px 30px 30px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  background-color: #FFFFFF0D;
}
.rs-services.services-style3 .services-item .services-wrap .services-icon img {
  height: 60px;
  width: unset;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
  transition-duration: 0s;
}
.rs-services.services-style3 .services-item .services-wrap .services-content {
  margin: 26px 0px 0px 0px;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  margin: 0 0 17px;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title a {
  color: #ffffff;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .title a:hover {
  color: #ffffff;
}
.rs-services.services-style3 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFFCC;
  margin-bottom: 0px;
}
.rs-services.services-style3 .services-item:hover {
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  box-shadow: 30px 30px 60px 0px rgba(66, 18, 111, 0.5);
}
.rs-services.services-style3 .services-item:hover .services-icon img {
  transform: scale(0.9);
}
.rs-services.services-style4 {
  position: relative;
  z-index: 11;
}
.rs-services.services-style4 .services-section-wrap {
  background-color: transparent;
  background-image: linear-gradient(180deg, #050119 0%, #1d1b48 100%);
  padding: 125px 0px 130px 0px;
  border-radius: 50px 50px 50px 50px;
}
.rs-services.services-style4 .services-section-wrap .services-item {
  position: relative;
  text-align: center;
  padding: 35px 18px 35px 18px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  background-color: transparent;
  background-image: linear-gradient(70deg, #d6a700 0%, #7d16ff 100%);
}
.rs-services.services-style4 .services-section-wrap .services-item .services-icon {
  margin-bottom: 30px;
}
.rs-services.services-style4 .services-section-wrap .services-item .services-icon img {
  width: 60px;
  height: unset;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
  transition-duration: 0s;
}
.rs-services.services-style4 .services-section-wrap .services-item .services-content {
  position: relative;
}
.rs-services.services-style4 .services-section-wrap .services-item .services-content .title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 35px;
  margin: 0px 0px 10px 0px;
}
.rs-services.services-style4 .services-section-wrap .services-item .services-content .title a {
  color: #FFFFFF;
}
.rs-services.services-style4 .services-section-wrap .services-item .services-content .services-txt {
  color: #FFFFFFCC;
  margin-bottom: 0px;
}
.rs-services.services-style4 .services-section-wrap .services-item:before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: inherit;
}
.rs-services.services-style4 .services-section-wrap .services-item:not(:hover):before {
  background-color: transparent;
  background-image: linear-gradient(180deg, #000000 0%, #18163f 100%);
}
.rs-services.services-style4 .services-section-wrap .services-animation .ser-animate {
  position: absolute;
}
.rs-services.services-style4 .services-section-wrap .services-animation .ser-animate.left {
  left: -15%;
  top: -10%;
  z-index: -5;
}
.rs-services.services-style4 .services-section-wrap .services-animation .ser-animate.right {
  right: -2%;
  top: 24%;
  z-index: -5;
}
.rs-services.services-style4 .services-section-wrap .services-animation .ser-animate.right img {
  max-width: 500px;
}
.rs-services.services-style5 .services-item {
  padding: 35px 40px 30px 40px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  box-shadow: 0px 15px 20px 0px rgba(0, 12, 68, 0.02);
  background-color: #FFFFFF;
}
.rs-services.services-style5 .services-item .services-wrap .services-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content {
  margin: 20px 0px 0px 0px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 20px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .title a:hover {
  color: #113cfc;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 5px;
}
.rs-services.services-style5 .services-item .services-wrap .services-content .services-button a:hover i {
  color: #113cfc;
}
.rs-services.services-style6 .services-item {
  padding: 25px 25px 25px 25px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  background-color: #FFFFFF;
  transition: all 0.4s;
}
.rs-services.services-style6 .services-item .services-wrap .services-icon {
  min-width: 57px;
  height: 57px;
  line-height: 57px;
  margin-bottom: 25px !important;
  border-radius: 15px 15px 15px 15px !important;
  background-color: #F05454;
  text-align: center;
  transition: all ease 0.3s;
  display: inline-block;
}
.rs-services.services-style6 .services-item .services-wrap .services-icon img {
  max-width: unset;
  width: 27px;
  height: 27px;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 17px;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .title a:hover {
  color: #f05454;
}
.rs-services.services-style6 .services-item .services-wrap .services-content .desc-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style6 .services-item:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.06);
  transform: translateY(-6px);
}
.rs-services.services-style7 .services-item .services-wrap {
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  background-color: #FFFFFF;
}
.rs-services.services-style7 .services-item .services-wrap .services-content {
  text-align: center;
  padding: 0px 20px 30px 20px;
  margin: 20px 0px 0px 0px;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .icons-list {
  display: flex;
  justify-content: center;
  margin: -35px 0 20px 0;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .icons-list li {
  display: inline-block;
  margin-right: 15px;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .icons-list li:last-child {
  margin-right: 0;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .icons-list li img {
  max-width: 20px;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  margin: 0 0 17px;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .title a:hover {
  color: #f05454;
}
.rs-services.services-style7 .services-item .services-wrap .services-content .desc-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 24px;
}
.rs-services.services-style8 .services-item {
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
}
.rs-services.services-style8 .services-item .services-wrap {
  padding: 28px 37px 28px 37px;
}
.rs-services.services-style8 .services-item .services-wrap .services-icon {
  margin-bottom: 12px;
}
.rs-services.services-style8 .services-item .services-wrap .services-icon img {
  width: 70px;
  height: unset;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  margin: 0 0 10px;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .title a:hover {
  color: #113cfc;
}
.rs-services.services-style8 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style8 .services-item:hover .services-wrap .services-icon img {
  transform: scale(0.9);
}
.rs-services.services-style9 .services-img {
  margin: 0px 0px 0px 45px;
}
.rs-services.services-style9 .services-img img {
  border-radius: 45px 45px 45px 45px;
}
.rs-services.services-style9 .services-item {
  display: flex;
  align-items: center;
}
.rs-services.services-style9 .services-item .services-icon img {
  height: 65px;
  width: 65px;
  max-width: unset;
}
.rs-services.services-style9 .services-item .services-text {
  margin-left: 20px;
}
.rs-services.services-style9 .services-item .services-text .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin: 0 0 7px;
  color: #121933;
}
.rs-services.services-style9 .services-item .services-text p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #303030;
  margin: 0 0 0px;
}
.rs-services.services-style10 .services-item {
  position: relative;
  text-align: center;
  padding: 40px 30px 35px 30px;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff5c8e 0%, #92b4fe 100%);
}
.rs-services.services-style10 .services-item .services-wrap {
  position: relative;
}
.rs-services.services-style10 .services-item .services-wrap .services-icon {
  position: relative;
  z-index: 3;
  margin-bottom: 12px;
  line-height: 95px !important;
  min-width: 90px;
  height: 90px;
  border-radius: 90px 90px 90px 90px !important;
  background-color: #534FF8;
  display: inline-block;
  transition: all ease 0.3s;
}
.rs-services.services-style10 .services-item .services-wrap .services-icon img {
  width: 50px;
  height: unset;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
}
.rs-services.services-style10 .services-item .services-wrap .services-content {
  margin: 20px 0 0;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  margin: 0 0 12px;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .title a:hover {
  color: #FF5C8E;
}
.rs-services.services-style10 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style10 .services-item:before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: inherit;
}
.rs-services.services-style10 .services-item:before {
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
}
.rs-services.services-style11 .services-item {
  position: relative;
  padding: 25px 25px 25px 25px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.03);
  background-color: #FFFFFF;
}
.rs-services.services-style11 .services-item .services-wrap {
  position: relative;
}
.rs-services.services-style11 .services-item .services-wrap .services-icon {
  position: relative;
  z-index: 3;
  text-align: center;
  min-width: 57px;
  height: 57px;
  line-height: 57px;
  margin-bottom: 23px !important;
  border-radius: 15px 15px 15px 15px !important;
  background-color: #7900FF;
  display: inline-block;
  transition: all ease 0.3s;
}
.rs-services.services-style11 .services-item .services-wrap .services-icon img {
  height: 27px;
  width: 28px;
  height: unset;
  transition: 0.4s;
}
.rs-services.services-style11 .services-item .services-wrap .services-content {
  margin: 0px 0 0;
}
.rs-services.services-style11 .services-item .services-wrap .services-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  margin: 0 0 12px;
}
.rs-services.services-style11 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style11 .services-item .services-wrap .services-content .title a:hover {
  color: #7900ff;
}
.rs-services.services-style11 .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style11 .services-item:hover {
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
}
.rs-services.services-style12 .services-effect-bg {
  background-color: #fff;
  border-radius: 50px 50px 50px 50px;
}
.rs-services.services-style12 .services-effect-bg .services-item {
  position: relative;
  padding: 30px 25px 20px 25px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.03);
  background-color: #FFFFFF;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap {
  position: relative;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-icon {
  margin-bottom: 20px;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-icon img {
  height: 50px;
  width: 50px;
  height: unset;
  transition: 0.4s;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-content {
  margin: 0px 0 0;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-content .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 12px;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-content .title a:hover {
  color: #ff0945;
}
.rs-services.services-style12 .services-effect-bg .services-item .services-wrap .services-content .services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style12 .services-effect-bg .services-item:hover {
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
}
.rs-services.services-style13 .services-item {
  padding: 30px 25px 20px 25px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 30px 60px rgba(0, 0, 1, 0.05);
  background-color: #FFFFFF;
}
.rs-services.services-style13 .services-item .services-wrap .services-icon {
  margin-bottom: 25px;
}
.rs-services.services-style13 .services-item .services-wrap .services-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
}
.rs-services.services-style13 .services-item .services-wrap .services-content .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 17px;
}
.rs-services.services-style13 .services-item .services-wrap .services-content .title a {
  color: #121933;
}
.rs-services.services-style13 .services-item .services-wrap .services-content .title a:hover {
  color: #da573a;
}
.rs-services.services-style13 .services-item .services-wrap .services-content .desc-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #353535;
  margin-bottom: 0;
}
.rs-services.services-style13.services-modify4 .services-item {
  padding: 30px 25px 25px 25px;
}
.rs-services.services-style13.services-modify4 .services-item .services-wrap .services-content .title a:hover {
  color: #2b59ff;
}
/* ------------------------------------
	16. Faq Section CSS
---------------------------------------*/
.rs-faq.faq-style1 .faq-content .accordion {
  border-width: 12px;
}
.rs-faq.faq-style1 .faq-content .accordion .card {
  margin-bottom: 15px;
  border: none;
  background: #F8F9FF;
  border-radius: 15px;
  padding: 20px 30px;
}
.rs-faq.faq-style1 .faq-content .accordion .card:last-child {
  margin-bottom: 0 !important;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
  background-color: unset;
  border-radius: unset;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .icon-image {
  background: #2b59ff;
  padding: 11px 13px;
  border-radius: 8px;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .icon-image img {
  max-width: 24px;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 30px 0 16px;
  height: 63px;
  font-size: 20px;
  font-weight: 500;
  color: #f45d48;
  background: unset;
  box-shadow: unset;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link:after {
  font-family: FontAwesome;
  position: absolute;
  content: "\f077";
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 16px;
  color: #f45d48;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 30px;
  color: #121933;
  background: #F8F9FF;
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #121933;
  content: "\f078";
}
.rs-faq.faq-style1 .faq-content .accordion .card .card-body {
  font-size: 16px;
  color: #3e3e3e;
  padding: 0 8px 6px 65px !important;
  width: 100%;
}
.rs-faq.faq-style1 .faq-img {
  position: relative;
  margin: 0px -310px 0px 115px;
}
.rs-faq.faq-style1 .faq-img .project-img {
  position: absolute;
  bottom: -87px;
  left: -62px;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card {
  border-style: unset;
  border-width: unset;
  border-color: unset;
  background-color: unset;
  padding: 0;
  border-radius: 0;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link {
  color: #353535;
  padding: 21px 30px 36px 34px;
  background: #ffffff;
  color: #113cfc;
  border-radius: 10px 10px 0px 0px;
  height: 75px;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #353535;
  background: transparent;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #c3c3c37d;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link:after {
  right: 30px;
  color: #113cfc;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #353535;
  opacity: 0.7;
}
.rs-faq.faq-style1.faq-modify2 .faq-content .accordion .card .card-body {
  color: #121933;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify2 .ser-faq-img {
  margin: 0px 0px 0px -120px;
}
.rs-faq.faq-style1.faq-modify3 .faq-content {
  padding: 0 70px 0 0;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .icon-image {
  background-color: #1A4D2E;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link,
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link:after {
  color: #1a4d2e;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 0px;
  background: transparent;
  color: #121933;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #353535;
  opacity: 0.8;
}
.rs-faq.faq-style1.faq-modify3 .faq-content .accordion .card {
  padding: 14px 20px 14px 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #30303020;
  background-color: #FFFFFF00;
}
.rs-faq.faq-style1.faq-modify3 .faq-img {
  position: relative;
  margin: 0px -230px 0px 80px;
}
.rs-faq.faq-style1.faq-modify3 .faq-img .project-img {
  left: -120px;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card {
  border-style: unset;
  border-width: unset;
  border-color: unset;
  background-color: unset;
  padding: 0;
  border-radius: 0;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link {
  color: #353535;
  padding: 21px 30px 36px 34px;
  background: #ffffff;
  color: #8030da;
  border-radius: 10px 10px 0px 0px;
  height: 75px;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #353535;
  background: transparent;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #c3c3c37d;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link:after {
  right: 30px;
  color: #8030da;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #353535;
  opacity: 0.7;
}
.rs-faq.faq-style1.faq-modify4 .faq-content .accordion .card .card-body {
  color: #121933;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify5 {
  position: relative;
}
.rs-faq.faq-style1.faq-modify5 .faq-content {
  max-width: 700px;
  margin: 0 auto;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card {
  border-style: unset;
  border-width: unset;
  border-color: unset;
  background-color: unset;
  padding: 0;
  border-radius: 0;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card .card-header .card-link {
  color: #353535;
  padding: 21px 30px 36px 34px;
  background: #ffffff;
  color: #f05454;
  border-radius: 0;
  height: 75px;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #353535;
  background: transparent;
  border-radius: 0;
  border: 1px solid #c3c3c37d;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card .card-header .card-link:after {
  right: 30px;
  font-family: FontAwesome;
  content: "\f068";
  color: #f05454;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  content: "\f067";
  color: #353535;
  opacity: 0.7;
}
.rs-faq.faq-style1.faq-modify5 .faq-content .accordion .card .card-body {
  color: #121933;
  background: #ffffff;
  border-radius: 0px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify5 .faq-animation .faq-animate {
  position: absolute;
}
.rs-faq.faq-style1.faq-modify5 .faq-animation .faq-animate.one {
  right: 2%;
  bottom: 0%;
}
.rs-faq.faq-style1.faq-modify5 .faq-animation .faq-animate.two {
  right: 13%;
  bottom: 36%;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content {
  max-width: 760px;
  margin: 0 auto;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #f1f3f469;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  opacity: 0.8;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content .accordion .card .card-header .card-link {
  background: #371464;
  color: #fff;
  border-radius: 10px 10px 0 0;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content .accordion .card .card-header .card-link:after {
  color: #fff;
}
.rs-faq.faq-style1.faq-modify5.faq-home15 .faq-content .accordion .card .card-body {
  color: rgba(255, 255, 255, 0.8);
  background: #371464;
  border-radius: 0 0 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #121933;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #888D9F52;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style1 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #121933;
  opacity: 0.8;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style1 .faq-content .accordion .card .card-header .card-link {
  background: #2b59ff;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style1 .faq-content .accordion .card .card-header .card-link:after {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style1 .faq-content .accordion .card .card-body {
  color: #ffffff;
  background: #2b59ff;
  border-radius: 0 0 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style2 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #888D9F52;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style2 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  opacity: 0.8;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style2 .faq-content .accordion .card .card-header .card-link {
  background: #2b59ff;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style2 .faq-content .accordion .card .card-header .card-link:after {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify5.inner-faq-style2 .faq-content .accordion .card .card-body {
  color: #ffffff;
  background: #2b59ff;
  border-radius: 0 0 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify6 {
  position: relative;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link {
  padding: 7px 0px 0 0px;
  color: #FFFFFF;
  height: 48px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-body {
  color: rgba(255, 255, 255, 0.73);
  padding: 5px 8px 8px 0px !important;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card {
  border-radius: 10px 10px 10px 10px;
  background-color: #131D31;
  padding: 14px 25px;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 30px;
  color: #ffffff;
  background: transparent;
}
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link:after,
.rs-faq.faq-style1.faq-modify6 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
}
.rs-faq.faq-style1.faq-modify6 .faq-animate .arrow-animate {
  position: absolute;
  right: 8%;
  bottom: 15%;
}
.rs-faq.faq-style1.faq-modify6 .faq-animate .arrow-animate img {
  width: 200px;
  max-width: 200px;
}
.rs-faq.faq-style1.faq-modify7 .faq-content {
  padding: 0 70px 0 0;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .icon-image img {
  max-width: 30px;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .icon-image {
  background-color: #7900FF;
  padding: 11px;
  border-radius: 50px;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link,
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link:after {
  color: #7900ff;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 0px;
  background: transparent;
  color: #121933;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #121933;
}
.rs-faq.faq-style1.faq-modify7 .faq-content .accordion .card {
  padding: 14px 20px 14px 0px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #12193317;
  border-radius: 0px 0px 0px 0px;
  background-color: #FFFFFF;
}
.rs-faq.faq-style1.faq-modify7 .faq-images-wrap {
  position: relative;
}
.rs-faq.faq-style1.faq-modify7 .faq-images-wrap img {
  position: relative;
  max-width: 650px;
  right: 30%;
}
.rs-faq.faq-style1.faq-modify7 .faq-images-wrap .img-animate {
  position: absolute;
  top: 0%;
  right: 36%;
}
.rs-faq.faq-style1.faq-modify8 .faq-content {
  padding: 0 70px 0 0;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-body {
  font-size: 16px;
  color: #3e3e3e;
  padding: 0 0px 0px 0px !important;
  width: 100%;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link {
  padding: 0px 0px 0 0px;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  content: "\f067";
  font-weight: 400;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link:after {
  content: "\f068";
  font-weight: 400;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link,
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link:after {
  color: #2b59ff;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 0px;
  background: transparent;
  color: #121933;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #121933;
}
.rs-faq.faq-style1.faq-modify8 .faq-content .accordion .card {
  padding: 20px 20px 20px 0px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #12193317;
  border-radius: 0px 0px 0px 0px;
  background-color: #FFFFFF;
  margin-bottom: 0;
}
.rs-faq.faq-style1.faq-modify8 .ser-faq-img {
  position: relative;
}
.rs-faq.faq-style1.faq-modify8 .ser-faq-img img {
  position: relative;
  right: -4%;
  border-radius: 50px 50px 50px 50px;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card {
  border-style: unset;
  border-width: unset;
  border-color: unset;
  background-color: unset;
  padding: 0;
  border-radius: 0;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card .card-header .card-link {
  color: #353535;
  padding: 21px 30px 36px 34px;
  background: #f9f9f9;
  color: #113cfc;
  border-radius: 10px 10px 0px 0px;
  height: 75px;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #353535;
  background: transparent;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #c3c3c37d;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card .card-header .card-link:after {
  right: 30px;
  color: #113cfc;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #353535;
  opacity: 0.7;
}
.rs-faq.faq-style1.faq-modify9 .faq-content .accordion .card .card-body {
  color: #121933;
  background: #f9f9f9;
  border-radius: 0px 0px 10px 10px;
  padding: 0 36px 25px 34px !important;
}
.rs-faq.faq-style1.faq-modify9 .ser-faq-img {
  margin: 0px 0px 0px -120px;
}
.rs-faq.faq-style2 {
  position: relative;
}
.rs-faq.faq-style2 .faq-content .accordion {
  border-width: 12px;
}
.rs-faq.faq-style2 .faq-content .accordion .card {
  margin-bottom: 25px;
  margin: 0px 0px 26px 0px;
  padding: 0px 0px 0px 0px;
  border: none;
  border-radius: 0px 0px 0px 0px;
}
.rs-faq.faq-style2 .faq-content .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
  background-color: #F1ECEC00;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-header .card-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 30px 0 30px;
  height: 71px;
  font-size: 20px;
  line-height: 1.35;
  font-weight: 700;
  background-color: #fff;
  color: #0531a3;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-header .card-link:after {
  font-family: FontAwesome;
  position: absolute;
  content: "\f077";
  right: 28px;
  top: 44%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #0531a3;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #121933;
  background-color: #F1ECEC00;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #121933;
  content: "\f078";
  font-weight: 300;
}
.rs-faq.faq-style2 .faq-content .accordion .card .card-body {
  font-size: 16px;
  color: #3e3e3ed1;
  background-color: #fff;
  padding: 0 30px 30px 30px !important;
  width: 100%;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion {
  border-width: 12px;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card {
  border-style: unset;
  border-width: unset;
  border-color: unset;
  background-color: unset;
  border-radius: 15px;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-header .card-link {
  color: #ffffff;
  background: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  border-radius: 15px 15px 0px 0px;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-header .card-link:after {
  color: #ffffff;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-header .card-link.collapsed {
  color: #ffffff;
  background: #15174C !important;
  border-radius: 15px;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  opacity: 0.7;
  content: "\f078";
  font-weight: 300;
}
.rs-faq.faq-style2.faq-modify1 .faq-content .accordion .card .card-body {
  color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
  border-radius: 0px 0px 15px 15px;
}
/* ------------------------------------
	17. Team Section CSS
---------------------------------------*/
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap {
  overflow: visible;
  border-radius: 20px;
  background: #FFFFFF;
  padding: 18px 18px 24px 18px;
  margin: 15px 14px 20px 14px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.04);
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .image-wrap a img {
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content {
  padding-top: 14px;
  opacity: 1;
  text-align: center;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .team-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .team-name a {
  color: #121933;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .team-name a:hover {
  color: #2b59ff;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .team-title {
  font-size: 14px;
  color: #353535C9;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .social-icons {
  padding-top: 15px;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .social-icons a {
  display: inline-block;
  margin-right: 4px;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .social-icons a i {
  font-size: 15px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #353535;
  color: #353535;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: transparent;
  text-align: center;
  transition: 0.3s all ease;
  border-radius: 50%;
}
.rs-team.team-style1 .slider .team-item-wrap .team-inner-wrap .team-content .social-icons a i:hover {
  border-color: #2b59ff;
  background: #2b59ff;
  color: #ffffff;
}
.rs-team.team-style1 .slider .slick-list {
  padding: 0 !important;
}
.rs-team.team-single-wrap .team-img {
  padding: 0 30px 0 0px;
}
.rs-team.team-single-wrap .team-img img {
  border-radius: 10px 10px 10px 10px;
}
.rs-team.team-single-wrap .team-content .sub-text {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 26px;
  color: #534FF8;
  display: block;
  margin: 0px 0px 10px 0px;
}
.rs-team.team-single-wrap .team-content .title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #19082D;
  margin: 0px 0px 0px 0px;
}
.rs-team.team-single-wrap .team-content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #353535;
  margin: 0px 0px 35px 0px;
}
.rs-team.team-single-wrap .team-content .team-social li {
  display: inline-block;
  margin-right: 10px;
}
.rs-team.team-single-wrap .team-content .team-social li a i {
  font-size: 18px;
  transition: all 0.3s ease-in;
  color: #3E3E3E;
  display: block;
}
.rs-team.team-single-wrap .team-content .team-social li a i:hover {
  color: #2b59ff;
}
.rs-team.team-single-wrap .team-content .team-social li:last-child {
  margin-right: 0px;
}
/*------------------------------------
	18. Process Section CSS
------------------------------------*/
.rs-process.process-style1 {
  position: relative;
  z-index: 11;
}
.rs-process.process-style1 .rs-html5-video {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}
.rs-process.process-style1 .background-video-wrap .background-overlay {
  background-color: #6647FF;
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.rs-process.process-style1 .addon-services-item .services-part {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.rs-process.process-style1 .addon-services-item .services-part .services-icon {
  margin: 0px 0px 25px 0px;
}
.rs-process.process-style1 .addon-services-item .services-part .services-icon img {
  width: 130px;
  height: unset;
  max-width: 100%;
  transform: scale(1);
  transition: 0.4s;
}
.rs-process.process-style1 .addon-services-item .services-part .services-content .services-title .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  color: #ffffff;
  margin: 0 0 10px;
}
.rs-process.process-style1 .addon-services-item .services-part .services-content .desc-text {
  color: #FFFFFFCC;
  margin-bottom: 0px;
}
.rs-process.process-style1 .addon-services-item .services-part:hover .services-icon img {
  transform: scale(0.9);
}
/*------------------------------------
	19. Partner Section CSS
------------------------------------*/
.rs-partner.partner-main-home .slider .partner-item {
  padding: 0 52px;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img {
  text-align: center;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a .hovers-logos {
  display: block;
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  display: block;
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a:hover .hovers-logos {
  display: block;
  opacity: 3;
  visibility: visible;
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-partner.partner-main-home .slider .partner-item .logo-img a:hover .mains-logos {
  display: block;
  visibility: hidden;
  transform: translateY(100%);
  filter: grayscale(1);
}
.rs-partner.partner-main-home .slider .slick-list {
  padding: 0 !important;
}
.rs-partner.partner-style2 .slider .partner-item {
  padding: 0 52px;
}
.rs-partner.partner-style2 .slider .partner-item .logo-img {
  text-align: center;
}
.rs-partner.partner-style2 .slider .partner-item .logo-img a .mains-logos {
  transition: all 0.3s ease-in-out;
  display: block;
}
.rs-partner.partner-style2 .slider .slick-list {
  padding: 0 !important;
}
.rs-partner.partner-style2.partner-modify1 {
  position: relative;
}
.rs-partner.partner-style2.partner-modify1 .ptnr-border-bottom {
  position: absolute;
  content: '';
  width: 1120px;
  height: 2px;
  background: #35353521;
  left: 21%;
  bottom: 13%;
  transform: translateX(0%);
}
/*------------------------------------
	20. Pricing Section CSS
------------------------------------*/
.pricing-container.pricing-style1 .pricing-switcher {
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-switcher .switch {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 45px;
  width: 90px;
  background-color: #28544b;
  border-radius: 50px;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(95px);
  -moz-transform: translateX(95px);
  -ms-transform: translateX(95px);
  -o-transform: translateX(95px);
  transform: translateX(95px);
  color: #fff;
}
.pricing-container.pricing-style1 .pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 20px 8px;
  border-radius: 50px;
  box-shadow: 10px 10px 50px rgba(0, 0, 1, 0.05);
  background: #fff;
  width: 100%;
  max-width: 205px;
}
.pricing-container.pricing-style1 .pricing-switcher .fieldset.mnt-ac .rs-mnt,
.pricing-container.pricing-style1 .pricing-switcher .fieldset.mnt-acs .rs-yrs {
  color: #fff !important;
}
.pricing-container.pricing-style1 .pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  cursor: pointer;
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 400;
  margin: 0;
  width: 50%;
}
.pricing-container.pricing-style1 .no-js .pricing-wrapper .is-hidden {
  position: relative;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  margin-top: 1em;
}
.pricing-container.pricing-style1 .no-js .pricing-switcher {
  display: none;
}
.pricing-container.pricing-style1 .pricing-header {
  position: relative;
  padding: 30px 0 25px;
}
.pricing-container.pricing-style1 .pricing-header .title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  color: #121933C2;
  margin: 0 0 15px;
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-header .price-inner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.pricing-container.pricing-style1 .pricing-header .price-inner .price {
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  color: #28544B;
}
.pricing-container.pricing-style1 .pricing-header .price-inner span {
  position: relative;
  top: -7px;
  margin-left: 10px;
  color: #353535;
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-header .price-inner:after {
  content: "";
  position: absolute;
  background-color: #28544B17;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-body {
  padding: 0px 35px 15px 35px;
}
.pricing-container.pricing-style1 .pricing-body .features {
  margin-top: 30px;
}
.pricing-container.pricing-style1 .pricing-body .features ul li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #353535;
  padding-right: 36px;
  margin: 15px 0;
  transition: all 0.5s ease;
}
.pricing-container.pricing-style1 .pricing-body .features ul li:before {
  content: "\eb80";
  color: #28544B;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  position: absolute;
  right: 0;
  transition: all 0.5s ease;
}
.pricing-container.pricing-style1 .pricing-body .features ul li.close-icon:before {
  content: "\eb97";
  opacity: 0.6;
  color: #353535;
}
.pricing-container.pricing-style1 .pricing-footer {
  margin: 0px 35px 0px 35px;
}
.pricing-container.pricing-style1 .pricing-footer a {
  display: inline-block;
  border: 2px solid #28544B;
  font-size: 18px;
  line-height: 28px;
  color: #28544B;
  font-weight: 700;
  width: 100%;
  border-radius: 50px 50px 50px 50px;
  padding: 17px 15px 15px 15px;
  text-align: center;
}
.pricing-container.pricing-style1 .pricing-wrapper > li:hover .pricing-footer a {
  background: #28544B;
  color: #ffffff;
  border: 2px solid #28544B;
}
.pricing-container.pricing-style1 .pricing-list {
  margin: 2em 0 0;
  list-style: none;
}
.pricing-container.pricing-style1 .pricing-list > li {
  position: relative;
}
.pricing-container.pricing-style1 .pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.pricing-container.pricing-style1 .pricing-wrapper {
  position: relative;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
}
.pricing-container.pricing-style1 .touch .pricing-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-visible {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-visible {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  -moz-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  -moz-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}
.pricing-container.pricing-style1 .pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}
.pricing-container.pricing-style1 .pricing-wrapper > li {
  background-color: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 30px;
  padding: 0 0 30px;
}
.pricing-container.pricing-style1 .pricing-wrapper > li:hover {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1 .pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}
.pricing-container.pricing-style1 .pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.pricing-container.pricing-style1 .pricing-wrapper .is-selected {
  z-index: 3 !important;
}
.pricing-container.pricing-style1 .is-switched .pricing-body {
  overflow: hidden;
}
.pricing-container.pricing-style1 .pricing-body {
  overflow-x: visible;
}
.pricing-container.pricing-style1 .pricing-features {
  width: 600px;
}
.pricing-container.pricing-style1 .pricing-features li {
  width: 100px;
  float: left;
  padding: 1.6em 1em;
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pricing-container.pricing-style1 .has-margins .exclusive .pricing-features li {
  margin: 0;
}
.pricing-container.pricing-style1 .has-margins .pricing-footer {
  padding-bottom: 0;
}
.pricing-container.pricing-style1 .select {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.pricing-container.pricing-style1 .select {
  position: static;
  display: inline-block;
  height: auto;
  padding: 1.3em 2em;
  color: #1bbc9d;
  border-radius: 8px;
  border: 2px solid #1bbc9d;
  font-size: 1.4rem;
  text-indent: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .6s;
  width: 70%;
}
.pricing-container.pricing-style1 .no-touch .select:hover {
  background-color: #1bbc9d;
  color: #ffffff;
}
.pricing-container.pricing-style1 .exclusive .select {
  background-color: #1bbc9d;
  color: #ffffff;
}
.pricing-container.pricing-style1 .no-touch .exclusive .select:hover {
  background-color: #24e0ba;
}
.pricing-container.pricing-style1 .secondary-theme .exclusive .select {
  background-color: #1bbc9d;
}
.pricing-container.pricing-style1 .no-touch .secondary-theme .exclusive .select:hover {
  background-color: #112e3c;
}
.pricing-container.pricing-style1 .has-margins .select {
  display: block;
  padding: 1.7em 0;
  border-radius: 0 0 4px 4px;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-header {
  padding: 40px 0 25px;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li {
  padding: 0 0 40px;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-switcher .switch {
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
  position: absolute;
  top: 10px;
  left: 10px;
  height: 45px;
  width: 96px;
  background-color: #2B59FF;
  border-radius: 50px;
  transition: transform 0.5s;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li {
  background-color: #2C2355;
  border-radius: 20px 20px 20px 20px;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-header .title {
  color: #FFFFFFC2;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-body .features ul li {
  color: #FFFFFF;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-body .features ul li:before {
  color: transparent;
  background-image: linear-gradient(94.57deg, #d6a700 8.35%, #7d16ff 100%);
  -webkit-background-clip: text;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-body .features ul li.close-icon:before {
  color: #FFFFFF;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-header .price-inner .price {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-header .price-inner span {
  color: #C8C8C8;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-footer a {
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
  border: none !important;
  position: relative;
  color: #fff;
  background-color: #2C2355;
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-footer a span {
  position: relative;
  z-index: 9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-footer a:before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: inherit;
  transition: all 0.3s;
  background-color: transparent;
  background-image: linear-gradient(80deg, #2c2355 0%, #2c2355 100%);
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li:hover .pricing-footer a {
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li:hover .pricing-footer a:before {
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 0%, #7d16ff 100%);
}
.pricing-container.pricing-style1.pricing-modify1 .pricing-wrapper > li:hover .pricing-footer a span {
  position: relative;
  z-index: 9;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-color: unset;
  background-image: unset;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-switcher .fieldset {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper > li {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper > li:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.09);
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-header .price-inner .price {
  color: #1a4d2e;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-footer a {
  border: 2px solid #1a4d2e;
  color: #28544b;
}
.pricing-container.pricing-style1.pricing-modify2 .pricing-wrapper > li:hover .pricing-footer a {
  background: #1a4d2e;
  color: #ffffff;
  border: 2px solid #1a4d2e;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-switcher .switch {
  background-color: #f05454;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-body .features ul li:before {
  color: #f05454;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-body .features ul li.close-icon:before {
  opacity: 0.6;
  color: #353535;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-switcher .fieldset {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-wrapper > li {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-wrapper > li:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.09);
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-header .price-inner .price {
  color: #f05454;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-footer a {
  border: 2px solid #f05454;
  color: #f05454;
}
.pricing-container.pricing-style1.pricing-modify3 .pricing-wrapper > li:hover .pricing-footer a {
  background: #f05454;
  color: #ffffff;
  border: 2px solid #f05454;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-switcher .fieldset {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-wrapper > li {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-wrapper > li:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.09);
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-header .price-inner .price {
  color: #534ff8;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-footer a {
  border: 2px solid #534ff8;
  color: #534ff8;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-wrapper > li:hover .pricing-footer a {
  background: #534ff8;
  color: #ffffff;
  border: 2px solid #534ff8;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-switcher .switch {
  background-color: #534ff8;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-body .features ul li:before {
  color: #534ff8;
}
.pricing-container.pricing-style1.pricing-modify4 .pricing-body .features ul li.close-icon:before {
  opacity: 0.6;
  color: #353535;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-switcher .fieldset {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-wrapper > li {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-wrapper > li:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.09);
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-header .price-inner .price {
  color: #7900ff;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-footer a {
  border: 2px solid #7900ff;
  color: #7900ff;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-wrapper > li:hover .pricing-footer a {
  background: #7900ff;
  color: #ffffff;
  border: 2px solid #7900ff;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-switcher .switch {
  background-color: #7900ff;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-body .features ul li:before {
  color: #7900ff;
}
.pricing-container.pricing-style1.pricing-modify5 .pricing-body .features ul li.close-icon:before {
  opacity: 0.6;
  color: #353535;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-switcher .fieldset {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-wrapper > li {
  box-shadow: 20px 20px 40px rgba(0, 0, 1, 0.05);
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-wrapper > li:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.09);
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-header .price-inner .price {
  color: #2b59ff;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-footer a {
  border: 2px solid #2b59ff;
  color: #2b59ff;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-wrapper > li:hover .pricing-footer a {
  background: #2b59ff;
  color: #ffffff;
  border: 2px solid #2b59ff;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-switcher .switch {
  background-color: #2b59ff;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-body .features ul li:before {
  color: #2b59ff;
}
.pricing-container.pricing-style1.pricing-modify6 .pricing-body .features ul li.close-icon:before {
  opacity: 0.6;
  color: #353535;
}
/*------------------------------------
	21. Blog Section CSS
------------------------------------*/
.rs-blog.blog-main-home .slider {
  position: relative;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap {
  transition: all 500ms ease;
  background: #fff;
  padding: 0px 15px 0px 15px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap {
  position: relative;
  overflow: hidden;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .image-wrap a img {
  border-radius: 30px 30px 30px 30px;
  transition: all 0.5s ease 0s;
  transform: scale(1);
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content {
  text-align: center;
  padding: 10px 0px 0px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta {
  display: flex;
  justify-content: center;
  margin: 4px auto 10px;
  padding: 0 0 5px;
  width: 100%;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta li {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  color: #2B59FF;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 12px;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background: #2b59ff;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-meta li:last-child:after {
  display: none;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0 0 0 0;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #121933;
}
.rs-blog.blog-main-home .slider .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #2b59ff;
}
.rs-blog.blog-main-home .slider .slick-list {
  padding: 0 !important;
}
.rs-blog.blog-main-home .slider .slick-arrow {
  position: absolute;
  top: 26%;
  transform: translate(0, -50%);
  display: block;
  bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  line-height: 50px;
  font-size: 0;
  border: 1px solid #2b59ff;
  z-index: 9;
  background: transparent;
  opacity: 1;
  visibility: hidden;
  transition: 0.4s;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-next {
  right: 0;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-next:before {
  content: "\ea6e";
  font-size: 28px;
  color: #2b59ff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-prev {
  left: 0;
}
.rs-blog.blog-main-home .slider .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #2b59ff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-prev {
  left: -75px;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-prev:before {
  color: #2b59ff;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-next {
  right: -75px;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow.slick-next:before {
  color: #2b59ff;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover {
  background: #2b59ff;
}
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover.slick-next:before,
.rs-blog.blog-main-home .slider:hover .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-blog.blog-main-home.blog-modify1 .blog-item .blog-inner-wrap .blog-content .blog-meta li,
.rs-blog.blog-main-home.blog-modify1 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #28544b;
}
.rs-blog.blog-main-home.blog-modify1 .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  background: #28544b;
}
.rs-blog.blog-main-home.blog-modify1 .blog-button a:hover {
  color: #ffffff;
  background-color: #28544b;
  border-color: #28544b;
}
.rs-blog.blog-main-home.blog-modify1 .slider .slick-arrow {
  border: 1px solid #28544B;
}
.rs-blog.blog-main-home.blog-modify1 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify1 .slider .slick-arrow.slick-prev:before {
  color: #28544B;
}
.rs-blog.blog-main-home.blog-modify1 .slider:hover .slick-arrow:hover {
  background: #28544B;
}
.rs-blog.blog-main-home.blog-modify2 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #bbb !important;
}
.rs-blog.blog-main-home.blog-modify2 .blog-item .blog-inner-wrap .blog-content .blog-meta li {
  color: #ccc;
}
.rs-blog.blog-main-home.blog-modify2 .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
}
.rs-blog.blog-main-home.blog-modify2 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #ffffff;
}
.rs-blog.blog-main-home.blog-modify2 .slider .blog-item .blog-inner-wrap {
  background: transparent;
}
.rs-blog.blog-main-home.blog-modify3 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #8030da !important;
}
.rs-blog.blog-main-home.blog-modify3 .blog-item .blog-inner-wrap .blog-content .blog-meta li {
  color: #8030da;
}
.rs-blog.blog-main-home.blog-modify3 .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  background: #8030da;
}
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #121933;
}
.rs-blog.blog-main-home.blog-modify3 .slider .blog-item .blog-inner-wrap {
  background: transparent;
}
.rs-blog.blog-main-home.blog-modify3 .blog-button a:hover {
  color: #ffffff;
  background-color: #8030da;
  border-color: #8030da;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow {
  border: 1px solid #3535354A;
}
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify3 .slider .slick-arrow.slick-prev:before {
  color: #3535354A;
}
.rs-blog.blog-main-home.blog-modify3 .slider:hover .slick-arrow:hover {
  background: #8030da;
  border: 1px solid #8030da;
}
.rs-blog.blog-main-home.blog-modify4 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #f05454 !important;
}
.rs-blog.blog-main-home.blog-modify4 .blog-item .blog-inner-wrap .image-wrap a img {
  border-radius: 30px 30px 0px 0px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-title a {
  color: #121933;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap {
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin: 0 10px 10px 10px;
  padding: 0 0 0 0;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content {
  padding: 28px 20px 32px 20px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .blog-title {
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 15px;
}
.rs-blog.blog-main-home.blog-modify4 .slider .blog-item .blog-inner-wrap .blog-content .desc {
  margin: 0;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow {
  border: 1px solid #3535354A;
}
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify4 .slider .slick-arrow.slick-prev:before {
  color: #3535354A;
}
.rs-blog.blog-main-home.blog-modify4 .slider:hover .slick-arrow:hover {
  background: #f05454;
  border: 1px solid #f05454;
}
.rs-blog.blog-main-home.blog-modify5 .slider .blog-item .blog-inner-wrap {
  background: transparent;
}
.rs-blog.blog-main-home.blog-modify5 .blog-inner-wrap .blog-content .blog-meta li {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(80deg, #ff0945 0%, #ff803b 100%);
}
.rs-blog.blog-main-home.blog-modify5 .blog-item .blog-inner-wrap .blog-content .blog-meta li,
.rs-blog.blog-main-home.blog-modify5 .blog-item .blog-inner-wrap .blog-content .blog-title a:hover {
  color: #ff0945;
}
.rs-blog.blog-main-home.blog-modify5 .blog-item .blog-inner-wrap .blog-content .blog-meta li:after {
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #ff0945 8.35%, #ff803b 94.57%);
}
.rs-blog.blog-main-home.blog-modify5 .slider .slick-arrow {
  border: 1px solid #3535354A;
}
.rs-blog.blog-main-home.blog-modify5 .slider .slick-arrow.slick-next:before,
.rs-blog.blog-main-home.blog-modify5 .slider .slick-arrow.slick-prev:before {
  color: #3535357D;
}
.rs-blog.blog-main-home.blog-modify5 .slider:hover .slick-arrow:hover {
  background: #ff0945;
  border: transparent;
}
.rs-inner-blog .blog-item {
  background-color: #ffffff;
}
.rs-inner-blog .blog-item .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  z-index: 0;
}
.rs-inner-blog .blog-item .blog-img a {
  overflow: hidden;
  position: relative;
}
.rs-inner-blog .blog-item .blog-img a img {
  border-radius: 10px 10px 0 0;
}
.rs-inner-blog .blog-item .blog-img .blog-meta {
  position: absolute;
  bottom: 16px;
  top: auto;
  left: 50px;
  right: 30px;
  z-index: 99;
  transition: all 0.3s ease 0s;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate {
  overflow: hidden;
  list-style: none;
  margin: 0 0 5px;
  padding: 0 0 12px;
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li {
  position: relative;
  margin-right: 25px;
  line-height: 42px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .user-svg svg {
  width: 16px;
  position: relative;
  top: -2px;
  color: #fff;
  margin-right: 5px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .blog-date {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .blog-date i {
  color: #ffffff;
  position: relative;
  margin-right: 2px;
  top: 2px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .blog-date i:before {
  font-size: 14px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .tag-line svg {
  width: 16px;
  position: relative;
  top: -2px;
  color: #fff;
  margin-right: 5px;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .tag-line a {
  padding-left: 4px;
  font-size: 14px;
  color: #ffffff;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li .tag-line a:hover {
  color: #ccc;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:last-child:after {
  display: none;
}
.rs-inner-blog .blog-item .blog-img .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-item .blog-img:before {
  position: absolute;
  content: "";
  height: 39%;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  transition: all 0.4s ease;
  background-image: linear-gradient(transparent, #000000 0), linear-gradient(transparent, #000000);
  z-index: 99;
}
.rs-inner-blog .blog-item .blog-content {
  padding: 36px 40px 30px 40px;
  overflow: hidden;
}
.rs-inner-blog .blog-item .blog-content .blog-title {
  margin: 4px 0 15px;
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
}
.rs-inner-blog .blog-item .blog-content .blog-title a {
  color: #121933;
}
.rs-inner-blog .blog-item .blog-content .blog-title a:hover {
  color: #2b59ff;
}
.rs-inner-blog .blog-item .blog-content .blog-desc {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 6px;
}
.rs-inner-blog .widget-area .widget-title .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: #121933;
  margin: 0;
}
.rs-inner-blog .widget-area .search-widget {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
}
.rs-inner-blog .widget-area .search-widget .search-wrap {
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap [type=search] {
  color: #444444;
  padding: 12px 17px;
  width: 100%;
  border: none;
  border-radius: 5px;
  position: relative;
  border: 1px solid rgba(62, 62, 62, 0.1);
}
.rs-inner-blog .widget-area .search-widget .search-wrap button {
  background: transparent;
  border: medium none;
  color: #3e3e3e;
  padding: 11px 15px 12px;
  position: absolute;
  display: block;
  right: 0px;
  top: 0;
  z-index: 10;
  font-size: 20px;
  border-radius: 0 5px 5px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button i:before {
  font-size: 18px;
  font-weight: 600;
  font-family: 'remixicon' !important;
  top: -6px;
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button:hover {
  color: #2b59ff;
}
.rs-inner-blog .widget-area .recent-posts {
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img {
  max-width: 140px;
  float: left;
  padding-right: 15px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img img {
  border-radius: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc {
  overflow: hidden;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #121933;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a:hover {
  color: #2b59ff;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post {
  display: block;
  font-size: 14px;
  color: #3E3E3E;
  margin-bottom: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i {
  margin-right: 8px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date-post i:before {
  top: 0px;
  font-size: 14px;
  color: #3E3E3E;
  position: relative;
}
.rs-inner-blog .widget-area .categories {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}
.rs-inner-blog .widget-area .categories ul li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.rs-inner-blog .widget-area .categories ul li a {
  font-weight: 500;
  font-size: 15px;
  color: #0a0a0a;
  transition: all 0.4s ease-in-out 0s;
}
.rs-inner-blog .widget-area .categories ul li a:hover {
  color: #2b59ff;
}
.rs-inner-blog .widget-area .categories ul li:first-child {
  border-top: none;
}
.rs-inner-blog .widget-area .tags-cloud {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}
.rs-inner-blog .widget-area .tags-cloud .tagcloud a {
  display: inline-block;
  margin: 10px 3px 0px 0;
  line-height: 20px;
  padding: 6px 12px;
  font-size: 13px;
  text-transform: capitalize;
  transition: all .3s ease;
  background: #ffffff;
  color: #444444;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
.rs-inner-blog .widget-area .tags-cloud .tagcloud a:hover {
  color: #ffffff;
  background: #2b59ff;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #121933;
  margin: 0;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title.title2 {
  font-size: 20px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .title.title3 {
  font-size: 24px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full p {
  margin: 0;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info {
  margin-top: 25px;
  clear: both;
  margin-bottom: 30px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info a {
  font-size: 13px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  color: #ffffff;
  background: #2b59ff;
  padding: 2px 15px 4px;
  margin-left: 7px;
  border-radius: 0px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 4px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .bs-info a:hover {
  background: #010D14;
  color: #fff;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note .from-control {
  width: 100%;
  padding: 12px 20px;
  background-color: #f6f7f9;
  border: 1px solid #f6f7f9;
  border-radius: 5px;
  max-width: 100%;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note textarea {
  height: 160px;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 0.5;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 0.5;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 0.5;
}
.rs-inner-blog .blog-details .blog-full .blog-content-full .comment-note :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 0.5;
}
/* ------------------------------------
	22. Testimonial Section CSS
---------------------------------------*/
.rs-testimonial.testimonial-style1 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style1 .slider .testi-item {
  margin: 0px 15px 15px 15px;
  padding: 38px 31px 38px 31px;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .item-content span img {
  width: 57px;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .item-content p {
  font-size: 25px;
  line-height: 40px;
  font-weight: 500;
  color: #121933;
  margin: 20px 0 15px 0;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .rattings i {
  color: #2b59ff;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-content {
  padding: 25px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-content .image-wrap img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  position: relative;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-content .testi-information {
  padding: 0 0 0 20px;
  text-align: left;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 16px;
  font-weight: 700;
  color: #121933;
}
.rs-testimonial.testimonial-style1 .slider .testi-item .testi-content .testi-information .testi-title {
  color: #353535;
  font-size: 14px;
  display: block;
}
.rs-testimonial.testimonial-style1 .slider .testi-item:not(.slick-center) {
  opacity: 0.7;
}
.rs-testimonial.testimonial-style1 .slider .slick-list {
  padding: 0 !important;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  line-height: 50px;
  font-size: 0;
  border: 1px solid #fff;
  z-index: 99;
  background: transparent;
  opacity: 1;
  visibility: hidden;
  transition: 0.4s;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-next {
  right: 0;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-next:before {
  content: "\ea6e";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-prev {
  left: 0;
}
.rs-testimonial.testimonial-style1 .slider .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow.slick-prev {
  left: -75px;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow.slick-prev:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow.slick-next {
  right: -75px;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow.slick-next:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow:hover {
  background: #ffffff;
}
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow:hover.slick-prev:before,
.rs-testimonial.testimonial-style1 .slider:hover .slick-arrow:hover.slick-next:before {
  color: #2b59ff;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 1, 0.05);
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .testi-content .image-wrap img {
  height: 50px;
  width: 50px;
  border-radius: 15px 15px 15px 15px;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .rattings i {
  color: #6647ff;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .item-content p {
  font-size: 22px;
  line-height: 35px;
  font-weight: 500;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slider .testi-item .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slick-dots li button {
  background-color: #6345f852;
}
.rs-testimonial.testimonial-style1.testimonial-modify1 .slick-dots li:hover button,
.rs-testimonial.testimonial-style1.testimonial-modify1 .slick-dots li.slick-active button {
  background-color: #6345F8;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item {
  background-color: #1D1F68;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 1, 0.05);
  border-radius: 32px 32px 32px 32px;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .rattings i {
  color: #eec252;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .testi-content .image-wrap img {
  height: 50px;
  width: 50px;
  border-radius: 15px 15px 15px 15px;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slick-center .slider .testi-item .item-content span img {
  display: none !important;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .testi-content .testi-information .testi-title,
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .testi-content .testi-information .testi-name,
.rs-testimonial.testimonial-style1.testimonial-modify3 .slider .testi-item .item-content p {
  color: #ffffff;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slick-center {
  background-color: transparent;
  background-image: linear-gradient(100.24deg, #9b25ff 8.35%, #0194e7 94.57%);
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slick-dots li button {
  background-color: #FFFFFFA1;
}
.rs-testimonial.testimonial-style1.testimonial-modify3 .slick-dots li:hover button,
.rs-testimonial.testimonial-style1.testimonial-modify3 .slick-dots li.slick-active button {
  background-color: #ffffff;
}
.rs-testimonial.testimonial-style1.testimonial-modify5 .slider .testi-item {
  box-shadow: 0px 12px 25px 0px rgba(0, 0, 1, 0.03);
  border-radius: 20px 20px 20px 20px;
}
.rs-testimonial.testimonial-style1.testimonial-modify5 .slider .testi-item .item-content p {
  font-size: 22px;
  line-height: 36px;
  margin: 25px 0 25px 0;
}
.rs-testimonial.testimonial-style1.testimonial-modify5 .slick-dots li button {
  background-color: #113CFC80;
}
.rs-testimonial.testimonial-style1.testimonial-modify5 .slick-dots li:hover button,
.rs-testimonial.testimonial-style1.testimonial-modify5 .slick-dots li.slick-active button {
  background-color: #113CFC;
}
.rs-testimonial.testimonial-style2 .slider {
  position: relative;
}
.rs-testimonial.testimonial-style2 .slider .testi-item {
  padding: 38px 32px 38px 32px;
  margin: 10px 15px 15px 15px;
  border-radius: 20px 20px 20px 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span {
  position: relative;
  display: block;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span .normal-logo {
  width: 34px;
  z-index: 9;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content span .hover-logo {
  width: 34px;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: 0.4s;
  z-index: 9;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .item-content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #303030;
  margin: 17px 0 12px 0;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .rattings i {
  color: #f05454;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information {
  margin: 17px 0px 0px 0px;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information .testi-name {
  font-size: 16px;
  font-weight: 700;
  color: #121933;
}
.rs-testimonial.testimonial-style2 .slider .testi-item .testi-content .testi-information .testi-title {
  font-size: 14px;
  color: #353535;
}
.rs-testimonial.testimonial-style2 .slider .testi-item:hover {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}
.rs-testimonial.testimonial-style2 .slider .testi-item:hover .item-content span .hover-logo {
  opacity: 1;
  z-index: 10;
}
.rs-testimonial.testimonial-style2 .slider .slick-dots li button {
  background-color: #f45d4859;
}
.rs-testimonial.testimonial-style2 .slider .slick-dots li:hover button,
.rs-testimonial.testimonial-style2 .slider .slick-dots li.slick-active button {
  background-color: #f45d48;
}
.rs-testimonial.testimonial-style2 .slider .slick-list {
  padding: 0 !important;
}
.rs-testimonial.testimonial-style2 .slider .slick-arrow {
  display: none !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item {
  padding: 38px 32px 38px 32px;
  margin: 10px 15px 15px 15px;
  border-radius: 0px 0px 0px 0px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item:not(.slick-center) {
  opacity: 0.7;
}
.rs-testimonial.testimonial-style2.testimonial-modify2.slick-center .testi-item:hover .item-content span .hover-logo {
  display: none;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item .rattings i {
  color: #0531A3;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slick-dots li button {
  background-color: #0531A375;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slick-dots li:hover button,
.rs-testimonial.testimonial-style2.testimonial-modify2 .slick-dots li.slick-active button {
  background-color: #0531A3;
}
.rs-testimonial.testimonial-style2.testimonial-modify2 .slider .testi-item:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item {
  background-color: #1D1F68;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 1, 0.03);
  padding: 38px 28px 38px 28px;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .testi-content .testi-information .testi-title,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .testi-content .testi-information .testi-name,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .item-content p {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .item-content span img {
  width: 57px;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .item-content p {
  margin: 20px 8px 15px 0px;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .testi-content .testi-information {
  margin: 10px 0px 0px 0px;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .testi-item:not(.slick-center) {
  background-color: transparent;
  background-image: linear-gradient(70deg, #d6a700 0%, #7d16ff 100%);
  position: relative;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .testi-item:not(.slick-center)::before {
  position: absolute;
  content: "";
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: inherit;
  transition: all 0.3s;
  background: #060314;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .testi-item:not(.slick-center) {
  opacity: 1;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slick-center {
  background-color: transparent;
  background-image: linear-gradient(70deg, #d6a700 0%, #7d16ff 100%);
  position: relative;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .item-content,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .testi-content {
  position: relative;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slick-dots li button {
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 7 D 0%, #7d16ff 7 A 100%);
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slick-dots li:hover button,
.rs-testimonial.testimonial-style2.testimonial-modify4 .slick-dots li.slick-active button {
  background-color: transparent;
  background-image: linear-gradient(80deg, #d6a700 7 D 0%, #7d16ff 7 A 100%);
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .rattings i {
  color: #fff;
}
.rs-testimonial.testimonial-style2.testimonial-modify4 .slider .testi-item .rattings i.star-color {
  color: #fff;
  opacity: 0.25;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slick-dots li button {
  background-color: #534FF86E;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slick-dots li:hover button,
.rs-testimonial.testimonial-style2.testimonial-modify7 .slick-dots li.slick-active button {
  background-color: #534ff8;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .testi-item:hover {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .testi-item .rattings i {
  color: #534ff8;
}
.rs-testimonial.testimonial-style2.testimonial-modify7 .slider .testi-item .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 {
  position: relative;
  z-index: 11;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item {
  margin: 10px 15px 0px 15px;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item .testi-content .rattings i {
  color: #534FF7;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slider .testi-item .testi-content .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-dots {
  display: none !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #fff;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow.slick-next {
  right: 20px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow.slick-next:before {
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  font-family: remixicon;
  content: "\ea6e";
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow.slick-prev {
  right: 90px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow:hover {
  background: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify8 .slick-arrow:hover.slick-prev:before {
  color: #534ff8;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 {
  position: relative;
  z-index: 11;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slider .testi-item .testi-content .rattings i {
  color: #7900ff;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slider .testi-item .testi-content .rattings i.star-color {
  color: #c4c5cc;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-dots {
  display: none !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow {
  position: absolute;
  bottom: 0;
  border: 1px solid #35353554;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow.slick-next {
  right: 20px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow.slick-next:before {
  font-size: 28px;
  color: #3535359C;
  margin: 0;
  font-family: remixicon;
  content: "\ea6e";
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow.slick-prev {
  right: 90px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #3535359C;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow:hover {
  background: #7900ff;
}
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify9 .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 {
  position: relative;
  z-index: 11;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item {
  background-color: #2B104E;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item .item-content p {
  color: #fff;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item .testi-content .rattings i {
  background-image: linear-gradient(120deg, #f95660 0%, #910feb 100%);
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item .testi-content .rattings i.star-color {
  background: #FFFFFF;
  color: #604c7a !important;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item .testi-content .testi-information .testi-name {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slider .testi-item .testi-content .testi-information .testi-title {
  color: #ffffff;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-dots {
  display: none !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow {
  position: absolute;
  bottom: 0;
  border: none;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  z-index: 99;
  opacity: 1;
  display: block !important;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow.slick-next {
  right: 20px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow.slick-next:before {
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  font-family: remixicon;
  content: "\ea6e";
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow.slick-prev {
  right: 90px;
  top: -134px;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow:hover {
  background: linear-gradient(84.87deg, #fe5a59 0%, #cc379e 46.01%, #8205ff 104.9%);
}
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style2.testimonial-modify10 .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section {
  max-width: 970px;
  margin: 0 auto;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider {
  position: relative;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-list {
  padding: 0 !important;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item {
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-radius: 200px 200px 200px 200px;
  padding: 80px 60px 55px 210px;
  margin: 0px 15px 15px 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0);
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item .image-wrap img {
  border-radius: 50%;
  position: relative;
  margin-left: -150px;
  margin-top: -24px;
  max-width: 320px;
  width: 210px !important;
  height: 210px !important;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item .item-content {
  margin: -24px 0px 0px 0px;
  padding: 0px 0px 0px 30px;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item .item-content p {
  color: #353535;
  font-size: 25px;
  line-height: 40px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item .item-content .testi-information .testi-name {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: #121933;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .testi-item .item-content .testi-information .testi-title {
  font-size: 14px;
  color: #353535;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-dots li button {
  background-color: #1A4D2E87;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-dots li:hover button,
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-dots li.slick-active button {
  background-color: #1A4D2E;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  line-height: 50px;
  font-size: 0;
  border: 1px solid #fff;
  z-index: 9;
  background: transparent;
  opacity: 1;
  visibility: hidden;
  transition: 0.4s;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-arrow.slick-next {
  right: 0;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-arrow.slick-next:before {
  content: "\ea6e";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-arrow.slick-prev {
  left: 0;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section .slider .slick-arrow.slick-prev:before {
  content: "\ea64";
  font-size: 28px;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  font-family: remixicon;
  text-align: center;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow {
  background: #ffffff;
  visibility: visible;
  opacity: 1;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow.slick-prev {
  left: -75px;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow.slick-prev:before {
  color: #121933;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow.slick-next {
  right: -75px;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow.slick-next:before {
  color: #121933;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow:hover {
  background: #1A4D2E;
}
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow:hover.slick-next:before,
.rs-testimonial.testimonial-style3 .testi-wrap-section:hover .slider .slick-arrow:hover.slick-prev:before {
  color: #ffffff;
}
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slick-dots li button {
  background-color: #730BE95E;
}
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slick-dots li:hover button,
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slick-dots li.slick-active button {
  background-color: #730BE9;
}
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slider .slick-arrow {
  border: 1px solid #3535354A;
  background: #6B0AD900;
}
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section .slider .slick-arrow.slick-prev:before {
  color: #3535354A;
}
.rs-testimonial.testimonial-style3.testimonial-modify6 .testi-wrap-section:hover .slider .slick-arrow:hover {
  background-color: #730BE9;
}
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slider .testi-item {
  background-color: #FBFBFB;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0);
}
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slick-dots li:hover button,
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slick-dots li.slick-active button {
  background-color: transparent;
  background-image: linear-gradient(180deg, #ff803b 0%, #ff0945 100%);
}
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slider .slick-arrow {
  border: none;
  background-color: transparent;
  background-image: linear-gradient(120deg, #f3f3f3 0%, #f3f3f3 100%);
}
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section .slider .slick-arrow.slick-prev:before {
  color: #3535354A;
}
.rs-testimonial.testimonial-style3.testimonial-modify10 .testi-wrap-section:hover .slider .slick-arrow:hover {
  background-color: transparent;
  background-image: linear-gradient(120deg, #ff0945 0%, #ff803b 100%);
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slider .testi-item {
  background-color: #FBFBFB;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0);
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slick-dots li button {
  background-color: #2B59FF6E;
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slick-dots li:hover button,
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slick-dots li.slick-active button {
  background-color: #2b59ff;
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slider .slick-arrow {
  background: #f3f3f3;
  border: none;
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slider .slick-arrow.slick-next:before,
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section .slider .slick-arrow.slick-prev:before {
  color: #3535354A;
}
.rs-testimonial.testimonial-style3.testimonial-modify11 .testi-wrap-section:hover .slider .slick-arrow:hover {
  background-color: #2b59ff;
}
/*------------------------------------
	23. Footer Section CSS
------------------------------------*/
.rs-footer.footer-main-home {
  background-color: #121933;
  position: relative;
  background-size: cover;
  background-position: center top !important;
}
.rs-footer.footer-main-home .footer-top {
  padding: 107px 0 42px;
}
.rs-footer.footer-main-home .footer-top .footer-logo {
  margin-bottom: 35px;
}
.rs-footer.footer-main-home .footer-top .footer-logo a img {
  height: 30px;
}
.rs-footer.footer-main-home .footer-top .footer-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 45px;
}
.rs-footer.footer-main-home .footer-top .textwidget-btn li .footer-btn {
  display: inline-block;
  color: #fff;
  background-color: #2b59ff;
  border-radius: 5px 5px 5px 5px;
  padding: 13px 33px 17px 33px;
  transition: 0.4s;
  margin-bottom: 20px;
}
.rs-footer.footer-main-home .footer-top .textwidget-btn li .footer-btn i {
  top: 4px;
  font-size: 20px;
  margin-right: 6px;
}
.rs-footer.footer-main-home .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background-color: #3863ff;
}
.rs-footer.footer-main-home .footer-top .textwidget-btn li .footer-btn.footer-dark {
  width: 192px;
  background-color: #222943;
}
.rs-footer.footer-main-home .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  color: #ffffff;
  background-color: #2b59ff;
}
.rs-footer.footer-main-home .footer-top .footer-social li {
  display: inline-block;
  margin-right: 6px;
}
.rs-footer.footer-main-home .footer-top .footer-social li a i {
  color: #ffffff;
  display: block;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 7px;
  width: 38px;
  height: 38px;
  line-height: 40px;
  text-align: center;
  transition: all 0.4s;
}
.rs-footer.footer-main-home .footer-top .footer-social li a i:hover {
  color: #ffffff;
  background: #2b59ff;
}
.rs-footer.footer-main-home .footer-top .footer-social li:last-child {
  margin-right: 0;
}
.rs-footer.footer-main-home .footer-top .site-map {
  margin-top: -10px;
}
.rs-footer.footer-main-home .footer-top .site-map li {
  margin-bottom: 5px;
}
.rs-footer.footer-main-home .footer-top .site-map li a {
  font-size: 15px;
  position: relative;
  padding: 6px 0 4px 0px;
  display: inline-block;
  color: #ffffff;
  opacity: .8;
  transition: all 0.3s;
}
.rs-footer.footer-main-home .footer-top .site-map li a:hover {
  color: #2b59ff;
}
.rs-footer.footer-main-home .footer-top .widget-desc {
  margin: 0px 0px 25px 0;
  color: #ffffff;
  opacity: .8;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border {
  border-top: 0.5px solid rgba(255, 255, 255, 0.09);
  padding: 25px 0;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p {
  font-size: 16px;
  font-weight: 300;
  opacity: .8;
  color: #ffffff;
  margin: 0;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p a {
  transition: all 0.4s;
  color: #ffffff;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copyright p a:hover {
  color: #2b59ff;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li a {
  font-size: 16px;
  font-weight: 300;
  opacity: .8;
  color: #ffffff;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li a:hover {
  color: #2b59ff;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  font-size: 18px;
  background: #fff;
  opacity: .8;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer.footer-main-home .footer-bottom .bottom-border .copy-right-menu li:first-child:before {
  display: none;
}
.rs-footer.footer-main-home.footer-inner-modify1 .footer-top {
  padding: 180px 0 42px;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top {
  padding: 180px 0 42px;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .textwidget-btn .footer-btn:hover,
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #da573a;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-inner-modify2 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .site-map li a:hover {
  color: #da573a;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .textwidget-btn li .footer-btn {
  background-color: #f45d48;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #f45d48;
}
.rs-footer.footer-main-home.footer-inner-modify2 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #df321a;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top {
  padding: 160px 0 42px;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .textwidget-btn li .footer-btn {
  background-color: #28544b;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover,
.rs-footer.footer-main-home.footer-modify1 .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background-color: #f45d48;
}
.rs-footer.footer-main-home.footer-modify1 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #f45d48;
}
.rs-footer.footer-main-home.footer-modify1 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify1 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify1 .footer-top .site-map li a:hover {
  color: #f45d48;
}
.rs-footer.footer-main-home.footer-modify2 .footer-top {
  padding: 160px 0 42px;
}
.rs-footer.footer-main-home.footer-modify2 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify2 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify2 .footer-top .site-map li a:hover {
  color: #6647ff;
}
.rs-footer.footer-main-home.footer-modify2 .footer-top .footer-social li a i:hover,
.rs-footer.footer-main-home.footer-modify2 .footer-top .textwidget-btn li .footer-btn {
  background-color: #6647ff;
}
.rs-footer.footer-main-home.footer-modify2 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify2 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #6647ff;
}
.rs-footer.footer-main-home.footer-modify2 .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background-color: #5c43d5;
}
.rs-footer.footer-main-home.footer-modify3 {
  background-color: #fff;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .footer-title {
  color: #121933;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copyright p a,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copy-right-menu li a,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copyright p,
.rs-footer.footer-main-home.footer-modify3 .footer-top .widget-desc,
.rs-footer.footer-main-home.footer-modify3 .footer-top .site-map li a {
  color: #353535;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .footer-social li a i {
  background: #6c757d;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top {
  padding: 250px 0 42px;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .textwidget-btn li .footer-btn {
  background-color: #0531a3;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .footer-social li a i:hover {
  color: #f45d48;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #0531a3;
}
.rs-footer.footer-main-home.footer-modify3 .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background-color: #114ce5;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify3 .footer-top .site-map li a:hover {
  color: #f45d48;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border {
  border-top: 0.5px solid rgba(53, 53, 53, 0.3);
  padding: 25px 0;
}
.rs-footer.footer-main-home.footer-modify3 .footer-bottom .bottom-border .copy-right-menu li:before {
  background: #0531a3;
}
.rs-footer.footer-main-home.footer-modify4 {
  background-color: #140d36;
}
.rs-footer.footer-main-home.footer-modify4 .footer-top {
  padding: 110px 0 42px;
}
.rs-footer.footer-main-home.footer-modify4 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #6647ff;
}
.rs-footer.footer-main-home.footer-modify4 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify4 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify4 .footer-top .site-map li a:hover {
  color: #6647ff;
}
.rs-footer.footer-main-home.footer-modify4 .footer-bottom .bottom-border .copy-right-menu li:before {
  background-color: #6647ff;
}
.rs-footer.footer-main-home.footer-modify4 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
  background-image: unset;
}
.rs-footer.footer-main-home.footer-modify4 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-image: linear-gradient(94.57deg, #9b25ff 8.35%, #0194e7 100%);
}
.rs-footer.footer-main-home.footer-modify4 .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(94.57deg, #0194e7 8.35%, #9b25ff 100%);
}
.rs-footer.footer-main-home.footer-modify4 .footer-top .textwidget-btn li .footer-btn {
  background-image: linear-gradient(94.57deg, #9b25ff 8.35%, #0194e7 100%);
}
.rs-footer.footer-main-home.footer-modify5 {
  background-color: #140d36;
}
.rs-footer.footer-main-home.footer-modify5 .footer-top {
  padding: 110px 0 42px;
}
.rs-footer.footer-main-home.footer-modify5 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
  background-image: unset;
}
.rs-footer.footer-main-home.footer-modify5 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background: linear-gradient(100.24deg, #7d16ff 8.35%, #d6a700 94.57%);
}
.rs-footer.footer-main-home.footer-modify5 .footer-top .textwidget-btn li .footer-btn:hover {
  color: #ffffff;
  background: linear-gradient(100.24deg, #7d16ff 8.35%, #d6a700 94.57%);
}
.rs-footer.footer-main-home.footer-modify5 .footer-top .textwidget-btn li .footer-btn {
  background: linear-gradient(100.24deg, #d6a700 8.35%, #7d16ff 94.57%);
}
.rs-footer.footer-main-home.footer-modify5 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify5 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify5 .footer-top .site-map li a:hover {
  color: #d6a700;
}
.rs-footer.footer-main-home.footer-modify5 .footer-bottom .bottom-border .copy-right-menu li:before {
  background-color: #d6a700;
}
.rs-footer.footer-main-home.footer-modify5 .footer-top .footer-social li a i:hover {
  color: #ffffff;
  background: #d6a700;
}
.rs-footer.footer-main-home.footer-modify6 .footer-top {
  padding: 100px 0 42px;
}
.rs-footer.footer-main-home.footer-modify6 .footer-top .textwidget-btn .footer-btn:hover,
.rs-footer.footer-main-home.footer-modify6 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #113cfc;
}
.rs-footer.footer-main-home.footer-modify6 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify6 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify6 .footer-top .site-map li a:hover {
  color: #113cfc;
}
.rs-footer.footer-main-home.footer-modify7 {
  background: #fff;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top {
  padding: 110px 0 42px;
}
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copy-right-menu li a,
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copyright p a,
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copyright p,
.rs-footer.footer-main-home.footer-modify7 .footer-top .site-map li a,
.rs-footer.footer-main-home.footer-modify7 .footer-top .widget-desc,
.rs-footer.footer-main-home.footer-modify7 .footer-top .footer-title {
  color: #121933;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top .footer-social li a i:hover {
  color: #ff9f29;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top .footer-social li a i {
  background: #1a4d2e;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top .textwidget-btn li .footer-btn {
  background-color: #1a4d2e;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify7 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover,
.rs-footer.footer-main-home.footer-modify7 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #ff9f29;
}
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify7 .footer-top .site-map li a:hover {
  color: #ff9f29;
}
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border .copy-right-menu li:before {
  background-color: #ff9f29;
}
.rs-footer.footer-main-home.footer-modify7 .footer-bottom .bottom-border {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #E6E4E48A;
  padding: 25px 0;
}
.rs-footer.footer-main-home.footer-modify8 .footer-top {
  padding: 200px 0 42px;
}
.rs-footer.footer-main-home.footer-modify8 .footer-top .textwidget-btn li .footer-btn {
  background-color: #8030da;
}
.rs-footer.footer-main-home.footer-modify8 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify8 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover,
.rs-footer.footer-main-home.footer-modify8 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #da573a;
}
.rs-footer.footer-main-home.footer-modify8 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #8030da;
}
.rs-footer.footer-main-home.footer-modify8 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify8 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify8 .footer-top .site-map li a:hover {
  color: #8030da;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top {
  padding: 200px 0 42px;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top .textwidget-btn li .footer-btn {
  background-color: #da573a;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #2E3652;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #e1330c;
}
.rs-footer.footer-main-home.footer-modify9 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #f05454;
}
.rs-footer.footer-main-home.footer-modify9 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify9 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify9 .footer-top .site-map li a:hover {
  color: #f05454;
}
.rs-footer.footer-main-home.footer-modify10 {
  background-color: transparent;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top {
  padding: 100px 0 42px;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .footer-title,
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border .copyright p a,
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border .copy-right-menu li a,
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border .copyright p,
.rs-footer.footer-main-home.footer-modify10 .footer-top .site-map li a,
.rs-footer.footer-main-home.footer-modify10 .footer-top .widget-desc {
  color: #121933;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .footer-social li a i {
  color: #ffffff;
  background: #222943;
  border-radius: 6px;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .footer-social li a i:hover {
  color: #534ff8;
}
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #e4dee65c;
}
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify10 .footer-top .site-map li a:hover,
.rs-footer.footer-main-home.footer-modify10 .footer-bottom .bottom-border .copyright p a:hover {
  color: #534ff8;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .textwidget-btn li .footer-btn {
  background-color: #534ff8;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #2E3652;
}
.rs-footer.footer-main-home.footer-modify10 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #4A46F4;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top {
  padding: 200px 0 42px;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top .textwidget-btn .footer-btn:hover,
.rs-footer.footer-main-home.footer-modify11 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #7900ff;
}
.rs-footer.footer-main-home.footer-modify11 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify11 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify11 .footer-top .site-map li a:hover {
  color: #7900ff;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top .textwidget-btn li .footer-btn {
  background-color: #7900FF;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background-color: #2E3652;
}
.rs-footer.footer-main-home.footer-modify11 .footer-top .textwidget-btn li .footer-btn:hover {
  background-color: #730BE9;
}
.rs-footer.footer-main-home.footer-modify12 .footer-top {
  padding: 200px 0 42px;
}
.rs-footer.footer-main-home.footer-modify12 .footer-top .textwidget-btn .footer-btn:hover,
.rs-footer.footer-main-home.footer-modify12 .footer-top .footer-social li a i:hover {
  background: linear-gradient(94.74deg, #ff813a 7.76%, #ff0844 94.35%);
}
.rs-footer.footer-main-home.footer-modify12 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify12 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify12 .footer-top .site-map li a:hover {
  color: #ff0945;
}
.rs-footer.footer-main-home.footer-modify12 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #222943;
  background-image: unset;
}
.rs-footer.footer-main-home.footer-modify12 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background: linear-gradient(94.74deg, #ff0844 7.76%, #ff813a 94.35%);
}
.rs-footer.footer-main-home.footer-modify12 .footer-top .textwidget-btn li .footer-btn:hover {
  background: linear-gradient(94.74deg, #ff813a 7.76%, #ff0844 94.35%);
}
.rs-footer.footer-main-home.footer-modify12 .footer-top .textwidget-btn li .footer-btn {
  background: linear-gradient(94.74deg, #ff0844 7.76%, #ff813a 94.35%);
  border-radius: 5px;
}
.rs-footer.footer-main-home.footer-modify13 {
  background-color: #2b104e;
}
.rs-footer.footer-main-home.footer-modify13 .footer-top {
  padding: 100px 0 42px;
}
.rs-footer.footer-main-home.footer-modify13 .footer-top .textwidget-btn li .footer-btn.footer-dark {
  background-color: #460363;
  background-image: unset;
}
.rs-footer.footer-main-home.footer-modify13 .footer-top .textwidget-btn li .footer-btn.footer-dark:hover {
  background: linear-gradient(84.87deg, #fe5a59 0%, #cc379e 46.01%, #8205ff 104.9%);
}
.rs-footer.footer-main-home.footer-modify13 .footer-top .textwidget-btn li .footer-btn:hover {
  background: linear-gradient(84.87deg, #fe5a59 0%, #8205ff 46.01%, #cc379e 104.9%);
}
.rs-footer.footer-main-home.footer-modify13 .footer-top .textwidget-btn li .footer-btn {
  background: linear-gradient(84.87deg, #fe5a59 0%, #cc379e 46.01%, #8205ff 104.9%);
  border-radius: 5px;
}
.rs-footer.footer-main-home.footer-modify13 .footer-top .footer-social li a i:hover {
  background: linear-gradient(84.87deg, #fe5a59 0%, #cc379e 46.01%, #8205ff 104.9%);
}
.rs-footer.footer-main-home.footer-modify13 .footer-bottom .bottom-border .copyright p a:hover,
.rs-footer.footer-main-home.footer-modify13 .footer-bottom .bottom-border .copy-right-menu li a:hover,
.rs-footer.footer-main-home.footer-modify13 .footer-top .site-map li a:hover {
  color: #da573a;
}
/* -----------------------
	24. Scroll Up CSS
--------------------------*/
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  height: 40px;
  font-size: 24px;
  width: 42px;
  border-radius: 5px;
  color: #ffffff;
  line-height: 36px;
  transition: all 0.3s ease 0s;
  background: #2B59FF;
  margin-left: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02);
  display: block;
}
#scrollUp i:before {
  font-weight: 900;
}
#scrollUp i:hover {
  background: #121933;
  color: #fff;
}
#scrollUp.green-color i {
  background: #28544b;
  color: #ffffff;
}
#scrollUp.green-color2 i {
  background: #1a4d2e;
  color: #ffffff;
}
#scrollUp.purple-color i {
  background: #6647ff;
  color: #ffffff;
}
#scrollUp.purple-color2 i {
  background: #8030da;
  color: #ffffff;
}
#scrollUp.purple-color3 i {
  background: #7900ff;
  color: #ffffff;
}
#scrollUp.purple-color4 i {
  background: #2b104e;
  color: #ffffff;
}
#scrollUp.blue-color i {
  background: #0531a3;
  color: #ffffff;
}
#scrollUp.blue-color2 i {
  background: #113cfc;
  color: #ffffff;
}
#scrollUp.blue-color3 i {
  background: #534ff8;
  color: #ffffff;
}
#scrollUp.yellow-color i {
  background: #d6a700;
  color: #ffffff;
}
#scrollUp.orange-color i {
  background: #f05454;
  color: #ffffff;
}
#scrollUp.orange-color2 i {
  background: #ff0945;
  color: #ffffff;
}
#scrollUp.orange-color3 i {
  background: #da573a;
  color: #ffffff;
}
/*----------------------------------------------
	25. Preloader CSS
----------------------------------------------*/
#pre-load {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999;
}
.loader .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 3px solid #ebebec;
  border-radius: 50%;
}
.loader .loader-container:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-top: 3px solid #2b59ff;
  border-radius: 50%;
  animation: loaderspin 1.8s infinite ease-in-out;
  -webkit-animation: loaderspin 1.8s infinite ease-in-out;
}
.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  text-align: center;
}
.loader .loader-icon img {
  animation: loaderpulse alternate 900ms infinite;
  width: 40px;
}
.woocommerce .quantity input[type="number"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]::-moz-placeholder {
  /* Firefox 19+ */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-ms-input-placeholder {
  /* IE 10+ */

  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-moz-placeholder {
  /* Firefox 18- */

  color: #363636 !important;
  opacity: 1;
}
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/* ------------------------------------
	26. Animation Part CSS
---------------------------------------*/
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.move-y {
  animation: move-y 2s alternate infinite;
  -webkit-animation: move-y 2s alternate infinite;
}
@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 2s;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 2s;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 2s;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 2s;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.scale {
  animation-duration: 2s;
  -webkit-animation: scale 2s alternate infinite;
}
.scale.new {
  animation-duration: 2.5s;
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
  }
}
.scale2 {
  animation: scale2 2s alternate infinite;
  -webkit-animation: scale2 2s alternate infinite;
}
@-webkit-keyframes scale2 {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.07);
    -webkit-transform: scale(1.07);
  }
}
.horizontal {
  animation: horizontal 2s alternate infinite;
  -webkit-animation: horizontal 2s alternate infinite;
}
@keyframes horizontal {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.horizontal2 {
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-name: horizontal2;
}
@keyframes horizontal2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
.horizontal3 {
  animation-duration: 2s;
  animation: horizontal3 2s alternate infinite;
  -webkit-animation: horizontal3 2s alternate infinite;
}
@keyframes horizontal3 {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.horizontal4 {
  animation-duration: 3s;
  animation: horizontal4 3s alternate infinite;
  -webkit-animation: horizontal4 3s alternate infinite;
}
@keyframes horizontal4 {
  0% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
}
.rotated-style {
  animation: rotated-style 5s linear infinite;
}
@keyframes rotated-style {
  0% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-10px, 10px) rotate(10deg);
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(25deg);
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    -webkit-transform: translate(15px, -5px) rotate(15deg);
    transform: translate(15px, -5px) rotate(15deg);
  }
  100% {
    -webkit-transform: translate(0) rotate(0deg);
    transform: translate(0) rotate(0deg);
  }
}
.spine {
  animation: spine 8s infinite linear;
  -webkit-animation: spine 8s infinite linear;
  animation-duration: spine 8s;
}
@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.spiner {
  animation: spiner 30s infinite linear;
  -webkit-animation: spiner 30s infinite linear;
  animation-duration: spiner 30s;
}
@keyframes spiner {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spiner {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.veritcal {
  animation-name: veritcal;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}
@keyframes veritcal {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.veritcal2 {
  animation-name: veritcal2 3s alternate infinite;
  -webkit-animation: veritcal2 3s alternate infinite;
}
@keyframes veritcal2 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
@-webkit-keyframes veritcal2 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.veritcal3 {
  animation-name: veritcal3 4s alternate infinite;
  -webkit-animation: veritcal3 4s alternate infinite;
}
@keyframes veritcal3 {
  0% {
    transform: translate3d(0, -10px, 0);
    -webkit-transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.left-right2 {
  animation-name: left-right2;
  -webkit-animation-name: left-right2;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  animation-direction: linear;
  -webkit-animation-direction: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes left-right2 {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotate(-2deg);
    -webkit-transform: translate3d(0, 0, 0) rotate(-2deg);
  }
  75% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.left-right {
  animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
  -webkit-animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
}
@keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translatex(-50px);
  }
  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}
@-webkit-keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
  50% {
    transform: translatex(-50px);
    -webkit-transform: translatex(-50px);
  }
  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}
.move-reverse {
  -webkit-animation-duration: 4s;
  animation-name: move-reverse;
  -webkit-animation-name: move-reverse;
  -webkit-animation-iteration-count: infinite;
}
@keyframes move-reverse {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotate(-2deg);
    -webkit-transform: translate3d(0, 0, 0) rotate(-2deg);
  }
  75% {
    transform: translate3d(5px, 0, 0) rotate(4deg);
    -webkit-transform: translate3d(5px, 0, 0) rotate(4deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.rs-services.services-style10 .services-item:hover .dance_hover,
.rs-services.services-style4 .services-item:hover .dance_hover,
.rs-services.services-style3 .services-item:hover .dance_hover {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
/********** Price Table *************/
.pricing-switcher .switch {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 45px;
  width: 90px;
  background-color: #6722B5;
  border-radius: 10px;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(95px);
  -moz-transform: translateX(95px);
  -ms-transform: translateX(95px);
  -o-transform: translateX(95px);
  transform: translateX(95px);
  color: #fff;
}
.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 20px 8px;
  border-radius: 15px;
  background: #fff;
  width: 100%;
  max-width: 205px;
}
.pricing-switcher .fieldset.mnt-ac .rs-mnt,
.pricing-switcher .fieldset.mnt-acs .rs-yrs {
  color: #fff !important;
}
.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  cursor: pointer;
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 400;
  margin: 0;
  width: 50%;
}
.no-js .pricing-switcher {
  display: none;
}
.pricing-list {
  margin: 2em 0 0;
  list-style: none;
}
.pricing-list > li {
  position: relative;
}
@media only screen and (min-width: 992px) {
  .pricing-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .pricing-list > li {
    width: 33.333%;
    float: left;
  }
  .has-margins .pricing-list > li {
    width: 32.3333333333%;
    float: left;
    margin-right: 1.5%;
  }
  .has-margins .pricing-list > li:last-of-type {
    margin-right: 0;
  }
}
.pricing-wrapper {
  position: relative;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
}
.touch .pricing-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}
.pricing-wrapper.is-switched .is-visible {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  -moz-animation: rotate 0.5s;
  animation: rotate 0.5s;
}
.pricing-wrapper.is-switched .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  -moz-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}
.pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}
.pricing-wrapper.is-switched.reverse-animation .is-visible {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  -moz-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}
.pricing-wrapper.is-switched.reverse-animation .is-hidden {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  -moz-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}
.pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}
.pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}
.pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.pricing-wrapper .is-selected {
  z-index: 3 !important;
}
@media only screen and (min-width: 1050px) {
  .pricing-wrapper > li::before {
    content: '';
    position: absolute;
    z-index: 6;
    left: -1px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
  }
  .pricing-wrapper > li::after {
    display: none;
  }
  .has-margins .pricing-wrapper > li,
  .has-margins .exclusive .pricing-wrapper > li {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  :nth-of-type(1) > .pricing-wrapper > li::before {
    display: none;
  }
  .has-margins .pricing-wrapper > li {
    border-radius: 4px 4px 6px 6px;
  }
  .has-margins .pricing-wrapper > li::before {
    display: none;
  }
}
@media only screen and (min-width: 1500px) {
  .full-width .pricing-wrapper > li {
    padding: 2.5em 0;
  }
}
.no-js .pricing-wrapper .is-hidden {
  position: relative;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
  margin-top: 1em;
}
@media only screen and (min-width: 1050px) {
  .exclusive .pricing-wrapper > li::before {
    display: none;
  }
  .exclusive + li .pricing-wrapper > li::before {
    display: none;
  }
}
.pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.is-switched .pricing-body {
  overflow: hidden;
}
.pricing-body {
  overflow-x: visible;
  padding-bottom: 20px;
}
.pricing-features {
  width: 600px;
}
.pricing-features li {
  width: 100px;
  float: left;
  padding: 1.6em 1em;
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.has-margins .exclusive .pricing-features li {
  margin: 0;
}
.has-margins .pricing-footer {
  padding-bottom: 0;
}
.select {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.select {
  position: static;
  display: inline-block;
  height: auto;
  padding: 1.3em 2em;
  color: #1bbc9d;
  border-radius: 8px;
  border: 2px solid #1bbc9d;
  font-size: 1.4rem;
  text-indent: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .6s;
  width: 70%;
}
.no-touch .select:hover {
  background-color: #1bbc9d;
  color: #ffffff;
}
.exclusive .select {
  background-color: #1bbc9d;
  color: #ffffff;
}
.no-touch .exclusive .select:hover {
  background-color: #24e0ba;
}
.secondary-theme .exclusive .select {
  background-color: #1bbc9d;
}
.no-touch .secondary-theme .exclusive .select:hover {
  background-color: #112e3c;
}
.has-margins .select {
  display: block;
  padding: 1.7em 0;
  border-radius: 0 0 4px 4px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}
@-webkit-keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}
@-moz-keyframes rotate-inverse {
  0% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}
@keyframes rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}
@-webkit-keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
  }
}
@-moz-keyframes rotate-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(-180deg);
  }
}
@keyframes rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}
@-webkit-keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
  }
}
@-moz-keyframes rotate-inverse-back {
  0% {
    -moz-transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -moz-transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -moz-transform: perspective(2000px) rotateY(0);
  }
}
@keyframes rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}
/*.rs-error-404 {*/
/*  background-image: url(assets/images/bg/404.jpg);*/
/*  position: relative;*/
/*  display: table;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  background-repeat: no-repeat !important;*/
/*  background-size: cover!important;*/
/*  background-position: 50%!important;*/
/*}*/
.rs-error-404 .main-content {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.rs-error-404 .main-content .error-page {
  position: relative;
  z-index: 101;
  margin: 0 auto;
  text-align: center;
}
.rs-error-404 .main-content .error-page .title {
  color: #fff;
  font-size: 22px;
  line-height: 1.35;
  font-weight: 600!important;
  margin: 0 auto 40px;
}
.rs-error-404 .main-content .error-page .title span {
  display: block;
  font-size: 240px;
  line-height: 260px;
  color: #fff;
  font-weight: 900;
}
