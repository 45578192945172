@font-face {
font-family: "uicons-bold-rounded";
src: url("../../webfonts/uicons-bold-rounded.eot#iefix") format("embedded-opentype"),
url("../../webfonts/uicons-bold-rounded.woff2") format("woff2"),
url("../../webfonts/uicons-bold-rounded.woff") format("woff");
}

    i[class^="fi-br-"]:before, i[class*=" fi-br-"]:before, span[class^="fi-br-"]:before, span[class*="fi-br-"]:before {
font-family: uicons-bold-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-br-0:before {
    content: "\f101";
    }
        .fi-br-1:before {
    content: "\f102";
    }
        .fi-br-2:before {
    content: "\f103";
    }
        .fi-br-3:before {
    content: "\f104";
    }
        .fi-br-4:before {
    content: "\f105";
    }
        .fi-br-5:before {
    content: "\f106";
    }
        .fi-br-6:before {
    content: "\f107";
    }
        .fi-br-7:before {
    content: "\f108";
    }
        .fi-br-8:before {
    content: "\f109";
    }
        .fi-br-9:before {
    content: "\f10a";
    }
        .fi-br-a:before {
    content: "\f10b";
    }
        .fi-br-acorn:before {
    content: "\f10c";
    }
        .fi-br-ad:before {
    content: "\f10d";
    }
        .fi-br-add-document:before {
    content: "\f10e";
    }
        .fi-br-add-folder:before {
    content: "\f10f";
    }
        .fi-br-add:before {
    content: "\f110";
    }
        .fi-br-address-book:before {
    content: "\f111";
    }
        .fi-br-address-card:before {
    content: "\f112";
    }
        .fi-br-air-conditioner:before {
    content: "\f113";
    }
        .fi-br-air-freshener:before {
    content: "\f114";
    }
        .fi-br-alarm-clock:before {
    content: "\f115";
    }
        .fi-br-alarm-exclamation:before {
    content: "\f116";
    }
        .fi-br-alarm-plus:before {
    content: "\f117";
    }
        .fi-br-alarm-snooze:before {
    content: "\f118";
    }
        .fi-br-album-circle-plus:before {
    content: "\f119";
    }
        .fi-br-album-circle-user:before {
    content: "\f11a";
    }
        .fi-br-album-collection:before {
    content: "\f11b";
    }
        .fi-br-album:before {
    content: "\f11c";
    }
        .fi-br-align-center:before {
    content: "\f11d";
    }
        .fi-br-align-justify:before {
    content: "\f11e";
    }
        .fi-br-align-left:before {
    content: "\f11f";
    }
        .fi-br-ambulance:before {
    content: "\f120";
    }
        .fi-br-angle-circle-down:before {
    content: "\f121";
    }
        .fi-br-angle-circle-left:before {
    content: "\f122";
    }
        .fi-br-angle-circle-right:before {
    content: "\f123";
    }
        .fi-br-angle-circle-up:before {
    content: "\f124";
    }
        .fi-br-angle-double-left:before {
    content: "\f125";
    }
        .fi-br-angle-double-right:before {
    content: "\f126";
    }
        .fi-br-angle-double-small-down:before {
    content: "\f127";
    }
        .fi-br-angle-double-small-left:before {
    content: "\f128";
    }
        .fi-br-angle-double-small-right:before {
    content: "\f129";
    }
        .fi-br-angle-double-small-up:before {
    content: "\f12a";
    }
        .fi-br-angle-down:before {
    content: "\f12b";
    }
        .fi-br-angle-left:before {
    content: "\f12c";
    }
        .fi-br-angle-right:before {
    content: "\f12d";
    }
        .fi-br-angle-small-down:before {
    content: "\f12e";
    }
        .fi-br-angle-small-left:before {
    content: "\f12f";
    }
        .fi-br-angle-small-right:before {
    content: "\f130";
    }
        .fi-br-angle-small-up:before {
    content: "\f131";
    }
        .fi-br-angle-square-down:before {
    content: "\f132";
    }
        .fi-br-angle-square-left:before {
    content: "\f133";
    }
        .fi-br-angle-square-right:before {
    content: "\f134";
    }
        .fi-br-angle-square-up:before {
    content: "\f135";
    }
        .fi-br-angle-up:before {
    content: "\f136";
    }
        .fi-br-angry:before {
    content: "\f137";
    }
        .fi-br-animated-icon:before {
    content: "\f138";
    }
        .fi-br-apartment:before {
    content: "\f139";
    }
        .fi-br-api:before {
    content: "\f13a";
    }
        .fi-br-apple-crate:before {
    content: "\f13b";
    }
        .fi-br-apple-whole:before {
    content: "\f13c";
    }
        .fi-br-apps-add:before {
    content: "\f13d";
    }
        .fi-br-apps-delete:before {
    content: "\f13e";
    }
        .fi-br-apps-sort:before {
    content: "\f13f";
    }
        .fi-br-apps:before {
    content: "\f140";
    }
        .fi-br-archive:before {
    content: "\f141";
    }
        .fi-br-archway:before {
    content: "\f142";
    }
        .fi-br-arrow-alt-circle-down:before {
    content: "\f143";
    }
        .fi-br-arrow-alt-circle-left:before {
    content: "\f144";
    }
        .fi-br-arrow-alt-circle-right:before {
    content: "\f145";
    }
        .fi-br-arrow-alt-circle-up:before {
    content: "\f146";
    }
        .fi-br-arrow-alt-down:before {
    content: "\f147";
    }
        .fi-br-arrow-alt-from-bottom:before {
    content: "\f148";
    }
        .fi-br-arrow-alt-from-left:before {
    content: "\f149";
    }
        .fi-br-arrow-alt-from-right:before {
    content: "\f14a";
    }
        .fi-br-arrow-alt-from-top:before {
    content: "\f14b";
    }
        .fi-br-arrow-alt-left:before {
    content: "\f14c";
    }
        .fi-br-arrow-alt-right:before {
    content: "\f14d";
    }
        .fi-br-arrow-alt-square-down:before {
    content: "\f14e";
    }
        .fi-br-arrow-alt-square-left:before {
    content: "\f14f";
    }
        .fi-br-arrow-alt-square-right:before {
    content: "\f150";
    }
        .fi-br-arrow-alt-square-up:before {
    content: "\f151";
    }
        .fi-br-arrow-alt-to-bottom:before {
    content: "\f152";
    }
        .fi-br-arrow-alt-to-left:before {
    content: "\f153";
    }
        .fi-br-arrow-alt-to-right:before {
    content: "\f154";
    }
        .fi-br-arrow-alt-to-top:before {
    content: "\f155";
    }
        .fi-br-arrow-alt-up:before {
    content: "\f156";
    }
        .fi-br-arrow-circle-down:before {
    content: "\f157";
    }
        .fi-br-arrow-circle-left:before {
    content: "\f158";
    }
        .fi-br-arrow-circle-right:before {
    content: "\f159";
    }
        .fi-br-arrow-circle-up:before {
    content: "\f15a";
    }
        .fi-br-arrow-down-from-dotted-line:before {
    content: "\f15b";
    }
        .fi-br-arrow-down-left:before {
    content: "\f15c";
    }
        .fi-br-arrow-down-small-big:before {
    content: "\f15d";
    }
        .fi-br-arrow-down-to-dotted-line:before {
    content: "\f15e";
    }
        .fi-br-arrow-down-to-square:before {
    content: "\f15f";
    }
        .fi-br-arrow-down-triangle-square:before {
    content: "\f160";
    }
        .fi-br-arrow-down:before {
    content: "\f161";
    }
        .fi-br-arrow-from-bottom:before {
    content: "\f162";
    }
        .fi-br-arrow-from-left:before {
    content: "\f163";
    }
        .fi-br-arrow-from-right:before {
    content: "\f164";
    }
        .fi-br-arrow-from-top:before {
    content: "\f165";
    }
        .fi-br-arrow-left-from-line:before {
    content: "\f166";
    }
        .fi-br-arrow-left:before {
    content: "\f167";
    }
        .fi-br-arrow-right-to-bracket:before {
    content: "\f168";
    }
        .fi-br-arrow-right:before {
    content: "\f169";
    }
        .fi-br-arrow-small-down:before {
    content: "\f16a";
    }
        .fi-br-arrow-small-left:before {
    content: "\f16b";
    }
        .fi-br-arrow-small-right:before {
    content: "\f16c";
    }
        .fi-br-arrow-small-up:before {
    content: "\f16d";
    }
        .fi-br-arrow-square-down:before {
    content: "\f16e";
    }
        .fi-br-arrow-square-left:before {
    content: "\f16f";
    }
        .fi-br-arrow-square-right:before {
    content: "\f170";
    }
        .fi-br-arrow-square-up:before {
    content: "\f171";
    }
        .fi-br-arrow-to-bottom:before {
    content: "\f172";
    }
        .fi-br-arrow-to-left:before {
    content: "\f173";
    }
        .fi-br-arrow-to-right:before {
    content: "\f174";
    }
        .fi-br-arrow-to-top:before {
    content: "\f175";
    }
        .fi-br-arrow-trend-down:before {
    content: "\f176";
    }
        .fi-br-arrow-trend-up:before {
    content: "\f177";
    }
        .fi-br-arrow-turn-down-left:before {
    content: "\f178";
    }
        .fi-br-arrow-turn-down-right:before {
    content: "\f179";
    }
        .fi-br-arrow-up-from-dotted-line:before {
    content: "\f17a";
    }
        .fi-br-arrow-up-from-square:before {
    content: "\f17b";
    }
        .fi-br-arrow-up-left-from-circle:before {
    content: "\f17c";
    }
        .fi-br-arrow-up-left:before {
    content: "\f17d";
    }
        .fi-br-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f17e";
    }
        .fi-br-arrow-up-right-from-square:before {
    content: "\f17f";
    }
        .fi-br-arrow-up-right:before {
    content: "\f180";
    }
        .fi-br-arrow-up-small-big:before {
    content: "\f181";
    }
        .fi-br-arrow-up-square-triangle:before {
    content: "\f182";
    }
        .fi-br-arrow-up-to-dotted-line:before {
    content: "\f183";
    }
        .fi-br-arrow-up:before {
    content: "\f184";
    }
        .fi-br-arrows-alt-h:before {
    content: "\f185";
    }
        .fi-br-arrows-alt-v:before {
    content: "\f186";
    }
        .fi-br-arrows-alt:before {
    content: "\f187";
    }
        .fi-br-arrows-cross:before {
    content: "\f188";
    }
        .fi-br-arrows-from-dotted-line:before {
    content: "\f189";
    }
        .fi-br-arrows-from-line:before {
    content: "\f18a";
    }
        .fi-br-arrows-h-copy:before {
    content: "\f18b";
    }
        .fi-br-arrows-h:before {
    content: "\f18c";
    }
        .fi-br-arrows-repeat-1:before {
    content: "\f18d";
    }
        .fi-br-arrows-repeat:before {
    content: "\f18e";
    }
        .fi-br-arrows-retweet:before {
    content: "\f18f";
    }
        .fi-br-arrows-to-dotted-line:before {
    content: "\f190";
    }
        .fi-br-arrows-to-line:before {
    content: "\f191";
    }
        .fi-br-arrows:before {
    content: "\f192";
    }
        .fi-br-assept-document:before {
    content: "\f193";
    }
        .fi-br-assistive-listening-systems:before {
    content: "\f194";
    }
        .fi-br-asterik:before {
    content: "\f195";
    }
        .fi-br-astonished-face:before {
    content: "\f196";
    }
        .fi-br-at:before {
    content: "\f197";
    }
        .fi-br-attribution-pen:before {
    content: "\f198";
    }
        .fi-br-attribution-pencil:before {
    content: "\f199";
    }
        .fi-br-aubergine:before {
    content: "\f19a";
    }
        .fi-br-audio-description-slash:before {
    content: "\f19b";
    }
        .fi-br-austral-sign:before {
    content: "\f19c";
    }
        .fi-br-avocado:before {
    content: "\f19d";
    }
        .fi-br-b:before {
    content: "\f19e";
    }
        .fi-br-baby-carriage:before {
    content: "\f19f";
    }
        .fi-br-background:before {
    content: "\f1a0";
    }
        .fi-br-backpack:before {
    content: "\f1a1";
    }
        .fi-br-bacon:before {
    content: "\f1a2";
    }
        .fi-br-bacteria:before {
    content: "\f1a3";
    }
        .fi-br-bacterium:before {
    content: "\f1a4";
    }
        .fi-br-badge-check:before {
    content: "\f1a5";
    }
        .fi-br-badge-dollar:before {
    content: "\f1a6";
    }
        .fi-br-badge-percent:before {
    content: "\f1a7";
    }
        .fi-br-badge-sheriff:before {
    content: "\f1a8";
    }
        .fi-br-badge:before {
    content: "\f1a9";
    }
        .fi-br-bags-shopping:before {
    content: "\f1aa";
    }
        .fi-br-bahai:before {
    content: "\f1ab";
    }
        .fi-br-baht-sign:before {
    content: "\f1ac";
    }
        .fi-br-balance-scale-left:before {
    content: "\f1ad";
    }
        .fi-br-balance-scale-right:before {
    content: "\f1ae";
    }
        .fi-br-balloons:before {
    content: "\f1af";
    }
        .fi-br-ballot-check:before {
    content: "\f1b0";
    }
        .fi-br-ballot:before {
    content: "\f1b1";
    }
        .fi-br-ban-bug:before {
    content: "\f1b2";
    }
        .fi-br-ban:before {
    content: "\f1b3";
    }
        .fi-br-band-aid:before {
    content: "\f1b4";
    }
        .fi-br-bangladeshi-taka-sign:before {
    content: "\f1b5";
    }
        .fi-br-bank:before {
    content: "\f1b6";
    }
        .fi-br-barber-shop:before {
    content: "\f1b7";
    }
        .fi-br-barcode-read:before {
    content: "\f1b8";
    }
        .fi-br-barcode-scan:before {
    content: "\f1b9";
    }
        .fi-br-barcode:before {
    content: "\f1ba";
    }
        .fi-br-bars-filter:before {
    content: "\f1bb";
    }
        .fi-br-bars-progress:before {
    content: "\f1bc";
    }
        .fi-br-bars-sort:before {
    content: "\f1bd";
    }
        .fi-br-bars-staggered:before {
    content: "\f1be";
    }
        .fi-br-baseball-alt:before {
    content: "\f1bf";
    }
        .fi-br-baseball:before {
    content: "\f1c0";
    }
        .fi-br-basket-shopping-simple:before {
    content: "\f1c1";
    }
        .fi-br-basketball-hoop:before {
    content: "\f1c2";
    }
        .fi-br-basketball:before {
    content: "\f1c3";
    }
        .fi-br-battery-bolt:before {
    content: "\f1c4";
    }
        .fi-br-battery-empty:before {
    content: "\f1c5";
    }
        .fi-br-battery-full:before {
    content: "\f1c6";
    }
        .fi-br-battery-half:before {
    content: "\f1c7";
    }
        .fi-br-battery-quarter:before {
    content: "\f1c8";
    }
        .fi-br-battery-slash:before {
    content: "\f1c9";
    }
        .fi-br-battery-three-quarters:before {
    content: "\f1ca";
    }
        .fi-br-beacon:before {
    content: "\f1cb";
    }
        .fi-br-bed-alt:before {
    content: "\f1cc";
    }
        .fi-br-bed:before {
    content: "\f1cd";
    }
        .fi-br-beer:before {
    content: "\f1ce";
    }
        .fi-br-bell-concierge:before {
    content: "\f1cf";
    }
        .fi-br-bell-ring:before {
    content: "\f1d0";
    }
        .fi-br-bell-school:before {
    content: "\f1d1";
    }
        .fi-br-bell:before {
    content: "\f1d2";
    }
        .fi-br-bells:before {
    content: "\f1d3";
    }
        .fi-br-bench-tree:before {
    content: "\f1d4";
    }
        .fi-br-betamax:before {
    content: "\f1d5";
    }
        .fi-br-bike:before {
    content: "\f1d6";
    }
        .fi-br-biking-mountain:before {
    content: "\f1d7";
    }
        .fi-br-biking:before {
    content: "\f1d8";
    }
        .fi-br-billiard:before {
    content: "\f1d9";
    }
        .fi-br-binary-circle-check:before {
    content: "\f1da";
    }
        .fi-br-binary-lock:before {
    content: "\f1db";
    }
        .fi-br-binary-slash:before {
    content: "\f1dc";
    }
        .fi-br-binary:before {
    content: "\f1dd";
    }
        .fi-br-binoculars:before {
    content: "\f1de";
    }
        .fi-br-biohazard:before {
    content: "\f1df";
    }
        .fi-br-bitcoin-sign:before {
    content: "\f1e0";
    }
        .fi-br-blender-phone:before {
    content: "\f1e1";
    }
        .fi-br-blinds-open:before {
    content: "\f1e2";
    }
        .fi-br-blinds-raised:before {
    content: "\f1e3";
    }
        .fi-br-blinds:before {
    content: "\f1e4";
    }
        .fi-br-block-brick:before {
    content: "\f1e5";
    }
        .fi-br-block:before {
    content: "\f1e6";
    }
        .fi-br-blog-pencil:before {
    content: "\f1e7";
    }
        .fi-br-blog-text:before {
    content: "\f1e8";
    }
        .fi-br-bold:before {
    content: "\f1e9";
    }
        .fi-br-bolt:before {
    content: "\f1ea";
    }
        .fi-br-bomb:before {
    content: "\f1eb";
    }
        .fi-br-bone-break:before {
    content: "\f1ec";
    }
        .fi-br-bone:before {
    content: "\f1ed";
    }
        .fi-br-bong:before {
    content: "\f1ee";
    }
        .fi-br-book-alt:before {
    content: "\f1ef";
    }
        .fi-br-book-arrow-right:before {
    content: "\f1f0";
    }
        .fi-br-book-arrow-up:before {
    content: "\f1f1";
    }
        .fi-br-book-atlas:before {
    content: "\f1f2";
    }
        .fi-br-book-bookmark:before {
    content: "\f1f3";
    }
        .fi-br-book-circle-arrow-right:before {
    content: "\f1f4";
    }
        .fi-br-book-circle-arrow-up:before {
    content: "\f1f5";
    }
        .fi-br-book-font:before {
    content: "\f1f6";
    }
        .fi-br-book-medical:before {
    content: "\f1f7";
    }
        .fi-br-book-open-cover:before {
    content: "\f1f8";
    }
        .fi-br-book-open-reader:before {
    content: "\f1f9";
    }
        .fi-br-book-quran:before {
    content: "\f1fa";
    }
        .fi-br-book-section:before {
    content: "\f1fb";
    }
        .fi-br-book-tanakh:before {
    content: "\f1fc";
    }
        .fi-br-book-user:before {
    content: "\f1fd";
    }
        .fi-br-book:before {
    content: "\f1fe";
    }
        .fi-br-bookmark-slash:before {
    content: "\f1ff";
    }
        .fi-br-bookmark:before {
    content: "\f200";
    }
        .fi-br-books-medical:before {
    content: "\f201";
    }
        .fi-br-books:before {
    content: "\f202";
    }
        .fi-br-border-all:before {
    content: "\f203";
    }
        .fi-br-border-bottom:before {
    content: "\f204";
    }
        .fi-br-border-center-h:before {
    content: "\f205";
    }
        .fi-br-border-center-v:before {
    content: "\f206";
    }
        .fi-br-border-inner:before {
    content: "\f207";
    }
        .fi-br-border-left:before {
    content: "\f208";
    }
        .fi-br-border-none:before {
    content: "\f209";
    }
        .fi-br-border-outer:before {
    content: "\f20a";
    }
        .fi-br-border-right:before {
    content: "\f20b";
    }
        .fi-br-border-style-alt:before {
    content: "\f20c";
    }
        .fi-br-border-style:before {
    content: "\f20d";
    }
        .fi-br-border-top:before {
    content: "\f20e";
    }
        .fi-br-bottle:before {
    content: "\f20f";
    }
        .fi-br-bow-arrow:before {
    content: "\f210";
    }
        .fi-br-bowling-ball:before {
    content: "\f211";
    }
        .fi-br-bowling-pins:before {
    content: "\f212";
    }
        .fi-br-bowling:before {
    content: "\f213";
    }
        .fi-br-box-alt:before {
    content: "\f214";
    }
        .fi-br-box-ballot:before {
    content: "\f215";
    }
        .fi-br-box-check:before {
    content: "\f216";
    }
        .fi-br-box-dollar:before {
    content: "\f217";
    }
        .fi-br-box-fragile:before {
    content: "\f218";
    }
        .fi-br-box-open-full:before {
    content: "\f219";
    }
        .fi-br-box-open:before {
    content: "\f21a";
    }
        .fi-br-box-tissue:before {
    content: "\f21b";
    }
        .fi-br-box-up:before {
    content: "\f21c";
    }
        .fi-br-box:before {
    content: "\f21d";
    }
        .fi-br-boxes:before {
    content: "\f21e";
    }
        .fi-br-boxing-glove:before {
    content: "\f21f";
    }
        .fi-br-bracket-curly-right:before {
    content: "\f220";
    }
        .fi-br-bracket-curly:before {
    content: "\f221";
    }
        .fi-br-bracket-round-right:before {
    content: "\f222";
    }
        .fi-br-bracket-round:before {
    content: "\f223";
    }
        .fi-br-bracket-square-right:before {
    content: "\f224";
    }
        .fi-br-bracket-square:before {
    content: "\f225";
    }
        .fi-br-brackets-curly:before {
    content: "\f226";
    }
        .fi-br-brackets-round:before {
    content: "\f227";
    }
        .fi-br-brackets-square:before {
    content: "\f228";
    }
        .fi-br-braille:before {
    content: "\f229";
    }
        .fi-br-brain-circuit:before {
    content: "\f22a";
    }
        .fi-br-brain:before {
    content: "\f22b";
    }
        .fi-br-bread-loaf:before {
    content: "\f22c";
    }
        .fi-br-bread-slice:before {
    content: "\f22d";
    }
        .fi-br-bread:before {
    content: "\f22e";
    }
        .fi-br-briefcase-arrow-right:before {
    content: "\f22f";
    }
        .fi-br-briefcase-blank:before {
    content: "\f230";
    }
        .fi-br-briefcase:before {
    content: "\f231";
    }
        .fi-br-brightness-low:before {
    content: "\f232";
    }
        .fi-br-brightness:before {
    content: "\f233";
    }
        .fi-br-bring-forward:before {
    content: "\f234";
    }
        .fi-br-bring-front:before {
    content: "\f235";
    }
        .fi-br-broccoli:before {
    content: "\f236";
    }
        .fi-br-broken-image:before {
    content: "\f237";
    }
        .fi-br-broom:before {
    content: "\f238";
    }
        .fi-br-browser:before {
    content: "\f239";
    }
        .fi-br-browsers:before {
    content: "\f23a";
    }
        .fi-br-brush:before {
    content: "\f23b";
    }
        .fi-br-bug-slash:before {
    content: "\f23c";
    }
        .fi-br-bug:before {
    content: "\f23d";
    }
        .fi-br-building:before {
    content: "\f23e";
    }
        .fi-br-bulb:before {
    content: "\f23f";
    }
        .fi-br-bullet:before {
    content: "\f240";
    }
        .fi-br-bullhorn:before {
    content: "\f241";
    }
        .fi-br-bullseye-arrow:before {
    content: "\f242";
    }
        .fi-br-bullseye-pointer:before {
    content: "\f243";
    }
        .fi-br-bullseye:before {
    content: "\f244";
    }
        .fi-br-burger-alt:before {
    content: "\f245";
    }
        .fi-br-burger-fries:before {
    content: "\f246";
    }
        .fi-br-burger-glass:before {
    content: "\f247";
    }
        .fi-br-burrito:before {
    content: "\f248";
    }
        .fi-br-bus-alt:before {
    content: "\f249";
    }
        .fi-br-bus:before {
    content: "\f24a";
    }
        .fi-br-business-time:before {
    content: "\f24b";
    }
        .fi-br-butterfly:before {
    content: "\f24c";
    }
        .fi-br-c:before {
    content: "\f24d";
    }
        .fi-br-cabin:before {
    content: "\f24e";
    }
        .fi-br-cake-birthday:before {
    content: "\f24f";
    }
        .fi-br-cake-wedding:before {
    content: "\f250";
    }
        .fi-br-calculator:before {
    content: "\f251";
    }
        .fi-br-calendar-arrow-down:before {
    content: "\f252";
    }
        .fi-br-calendar-arrow-up:before {
    content: "\f253";
    }
        .fi-br-calendar-check:before {
    content: "\f254";
    }
        .fi-br-calendar-clock:before {
    content: "\f255";
    }
        .fi-br-calendar-day:before {
    content: "\f256";
    }
        .fi-br-calendar-days:before {
    content: "\f257";
    }
        .fi-br-calendar-exclamation:before {
    content: "\f258";
    }
        .fi-br-calendar-image:before {
    content: "\f259";
    }
        .fi-br-calendar-lines-pen:before {
    content: "\f25a";
    }
        .fi-br-calendar-lines:before {
    content: "\f25b";
    }
        .fi-br-calendar-minus:before {
    content: "\f25c";
    }
        .fi-br-calendar-pen:before {
    content: "\f25d";
    }
        .fi-br-calendar-plus:before {
    content: "\f25e";
    }
        .fi-br-calendar-star:before {
    content: "\f25f";
    }
        .fi-br-calendar-week:before {
    content: "\f260";
    }
        .fi-br-calendar-xmark:before {
    content: "\f261";
    }
        .fi-br-calendar:before {
    content: "\f262";
    }
        .fi-br-calendars:before {
    content: "\f263";
    }
        .fi-br-call-history:before {
    content: "\f264";
    }
        .fi-br-call-incoming:before {
    content: "\f265";
    }
        .fi-br-call-missed:before {
    content: "\f266";
    }
        .fi-br-call-outgoing:before {
    content: "\f267";
    }
        .fi-br-camcorder:before {
    content: "\f268";
    }
        .fi-br-camera:before {
    content: "\f269";
    }
        .fi-br-camping:before {
    content: "\f26a";
    }
        .fi-br-candy-alt:before {
    content: "\f26b";
    }
        .fi-br-candy-cane:before {
    content: "\f26c";
    }
        .fi-br-candy-corn:before {
    content: "\f26d";
    }
        .fi-br-candy:before {
    content: "\f26e";
    }
        .fi-br-Cannabis:before {
    content: "\f26f";
    }
        .fi-br-canned-food:before {
    content: "\f270";
    }
        .fi-br-capsules:before {
    content: "\f271";
    }
        .fi-br-car-alt:before {
    content: "\f272";
    }
        .fi-br-car-battery:before {
    content: "\f273";
    }
        .fi-br-car-bolt:before {
    content: "\f274";
    }
        .fi-br-car-building:before {
    content: "\f275";
    }
        .fi-br-car-bump:before {
    content: "\f276";
    }
        .fi-br-car-bus:before {
    content: "\f277";
    }
        .fi-br-car-circle-bolt:before {
    content: "\f278";
    }
        .fi-br-car-crash:before {
    content: "\f279";
    }
        .fi-br-car-garage:before {
    content: "\f27a";
    }
        .fi-br-car-mechanic:before {
    content: "\f27b";
    }
        .fi-br-car-side-bolt:before {
    content: "\f27c";
    }
        .fi-br-car-side:before {
    content: "\f27d";
    }
        .fi-br-car-tilt:before {
    content: "\f27e";
    }
        .fi-br-car-wash:before {
    content: "\f27f";
    }
        .fi-br-car:before {
    content: "\f280";
    }
        .fi-br-caravan-alt:before {
    content: "\f281";
    }
        .fi-br-caravan:before {
    content: "\f282";
    }
        .fi-br-care:before {
    content: "\f283";
    }
        .fi-br-caret-circle-down:before {
    content: "\f284";
    }
        .fi-br-caret-circle-right:before {
    content: "\f285";
    }
        .fi-br-caret-circle-up:before {
    content: "\f286";
    }
        .fi-br-caret-down:before {
    content: "\f287";
    }
        .fi-br-caret-left:before {
    content: "\f288";
    }
        .fi-br-caret-quare-up:before {
    content: "\f289";
    }
        .fi-br-caret-right:before {
    content: "\f28a";
    }
        .fi-br-caret-square-down:before {
    content: "\f28b";
    }
        .fi-br-caret-square-left_1:before {
    content: "\f28c";
    }
        .fi-br-caret-square-left:before {
    content: "\f28d";
    }
        .fi-br-caret-square-right:before {
    content: "\f28e";
    }
        .fi-br-caret-up:before {
    content: "\f28f";
    }
        .fi-br-carrot:before {
    content: "\f290";
    }
        .fi-br-cars:before {
    content: "\f291";
    }
        .fi-br-cart-arrow-down:before {
    content: "\f292";
    }
        .fi-br-cart-minus:before {
    content: "\f293";
    }
        .fi-br-cart-shopping-fast:before {
    content: "\f294";
    }
        .fi-br-cash-register:before {
    content: "\f295";
    }
        .fi-br-cassette-tape:before {
    content: "\f296";
    }
        .fi-br-cassette-vhs:before {
    content: "\f297";
    }
        .fi-br-castle:before {
    content: "\f298";
    }
        .fi-br-cedi-sign:before {
    content: "\f299";
    }
        .fi-br-cent-sign:before {
    content: "\f29a";
    }
        .fi-br-chair-office:before {
    content: "\f29b";
    }
        .fi-br-chair:before {
    content: "\f29c";
    }
        .fi-br-charging-station:before {
    content: "\f29d";
    }
        .fi-br-chart-area:before {
    content: "\f29e";
    }
        .fi-br-chart-bullet:before {
    content: "\f29f";
    }
        .fi-br-chart-candlestick:before {
    content: "\f2a0";
    }
        .fi-br-chart-connected:before {
    content: "\f2a1";
    }
        .fi-br-chart-gantt:before {
    content: "\f2a2";
    }
        .fi-br-chart-histogram:before {
    content: "\f2a3";
    }
        .fi-br-chart-line-up:before {
    content: "\f2a4";
    }
        .fi-br-chart-mixed:before {
    content: "\f2a5";
    }
        .fi-br-chart-network:before {
    content: "\f2a6";
    }
        .fi-br-chart-pie-alt:before {
    content: "\f2a7";
    }
        .fi-br-chart-pie:before {
    content: "\f2a8";
    }
        .fi-br-chart-pyramid:before {
    content: "\f2a9";
    }
        .fi-br-chart-radar:before {
    content: "\f2aa";
    }
        .fi-br-chart-scatter-3d:before {
    content: "\f2ab";
    }
        .fi-br-chart-scatter-bubble:before {
    content: "\f2ac";
    }
        .fi-br-chart-scatter:before {
    content: "\f2ad";
    }
        .fi-br-chart-set-theory:before {
    content: "\f2ae";
    }
        .fi-br-chart-simple-horizontal:before {
    content: "\f2af";
    }
        .fi-br-chart-simple:before {
    content: "\f2b0";
    }
        .fi-br-chart-tree-map:before {
    content: "\f2b1";
    }
        .fi-br-chart-tree:before {
    content: "\f2b2";
    }
        .fi-br-chart-user:before {
    content: "\f2b3";
    }
        .fi-br-chart-waterfall:before {
    content: "\f2b4";
    }
        .fi-br-chat-arrow-down:before {
    content: "\f2b5";
    }
        .fi-br-chat-arrow-grow:before {
    content: "\f2b6";
    }
        .fi-br-check:before {
    content: "\f2b7";
    }
        .fi-br-checkbox:before {
    content: "\f2b8";
    }
        .fi-br-cheese-alt:before {
    content: "\f2b9";
    }
        .fi-br-cheese:before {
    content: "\f2ba";
    }
        .fi-br-cherry:before {
    content: "\f2bb";
    }
        .fi-br-chess-bishop:before {
    content: "\f2bc";
    }
        .fi-br-chess-board:before {
    content: "\f2bd";
    }
        .fi-br-chess-clock-alt:before {
    content: "\f2be";
    }
        .fi-br-chess-clock:before {
    content: "\f2bf";
    }
        .fi-br-chess-king-alt:before {
    content: "\f2c0";
    }
        .fi-br-chess-king:before {
    content: "\f2c1";
    }
        .fi-br-chess-knight-alt:before {
    content: "\f2c2";
    }
        .fi-br-chess-knight:before {
    content: "\f2c3";
    }
        .fi-br-chess-pawn-alt:before {
    content: "\f2c4";
    }
        .fi-br-chess-pawn:before {
    content: "\f2c5";
    }
        .fi-br-chess-piece:before {
    content: "\f2c6";
    }
        .fi-br-chess-queen-alt:before {
    content: "\f2c7";
    }
        .fi-br-chess-queen:before {
    content: "\f2c8";
    }
        .fi-br-chess-rook-alt:before {
    content: "\f2c9";
    }
        .fi-br-chess-rook:before {
    content: "\f2ca";
    }
        .fi-br-chess:before {
    content: "\f2cb";
    }
        .fi-br-chevron-double-down:before {
    content: "\f2cc";
    }
        .fi-br-chevron-double-up:before {
    content: "\f2cd";
    }
        .fi-br-child-head:before {
    content: "\f2ce";
    }
        .fi-br-chocolate:before {
    content: "\f2cf";
    }
        .fi-br-circle-0:before {
    content: "\f2d0";
    }
        .fi-br-circle-1:before {
    content: "\f2d1";
    }
        .fi-br-circle-2:before {
    content: "\f2d2";
    }
        .fi-br-circle-3:before {
    content: "\f2d3";
    }
        .fi-br-circle-4:before {
    content: "\f2d4";
    }
        .fi-br-circle-5:before {
    content: "\f2d5";
    }
        .fi-br-circle-6:before {
    content: "\f2d6";
    }
        .fi-br-circle-7:before {
    content: "\f2d7";
    }
        .fi-br-circle-8:before {
    content: "\f2d8";
    }
        .fi-br-circle-9:before {
    content: "\f2d9";
    }
        .fi-br-circle-bolt:before {
    content: "\f2da";
    }
        .fi-br-circle-book-open:before {
    content: "\f2db";
    }
        .fi-br-circle-bookmark:before {
    content: "\f2dc";
    }
        .fi-br-circle-calendar:before {
    content: "\f2dd";
    }
        .fi-br-circle-dashed:before {
    content: "\f2de";
    }
        .fi-br-circle-envelope:before {
    content: "\f2df";
    }
        .fi-br-circle-half-stroke:before {
    content: "\f2e0";
    }
        .fi-br-circle-half:before {
    content: "\f2e1";
    }
        .fi-br-circle-heart:before {
    content: "\f2e2";
    }
        .fi-br-circle-phone-flip:before {
    content: "\f2e3";
    }
        .fi-br-circle-phone-hangup:before {
    content: "\f2e4";
    }
        .fi-br-circle-phone:before {
    content: "\f2e5";
    }
        .fi-br-circle-quarter:before {
    content: "\f2e6";
    }
        .fi-br-circle-small:before {
    content: "\f2e7";
    }
        .fi-br-circle-star:before {
    content: "\f2e8";
    }
        .fi-br-circle-three-quarters:before {
    content: "\f2e9";
    }
        .fi-br-circle-user:before {
    content: "\f2ea";
    }
        .fi-br-circle:before {
    content: "\f2eb";
    }
        .fi-br-city:before {
    content: "\f2ec";
    }
        .fi-br-clip:before {
    content: "\f2ed";
    }
        .fi-br-clipboard-list-check:before {
    content: "\f2ee";
    }
        .fi-br-clipboard-list:before {
    content: "\f2ef";
    }
        .fi-br-clipboard-prescription:before {
    content: "\f2f0";
    }
        .fi-br-clipboard-user:before {
    content: "\f2f1";
    }
        .fi-br-clock-eight-thirty:before {
    content: "\f2f2";
    }
        .fi-br-clock-eleven-thirty:before {
    content: "\f2f3";
    }
        .fi-br-clock-eleven:before {
    content: "\f2f4";
    }
        .fi-br-clock-five-thirty:before {
    content: "\f2f5";
    }
        .fi-br-clock-five:before {
    content: "\f2f6";
    }
        .fi-br-clock-four-thirty:before {
    content: "\f2f7";
    }
        .fi-br-clock-nine-thirty:before {
    content: "\f2f8";
    }
        .fi-br-clock-nine:before {
    content: "\f2f9";
    }
        .fi-br-clock-one-thirty:before {
    content: "\f2fa";
    }
        .fi-br-clock-one:before {
    content: "\f2fb";
    }
        .fi-br-clock-seven-thirty:before {
    content: "\f2fc";
    }
        .fi-br-clock-seven:before {
    content: "\f2fd";
    }
        .fi-br-clock-six-thirty:before {
    content: "\f2fe";
    }
        .fi-br-clock-six:before {
    content: "\f2ff";
    }
        .fi-br-clock-ten-thirty:before {
    content: "\f300";
    }
        .fi-br-clock-ten:before {
    content: "\f301";
    }
        .fi-br-clock-three-thirty:before {
    content: "\f302";
    }
        .fi-br-clock-three:before {
    content: "\f303";
    }
        .fi-br-clock-twelve-thirty:before {
    content: "\f304";
    }
        .fi-br-clock-twelve:before {
    content: "\f305";
    }
        .fi-br-clock-two-thirty:before {
    content: "\f306";
    }
        .fi-br-clock-two:before {
    content: "\f307";
    }
        .fi-br-clock:before {
    content: "\f308";
    }
        .fi-br-closed-captioning-slash:before {
    content: "\f309";
    }
        .fi-br-cloud-check:before {
    content: "\f30a";
    }
        .fi-br-cloud-disabled:before {
    content: "\f30b";
    }
        .fi-br-cloud-download-alt:before {
    content: "\f30c";
    }
        .fi-br-cloud-download:before {
    content: "\f30d";
    }
        .fi-br-cloud-drizzle:before {
    content: "\f30e";
    }
        .fi-br-cloud-hail-mixed:before {
    content: "\f30f";
    }
        .fi-br-cloud-hail:before {
    content: "\f310";
    }
        .fi-br-cloud-moon-rain:before {
    content: "\f311";
    }
        .fi-br-cloud-moon:before {
    content: "\f312";
    }
        .fi-br-cloud-rain:before {
    content: "\f313";
    }
        .fi-br-cloud-rainbow:before {
    content: "\f314";
    }
        .fi-br-cloud-share:before {
    content: "\f315";
    }
        .fi-br-cloud-showers-heavy:before {
    content: "\f316";
    }
        .fi-br-cloud-showers:before {
    content: "\f317";
    }
        .fi-br-cloud-sleet:before {
    content: "\f318";
    }
        .fi-br-cloud-snow:before {
    content: "\f319";
    }
        .fi-br-cloud-sun-rain:before {
    content: "\f31a";
    }
        .fi-br-cloud-sun:before {
    content: "\f31b";
    }
        .fi-br-cloud-upload-alt:before {
    content: "\f31c";
    }
        .fi-br-cloud-upload:before {
    content: "\f31d";
    }
        .fi-br-cloud:before {
    content: "\f31e";
    }
        .fi-br-clouds-moon:before {
    content: "\f31f";
    }
        .fi-br-clouds-sun:before {
    content: "\f320";
    }
        .fi-br-clouds:before {
    content: "\f321";
    }
        .fi-br-club:before {
    content: "\f322";
    }
        .fi-br-cocktail-alt:before {
    content: "\f323";
    }
        .fi-br-cocktail:before {
    content: "\f324";
    }
        .fi-br-code-branch:before {
    content: "\f325";
    }
        .fi-br-code-commit:before {
    content: "\f326";
    }
        .fi-br-code-compare:before {
    content: "\f327";
    }
        .fi-br-code-fork:before {
    content: "\f328";
    }
        .fi-br-code-merge:before {
    content: "\f329";
    }
        .fi-br-code-pull-request-closed:before {
    content: "\f32a";
    }
        .fi-br-code-pull-request-draft:before {
    content: "\f32b";
    }
        .fi-br-code-pull-request:before {
    content: "\f32c";
    }
        .fi-br-code-simple:before {
    content: "\f32d";
    }
        .fi-br-coffee-pot:before {
    content: "\f32e";
    }
        .fi-br-coffee:before {
    content: "\f32f";
    }
        .fi-br-coin:before {
    content: "\f330";
    }
        .fi-br-coins:before {
    content: "\f331";
    }
        .fi-br-colon-sign:before {
    content: "\f332";
    }
        .fi-br-comet:before {
    content: "\f333";
    }
        .fi-br-comment-alt-check:before {
    content: "\f334";
    }
        .fi-br-comment-alt-dots:before {
    content: "\f335";
    }
        .fi-br-comment-alt-edit:before {
    content: "\f336";
    }
        .fi-br-comment-alt-medical:before {
    content: "\f337";
    }
        .fi-br-comment-alt-middle-top:before {
    content: "\f338";
    }
        .fi-br-comment-alt-middle:before {
    content: "\f339";
    }
        .fi-br-comment-alt-minus:before {
    content: "\f33a";
    }
        .fi-br-comment-alt:before {
    content: "\f33b";
    }
        .fi-br-comment-arrow-down:before {
    content: "\f33c";
    }
        .fi-br-comment-arrow-up-right:before {
    content: "\f33d";
    }
        .fi-br-comment-arrow-up:before {
    content: "\f33e";
    }
        .fi-br-comment-check:before {
    content: "\f33f";
    }
        .fi-br-comment-code:before {
    content: "\f340";
    }
        .fi-br-comment-dollar:before {
    content: "\f341";
    }
        .fi-br-comment-dots:before {
    content: "\f342";
    }
        .fi-br-comment-exclamation:before {
    content: "\f343";
    }
        .fi-br-comment-heart:before {
    content: "\f344";
    }
        .fi-br-comment-image:before {
    content: "\f345";
    }
        .fi-br-comment-info:before {
    content: "\f346";
    }
        .fi-br-comment-medical:before {
    content: "\f347";
    }
        .fi-br-comment-minus:before {
    content: "\f348";
    }
        .fi-br-comment-pen:before {
    content: "\f349";
    }
        .fi-br-comment-question:before {
    content: "\f34a";
    }
        .fi-br-comment-quote:before {
    content: "\f34b";
    }
        .fi-br-comment-slash:before {
    content: "\f34c";
    }
        .fi-br-comment-smile:before {
    content: "\f34d";
    }
        .fi-br-comment-sms:before {
    content: "\f34e";
    }
        .fi-br-comment-text:before {
    content: "\f34f";
    }
        .fi-br-comment-user:before {
    content: "\f350";
    }
        .fi-br-comment-xmark:before {
    content: "\f351";
    }
        .fi-br-comment:before {
    content: "\f352";
    }
        .fi-br-comments-dollar:before {
    content: "\f353";
    }
        .fi-br-comments-question-check:before {
    content: "\f354";
    }
        .fi-br-comments-question:before {
    content: "\f355";
    }
        .fi-br-comments:before {
    content: "\f356";
    }
        .fi-br-compress-alt:before {
    content: "\f357";
    }
        .fi-br-compress:before {
    content: "\f358";
    }
        .fi-br-computer:before {
    content: "\f359";
    }
        .fi-br-concierge-bell:before {
    content: "\f35a";
    }
        .fi-br-confetti:before {
    content: "\f35b";
    }
        .fi-br-cookie:before {
    content: "\f35c";
    }
        .fi-br-copy-alt:before {
    content: "\f35d";
    }
        .fi-br-copy-image:before {
    content: "\f35e";
    }
        .fi-br-copy:before {
    content: "\f35f";
    }
        .fi-br-copyright:before {
    content: "\f360";
    }
        .fi-br-corn:before {
    content: "\f361";
    }
        .fi-br-couple:before {
    content: "\f362";
    }
        .fi-br-cow:before {
    content: "\f363";
    }
        .fi-br-cowbell-more:before {
    content: "\f364";
    }
        .fi-br-cowbell:before {
    content: "\f365";
    }
        .fi-br-cream:before {
    content: "\f366";
    }
        .fi-br-credit-card:before {
    content: "\f367";
    }
        .fi-br-cricket:before {
    content: "\f368";
    }
        .fi-br-croissant:before {
    content: "\f369";
    }
        .fi-br-cross-circle:before {
    content: "\f36a";
    }
        .fi-br-cross-religion:before {
    content: "\f36b";
    }
        .fi-br-cross-small:before {
    content: "\f36c";
    }
        .fi-br-cross:before {
    content: "\f36d";
    }
        .fi-br-crown:before {
    content: "\f36e";
    }
        .fi-br-crutch:before {
    content: "\f36f";
    }
        .fi-br-crutches:before {
    content: "\f370";
    }
        .fi-br-cruzeiro-sign:before {
    content: "\f371";
    }
        .fi-br-crystal-ball:before {
    content: "\f372";
    }
        .fi-br-cube:before {
    content: "\f373";
    }
        .fi-br-cubes:before {
    content: "\f374";
    }
        .fi-br-cupcake:before {
    content: "\f375";
    }
        .fi-br-curling:before {
    content: "\f376";
    }
        .fi-br-cursor-finger:before {
    content: "\f377";
    }
        .fi-br-cursor-plus:before {
    content: "\f378";
    }
        .fi-br-cursor-text-alt:before {
    content: "\f379";
    }
        .fi-br-cursor-text:before {
    content: "\f37a";
    }
        .fi-br-cursor:before {
    content: "\f37b";
    }
        .fi-br-d:before {
    content: "\f37c";
    }
        .fi-br-dart:before {
    content: "\f37d";
    }
        .fi-br-dashboard:before {
    content: "\f37e";
    }
        .fi-br-data-transfer:before {
    content: "\f37f";
    }
        .fi-br-database:before {
    content: "\f380";
    }
        .fi-br-delete-document:before {
    content: "\f381";
    }
        .fi-br-delete-user:before {
    content: "\f382";
    }
        .fi-br-delete:before {
    content: "\f383";
    }
        .fi-br-democrat:before {
    content: "\f384";
    }
        .fi-br-desktop-wallpaper:before {
    content: "\f385";
    }
        .fi-br-devices:before {
    content: "\f386";
    }
        .fi-br-dewpoint:before {
    content: "\f387";
    }
        .fi-br-diagram-cells:before {
    content: "\f388";
    }
        .fi-br-diagram-lean-canvas:before {
    content: "\f389";
    }
        .fi-br-diagram-nested:before {
    content: "\f38a";
    }
        .fi-br-diagram-next:before {
    content: "\f38b";
    }
        .fi-br-diagram-predecessor:before {
    content: "\f38c";
    }
        .fi-br-diagram-previous:before {
    content: "\f38d";
    }
        .fi-br-diagram-project:before {
    content: "\f38e";
    }
        .fi-br-diagram-sankey:before {
    content: "\f38f";
    }
        .fi-br-diagram-subtask:before {
    content: "\f390";
    }
        .fi-br-diagram-successor:before {
    content: "\f391";
    }
        .fi-br-diagram-venn:before {
    content: "\f392";
    }
        .fi-br-diamond-turn-right:before {
    content: "\f393";
    }
        .fi-br-diamond:before {
    content: "\f394";
    }
        .fi-br-dice-alt:before {
    content: "\f395";
    }
        .fi-br-dice-d10:before {
    content: "\f396";
    }
        .fi-br-dice-d12:before {
    content: "\f397";
    }
        .fi-br-dice-d20:before {
    content: "\f398";
    }
        .fi-br-dice-d4:before {
    content: "\f399";
    }
        .fi-br-dice-d6:before {
    content: "\f39a";
    }
        .fi-br-dice-d8:before {
    content: "\f39b";
    }
        .fi-br-dice-four:before {
    content: "\f39c";
    }
        .fi-br-dice-one:before {
    content: "\f39d";
    }
        .fi-br-dice-six:before {
    content: "\f39e";
    }
        .fi-br-dice-three:before {
    content: "\f39f";
    }
        .fi-br-dice-two:before {
    content: "\f3a0";
    }
        .fi-br-dice:before {
    content: "\f3a1";
    }
        .fi-br-diploma:before {
    content: "\f3a2";
    }
        .fi-br-disco-ball:before {
    content: "\f3a3";
    }
        .fi-br-disease:before {
    content: "\f3a4";
    }
        .fi-br-disk:before {
    content: "\f3a5";
    }
        .fi-br-display-code:before {
    content: "\f3a6";
    }
        .fi-br-distribute-spacing-horizontal:before {
    content: "\f3a7";
    }
        .fi-br-distribute-spacing-vertical:before {
    content: "\f3a8";
    }
        .fi-br-dizzy:before {
    content: "\f3a9";
    }
        .fi-br-dna:before {
    content: "\f3aa";
    }
        .fi-br-doctor:before {
    content: "\f3ab";
    }
        .fi-br-document-signed:before {
    content: "\f3ac";
    }
        .fi-br-document:before {
    content: "\f3ad";
    }
        .fi-br-dollar:before {
    content: "\f3ae";
    }
        .fi-br-donate:before {
    content: "\f3af";
    }
        .fi-br-dong-sign:before {
    content: "\f3b0";
    }
        .fi-br-door-closed:before {
    content: "\f3b1";
    }
        .fi-br-door-open:before {
    content: "\f3b2";
    }
        .fi-br-down-left-and-up-right-to-center:before {
    content: "\f3b3";
    }
        .fi-br-down-left:before {
    content: "\f3b4";
    }
        .fi-br-down-right:before {
    content: "\f3b5";
    }
        .fi-br-down-to-line:before {
    content: "\f3b6";
    }
        .fi-br-down:before {
    content: "\f3b7";
    }
        .fi-br-download:before {
    content: "\f3b8";
    }
        .fi-br-drafting-compass:before {
    content: "\f3b9";
    }
        .fi-br-dreidel:before {
    content: "\f3ba";
    }
        .fi-br-drink-alt:before {
    content: "\f3bb";
    }
        .fi-br-drumstick:before {
    content: "\f3bc";
    }
        .fi-br-dungeon:before {
    content: "\f3bd";
    }
        .fi-br-duplicate:before {
    content: "\f3be";
    }
        .fi-br-e-learning:before {
    content: "\f3bf";
    }
        .fi-br-e:before {
    content: "\f3c0";
    }
        .fi-br-ear-deaf:before {
    content: "\f3c1";
    }
        .fi-br-ear:before {
    content: "\f3c2";
    }
        .fi-br-earnings:before {
    content: "\f3c3";
    }
        .fi-br-earth-africa:before {
    content: "\f3c4";
    }
        .fi-br-earth-americas:before {
    content: "\f3c5";
    }
        .fi-br-earth-asia:before {
    content: "\f3c6";
    }
        .fi-br-earth-europa:before {
    content: "\f3c7";
    }
        .fi-br-eclipse-alt:before {
    content: "\f3c8";
    }
        .fi-br-eclipse:before {
    content: "\f3c9";
    }
        .fi-br-edit-alt:before {
    content: "\f3ca";
    }
        .fi-br-edit:before {
    content: "\f3cb";
    }
        .fi-br-egg-fried:before {
    content: "\f3cc";
    }
        .fi-br-egg:before {
    content: "\f3cd";
    }
        .fi-br-elevator:before {
    content: "\f3ce";
    }
        .fi-br-engine-warning:before {
    content: "\f3cf";
    }
        .fi-br-enter:before {
    content: "\f3d0";
    }
        .fi-br-envelope-ban:before {
    content: "\f3d1";
    }
        .fi-br-envelope-bulk:before {
    content: "\f3d2";
    }
        .fi-br-envelope-dot:before {
    content: "\f3d3";
    }
        .fi-br-envelope-download:before {
    content: "\f3d4";
    }
        .fi-br-envelope-marker:before {
    content: "\f3d5";
    }
        .fi-br-envelope-open-dollar:before {
    content: "\f3d6";
    }
        .fi-br-envelope-open-text:before {
    content: "\f3d7";
    }
        .fi-br-envelope-open:before {
    content: "\f3d8";
    }
        .fi-br-envelope-plus:before {
    content: "\f3d9";
    }
        .fi-br-envelope:before {
    content: "\f3da";
    }
        .fi-br-envelopes:before {
    content: "\f3db";
    }
        .fi-br-equality:before {
    content: "\f3dc";
    }
        .fi-br-escalator:before {
    content: "\f3dd";
    }
        .fi-br-euro:before {
    content: "\f3de";
    }
        .fi-br-exchange-alt:before {
    content: "\f3df";
    }
        .fi-br-exchange:before {
    content: "\f3e0";
    }
        .fi-br-exclamation:before {
    content: "\f3e1";
    }
        .fi-br-exit:before {
    content: "\f3e2";
    }
        .fi-br-expand-arrows-alt:before {
    content: "\f3e3";
    }
        .fi-br-expand-arrows:before {
    content: "\f3e4";
    }
        .fi-br-expand:before {
    content: "\f3e5";
    }
        .fi-br-eye-crossed:before {
    content: "\f3e6";
    }
        .fi-br-eye-dropper:before {
    content: "\f3e7";
    }
        .fi-br-eye:before {
    content: "\f3e8";
    }
        .fi-br-eyes:before {
    content: "\f3e9";
    }
        .fi-br-f:before {
    content: "\f3ea";
    }
        .fi-br-face-awesome:before {
    content: "\f3eb";
    }
        .fi-br-face-head-bandage:before {
    content: "\f3ec";
    }
        .fi-br-farm:before {
    content: "\f3ed";
    }
        .fi-br-fax:before {
    content: "\f3ee";
    }
        .fi-br-feather:before {
    content: "\f3ef";
    }
        .fi-br-female-couple:before {
    content: "\f3f0";
    }
        .fi-br-female:before {
    content: "\f3f1";
    }
        .fi-br-fence:before {
    content: "\f3f2";
    }
        .fi-br-ferris-wheel:before {
    content: "\f3f3";
    }
        .fi-br-field-hockey:before {
    content: "\f3f4";
    }
        .fi-br-fighter-jet:before {
    content: "\f3f5";
    }
        .fi-br-file-ai:before {
    content: "\f3f6";
    }
        .fi-br-file-binary:before {
    content: "\f3f7";
    }
        .fi-br-file-chart-line:before {
    content: "\f3f8";
    }
        .fi-br-file-chart-pie:before {
    content: "\f3f9";
    }
        .fi-br-file-circle-info:before {
    content: "\f3fa";
    }
        .fi-br-file-code:before {
    content: "\f3fb";
    }
        .fi-br-file-eps:before {
    content: "\f3fc";
    }
        .fi-br-file-excel:before {
    content: "\f3fd";
    }
        .fi-br-file-export:before {
    content: "\f3fe";
    }
        .fi-br-file-invoice-dollar:before {
    content: "\f3ff";
    }
        .fi-br-file-invoice:before {
    content: "\f400";
    }
        .fi-br-file-medical-alt:before {
    content: "\f401";
    }
        .fi-br-file-medical:before {
    content: "\f402";
    }
        .fi-br-file-pdf:before {
    content: "\f403";
    }
        .fi-br-file-powerpoint:before {
    content: "\f404";
    }
        .fi-br-file-prescription:before {
    content: "\f405";
    }
        .fi-br-file-psd:before {
    content: "\f406";
    }
        .fi-br-file-signature:before {
    content: "\f407";
    }
        .fi-br-file-spreadsheet:before {
    content: "\f408";
    }
        .fi-br-file-video:before {
    content: "\f409";
    }
        .fi-br-file-word:before {
    content: "\f40a";
    }
        .fi-br-file:before {
    content: "\f40b";
    }
        .fi-br-files-medical:before {
    content: "\f40c";
    }
        .fi-br-fill:before {
    content: "\f40d";
    }
        .fi-br-film-slash:before {
    content: "\f40e";
    }
        .fi-br-film:before {
    content: "\f40f";
    }
        .fi-br-filter-slash:before {
    content: "\f410";
    }
        .fi-br-filter:before {
    content: "\f411";
    }
        .fi-br-filters:before {
    content: "\f412";
    }
        .fi-br-fingerprint:before {
    content: "\f413";
    }
        .fi-br-fire-flame-curved:before {
    content: "\f414";
    }
        .fi-br-fire-smoke:before {
    content: "\f415";
    }
        .fi-br-fish:before {
    content: "\f416";
    }
        .fi-br-fishing-rod:before {
    content: "\f417";
    }
        .fi-br-flag-alt:before {
    content: "\f418";
    }
        .fi-br-flag-checkered:before {
    content: "\f419";
    }
        .fi-br-flag-usa:before {
    content: "\f41a";
    }
        .fi-br-flag:before {
    content: "\f41b";
    }
        .fi-br-flame:before {
    content: "\f41c";
    }
        .fi-br-flip-horizontal:before {
    content: "\f41d";
    }
        .fi-br-floppy-disk-circle-arrow-right:before {
    content: "\f41e";
    }
        .fi-br-floppy-disk-circle-xmark:before {
    content: "\f41f";
    }
        .fi-br-florin-sign:before {
    content: "\f420";
    }
        .fi-br-flower-bouquet:before {
    content: "\f421";
    }
        .fi-br-flower-tulip:before {
    content: "\f422";
    }
        .fi-br-flower:before {
    content: "\f423";
    }
        .fi-br-flushed-face:before {
    content: "\f424";
    }
        .fi-br-flushed:before {
    content: "\f425";
    }
        .fi-br-fog:before {
    content: "\f426";
    }
        .fi-br-folder-download:before {
    content: "\f427";
    }
        .fi-br-folder-minus:before {
    content: "\f428";
    }
        .fi-br-folder-times:before {
    content: "\f429";
    }
        .fi-br-folder-tree:before {
    content: "\f42a";
    }
        .fi-br-folder-upload:before {
    content: "\f42b";
    }
        .fi-br-folder-xmark:before {
    content: "\f42c";
    }
        .fi-br-folder:before {
    content: "\f42d";
    }
        .fi-br-folders:before {
    content: "\f42e";
    }
        .fi-br-follow-folder:before {
    content: "\f42f";
    }
        .fi-br-followcollection:before {
    content: "\f430";
    }
        .fi-br-following:before {
    content: "\f431";
    }
        .fi-br-football:before {
    content: "\f432";
    }
        .fi-br-fork:before {
    content: "\f433";
    }
        .fi-br-form:before {
    content: "\f434";
    }
        .fi-br-fort:before {
    content: "\f435";
    }
        .fi-br-forward:before {
    content: "\f436";
    }
        .fi-br-fox:before {
    content: "\f437";
    }
        .fi-br-franc-sign:before {
    content: "\f438";
    }
        .fi-br-french-fries:before {
    content: "\f439";
    }
        .fi-br-ftp:before {
    content: "\f43a";
    }
        .fi-br-funnel-dollar:before {
    content: "\f43b";
    }
        .fi-br-g:before {
    content: "\f43c";
    }
        .fi-br-gallery-thumbnails:before {
    content: "\f43d";
    }
        .fi-br-gallery:before {
    content: "\f43e";
    }
        .fi-br-game-board-alt:before {
    content: "\f43f";
    }
        .fi-br-gamepad:before {
    content: "\f440";
    }
        .fi-br-garage-car:before {
    content: "\f441";
    }
        .fi-br-garage-open:before {
    content: "\f442";
    }
        .fi-br-garage:before {
    content: "\f443";
    }
        .fi-br-garlic:before {
    content: "\f444";
    }
        .fi-br-gas-pump-alt:before {
    content: "\f445";
    }
        .fi-br-gas-pump-slash:before {
    content: "\f446";
    }
        .fi-br-gas-pump:before {
    content: "\f447";
    }
        .fi-br-gavel:before {
    content: "\f448";
    }
        .fi-br-gears:before {
    content: "\f449";
    }
        .fi-br-gem:before {
    content: "\f44a";
    }
        .fi-br-gif:before {
    content: "\f44b";
    }
        .fi-br-gift-card:before {
    content: "\f44c";
    }
        .fi-br-gift:before {
    content: "\f44d";
    }
        .fi-br-gifts:before {
    content: "\f44e";
    }
        .fi-br-gingerbread-man:before {
    content: "\f44f";
    }
        .fi-br-glass-cheers:before {
    content: "\f450";
    }
        .fi-br-glass:before {
    content: "\f451";
    }
        .fi-br-glasses:before {
    content: "\f452";
    }
        .fi-br-globe-alt:before {
    content: "\f453";
    }
        .fi-br-globe:before {
    content: "\f454";
    }
        .fi-br-golf-ball:before {
    content: "\f455";
    }
        .fi-br-golf-club:before {
    content: "\f456";
    }
        .fi-br-golf:before {
    content: "\f457";
    }
        .fi-br-graduation-cap:before {
    content: "\f458";
    }
        .fi-br-grape:before {
    content: "\f459";
    }
        .fi-br-graphic-style:before {
    content: "\f45a";
    }
        .fi-br-graphic-tablet:before {
    content: "\f45b";
    }
        .fi-br-grid-alt:before {
    content: "\f45c";
    }
        .fi-br-grid-dividers:before {
    content: "\f45d";
    }
        .fi-br-grid:before {
    content: "\f45e";
    }
        .fi-br-grill:before {
    content: "\f45f";
    }
        .fi-br-grimace:before {
    content: "\f460";
    }
        .fi-br-grin-alt:before {
    content: "\f461";
    }
        .fi-br-grin-beam-sweat:before {
    content: "\f462";
    }
        .fi-br-grin-beam:before {
    content: "\f463";
    }
        .fi-br-grin-hearts:before {
    content: "\f464";
    }
        .fi-br-grin-squint-tears:before {
    content: "\f465";
    }
        .fi-br-grin-squint:before {
    content: "\f466";
    }
        .fi-br-grin-stars:before {
    content: "\f467";
    }
        .fi-br-grin-tears:before {
    content: "\f468";
    }
        .fi-br-grin-tongue-squint:before {
    content: "\f469";
    }
        .fi-br-grin-tongue-wink:before {
    content: "\f46a";
    }
        .fi-br-grin-tongue:before {
    content: "\f46b";
    }
        .fi-br-grin-wink:before {
    content: "\f46c";
    }
        .fi-br-grin:before {
    content: "\f46d";
    }
        .fi-br-guarani-sign:before {
    content: "\f46e";
    }
        .fi-br-guitar:before {
    content: "\f46f";
    }
        .fi-br-gym:before {
    content: "\f470";
    }
        .fi-br-h-square:before {
    content: "\f471";
    }
        .fi-br-h:before {
    content: "\f472";
    }
        .fi-br-hamburger-soda:before {
    content: "\f473";
    }
        .fi-br-hamburger:before {
    content: "\f474";
    }
        .fi-br-hammer-crash:before {
    content: "\f475";
    }
        .fi-br-hammer-war:before {
    content: "\f476";
    }
        .fi-br-hammer:before {
    content: "\f477";
    }
        .fi-br-hand-holding-box:before {
    content: "\f478";
    }
        .fi-br-hand-holding-medical:before {
    content: "\f479";
    }
        .fi-br-hand-holding-seeding:before {
    content: "\f47a";
    }
        .fi-br-hand-holding-usd:before {
    content: "\f47b";
    }
        .fi-br-hand-horns:before {
    content: "\f47c";
    }
        .fi-br-hand-lizard:before {
    content: "\f47d";
    }
        .fi-br-hand-peace:before {
    content: "\f47e";
    }
        .fi-br-hand:before {
    content: "\f47f";
    }
        .fi-br-hands-clapping:before {
    content: "\f480";
    }
        .fi-br-hands-usd:before {
    content: "\f481";
    }
        .fi-br-handshake-angle:before {
    content: "\f482";
    }
        .fi-br-handshake:before {
    content: "\f483";
    }
        .fi-br-happy:before {
    content: "\f484";
    }
        .fi-br-hastag:before {
    content: "\f485";
    }
        .fi-br-hat-birthday:before {
    content: "\f486";
    }
        .fi-br-hat-chef:before {
    content: "\f487";
    }
        .fi-br-hdd:before {
    content: "\f488";
    }
        .fi-br-head-side-brain:before {
    content: "\f489";
    }
        .fi-br-head-side-cough-slash:before {
    content: "\f48a";
    }
        .fi-br-head-side-cough:before {
    content: "\f48b";
    }
        .fi-br-head-side-heart:before {
    content: "\f48c";
    }
        .fi-br-head-side-mask:before {
    content: "\f48d";
    }
        .fi-br-head-side-medical:before {
    content: "\f48e";
    }
        .fi-br-head-side-thinking:before {
    content: "\f48f";
    }
        .fi-br-head-side-virus:before {
    content: "\f490";
    }
        .fi-br-head-side:before {
    content: "\f491";
    }
        .fi-br-head-vr:before {
    content: "\f492";
    }
        .fi-br-headphones:before {
    content: "\f493";
    }
        .fi-br-headset:before {
    content: "\f494";
    }
        .fi-br-heart-arrow:before {
    content: "\f495";
    }
        .fi-br-heart-crack:before {
    content: "\f496";
    }
        .fi-br-heart-half-stroke:before {
    content: "\f497";
    }
        .fi-br-heart-half:before {
    content: "\f498";
    }
        .fi-br-heart-rate:before {
    content: "\f499";
    }
        .fi-br-heart:before {
    content: "\f49a";
    }
        .fi-br-heat:before {
    content: "\f49b";
    }
        .fi-br-helicopter-side:before {
    content: "\f49c";
    }
        .fi-br-hexagon:before {
    content: "\f49d";
    }
        .fi-br-highlighter-line:before {
    content: "\f49e";
    }
        .fi-br-highlighter:before {
    content: "\f49f";
    }
        .fi-br-hiking:before {
    content: "\f4a0";
    }
        .fi-br-hockey-mask:before {
    content: "\f4a1";
    }
        .fi-br-hockey-puck:before {
    content: "\f4a2";
    }
        .fi-br-hockey-sticks:before {
    content: "\f4a3";
    }
        .fi-br-home-location-alt:before {
    content: "\f4a4";
    }
        .fi-br-home-location:before {
    content: "\f4a5";
    }
        .fi-br-home:before {
    content: "\f4a6";
    }
        .fi-br-horizontal-rule:before {
    content: "\f4a7";
    }
        .fi-br-hospital-symbol:before {
    content: "\f4a8";
    }
        .fi-br-hospital-user:before {
    content: "\f4a9";
    }
        .fi-br-hospital:before {
    content: "\f4aa";
    }
        .fi-br-hospitals:before {
    content: "\f4ab";
    }
        .fi-br-hot-tub:before {
    content: "\f4ac";
    }
        .fi-br-hotdog:before {
    content: "\f4ad";
    }
        .fi-br-hotel:before {
    content: "\f4ae";
    }
        .fi-br-hourglass-end:before {
    content: "\f4af";
    }
        .fi-br-hourglass-start:before {
    content: "\f4b0";
    }
        .fi-br-hourglass:before {
    content: "\f4b1";
    }
        .fi-br-house-blank:before {
    content: "\f4b2";
    }
        .fi-br-house-building:before {
    content: "\f4b3";
    }
        .fi-br-house-chimney-blank:before {
    content: "\f4b4";
    }
        .fi-br-house-chimney-crack:before {
    content: "\f4b5";
    }
        .fi-br-house-chimney-medical:before {
    content: "\f4b6";
    }
        .fi-br-house-chimney-window:before {
    content: "\f4b7";
    }
        .fi-br-house-chimney:before {
    content: "\f4b8";
    }
        .fi-br-house-crack:before {
    content: "\f4b9";
    }
        .fi-br-house-flood:before {
    content: "\f4ba";
    }
        .fi-br-house-laptop:before {
    content: "\f4bb";
    }
        .fi-br-house-medical:before {
    content: "\f4bc";
    }
        .fi-br-house-tree:before {
    content: "\f4bd";
    }
        .fi-br-house-turret:before {
    content: "\f4be";
    }
        .fi-br-house-window:before {
    content: "\f4bf";
    }
        .fi-br-hryvnia:before {
    content: "\f4c0";
    }
        .fi-br-humidity:before {
    content: "\f4c1";
    }
        .fi-br-hundred-points:before {
    content: "\f4c2";
    }
        .fi-br-hurricane:before {
    content: "\f4c3";
    }
        .fi-br-i:before {
    content: "\f4c4";
    }
        .fi-br-ice-cream:before {
    content: "\f4c5";
    }
        .fi-br-ice-skate:before {
    content: "\f4c6";
    }
        .fi-br-icon-star:before {
    content: "\f4c7";
    }
        .fi-br-id-badge:before {
    content: "\f4c8";
    }
        .fi-br-id-card-clip-alt:before {
    content: "\f4c9";
    }
        .fi-br-igloo:before {
    content: "\f4ca";
    }
        .fi-br-images:before {
    content: "\f4cb";
    }
        .fi-br-inbox-in:before {
    content: "\f4cc";
    }
        .fi-br-inbox-out:before {
    content: "\f4cd";
    }
        .fi-br-inbox:before {
    content: "\f4ce";
    }
        .fi-br-inboxes:before {
    content: "\f4cf";
    }
        .fi-br-incognito:before {
    content: "\f4d0";
    }
        .fi-br-indent:before {
    content: "\f4d1";
    }
        .fi-br-indian-rupee-sign:before {
    content: "\f4d2";
    }
        .fi-br-industry-windows:before {
    content: "\f4d3";
    }
        .fi-br-infinity:before {
    content: "\f4d4";
    }
        .fi-br-info:before {
    content: "\f4d5";
    }
        .fi-br-inhaler:before {
    content: "\f4d6";
    }
        .fi-br-input-numeric:before {
    content: "\f4d7";
    }
        .fi-br-input-pipe:before {
    content: "\f4d8";
    }
        .fi-br-input-text:before {
    content: "\f4d9";
    }
        .fi-br-interactive:before {
    content: "\f4da";
    }
        .fi-br-interlining:before {
    content: "\f4db";
    }
        .fi-br-interrogation:before {
    content: "\f4dc";
    }
        .fi-br-island-tropical:before {
    content: "\f4dd";
    }
        .fi-br-italian-lira-sign:before {
    content: "\f4de";
    }
        .fi-br-italic:before {
    content: "\f4df";
    }
        .fi-br-j:before {
    content: "\f4e0";
    }
        .fi-br-jam:before {
    content: "\f4e1";
    }
        .fi-br-joint:before {
    content: "\f4e2";
    }
        .fi-br-jpg:before {
    content: "\f4e3";
    }
        .fi-br-jug:before {
    content: "\f4e4";
    }
        .fi-br-k:before {
    content: "\f4e5";
    }
        .fi-br-kerning:before {
    content: "\f4e6";
    }
        .fi-br-key-skeleton-left-right:before {
    content: "\f4e7";
    }
        .fi-br-key:before {
    content: "\f4e8";
    }
        .fi-br-keyboard-brightness-low:before {
    content: "\f4e9";
    }
        .fi-br-keyboard-brightness:before {
    content: "\f4ea";
    }
        .fi-br-keyboard:before {
    content: "\f4eb";
    }
        .fi-br-keynote:before {
    content: "\f4ec";
    }
        .fi-br-kidneys:before {
    content: "\f4ed";
    }
        .fi-br-kip-sign:before {
    content: "\f4ee";
    }
        .fi-br-kiss-beam:before {
    content: "\f4ef";
    }
        .fi-br-kiss-wink-heart:before {
    content: "\f4f0";
    }
        .fi-br-kiss:before {
    content: "\f4f1";
    }
        .fi-br-kite:before {
    content: "\f4f2";
    }
        .fi-br-knife:before {
    content: "\f4f3";
    }
        .fi-br-l:before {
    content: "\f4f4";
    }
        .fi-br-label:before {
    content: "\f4f5";
    }
        .fi-br-landmark-alt:before {
    content: "\f4f6";
    }
        .fi-br-language:before {
    content: "\f4f7";
    }
        .fi-br-laptop-code:before {
    content: "\f4f8";
    }
        .fi-br-laptop-medical:before {
    content: "\f4f9";
    }
        .fi-br-laptop-mobile:before {
    content: "\f4fa";
    }
        .fi-br-laptop:before {
    content: "\f4fb";
    }
        .fi-br-lari-sign:before {
    content: "\f4fc";
    }
        .fi-br-lasso-sparkles:before {
    content: "\f4fd";
    }
        .fi-br-lasso:before {
    content: "\f4fe";
    }
        .fi-br-laugh-beam:before {
    content: "\f4ff";
    }
        .fi-br-laugh-squint:before {
    content: "\f500";
    }
        .fi-br-laugh-wink:before {
    content: "\f501";
    }
        .fi-br-laugh:before {
    content: "\f502";
    }
        .fi-br-layer-minus:before {
    content: "\f503";
    }
        .fi-br-layer-plus:before {
    content: "\f504";
    }
        .fi-br-layers:before {
    content: "\f505";
    }
        .fi-br-layout-fluid:before {
    content: "\f506";
    }
        .fi-br-leaf:before {
    content: "\f507";
    }
        .fi-br-left:before {
    content: "\f508";
    }
        .fi-br-lemon:before {
    content: "\f509";
    }
        .fi-br-letter-case:before {
    content: "\f50a";
    }
        .fi-br-lettuce:before {
    content: "\f50b";
    }
        .fi-br-level-down-alt:before {
    content: "\f50c";
    }
        .fi-br-level-down:before {
    content: "\f50d";
    }
        .fi-br-level-up-alt:before {
    content: "\f50e";
    }
        .fi-br-level-up:before {
    content: "\f50f";
    }
        .fi-br-license:before {
    content: "\f510";
    }
        .fi-br-life-ring:before {
    content: "\f511";
    }
        .fi-br-light-switch-off:before {
    content: "\f512";
    }
        .fi-br-light-switch-on:before {
    content: "\f513";
    }
        .fi-br-light-switch:before {
    content: "\f514";
    }
        .fi-br-lightbulb-dollar:before {
    content: "\f515";
    }
        .fi-br-lightbulb-slash:before {
    content: "\f516";
    }
        .fi-br-line-width:before {
    content: "\f517";
    }
        .fi-br-link-alt:before {
    content: "\f518";
    }
        .fi-br-link-horizontal-slash:before {
    content: "\f519";
    }
        .fi-br-link-horizontal:before {
    content: "\f51a";
    }
        .fi-br-link-slash-alt:before {
    content: "\f51b";
    }
        .fi-br-link-slash:before {
    content: "\f51c";
    }
        .fi-br-link:before {
    content: "\f51d";
    }
        .fi-br-lips:before {
    content: "\f51e";
    }
        .fi-br-lipstick:before {
    content: "\f51f";
    }
        .fi-br-lira-sign:before {
    content: "\f520";
    }
        .fi-br-list-check:before {
    content: "\f521";
    }
        .fi-br-list-dropdown:before {
    content: "\f522";
    }
        .fi-br-list-timeline:before {
    content: "\f523";
    }
        .fi-br-list:before {
    content: "\f524";
    }
        .fi-br-litecoin-sign:before {
    content: "\f525";
    }
        .fi-br-loading:before {
    content: "\f526";
    }
        .fi-br-location-alt:before {
    content: "\f527";
    }
        .fi-br-location-crosshairs-slash:before {
    content: "\f528";
    }
        .fi-br-location-crosshairs:before {
    content: "\f529";
    }
        .fi-br-location-dot-slash:before {
    content: "\f52a";
    }
        .fi-br-lock-alt:before {
    content: "\f52b";
    }
        .fi-br-lock:before {
    content: "\f52c";
    }
        .fi-br-luchador:before {
    content: "\f52d";
    }
        .fi-br-luggage-cart:before {
    content: "\f52e";
    }
        .fi-br-luggage-rolling:before {
    content: "\f52f";
    }
        .fi-br-lungs-virus:before {
    content: "\f530";
    }
        .fi-br-lungs:before {
    content: "\f531";
    }
        .fi-br-m:before {
    content: "\f532";
    }
        .fi-br-magic-wand:before {
    content: "\f533";
    }
        .fi-br-mailbox:before {
    content: "\f534";
    }
        .fi-br-makeup-brush:before {
    content: "\f535";
    }
        .fi-br-male-couple:before {
    content: "\f536";
    }
        .fi-br-male:before {
    content: "\f537";
    }
        .fi-br-man-head:before {
    content: "\f538";
    }
        .fi-br-manat-sign:before {
    content: "\f539";
    }
        .fi-br-map-marker-check:before {
    content: "\f53a";
    }
        .fi-br-map-marker-cross:before {
    content: "\f53b";
    }
        .fi-br-map-marker-edit:before {
    content: "\f53c";
    }
        .fi-br-map-marker-home:before {
    content: "\f53d";
    }
        .fi-br-map-marker-minus:before {
    content: "\f53e";
    }
        .fi-br-map-marker-plus:before {
    content: "\f53f";
    }
        .fi-br-map-marker-question:before {
    content: "\f540";
    }
        .fi-br-map-marker-slash:before {
    content: "\f541";
    }
        .fi-br-map-marker-smile:before {
    content: "\f542";
    }
        .fi-br-map-marker:before {
    content: "\f543";
    }
        .fi-br-map-pin:before {
    content: "\f544";
    }
        .fi-br-map:before {
    content: "\f545";
    }
        .fi-br-marker-time:before {
    content: "\f546";
    }
        .fi-br-marker:before {
    content: "\f547";
    }
        .fi-br-mask-carnival:before {
    content: "\f548";
    }
        .fi-br-mask-face:before {
    content: "\f549";
    }
        .fi-br-medical-star:before {
    content: "\f54a";
    }
        .fi-br-medicine:before {
    content: "\f54b";
    }
        .fi-br-megaphone:before {
    content: "\f54c";
    }
        .fi-br-meh-blank:before {
    content: "\f54d";
    }
        .fi-br-meh-rolling-eyes:before {
    content: "\f54e";
    }
        .fi-br-melon:before {
    content: "\f54f";
    }
        .fi-br-memo-circle-check:before {
    content: "\f550";
    }
        .fi-br-memo-pad:before {
    content: "\f551";
    }
        .fi-br-memo:before {
    content: "\f552";
    }
        .fi-br-memory:before {
    content: "\f553";
    }
        .fi-br-menu-burger:before {
    content: "\f554";
    }
        .fi-br-menu-dots-vertical:before {
    content: "\f555";
    }
        .fi-br-menu-dots:before {
    content: "\f556";
    }
        .fi-br-message-arrow-down:before {
    content: "\f557";
    }
        .fi-br-message-arrow-up-right:before {
    content: "\f558";
    }
        .fi-br-message-arrow-up:before {
    content: "\f559";
    }
        .fi-br-message-bot:before {
    content: "\f55a";
    }
        .fi-br-message-code:before {
    content: "\f55b";
    }
        .fi-br-message-dollar:before {
    content: "\f55c";
    }
        .fi-br-message-image:before {
    content: "\f55d";
    }
        .fi-br-message-question:before {
    content: "\f55e";
    }
        .fi-br-message-quote:before {
    content: "\f55f";
    }
        .fi-br-message-slash:before {
    content: "\f560";
    }
        .fi-br-message-sms:before {
    content: "\f561";
    }
        .fi-br-message-text:before {
    content: "\f562";
    }
        .fi-br-message-xmark:before {
    content: "\f563";
    }
        .fi-br-messages-dollar:before {
    content: "\f564";
    }
        .fi-br-messages-question:before {
    content: "\f565";
    }
        .fi-br-messages:before {
    content: "\f566";
    }
        .fi-br-meteor:before {
    content: "\f567";
    }
        .fi-br-microchip:before {
    content: "\f568";
    }
        .fi-br-microphone-alt:before {
    content: "\f569";
    }
        .fi-br-microphone:before {
    content: "\f56a";
    }
        .fi-br-microscope:before {
    content: "\f56b";
    }
        .fi-br-mill-sign:before {
    content: "\f56c";
    }
        .fi-br-mind-share:before {
    content: "\f56d";
    }
        .fi-br-minus-small:before {
    content: "\f56e";
    }
        .fi-br-minus:before {
    content: "\f56f";
    }
        .fi-br-mobile-button:before {
    content: "\f570";
    }
        .fi-br-mobile-notch:before {
    content: "\f571";
    }
        .fi-br-mobile:before {
    content: "\f572";
    }
        .fi-br-mockup:before {
    content: "\f573";
    }
        .fi-br-mode-landscape:before {
    content: "\f574";
    }
        .fi-br-mode-portrait:before {
    content: "\f575";
    }
        .fi-br-money-bill-simple:before {
    content: "\f576";
    }
        .fi-br-money-bill-wave-alt:before {
    content: "\f577";
    }
        .fi-br-money-bill-wave:before {
    content: "\f578";
    }
        .fi-br-money-bills-simple:before {
    content: "\f579";
    }
        .fi-br-money-bills:before {
    content: "\f57a";
    }
        .fi-br-money-check-edit-alt:before {
    content: "\f57b";
    }
        .fi-br-money-check-edit:before {
    content: "\f57c";
    }
        .fi-br-money-check:before {
    content: "\f57d";
    }
        .fi-br-money-from-bracket:before {
    content: "\f57e";
    }
        .fi-br-money-simple-from-bracket:before {
    content: "\f57f";
    }
        .fi-br-money:before {
    content: "\f580";
    }
        .fi-br-monument:before {
    content: "\f581";
    }
        .fi-br-moon-stars:before {
    content: "\f582";
    }
        .fi-br-moon:before {
    content: "\f583";
    }
        .fi-br-mortar-pestle:before {
    content: "\f584";
    }
        .fi-br-motorcycle:before {
    content: "\f585";
    }
        .fi-br-mountains:before {
    content: "\f586";
    }
        .fi-br-mouse:before {
    content: "\f587";
    }
        .fi-br-move-to-folder-2:before {
    content: "\f588";
    }
        .fi-br-move-to-folder:before {
    content: "\f589";
    }
        .fi-br-mug-alt:before {
    content: "\f58a";
    }
        .fi-br-mug-hot-alt:before {
    content: "\f58b";
    }
        .fi-br-mug-hot:before {
    content: "\f58c";
    }
        .fi-br-mug-tea:before {
    content: "\f58d";
    }
        .fi-br-mug:before {
    content: "\f58e";
    }
        .fi-br-mushroom:before {
    content: "\f58f";
    }
        .fi-br-music-alt:before {
    content: "\f590";
    }
        .fi-br-music-file:before {
    content: "\f591";
    }
        .fi-br-music:before {
    content: "\f592";
    }
        .fi-br-n:before {
    content: "\f593";
    }
        .fi-br-naira-sign:before {
    content: "\f594";
    }
        .fi-br-navigation:before {
    content: "\f595";
    }
        .fi-br-network-cloud:before {
    content: "\f596";
    }
        .fi-br-network:before {
    content: "\f597";
    }
        .fi-br-nfc-lock:before {
    content: "\f598";
    }
        .fi-br-nfc-magnifying-glass:before {
    content: "\f599";
    }
        .fi-br-nfc-pen:before {
    content: "\f59a";
    }
        .fi-br-nfc-slash:before {
    content: "\f59b";
    }
        .fi-br-nfc-trash:before {
    content: "\f59c";
    }
        .fi-br-nfc:before {
    content: "\f59d";
    }
        .fi-br-no-people:before {
    content: "\f59e";
    }
        .fi-br-noodles:before {
    content: "\f59f";
    }
        .fi-br-notdef:before {
    content: "\f5a0";
    }
        .fi-br-note-medical:before {
    content: "\f5a1";
    }
        .fi-br-note-sticky:before {
    content: "\f5a2";
    }
        .fi-br-note:before {
    content: "\f5a3";
    }
        .fi-br-notebook-alt:before {
    content: "\f5a4";
    }
        .fi-br-notebook:before {
    content: "\f5a5";
    }
        .fi-br-notes-medical:before {
    content: "\f5a6";
    }
        .fi-br-notes:before {
    content: "\f5a7";
    }
        .fi-br-o:before {
    content: "\f5a8";
    }
        .fi-br-object-exclude:before {
    content: "\f5a9";
    }
        .fi-br-object-intersect:before {
    content: "\f5aa";
    }
        .fi-br-object-subtract:before {
    content: "\f5ab";
    }
        .fi-br-object-union:before {
    content: "\f5ac";
    }
        .fi-br-octagon:before {
    content: "\f5ad";
    }
        .fi-br-oil-can:before {
    content: "\f5ae";
    }
        .fi-br-oil-temp:before {
    content: "\f5af";
    }
        .fi-br-olive-oil:before {
    content: "\f5b0";
    }
        .fi-br-olives:before {
    content: "\f5b1";
    }
        .fi-br-onion:before {
    content: "\f5b2";
    }
        .fi-br-opacity:before {
    content: "\f5b3";
    }
        .fi-br-overline:before {
    content: "\f5b4";
    }
        .fi-br-p:before {
    content: "\f5b5";
    }
        .fi-br-package:before {
    content: "\f5b6";
    }
        .fi-br-page-break:before {
    content: "\f5b7";
    }
        .fi-br-pager:before {
    content: "\f5b8";
    }
        .fi-br-paint-brush:before {
    content: "\f5b9";
    }
        .fi-br-paint-roller:before {
    content: "\f5ba";
    }
        .fi-br-palette:before {
    content: "\f5bb";
    }
        .fi-br-pan:before {
    content: "\f5bc";
    }
        .fi-br-paper-plane-top:before {
    content: "\f5bd";
    }
        .fi-br-paper-plane:before {
    content: "\f5be";
    }
        .fi-br-paperclip-vertical:before {
    content: "\f5bf";
    }
        .fi-br-parking-circle-slash:before {
    content: "\f5c0";
    }
        .fi-br-parking-circle:before {
    content: "\f5c1";
    }
        .fi-br-parking-slash:before {
    content: "\f5c2";
    }
        .fi-br-parking:before {
    content: "\f5c3";
    }
        .fi-br-passport:before {
    content: "\f5c4";
    }
        .fi-br-password:before {
    content: "\f5c5";
    }
        .fi-br-paste:before {
    content: "\f5c6";
    }
        .fi-br-pattern:before {
    content: "\f5c7";
    }
        .fi-br-pause:before {
    content: "\f5c8";
    }
        .fi-br-paw:before {
    content: "\f5c9";
    }
        .fi-br-peach:before {
    content: "\f5ca";
    }
        .fi-br-pen-clip:before {
    content: "\f5cb";
    }
        .fi-br-pen-nib-slash:before {
    content: "\f5cc";
    }
        .fi-br-pencil-ruler:before {
    content: "\f5cd";
    }
        .fi-br-pencil:before {
    content: "\f5ce";
    }
        .fi-br-pennant:before {
    content: "\f5cf";
    }
        .fi-br-people-arrows-left-right:before {
    content: "\f5d0";
    }
        .fi-br-people-carry-box:before {
    content: "\f5d1";
    }
        .fi-br-people-dress:before {
    content: "\f5d2";
    }
        .fi-br-people-pants:before {
    content: "\f5d3";
    }
        .fi-br-people-poll:before {
    content: "\f5d4";
    }
        .fi-br-people:before {
    content: "\f5d5";
    }
        .fi-br-pepper-hot:before {
    content: "\f5d6";
    }
        .fi-br-pepper:before {
    content: "\f5d7";
    }
        .fi-br-percentage:before {
    content: "\f5d8";
    }
        .fi-br-person-praying:before {
    content: "\f5d9";
    }
        .fi-br-person-seat-reclined:before {
    content: "\f5da";
    }
        .fi-br-person-seat:before {
    content: "\f5db";
    }
        .fi-br-person-walking-with-cane:before {
    content: "\f5dc";
    }
        .fi-br-peseta-sign:before {
    content: "\f5dd";
    }
        .fi-br-peso-sign:before {
    content: "\f5de";
    }
        .fi-br-pharmacy:before {
    content: "\f5df";
    }
        .fi-br-phone-call:before {
    content: "\f5e0";
    }
        .fi-br-phone-cross:before {
    content: "\f5e1";
    }
        .fi-br-phone-flip:before {
    content: "\f5e2";
    }
        .fi-br-phone-office:before {
    content: "\f5e3";
    }
        .fi-br-phone-pause:before {
    content: "\f5e4";
    }
        .fi-br-phone-plus:before {
    content: "\f5e5";
    }
        .fi-br-phone-slash:before {
    content: "\f5e6";
    }
        .fi-br-photo-film-music:before {
    content: "\f5e7";
    }
        .fi-br-photo-video:before {
    content: "\f5e8";
    }
        .fi-br-physics:before {
    content: "\f5e9";
    }
        .fi-br-picnic:before {
    content: "\f5ea";
    }
        .fi-br-picpeople-filled:before {
    content: "\f5eb";
    }
        .fi-br-picpeople:before {
    content: "\f5ec";
    }
        .fi-br-picture:before {
    content: "\f5ed";
    }
        .fi-br-pie:before {
    content: "\f5ee";
    }
        .fi-br-piggy-bank:before {
    content: "\f5ef";
    }
        .fi-br-pills:before {
    content: "\f5f0";
    }
        .fi-br-pineapple:before {
    content: "\f5f1";
    }
        .fi-br-ping-pong:before {
    content: "\f5f2";
    }
        .fi-br-pizza-slice:before {
    content: "\f5f3";
    }
        .fi-br-plane-alt:before {
    content: "\f5f4";
    }
        .fi-br-plane-arrival:before {
    content: "\f5f5";
    }
        .fi-br-plane-departure:before {
    content: "\f5f6";
    }
        .fi-br-plane-prop:before {
    content: "\f5f7";
    }
        .fi-br-plane-tail:before {
    content: "\f5f8";
    }
        .fi-br-plane:before {
    content: "\f5f9";
    }
        .fi-br-plate:before {
    content: "\f5fa";
    }
        .fi-br-play-alt:before {
    content: "\f5fb";
    }
        .fi-br-play-pause:before {
    content: "\f5fc";
    }
        .fi-br-play:before {
    content: "\f5fd";
    }
        .fi-br-playing-cards:before {
    content: "\f5fe";
    }
        .fi-br-plus-small:before {
    content: "\f5ff";
    }
        .fi-br-plus:before {
    content: "\f600";
    }
        .fi-br-podium-star:before {
    content: "\f601";
    }
        .fi-br-podium:before {
    content: "\f602";
    }
        .fi-br-poker-chip:before {
    content: "\f603";
    }
        .fi-br-poll-h:before {
    content: "\f604";
    }
        .fi-br-poo:before {
    content: "\f605";
    }
        .fi-br-poop:before {
    content: "\f606";
    }
        .fi-br-popcorn:before {
    content: "\f607";
    }
        .fi-br-portrait:before {
    content: "\f608";
    }
        .fi-br-pot:before {
    content: "\f609";
    }
        .fi-br-pound:before {
    content: "\f60a";
    }
        .fi-br-power:before {
    content: "\f60b";
    }
        .fi-br-prescription-bottle-alt:before {
    content: "\f60c";
    }
        .fi-br-prescription-bottle-pill:before {
    content: "\f60d";
    }
        .fi-br-prescription-bottle:before {
    content: "\f60e";
    }
        .fi-br-prescription:before {
    content: "\f60f";
    }
        .fi-br-presentation:before {
    content: "\f610";
    }
        .fi-br-print-magnifying-glass:before {
    content: "\f611";
    }
        .fi-br-print:before {
    content: "\f612";
    }
        .fi-br-procedures:before {
    content: "\f613";
    }
        .fi-br-projector:before {
    content: "\f614";
    }
        .fi-br-protractor:before {
    content: "\f615";
    }
        .fi-br-pulse:before {
    content: "\f616";
    }
        .fi-br-pump-medical:before {
    content: "\f617";
    }
        .fi-br-pumpkin:before {
    content: "\f618";
    }
        .fi-br-puzzle-piece:before {
    content: "\f619";
    }
        .fi-br-pyramid:before {
    content: "\f61a";
    }
        .fi-br-q:before {
    content: "\f61b";
    }
        .fi-br-qrcode:before {
    content: "\f61c";
    }
        .fi-br-question-square:before {
    content: "\f61d";
    }
        .fi-br-question:before {
    content: "\f61e";
    }
        .fi-br-quote-right:before {
    content: "\f61f";
    }
        .fi-br-r:before {
    content: "\f620";
    }
        .fi-br-racquet:before {
    content: "\f621";
    }
        .fi-br-radiation-alt:before {
    content: "\f622";
    }
        .fi-br-radiation:before {
    content: "\f623";
    }
        .fi-br-radish:before {
    content: "\f624";
    }
        .fi-br-rainbow:before {
    content: "\f625";
    }
        .fi-br-raindrops:before {
    content: "\f626";
    }
        .fi-br-ramp-loading:before {
    content: "\f627";
    }
        .fi-br-rec:before {
    content: "\f628";
    }
        .fi-br-receipt:before {
    content: "\f629";
    }
        .fi-br-record-vinyl:before {
    content: "\f62a";
    }
        .fi-br-rectabgle-vertical:before {
    content: "\f62b";
    }
        .fi-br-rectangle-barcode:before {
    content: "\f62c";
    }
        .fi-br-rectangle-code:before {
    content: "\f62d";
    }
        .fi-br-rectangle-horizontal:before {
    content: "\f62e";
    }
        .fi-br-rectangle-panoramic:before {
    content: "\f62f";
    }
        .fi-br-rectangle-pro:before {
    content: "\f630";
    }
        .fi-br-rectangle-vertical:before {
    content: "\f631";
    }
        .fi-br-rectangle-xmark:before {
    content: "\f632";
    }
        .fi-br-recycle:before {
    content: "\f633";
    }
        .fi-br-redo-alt:before {
    content: "\f634";
    }
        .fi-br-redo:before {
    content: "\f635";
    }
        .fi-br-reflect-horizontal:before {
    content: "\f636";
    }
        .fi-br-reflect-vertical:before {
    content: "\f637";
    }
        .fi-br-reflect:before {
    content: "\f638";
    }
        .fi-br-refresh:before {
    content: "\f639";
    }
        .fi-br-registered:before {
    content: "\f63a";
    }
        .fi-br-remove-folder:before {
    content: "\f63b";
    }
        .fi-br-remove-user:before {
    content: "\f63c";
    }
        .fi-br-replace:before {
    content: "\f63d";
    }
        .fi-br-reply-all:before {
    content: "\f63e";
    }
        .fi-br-republican:before {
    content: "\f63f";
    }
        .fi-br-resize:before {
    content: "\f640";
    }
        .fi-br-resources:before {
    content: "\f641";
    }
        .fi-br-restaurant:before {
    content: "\f642";
    }
        .fi-br-restroom-simple:before {
    content: "\f643";
    }
        .fi-br-rewind:before {
    content: "\f644";
    }
        .fi-br-rhombus:before {
    content: "\f645";
    }
        .fi-br-right:before {
    content: "\f646";
    }
        .fi-br-rings-wedding:before {
    content: "\f647";
    }
        .fi-br-road:before {
    content: "\f648";
    }
        .fi-br-rocket-lunch:before {
    content: "\f649";
    }
        .fi-br-rocket:before {
    content: "\f64a";
    }
        .fi-br-roller-coaster:before {
    content: "\f64b";
    }
        .fi-br-room-service:before {
    content: "\f64c";
    }
        .fi-br-rotate-left:before {
    content: "\f64d";
    }
        .fi-br-rotate-right:before {
    content: "\f64e";
    }
        .fi-br-route-highway:before {
    content: "\f64f";
    }
        .fi-br-route-interstate:before {
    content: "\f650";
    }
        .fi-br-route:before {
    content: "\f651";
    }
        .fi-br-rss:before {
    content: "\f652";
    }
        .fi-br-ruble-sign:before {
    content: "\f653";
    }
        .fi-br-rugby-helmet:before {
    content: "\f654";
    }
        .fi-br-rugby:before {
    content: "\f655";
    }
        .fi-br-ruler-combined:before {
    content: "\f656";
    }
        .fi-br-ruler-horizontal:before {
    content: "\f657";
    }
        .fi-br-ruler-triangle:before {
    content: "\f658";
    }
        .fi-br-ruler-vertical:before {
    content: "\f659";
    }
        .fi-br-running:before {
    content: "\f65a";
    }
        .fi-br-rupee-sign:before {
    content: "\f65b";
    }
        .fi-br-rupiah-sign:before {
    content: "\f65c";
    }
        .fi-br-rv:before {
    content: "\f65d";
    }
        .fi-br-s:before {
    content: "\f65e";
    }
        .fi-br-sack-dollar:before {
    content: "\f65f";
    }
        .fi-br-sack:before {
    content: "\f660";
    }
        .fi-br-sad-cry:before {
    content: "\f661";
    }
        .fi-br-sad-tear:before {
    content: "\f662";
    }
        .fi-br-sad:before {
    content: "\f663";
    }
        .fi-br-salad:before {
    content: "\f664";
    }
        .fi-br-salt-pepper:before {
    content: "\f665";
    }
        .fi-br-sandwich:before {
    content: "\f666";
    }
        .fi-br-sauce:before {
    content: "\f667";
    }
        .fi-br-sausage:before {
    content: "\f668";
    }
        .fi-br-scale:before {
    content: "\f669";
    }
        .fi-br-scalpel-path:before {
    content: "\f66a";
    }
        .fi-br-scalpel:before {
    content: "\f66b";
    }
        .fi-br-school-bus:before {
    content: "\f66c";
    }
        .fi-br-school:before {
    content: "\f66d";
    }
        .fi-br-scissors:before {
    content: "\f66e";
    }
        .fi-br-screen:before {
    content: "\f66f";
    }
        .fi-br-scroll-torah:before {
    content: "\f670";
    }
        .fi-br-seal-exclamation:before {
    content: "\f671";
    }
        .fi-br-search-alt:before {
    content: "\f672";
    }
        .fi-br-search-dollar:before {
    content: "\f673";
    }
        .fi-br-search-heart:before {
    content: "\f674";
    }
        .fi-br-search-location:before {
    content: "\f675";
    }
        .fi-br-search:before {
    content: "\f676";
    }
        .fi-br-seat-airline:before {
    content: "\f677";
    }
        .fi-br-sensor-alert:before {
    content: "\f678";
    }
        .fi-br-sensor-fire:before {
    content: "\f679";
    }
        .fi-br-sensor-on:before {
    content: "\f67a";
    }
        .fi-br-sensor-smoke:before {
    content: "\f67b";
    }
        .fi-br-sensor:before {
    content: "\f67c";
    }
        .fi-br-settings-sliders:before {
    content: "\f67d";
    }
        .fi-br-settings:before {
    content: "\f67e";
    }
        .fi-br-share-square:before {
    content: "\f67f";
    }
        .fi-br-share:before {
    content: "\f680";
    }
        .fi-br-shekel-sign:before {
    content: "\f681";
    }
        .fi-br-shield-check:before {
    content: "\f682";
    }
        .fi-br-shield-exclamation:before {
    content: "\f683";
    }
        .fi-br-shield-interrogation:before {
    content: "\f684";
    }
        .fi-br-shield-plus:before {
    content: "\f685";
    }
        .fi-br-shield-virus:before {
    content: "\f686";
    }
        .fi-br-shield:before {
    content: "\f687";
    }
        .fi-br-ship-side:before {
    content: "\f688";
    }
        .fi-br-ship:before {
    content: "\f689";
    }
        .fi-br-shoe-prints:before {
    content: "\f68a";
    }
        .fi-br-shop-lock:before {
    content: "\f68b";
    }
        .fi-br-shop-slash:before {
    content: "\f68c";
    }
        .fi-br-shop:before {
    content: "\f68d";
    }
        .fi-br-shopping-bag-add:before {
    content: "\f68e";
    }
        .fi-br-shopping-bag:before {
    content: "\f68f";
    }
        .fi-br-shopping-basket:before {
    content: "\f690";
    }
        .fi-br-shopping-cart-add:before {
    content: "\f691";
    }
        .fi-br-shopping-cart-check:before {
    content: "\f692";
    }
        .fi-br-shopping-cart:before {
    content: "\f693";
    }
        .fi-br-shredder:before {
    content: "\f694";
    }
        .fi-br-shrimp:before {
    content: "\f695";
    }
        .fi-br-shuffle:before {
    content: "\f696";
    }
        .fi-br-shuttle-van:before {
    content: "\f697";
    }
        .fi-br-shuttlecock:before {
    content: "\f698";
    }
        .fi-br-sidebar-flip:before {
    content: "\f699";
    }
        .fi-br-sidebar:before {
    content: "\f69a";
    }
        .fi-br-sign-hanging:before {
    content: "\f69b";
    }
        .fi-br-sign-in-alt:before {
    content: "\f69c";
    }
        .fi-br-sign-out-alt:before {
    content: "\f69d";
    }
        .fi-br-signal-alt-1:before {
    content: "\f69e";
    }
        .fi-br-signal-alt-2:before {
    content: "\f69f";
    }
        .fi-br-signal-alt:before {
    content: "\f6a0";
    }
        .fi-br-signal-stream-slash:before {
    content: "\f6a1";
    }
        .fi-br-signal-stream:before {
    content: "\f6a2";
    }
        .fi-br-signature-lock:before {
    content: "\f6a3";
    }
        .fi-br-signature-slash:before {
    content: "\f6a4";
    }
        .fi-br-signature:before {
    content: "\f6a5";
    }
        .fi-br-sitemap:before {
    content: "\f6a6";
    }
        .fi-br-skateboard:before {
    content: "\f6a7";
    }
        .fi-br-skating:before {
    content: "\f6a8";
    }
        .fi-br-skeleton:before {
    content: "\f6a9";
    }
        .fi-br-skewer:before {
    content: "\f6aa";
    }
        .fi-br-ski-jump:before {
    content: "\f6ab";
    }
        .fi-br-ski-lift:before {
    content: "\f6ac";
    }
        .fi-br-skiing-nordic:before {
    content: "\f6ad";
    }
        .fi-br-skiing:before {
    content: "\f6ae";
    }
        .fi-br-slash:before {
    content: "\f6af";
    }
        .fi-br-sledding:before {
    content: "\f6b0";
    }
        .fi-br-sleigh:before {
    content: "\f6b1";
    }
        .fi-br-smartphone:before {
    content: "\f6b2";
    }
        .fi-br-smile-beam:before {
    content: "\f6b3";
    }
        .fi-br-smile-plus:before {
    content: "\f6b4";
    }
        .fi-br-smiley-comment-alt:before {
    content: "\f6b5";
    }
        .fi-br-smog:before {
    content: "\f6b6";
    }
        .fi-br-smoke:before {
    content: "\f6b7";
    }
        .fi-br-smoking-ban:before {
    content: "\f6b8";
    }
        .fi-br-smoking:before {
    content: "\f6b9";
    }
        .fi-br-snooze:before {
    content: "\f6ba";
    }
        .fi-br-snow-blowing:before {
    content: "\f6bb";
    }
        .fi-br-snowboarding:before {
    content: "\f6bc";
    }
        .fi-br-snowflake:before {
    content: "\f6bd";
    }
        .fi-br-snowflakes:before {
    content: "\f6be";
    }
        .fi-br-snowmobile:before {
    content: "\f6bf";
    }
        .fi-br-snowplow:before {
    content: "\f6c0";
    }
        .fi-br-soap:before {
    content: "\f6c1";
    }
        .fi-br-social-network:before {
    content: "\f6c2";
    }
        .fi-br-sort-alpha-down-alt:before {
    content: "\f6c3";
    }
        .fi-br-sort-alpha-down:before {
    content: "\f6c4";
    }
        .fi-br-sort-alpha-up-alt:before {
    content: "\f6c5";
    }
        .fi-br-sort-alpha-up:before {
    content: "\f6c6";
    }
        .fi-br-sort-alt:before {
    content: "\f6c7";
    }
        .fi-br-sort-amount-down-alt:before {
    content: "\f6c8";
    }
        .fi-br-sort-amount-down:before {
    content: "\f6c9";
    }
        .fi-br-sort-amount-up-alt:before {
    content: "\f6ca";
    }
        .fi-br-sort-amount-up:before {
    content: "\f6cb";
    }
        .fi-br-sort-down:before {
    content: "\f6cc";
    }
        .fi-br-sort-numeric-down-alt:before {
    content: "\f6cd";
    }
        .fi-br-sort-numeric-down:before {
    content: "\f6ce";
    }
        .fi-br-sort-shapes-down:before {
    content: "\f6cf";
    }
        .fi-br-sort-shapes-up:before {
    content: "\f6d0";
    }
        .fi-br-sort-size-down:before {
    content: "\f6d1";
    }
        .fi-br-sort-size-up:before {
    content: "\f6d2";
    }
        .fi-br-sort:before {
    content: "\f6d3";
    }
        .fi-br-soup:before {
    content: "\f6d4";
    }
        .fi-br-spa:before {
    content: "\f6d5";
    }
        .fi-br-space-shuttle:before {
    content: "\f6d6";
    }
        .fi-br-spade:before {
    content: "\f6d7";
    }
        .fi-br-spaghetti-monster-flying:before {
    content: "\f6d8";
    }
        .fi-br-sparkles:before {
    content: "\f6d9";
    }
        .fi-br-speaker:before {
    content: "\f6da";
    }
        .fi-br-sphere:before {
    content: "\f6db";
    }
        .fi-br-spinner:before {
    content: "\f6dc";
    }
        .fi-br-split:before {
    content: "\f6dd";
    }
        .fi-br-spoon:before {
    content: "\f6de";
    }
        .fi-br-square-0:before {
    content: "\f6df";
    }
        .fi-br-square-1:before {
    content: "\f6e0";
    }
        .fi-br-square-2:before {
    content: "\f6e1";
    }
        .fi-br-square-3:before {
    content: "\f6e2";
    }
        .fi-br-square-4:before {
    content: "\f6e3";
    }
        .fi-br-square-5:before {
    content: "\f6e4";
    }
        .fi-br-square-6:before {
    content: "\f6e5";
    }
        .fi-br-square-7:before {
    content: "\f6e6";
    }
        .fi-br-square-8:before {
    content: "\f6e7";
    }
        .fi-br-square-9:before {
    content: "\f6e8";
    }
        .fi-br-square-bolt:before {
    content: "\f6e9";
    }
        .fi-br-square-code:before {
    content: "\f6ea";
    }
        .fi-br-square-heart:before {
    content: "\f6eb";
    }
        .fi-br-square-info:before {
    content: "\f6ec";
    }
        .fi-br-square-kanban:before {
    content: "\f6ed";
    }
        .fi-br-square-minus:before {
    content: "\f6ee";
    }
        .fi-br-square-phone-hangup:before {
    content: "\f6ef";
    }
        .fi-br-square-plus:before {
    content: "\f6f0";
    }
        .fi-br-square-poll-horizontal:before {
    content: "\f6f1";
    }
        .fi-br-square-poll-vertical:before {
    content: "\f6f2";
    }
        .fi-br-square-quote:before {
    content: "\f6f3";
    }
        .fi-br-square-root:before {
    content: "\f6f4";
    }
        .fi-br-square-small:before {
    content: "\f6f5";
    }
        .fi-br-square-star:before {
    content: "\f6f6";
    }
        .fi-br-square-terminal:before {
    content: "\f6f7";
    }
        .fi-br-square:before {
    content: "\f6f8";
    }
        .fi-br-squircle:before {
    content: "\f6f9";
    }
        .fi-br-stamp:before {
    content: "\f6fa";
    }
        .fi-br-star-comment-alt:before {
    content: "\f6fb";
    }
        .fi-br-star-octogram:before {
    content: "\f6fc";
    }
        .fi-br-star:before {
    content: "\f6fd";
    }
        .fi-br-starfighter:before {
    content: "\f6fe";
    }
        .fi-br-stars:before {
    content: "\f6ff";
    }
        .fi-br-stats:before {
    content: "\f700";
    }
        .fi-br-steak:before {
    content: "\f701";
    }
        .fi-br-steering-wheel:before {
    content: "\f702";
    }
        .fi-br-sterling-sign:before {
    content: "\f703";
    }
        .fi-br-stethoscope:before {
    content: "\f704";
    }
        .fi-br-sticker:before {
    content: "\f705";
    }
        .fi-br-stomach:before {
    content: "\f706";
    }
        .fi-br-stop:before {
    content: "\f707";
    }
        .fi-br-stopwatch:before {
    content: "\f708";
    }
        .fi-br-store-alt:before {
    content: "\f709";
    }
        .fi-br-store-lock:before {
    content: "\f70a";
    }
        .fi-br-store-slash:before {
    content: "\f70b";
    }
        .fi-br-strawberry:before {
    content: "\f70c";
    }
        .fi-br-street-view:before {
    content: "\f70d";
    }
        .fi-br-stretcher:before {
    content: "\f70e";
    }
        .fi-br-subtitles:before {
    content: "\f70f";
    }
        .fi-br-subway:before {
    content: "\f710";
    }
        .fi-br-suitcase-alt:before {
    content: "\f711";
    }
        .fi-br-summer:before {
    content: "\f712";
    }
        .fi-br-sun:before {
    content: "\f713";
    }
        .fi-br-sunrise-alt:before {
    content: "\f714";
    }
        .fi-br-sunrise:before {
    content: "\f715";
    }
        .fi-br-sunset:before {
    content: "\f716";
    }
        .fi-br-surfing:before {
    content: "\f717";
    }
        .fi-br-surprise:before {
    content: "\f718";
    }
        .fi-br-sushi:before {
    content: "\f719";
    }
        .fi-br-swimmer:before {
    content: "\f71a";
    }
        .fi-br-sword:before {
    content: "\f71b";
    }
        .fi-br-symbol:before {
    content: "\f71c";
    }
        .fi-br-symbols:before {
    content: "\f71d";
    }
        .fi-br-syringe:before {
    content: "\f71e";
    }
        .fi-br-t:before {
    content: "\f71f";
    }
        .fi-br-table-columns:before {
    content: "\f720";
    }
        .fi-br-table-layout:before {
    content: "\f721";
    }
        .fi-br-table-picnic:before {
    content: "\f722";
    }
        .fi-br-table-pivot:before {
    content: "\f723";
    }
        .fi-br-table-rows:before {
    content: "\f724";
    }
        .fi-br-table-tree:before {
    content: "\f725";
    }
        .fi-br-tablet:before {
    content: "\f726";
    }
        .fi-br-tachometer-alt-average:before {
    content: "\f727";
    }
        .fi-br-tachometer-alt-fastest:before {
    content: "\f728";
    }
        .fi-br-tachometer-alt-slow:before {
    content: "\f729";
    }
        .fi-br-tachometer-alt-slowest:before {
    content: "\f72a";
    }
        .fi-br-tachometer-average:before {
    content: "\f72b";
    }
        .fi-br-tachometer-fast:before {
    content: "\f72c";
    }
        .fi-br-tachometer-fastest:before {
    content: "\f72d";
    }
        .fi-br-tachometer-slow:before {
    content: "\f72e";
    }
        .fi-br-tachometer-slowest:before {
    content: "\f72f";
    }
        .fi-br-tachometer:before {
    content: "\f730";
    }
        .fi-br-taco:before {
    content: "\f731";
    }
        .fi-br-tags:before {
    content: "\f732";
    }
        .fi-br-tally-1:before {
    content: "\f733";
    }
        .fi-br-tally-2:before {
    content: "\f734";
    }
        .fi-br-tally-3:before {
    content: "\f735";
    }
        .fi-br-tally-4:before {
    content: "\f736";
    }
        .fi-br-tally:before {
    content: "\f737";
    }
        .fi-br-target:before {
    content: "\f738";
    }
        .fi-br-taxi-bus:before {
    content: "\f739";
    }
        .fi-br-taxi:before {
    content: "\f73a";
    }
        .fi-br-teeth-open:before {
    content: "\f73b";
    }
        .fi-br-temperature-down:before {
    content: "\f73c";
    }
        .fi-br-temperature-frigid:before {
    content: "\f73d";
    }
        .fi-br-temperature-high:before {
    content: "\f73e";
    }
        .fi-br-temperature-low:before {
    content: "\f73f";
    }
        .fi-br-temperature-up:before {
    content: "\f740";
    }
        .fi-br-template-alt:before {
    content: "\f741";
    }
        .fi-br-template:before {
    content: "\f742";
    }
        .fi-br-tenge:before {
    content: "\f743";
    }
        .fi-br-tennis:before {
    content: "\f744";
    }
        .fi-br-terminal:before {
    content: "\f745";
    }
        .fi-br-terrace:before {
    content: "\f746";
    }
        .fi-br-test-tube:before {
    content: "\f747";
    }
        .fi-br-test:before {
    content: "\f748";
    }
        .fi-br-text-check:before {
    content: "\f749";
    }
        .fi-br-text-height:before {
    content: "\f74a";
    }
        .fi-br-text-shadow:before {
    content: "\f74b";
    }
        .fi-br-text-size:before {
    content: "\f74c";
    }
        .fi-br-text-slash:before {
    content: "\f74d";
    }
        .fi-br-text-width:before {
    content: "\f74e";
    }
        .fi-br-text:before {
    content: "\f74f";
    }
        .fi-br-thermometer-half:before {
    content: "\f750";
    }
        .fi-br-thumbtack:before {
    content: "\f751";
    }
        .fi-br-thunderstorm-moon:before {
    content: "\f752";
    }
        .fi-br-thunderstorm-sun:before {
    content: "\f753";
    }
        .fi-br-thunderstorm:before {
    content: "\f754";
    }
        .fi-br-ticket-airline:before {
    content: "\f755";
    }
        .fi-br-ticket-alt:before {
    content: "\f756";
    }
        .fi-br-ticket:before {
    content: "\f757";
    }
        .fi-br-tickets-airline:before {
    content: "\f758";
    }
        .fi-br-time-add:before {
    content: "\f759";
    }
        .fi-br-time-check:before {
    content: "\f75a";
    }
        .fi-br-time-delete:before {
    content: "\f75b";
    }
        .fi-br-time-fast:before {
    content: "\f75c";
    }
        .fi-br-time-forward-sixty:before {
    content: "\f75d";
    }
        .fi-br-time-forward-ten:before {
    content: "\f75e";
    }
        .fi-br-time-forward:before {
    content: "\f75f";
    }
        .fi-br-time-half-past:before {
    content: "\f760";
    }
        .fi-br-time-oclock:before {
    content: "\f761";
    }
        .fi-br-time-past:before {
    content: "\f762";
    }
        .fi-br-time-quarter-past:before {
    content: "\f763";
    }
        .fi-br-time-quarter-to:before {
    content: "\f764";
    }
        .fi-br-time-twenty-four:before {
    content: "\f765";
    }
        .fi-br-tire-flat:before {
    content: "\f766";
    }
        .fi-br-tire-pressure-warning:before {
    content: "\f767";
    }
        .fi-br-tire-rugged:before {
    content: "\f768";
    }
        .fi-br-tire:before {
    content: "\f769";
    }
        .fi-br-tired:before {
    content: "\f76a";
    }
        .fi-br-toilet-paper-blank:before {
    content: "\f76b";
    }
        .fi-br-tomato:before {
    content: "\f76c";
    }
        .fi-br-tool-box:before {
    content: "\f76d";
    }
        .fi-br-tool-crop:before {
    content: "\f76e";
    }
        .fi-br-tool-marquee:before {
    content: "\f76f";
    }
        .fi-br-tooth:before {
    content: "\f770";
    }
        .fi-br-toothbrush:before {
    content: "\f771";
    }
        .fi-br-tornado:before {
    content: "\f772";
    }
        .fi-br-tower-control:before {
    content: "\f773";
    }
        .fi-br-tractor:before {
    content: "\f774";
    }
        .fi-br-trademark:before {
    content: "\f775";
    }
        .fi-br-traffic-light-go:before {
    content: "\f776";
    }
        .fi-br-traffic-light-slow:before {
    content: "\f777";
    }
        .fi-br-traffic-light-stop:before {
    content: "\f778";
    }
        .fi-br-traffic-light:before {
    content: "\f779";
    }
        .fi-br-trailer:before {
    content: "\f77a";
    }
        .fi-br-train-side:before {
    content: "\f77b";
    }
        .fi-br-train-subway-tunnel:before {
    content: "\f77c";
    }
        .fi-br-train-tram:before {
    content: "\f77d";
    }
        .fi-br-train:before {
    content: "\f77e";
    }
        .fi-br-tram:before {
    content: "\f77f";
    }
        .fi-br-transform:before {
    content: "\f780";
    }
        .fi-br-trash:before {
    content: "\f781";
    }
        .fi-br-treasure-chest:before {
    content: "\f782";
    }
        .fi-br-treatment:before {
    content: "\f783";
    }
        .fi-br-tree-christmas:before {
    content: "\f784";
    }
        .fi-br-tree-deciduous:before {
    content: "\f785";
    }
        .fi-br-tree:before {
    content: "\f786";
    }
        .fi-br-triangle-warning:before {
    content: "\f787";
    }
        .fi-br-triangle:before {
    content: "\f788";
    }
        .fi-br-trophy-star:before {
    content: "\f789";
    }
        .fi-br-trophy:before {
    content: "\f78a";
    }
        .fi-br-truck-container:before {
    content: "\f78b";
    }
        .fi-br-truck-couch:before {
    content: "\f78c";
    }
        .fi-br-truck-loading:before {
    content: "\f78d";
    }
        .fi-br-truck-monster:before {
    content: "\f78e";
    }
        .fi-br-truck-moving:before {
    content: "\f78f";
    }
        .fi-br-truck-pickup:before {
    content: "\f790";
    }
        .fi-br-truck-plow:before {
    content: "\f791";
    }
        .fi-br-truck-ramp:before {
    content: "\f792";
    }
        .fi-br-truck-side:before {
    content: "\f793";
    }
        .fi-br-truck-tow:before {
    content: "\f794";
    }
        .fi-br-tty:before {
    content: "\f795";
    }
        .fi-br-tubes:before {
    content: "\f796";
    }
        .fi-br-tugrik-sign:before {
    content: "\f797";
    }
        .fi-br-turkey:before {
    content: "\f798";
    }
        .fi-br-tv-music:before {
    content: "\f799";
    }
        .fi-br-typewriter:before {
    content: "\f79a";
    }
        .fi-br-u:before {
    content: "\f79b";
    }
        .fi-br-umbrella-beach:before {
    content: "\f79c";
    }
        .fi-br-umbrella:before {
    content: "\f79d";
    }
        .fi-br-underline:before {
    content: "\f79e";
    }
        .fi-br-undo-alt:before {
    content: "\f79f";
    }
        .fi-br-undo:before {
    content: "\f7a0";
    }
        .fi-br-universal-access:before {
    content: "\f7a1";
    }
        .fi-br-unlock:before {
    content: "\f7a2";
    }
        .fi-br-up-left:before {
    content: "\f7a3";
    }
        .fi-br-up-right:before {
    content: "\f7a4";
    }
        .fi-br-up:before {
    content: "\f7a5";
    }
        .fi-br-upload:before {
    content: "\f7a6";
    }
        .fi-br-usb-pendrive:before {
    content: "\f7a7";
    }
        .fi-br-usd-circle:before {
    content: "\f7a8";
    }
        .fi-br-usd-square:before {
    content: "\f7a9";
    }
        .fi-br-user-add:before {
    content: "\f7aa";
    }
        .fi-br-user-md-chat:before {
    content: "\f7ab";
    }
        .fi-br-user-md:before {
    content: "\f7ac";
    }
        .fi-br-user-nurse:before {
    content: "\f7ad";
    }
        .fi-br-user-pilot-tie:before {
    content: "\f7ae";
    }
        .fi-br-user-pilot:before {
    content: "\f7af";
    }
        .fi-br-user-time:before {
    content: "\f7b0";
    }
        .fi-br-user:before {
    content: "\f7b1";
    }
        .fi-br-users-alt:before {
    content: "\f7b2";
    }
        .fi-br-users-medical:before {
    content: "\f7b3";
    }
        .fi-br-users:before {
    content: "\f7b4";
    }
        .fi-br-utensils:before {
    content: "\f7b5";
    }
        .fi-br-v:before {
    content: "\f7b6";
    }
        .fi-br-vault:before {
    content: "\f7b7";
    }
        .fi-br-vector-alt:before {
    content: "\f7b8";
    }
        .fi-br-vector:before {
    content: "\f7b9";
    }
        .fi-br-vest-patches:before {
    content: "\f7ba";
    }
        .fi-br-vest:before {
    content: "\f7bb";
    }
        .fi-br-video-arrow-down-left:before {
    content: "\f7bc";
    }
        .fi-br-video-arrow-up-right:before {
    content: "\f7bd";
    }
        .fi-br-video-camera-alt:before {
    content: "\f7be";
    }
        .fi-br-video-camera:before {
    content: "\f7bf";
    }
        .fi-br-video-plus:before {
    content: "\f7c0";
    }
        .fi-br-video-slash:before {
    content: "\f7c1";
    }
        .fi-br-virus-slash:before {
    content: "\f7c2";
    }
        .fi-br-virus:before {
    content: "\f7c3";
    }
        .fi-br-viruses:before {
    content: "\f7c4";
    }
        .fi-br-voicemail:before {
    content: "\f7c5";
    }
        .fi-br-volcano:before {
    content: "\f7c6";
    }
        .fi-br-volleyball:before {
    content: "\f7c7";
    }
        .fi-br-volume:before {
    content: "\f7c8";
    }
        .fi-br-vote-nay:before {
    content: "\f7c9";
    }
        .fi-br-vote-yea:before {
    content: "\f7ca";
    }
        .fi-br-w:before {
    content: "\f7cb";
    }
        .fi-br-wagon-covered:before {
    content: "\f7cc";
    }
        .fi-br-walker:before {
    content: "\f7cd";
    }
        .fi-br-walkie-talkie:before {
    content: "\f7ce";
    }
        .fi-br-wallet:before {
    content: "\f7cf";
    }
        .fi-br-warehouse-alt:before {
    content: "\f7d0";
    }
        .fi-br-watch-fitness:before {
    content: "\f7d1";
    }
        .fi-br-watch-smart:before {
    content: "\f7d2";
    }
        .fi-br-watch:before {
    content: "\f7d3";
    }
        .fi-br-water-bottle:before {
    content: "\f7d4";
    }
        .fi-br-water-ladder:before {
    content: "\f7d5";
    }
        .fi-br-water-lower:before {
    content: "\f7d6";
    }
        .fi-br-water-rise:before {
    content: "\f7d7";
    }
        .fi-br-water:before {
    content: "\f7d8";
    }
        .fi-br-watermelon:before {
    content: "\f7d9";
    }
        .fi-br-wheat:before {
    content: "\f7da";
    }
        .fi-br-wheelchair-move:before {
    content: "\f7db";
    }
        .fi-br-wheelchair:before {
    content: "\f7dc";
    }
        .fi-br-whistle:before {
    content: "\f7dd";
    }
        .fi-br-wifi-1:before {
    content: "\f7de";
    }
        .fi-br-wifi-2:before {
    content: "\f7df";
    }
        .fi-br-wifi-alt:before {
    content: "\f7e0";
    }
        .fi-br-wifi-exclamation:before {
    content: "\f7e1";
    }
        .fi-br-wifi-slash:before {
    content: "\f7e2";
    }
        .fi-br-wifi:before {
    content: "\f7e3";
    }
        .fi-br-wind-warning:before {
    content: "\f7e4";
    }
        .fi-br-wind:before {
    content: "\f7e5";
    }
        .fi-br-window-alt:before {
    content: "\f7e6";
    }
        .fi-br-window-maximize:before {
    content: "\f7e7";
    }
        .fi-br-window-minimize:before {
    content: "\f7e8";
    }
        .fi-br-window-restore:before {
    content: "\f7e9";
    }
        .fi-br-windsock:before {
    content: "\f7ea";
    }
        .fi-br-wine-glass-crack:before {
    content: "\f7eb";
    }
        .fi-br-wink:before {
    content: "\f7ec";
    }
        .fi-br-woman-head:before {
    content: "\f7ed";
    }
        .fi-br-won-sign:before {
    content: "\f7ee";
    }
        .fi-br-world:before {
    content: "\f7ef";
    }
        .fi-br-wrench-simple:before {
    content: "\f7f0";
    }
        .fi-br-x-ray:before {
    content: "\f7f1";
    }
        .fi-br-x:before {
    content: "\f7f2";
    }
        .fi-br-y:before {
    content: "\f7f3";
    }
        .fi-br-yen:before {
    content: "\f7f4";
    }
        .fi-br-yin-yang:before {
    content: "\f7f5";
    }
        .fi-br-z:before {
    content: "\f7f6";
    }
        .fi-br-zoom-in:before {
    content: "\f7f7";
    }
        .fi-br-zoom-out:before {
    content: "\f7f8";
    }
